import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import Footer from "components/Footer";

import { live } from "./content";

import ScrollUpButton from "react-scroll-up-button";

function News({ match }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });
  const News = match.params.id;
  const n = live.find((x) => x.link === News);
  return (
    <div id="outer-container">
      <Navbar />
      <ScrollUpButton />
      <section id="" />
      <ProfilePageHeader title={n.description} />

      <Footer />
    </div>
  );
}

export default News;
