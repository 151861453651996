import React, { useEffect } from "react";
import anime from "animejs";

function AnimeBackground() {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".st0",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 2000,
      delay: function (el, i) {
        return i * 10;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={{
        marginTop: "-2rem",
        position: "absolute",
        top: 0,
        width: "100%",
        zIndex: -100,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1280 720"
        style={{
          fill: "rgba(255,255,255,0.08)",
          stroke: "#40b5cb66",
          strokeWidth: 1.5,
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="st0"
          d="M1269.1,691.1c-3.9,0.8-7.7,2.3-11.6,2.3c-180.5,0.1-360.9,0.1-541.4,0.1c-227.1,0-454.2,0.1-681.2,0.2
	c-2.8,0-5.6,0.3-8.3-0.1c-3-0.4-6-1.5-8.9-2.2c0.7-1.5,1.2-3.1,1.8-4.6c0.5-1.3,1.1-2.5,2-3.5c0.6-0.7,1.2-1.4,1.8-2.1
	c8.5-9.9,17-19.8,25.6-29.7c1.8-2.1,3.6-4.3,5.5-6.4c1.5-1.8,3.1-3.6,4.6-5.3c5.8-6.7,11.6-13.4,17.3-20.2
	c4.4-5.1,8.8-10.2,13.2-15.3c1.8-2,3.5-4.1,5.3-6.1c4.9-5.7,9.7-11.3,14.6-17c0.9-1,1.7-2,2.6-3.1c0,0,0,0,0,0
	c0.3-0.4,0.7-0.8,1-1.2c2.5-2.9,4.9-5.7,7.5-8.6l0,0c0,0,0,0,0,0c7.9-9.2,15.9-18.4,23.8-27.7c6-7,12.1-14,18.1-21.1
	c0.3-0.4,0.7-0.8,1-1.2c0.8-0.9,1.5-1.8,2.3-2.7c2.8-3.2,5.5-6.4,8.3-9.7c1.9-2.3,3.9-4.5,5.9-6.8c3.9-4.5,7.7-9,11.6-13.5
	c3.7-4.3,7.4-8.6,11.1-12.9c4.7-5.5,9.5-11,14.2-16.5c2.2-2.6,4.5-5.2,6.7-7.8c6.1-7.1,12.2-14.2,18.3-21.3c1.5-1.8,3.1-3.6,4.7-5.4
	c9.2-10.7,18.3-21.3,27.5-32c0.4-0.5,0.9-1,1.3-1.5c4.1-4.8,8.2-9.5,12.3-14.3c0.8-0.9,1.6-1.9,2.4-2.8c3.2-3.7,6.3-7.4,9.5-11.1
	c2.1-2.4,4.2-4.9,6.3-7.3c4.4-5.1,8.7-10.1,13.1-15.2c1.7-2,3.5-4.1,5.2-6.1c9.1-10.6,18.2-21.2,27.3-31.7c0,0,0,0,0,0l7.6-8.8
	c0.3-0.3,0.5-0.6,0.8-0.9c5-5.7,6-5.4,11.4,0.9c4.6,5.3,9.2,10.7,13.8,16c2,2.4,4.1,4.7,6.1,7.1c5,5.8,10.1,11.7,15.1,17.6
	c1.9,2.2,3.8,4.5,5.8,6.7c11.2,13,22.5,26.2,34,39.5c3.1-3.6,6.2-7.3,9.4-10.9c0,0,0,0,0,0c3.3-3.8,6.5-7.6,9.7-11.3
	c4.7-5.5,9.4-10.9,14.1-16.4c2.1-2.5,4.2-4.9,6.3-7.4c3.2-3.7,6.3-7.4,9.5-11c1.6-1.9,3.2-3.7,4.8-5.6c0.8-1,1.7-1.9,2.5-2.9l0,0
	c2.2-2.5,4.3-5,6.5-7.5c0.5-0.6,1.1-1.2,1.6-1.9c1.3-1.5,2.6-3,3.8-4.5c0.4-0.4,0.8-0.9,1.1-1.3c1-1.2,2-2.4,3-3.5
	c1.4-1.6,2.8-3.3,4.2-4.9c4-4.6,8-9.3,12-13.9c1.5-1.8,3-3.5,4.5-5.3c3.6-4.2,7.2-8.4,10.9-12.6c1.8-2.1,3.6-4.2,5.4-6.3
	c8.8-10.3,17.7-20.5,26.5-30.8c2.1-2.5,4.3-5,6.4-7.4c1.8-2.1,3.6-4.2,5.5-6.4c5.9-6.9,11.9-13.8,17.9-20.7c0,0,0.1-0.1,0.1-0.1
	c2.2-2.6,4.4-5.1,6.6-7.7c0.9-1.1,1.9-2.2,2.8-3.3c4.6-5.3,9.2-10.7,13.8-16c0.9-1,1.8-2.1,2.7-3.1c0.7-0.8,1.3-1.5,1.8-2
	c4-4.4,5.3-4.2,9.4,0.3c0.4,0.5,0.9,1,1.4,1.6c0.9,1.1,1.9,2.2,2.8,3.3c9.8,11.4,19.7,22.9,29.5,34.3c1.7,2,3.5,4,5.2,6
	c1.2,1.4,2.3,2.7,3.5,4.1c1.7,1.9,3.3,3.9,5,5.8c0.8,1,1.7,1.9,2.5,2.9c2.4,2.8,4.9,5.7,7.4,8.6c0.5,0.6,1,1.2,1.5,1.8
	c1,1.2,2,2.3,3,3.5c7.4,8.7,14.9,17.3,22.4,26c2.2,2.5,4.3,5,6.5,7.5c21.8,25.3,43.6,50.6,65.3,75.9c1.8,2.1,3.6,4.2,5.5,6.4
	c3.8,4.4,7.6,8.8,11.4,13.2c1.5,1.7,2.9,3.4,4.4,5.1c1.2,1.4,2.5,2.9,3.7,4.3c1.1,1.2,2.1,2.5,3.2,3.7c5.4,6.2,10.7,12.5,16.1,18.7
	c2.1,2.4,4.2,4.9,6.3,7.3c2.7,3.2,5.5,6.4,8.2,9.5c2.1,2.5,4.3,5,6.4,7.4c1.9,2.3,3.9,4.5,5.8,6.8c1.3,1.6,2.8,3.1,4.5,5
	c1.8-2,3.6-3.7,5.1-5.5c2.2-2.6,4.4-5.1,6.6-7.7c3-3.5,6-7,9-10.4c2.6-3,5.1-6,7.7-9c2.9-3.4,5.8-6.8,8.7-10.2c1-1.2,2-2.3,3-3.5
	c1.5-1.7,3-3.5,4.5-5.2c1.6-1.9,3.2-3.7,4.8-5.6c4.6-5.4,9.3-10.8,14-16.3c2.3-2.7,4.5-5.3,6.8-7.9c1.2-1.4,2.4-2.8,3.5-4.1
	c0.8-0.9,1.4-1.6,2-2.3c3.6-3.8,5.1-3.8,8.6,0c0.6,0.6,1.2,1.4,1.9,2.2c1.2,1.4,2.4,2.8,3.6,4.2c2.8,3.2,5.6,6.5,8.3,9.7
	c1.8,2,3.5,4.1,5.3,6.1c8.8,10.2,17.6,20.4,26.3,30.6c3.1,3.6,6.2,7.2,9.4,10.9c7.6,8.8,15.2,17.6,22.7,26.4
	c1.6,1.9,3.2,3.7,4.8,5.6c20.8,24.2,41.6,48.3,62.4,72.5c1.9,2.2,3.8,4.5,5.8,6.7c5.1,6,10.3,11.9,15.4,17.9
	c1.9,2.2,3.8,4.4,5.6,6.6c1.2,1.4,2.5,2.9,3.7,4.3c1.7,1.9,3.3,3.9,5,5.8c3.4,3.9,6.8,7.9,10.1,11.8c1.3,1.5,2.6,3,3.9,4.5
	c2.4,2.8,4.9,5.6,7.3,8.4c2.5,2.9,4.9,5.7,7.4,8.6c7.1,8.2,14.2,16.5,21.3,24.7c2,2.3,4,4.7,6,7c5.5,6.4,11.1,12.9,16.6,19.3
	c2.3,2.7,4.6,5.3,6.9,8c3.4,3.9,6.8,7.8,10.1,11.8c4.2,4.9,8.4,9.8,12.6,14.7c5.3,6.2,10.7,12.4,16,18.6c0.6,0.7,1.2,1.5,1.7,2.4
	c1.3,2,2.3,4.3,3.4,6.5C1268.6,690.2,1268.9,690.6,1269.1,691.1z"
        />
        <g>
          <g>
            <path
              class="st0"
              d="M-631.3,2552.8c0.7-2.1,0.3-4.7-1.6-6.3c-3.4-2.8-7.1-3.1-10.8-0.8c-0.8-0.4-1.4-0.8-2-1.2
			c-1.9-1.3-4.1-1.4-6-0.5c-2.1,1-3.8,2.6-4.8,4.8c-0.6,1.3-0.6,1.3,0.9,2.4c0.5-0.3,0.8-0.6,1.2-0.9c1.3-1.1,2.9-1.6,4.6-1.6
			c1.9-0.1,2.7,0.3,3.2,2.2c0.4,1.6,0.6,3.2,0.6,4.8c0.1,3.7,0.1,7.3,0.2,11c0.1,2.5,0.2,5.1,0.4,7.6c0.1,1.5,1.4,2.5,3,2.3
			c1.3-0.2,3-1.9,2.9-3c-0.1-1-0.4-1.9-0.4-2.9c-0.3-3.8-0.6-7.7-0.8-11.5c-0.1-2.4-0.1-4.8-0.1-7.3c0-2.3,1.3-3.1,3.4-2.1
			c1,0.4,1.9,1.2,2.7,1.9C-633.8,2552.8-632.8,2553.3-631.3,2552.8z"
            />
            <path
              class="st0"
              d="M-639.5,2575.8c-0.5,0.6-0.9,1.1-1.3,1.5c-1.6,1.8-2.6,2.1-4.9,1.3c-1.3-0.5-2.1-0.1-3,0.8
			c-2.9,2.8-3.6,6.4-3.4,10.1c0.2,3.9,1.3,7.6,2.6,11.3c1.3,3.7,2.1,7.4,2.4,11.3c0.3,3.2,1.6,4.3,4.9,4.3c0.5,0,0.9,0,1.4,0
			c2.3-0.2,3-0.8,3.6-3c0.8-3,1.7-6,2.4-9.1c0.9-4.4,1.7-8.9,2.2-13.3C-631.8,2584.5-634.3,2579.6-639.5,2575.8z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-214.5,2573.9c-0.7-1.2-1.5-2.3-2.3-3.4c-1-1.4-1.2-2.7-0.4-4.3c0.6-1.2,1.1-2.4,0.6-3.9
			c-0.3,0-0.7-0.2-1-0.2c-1.8,0-3.5-0.1-5.3-0.1c-2.5,0-4.2-1.2-5.1-3.5c-0.3-0.8-0.6-1.6-0.8-2.5c-0.7-2.6-1.2-5.3-1.9-7.9
			c-0.3-1.3-1.2-1.7-2.6-1.4c-1.1,0.2-1.4,0.6-1.3,2.1c0.1,0.6,0.2,1.3,0.3,1.9c0.4,3.3,1.1,6.5,1.3,9.8c0.2,3.8,1.3,7.2,3.1,10.4
			c1.1,1.9,1.3,3.4,0.2,5.2c-1,1.6-1.6,3.6-3.5,4.3c-2.9,1.2-5.5,2.9-7.9,4.7c-2.8,2.1-5.8,4-8.3,6.5c-4.2,4-8.1,8.4-12.2,12.6
			c-2.6,2.7-5.1,5.5-6.9,8.7c-0.3,0.6-0.6,1.3-0.8,2c-0.2,0.8,0,1.7,0.9,2c0.9,0.3,1.6,0,2.2-0.9c3.1-4.4,7.1-7.7,12-9.6
			c5.2-2.1,10.6-3.5,15.9-5.2c0.3-0.1,0.7,0.1,1.3,0.2c-0.9,0.6-1.5,0.9-2,1.4c-1.1,1-2.3,1.6-3.8,2c-4.5,1.1-9,2.1-13.1,4.3
			c-2.9,1.6-5.3,3.7-7.2,6.3c-0.6,0.8-0.9,1.7-1,2.7c0,0.4,0,0.8,0.1,1.2c3.1,0.9,3.7,0.9,4.9-1.2c0.1-0.2,0.3-0.5,0.4-0.8
			c0-0.1,0.1-0.1,0.1-0.2c1.6-3.6,4.7-5.3,8.1-6.6c1.1-0.4,2.3-0.4,3.4,0.1c0.1,0.4,0.2,0.8,0.3,1.1c0.5,2.1,1.1,4.1,3.9,4.2
			c1.3,0.1,1.3,1.2,1.3,2c0,0,0,0.1,0,0.1c0.1,1,0,1.9-1.3,2.2c-0.9,0.2-1.9,0.4-2.7,0.8c-1,0.5-1.9,1.2-1.5,2.5
			c0,0.1,0.1,0.2,0.1,0.3c0.2,0.1,0.4,0.3,0.6,0.3c3.2,0.4,6.3,1.2,9.6,1c1.2-0.1,2.3,0.1,3.5,0.1c1,0,2-0.1,3-0.2
			c0.8-0.1,1.6-0.3,1.8-1.4c0,0,0,0,0-0.1c0.1-0.9-0.1-1.7-0.9-2.2c-0.9-0.5-1.8-1-2.7-1.5c-1.3-0.6-1.9-1.1-2.1-1.9
			c-0.1-0.5-0.2-1.1-0.1-1.9c0-0.3,0.1-0.6,0.1-0.9c0.3-2.5,0-4.8-2.1-6.6c-1-0.8-1.1-1.8-0.8-3c0.3-1.1,0.7-2.1,0.8-3.2
			c0.1-2,1.4-2.9,3.1-3.4c0.4-0.1,0.8-0.2,1.3-0.4c1.8-0.6,3.6-1.2,5.4-1.8c4.1-1.3,6.5-4.1,7.2-8.3c0.3-2.2,0.5-4.3,0.5-6.5
			c0.1-1.3,0.6-2.2,1.7-2.9c0.4-0.3,0.9-0.5,1.4-0.7C-213.6,2577.5-213.2,2575.9-214.5,2573.9z M-220.9,2576.6
			c-0.9,0.1-2-0.8-2.1-1.5c-0.1-0.9,1.5-2.4,2.6-2.2c0.5,0.1,0.9,0.8,1.1,1.1C-219.3,2575.5-220,2576.5-220.9,2576.6z"
            />
            <path
              class="st0"
              d="M-233,2615.7c0,0.5,0.2,1.2,0.5,1.6c1,1.1,2.1,2.3,3.3,3.1c1.1,0.8,2,1.4,1.9,2.9c0,0.7,0.4,1.2,1,1.1
			c0.5,0,1.2-0.3,1.5-0.7c0.9-1.1,0.7-2.6-0.4-3.5c-0.9-0.7-1.9-1.4-2.9-1.9c-1.4-0.7-2-1.7-2.3-3.2c-0.4-2.4-0.3-4.7,0.6-7
			c0.7-1.8,1.2-3.6,1.6-5.4c0.2-0.9,0.1-1.8,0.2-2.7c-4.8-0.9-7.6,1.7-7.3,6.8c2.6,1.3,2.7,1.6,2.5,4.5
			C-232.9,2612.8-233,2614.3-233,2615.7z"
            />
            <path
              class="st0"
              d="M-224.4,2560.8c1.5,1,3.2,0.6,4.9,0.5c1.2-0.1,1.4-0.3,1.1-1.4c-0.4-1.8-1.3-3.4-2.8-4.6
			c-1.6-1.3-2.5-3-2.7-5c-0.1-0.5-0.2-1-0.4-1.5c-0.6-1.7-1.1-2-3.2-1.8c-0.1,0.3-0.2,0.4-0.2,0.5c0.6,3.9,1.2,7.9,1.9,11.8
			C-225.7,2559.9-224.9,2560.4-224.4,2560.8z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-543.4,2547.5c-0.1-0.2-0.2-0.3-0.4-0.5c-2.8-2.6-5.9-4-9.8-4.1c-3.3-0.1-5.9,1-7.5,4.1
			c-1.3,2.6-2.6,5.3-3.8,8c-1,2.2-2,4.4-3,6.6c-1.2,2.6-2.3,5.1-3.5,7.7c-1.2,2.6-2.3,5.3-3.6,7.9c-1,2.2-2.2,4.3-3.2,6.5
			c-2.2,5-4.2,10.2-6.7,15.1c-1.8,3.6-2.8,7.5-4.1,11.2c-0.2,0.7-0.3,1.4-0.5,2.4c1.6-0.4,2.6-0.9,3.3-1.9c1.4-1.8,2.9-3.6,4.2-5.5
			c4-5.7,8.4-11,13.1-16c2.3-2.5,5-4.6,7.7-6.7c2-1.6,4.3-2.8,6.4-4.2c2.7-1.8,4.6-4.3,5-7.4c0.5-4.1,0.6-8.2,0.2-12.3
			c-0.2-1.8-0.3-3.5-0.5-5.3c-0.1-1,0.2-1.7,1.1-2.1c0.4-0.2,0.9-0.3,1.3-0.6c1.4-0.9,3.1-1.4,4.2-2.8
			C-543.4,2547.6-543.4,2547.6-543.4,2547.5z M-552.8,2547.7c-0.2-0.1-0.4-0.8-0.3-1c0.5-0.8,1.2-0.4,1.9,0
			C-551.5,2547.8-552,2548.3-552.8,2547.7z"
            />
            <path
              class="st0"
              d="M-558.7,2604.7c-0.5,2.6,0.6,4.6,3.1,6.3c0.2-1.2,0.4-2.2,0.5-3.2c0.4-2.5,0.8-5,1.8-7.4
			c1.5-3.4,3-6.9,3.4-10.6c0.4-4,0.7-8.1-0.9-12.1c-2,1.2-2.3,1.8-2.4,3.9c-0.1,1-0.1,2-0.3,2.9c-1.1,4.3-2.2,8.5-3.3,12.7
			C-557.5,2599.6-558.3,2602.1-558.7,2604.7z"
            />
            <path
              class="st0"
              d="M-557.2,2581.9c-2.4,1.4-4.9,2.6-6.9,4.6c-2.5,2.7-5,5.3-7.5,8.1c-1,1.1-2,2.3-2.9,3.5c-2.7,4-5.3,8-8.5,11.6
			c-1.8,2.1-2.4,4.7-3.3,7.4c0.6,0,1.1,0.2,1.5,0.1c3.1-0.6,6.1-0.9,9.2-0.6c0.8,0.1,1.2-0.4,1.5-1.1c1-2.4,2.1-4.8,3-7.2
			c0.6-1.6,1.1-3.2,1.6-4.8c0.2,0,0.3,0,0.5-0.1c0.7,1.6,1.4,3.2,2.2,5c1.7-1.1,3.2-2,4.7-2.9c1.7-1,3.2-2.1,3.1-4.4
			c0-0.2,0.1-0.4,0.1-0.6c0.8-3,1.5-5.9,2.3-8.9c0.2-0.9,0.4-1.8,0.7-2.7c0.7-2.4,1.1-4.8,0.8-7.7
			C-555.9,2581.5-556.6,2581.6-557.2,2581.9z"
            />
            <path
              class="st0"
              d="M-570.1,2618.6c1-0.1,2.1-0.4,3-0.8c2.8-1.2,5.7-1.5,8.7-1c2.1,0.4,4.3,0.6,6.4,0.9c1.3,0.1,2.5,0,3.6-1
			c-0.3-1.6-1-2.8-2.6-3.3c-1.4-0.4-2.8-1-4.2-1.1c-2.6-0.1-3.9-1.5-4.7-3.7c-0.3-0.8-0.6-1.5-0.9-2.3c-2.1,0.7-2.1,0.7-4,3.4
			c0.8,2.5,0.8,2.5-1.5,3.4c-0.2,0.1-0.5,0.1-0.7,0.3c-1.7,0.8-3.2,1.8-4.2,3.5C-571.9,2617.9-571.4,2618.7-570.1,2618.6z"
            />
            <path
              class="st0"
              d="M-542.5,2617.1c-0.2-2.9-3.2-5-5.6-4c0.5,0.7,1,1.4,1.4,2.1C-545.8,2617.1-544.9,2617.6-542.5,2617.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-507.5,2613.8c-2.7-1.8-5.5-3.4-8.4-4.6c-2.6-1.1-3.5-2.7-3.5-5.3c0-0.4,0-0.8,0-1.2
			c-0.2-3.9-0.3-7.8-0.4-11.8c-0.2-7.9-0.3-15.7-0.5-23.6c-0.1-4.4-0.1-8.8-0.3-13.2c-0.1-2.6-0.3-5.2-0.5-7.8
			c0-0.6-0.2-1.1-0.3-1.8c-0.5-0.1-0.9-0.2-1.3-0.2c-2.3,0.2-4.6,0.2-6.9-0.1c-1.2-0.2-2.4,0-3.5,0.1c-1.6,0.1-2.1,0.9-1.8,2.5
			c0.4,2,0.7,4,1,6c0.6,4.3,0.2,8.8,1.2,13.1c0,0.2,0,0.4,0,0.6c0.6,5.8,1,11.6,1.7,17.4c0.8,6.6,1.5,13.1,1.6,19.8
			c0,3.1,0.2,6.3,1.1,9.3c0.6,2,1,2.4,3,2.3c3.5-0.1,6.9,0.7,10.2,1.8c2.7,0.9,5.5,1.7,8.3,2.5c0.6,0.2,1.3,0.4,1.9,0.4
			c0.5,0,1.4-0.2,1.5-0.6c0.2-0.5,0.2-1.4-0.2-1.8C-504.8,2616.3-506,2614.8-507.5,2613.8z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-496.4,2568.3c2.2,0.3,3.3-1.1,2.4-3.2c-0.3-0.7-0.6-1.3-1.1-1.8c-2.8-3.2-4.5-7-6.2-10.9
			c-1-2.4-2.3-4.7-3.5-7c-0.4-0.8-1.1-1.3-2.1-1.3c-0.9,0-1.4,0.8-1.6,1.5c-0.2,0.7-0.3,1.4-0.2,2.1c0.1,2.6,0.3,5.2,0.4,7.8
			c0.1,2.4,0,4.8,0.1,7.2c0.1,2.9,0.3,5.7,0.5,8.6c0.3,5,0.6,9.9,0.9,14.9c0.1,1.7,1,2.7,2.5,3.8c0.4-0.6,0.9-1.1,1-1.7
			c0.3-1.7,0.6-3.4,0.5-5c-0.1-4.6-0.9-9.2-0.2-13.9c0.5-3.2,0.9-3.5,3.8-2.1C-498.3,2567.8-497.3,2568.2-496.4,2568.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-275.8,2598.7c-3-2.1-3.9-5.4-4.7-8.4c-1.4-5.3-2.2-10.8-3.1-16.2c-0.5-3-0.9-6.1-1.2-9.1
			c-0.2-1.9-1.1-3.2-2.4-4.4c-0.6-0.6-1.4-0.9-2.2-1.4c0.1-1.2,0.3-2.5,0.3-3.6c-0.1-4.6-2.6-6.3-6.4-6.5c-1.9-0.1-3.2,0.7-3.9,2.4
			c-0.6,1.5-1.2,3.1-1.6,4.6c-0.5,1.8-1.1,3.1-3,3.8c-1.8,0.6-2.9,2.1-2.8,4.1c0.1,1.3,0,2.6-0.1,3.8c-0.2,4.2-0.2,8.4-0.5,12.6
			c-0.2,3.6-0.6,7.1-0.9,10.7c0,0.6,0,1.2,0.1,1.7c0.4,1.3,1.5,1.7,2.4,0.6c0.5-0.6,0.9-1.5,1-2.3c0.9-6.1,1.7-12.2,1.6-18.3
			c0-2.2,0.2-4.4,0.4-6.6c0.1-0.8,0-1.7,1.2-2.1c0.8,0.8,0.4,1.6,0.4,2.4c-0.1,2.4-0.3,4.7-0.3,7c0,0.8,0.6,1.6,0.9,2.6
			c-0.2,0.8-0.5,1.8-0.7,2.8c-0.6,4-1.2,8.1-1.8,12.1c-0.4,2.4,0.1,3.2,2.4,4c1.6,0.5,3.2,1,4.7,1.4c1.6,0.5,3.1,1.1,4.7,1.5
			c1.2,0.3,1.6-0.1,1.3-1.3c-0.4-1.4-0.8-2.9-1.1-4.3c-0.7-4.2-1.3-8.5-2-12.7c-0.1-0.8-0.5-1.5-0.8-2.3c1.3-1.9,1.2-4.4,1.7-6.8
			c0.2,0,0.3-0.1,0.5-0.1c0.5,1.3,1.1,2.6,1.4,4c1,4.3,1.9,8.6,2.8,13c0.7,3.2,1.3,6.3,2.8,9.2c0.4,0.8,0.8,1.8,1.6,2.3
			c1.6,1.1,3.3,2.1,5,2.9c1.3,0.7,2,0.4,2.6-0.6C-274.8,2600.5-274.8,2599.4-275.8,2598.7z M-293.9,2557.1c-0.2,2-0.5,3.9-0.8,5.9
			c-0.1,0.9-0.3,1.8-1.5,1.7c-1-0.1-1.3-0.6-1.3-2c0-0.3,0-0.6,0-0.9c-0.1,0-0.1,0-0.2-0.1c0.5-2.3,0.9-4.7,1.5-7
			c0.1-0.5,0.7-0.8,1-1.2c0.4,0.4,1,0.8,1.2,1.3C-293.8,2555.5-293.8,2556.4-293.9,2557.1z"
            />
            <path
              class="st0"
              d="M-309.3,2580.2c0.4-7,1.1-13.9,1-21c0-3,0.2-6,0.3-9c0-0.5,0-1-0.1-1.5c-0.2-0.8-0.7-1.5-1.7-1.4
			c-0.9,0-1.8,0.2-2,1.2c-0.3,1.2-0.8,2.5-0.9,3.7c-0.2,2.6-0.2,5.1-0.2,7.7c0,1.9-0.1,3.7-0.2,5.6c-0.2,3.9-0.5,7.8-0.6,11.8
			c-0.2,7.2-0.1,14.4-0.4,21.6c-0.2,4.3-1,8.5-1.4,12.8c-0.3,3-0.3,6-0.4,9c0,1.3,0.7,2.1,2,2.7c2.9,1.3,5.9,1.2,8.9,1.3
			c3.5,0,7,0,10.6-0.1c0-0.1,0-0.2,0-0.2c5.1,0.1,10.2,0.2,15.3,0.2c0.9,0,1.8-0.3,2.7-0.5c0.9-0.2,1.1-0.9,0.9-1.7
			c-0.2-1.2-0.8-1.7-2.5-1.8c-4.5-0.3-9-0.6-13.6-0.9c-3.3-0.2-6.6-0.5-9.9-0.6c-1.9-0.1-3.8-0.1-5.8-0.1c-0.5,0-1,0-1.5,0
			c-0.8,0-1.2-0.5-1.2-1.2c0-1.9-0.1-3.8-0.1-5.7c0-1.6,0-3.1,0-4.7C-309.8,2598.3-309.8,2589.3-309.3,2580.2z"
            />
            <path
              class="st0"
              d="M-291.9,2618.1c2.5,0.1,5,0.1,7.5,0.1c0.6,0,1.3-0.4,2.2-0.7c-1.2-2-2.5-3.3-4.4-3.9
			c-0.8-0.2-1.8-0.7-2.1-1.3c-1.3-3.1-2.2-6.3-2.3-9.7c0-1.1-0.3-2.2-0.4-3.2c-1.2-0.2-2-0.4-2.9-0.4c-0.8-0.1-1.7,0-2.5,0
			c-0.1,0.5-0.2,0.7-0.2,1c0.5,5.7,1.7,11.3,3.3,16.7C-293.3,2617.6-292.8,2618.1-291.9,2618.1z"
            />
            <path
              class="st0"
              d="M-301.5,2614.6c-1.1-0.8-1.5-1.8-1.3-3.2c0.1-0.6,0.2-1.1,0.3-1.7c1.1-4.1,2.2-8.1,3.5-12.5
			c-1-0.2-2-0.4-3-0.6c-0.7-0.1-1.2,0.1-1.5,0.8c-2.1,4.7-3.7,9.6-4.4,14.8c-0.2,1.3,0.2,2.3,1.1,3.3c1.2,1.3,2.7,2,4.3,2.4
			c1.2,0.3,2.5,0,3.6-1.3C-299.9,2615.9-300.7,2615.2-301.5,2614.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-401.4,2549.4c-3.4-1.7-7-3.2-10.8-2.8c-5.9,0.6-11.8,1.4-17.6,2.4c-4.3,0.8-8.6,2.3-12.9,3.4
			c-1.9,0.5-3.9,1.1-5.8,1.2c-2.7,0.2-5.5-0.2-8.2-0.1c-4.6,0.2-9-0.6-13.4-1.8c-1.2-0.3-2.5-0.6-3.7-0.6c-2.5,0-4.1,1.4-4.4,3.8
			c-0.3,2.6-0.4,5.1-0.6,7.7c-0.2,2.1-0.4,4.3-0.4,6.4c0.1,4.2,0.5,8.4,0.4,12.5c0,2.9-0.3,5.9-0.9,8.8c-0.9,4.2-1.2,8.5-1.1,12.7
			c0.1,3.5,0.5,6.9,3.2,9.8c2.9,3.2,6.3,4.2,10.3,3.8c3.6-0.3,6.6-2,9.5-4.3c4.7-3.7,9.1-7.7,12.9-12.4c3.1-3.8,5.8-7.9,8.4-12.1
			c1.4-2.3,2.8-4.7,4.2-7c0.6-0.9,1.2-1.7,2.6-1.3c0.1,0.5,0.2,1.1,0.2,1.7c0,5.7,1.2,11.4,0.8,17.2c-0.2,3.2-0.1,6.5,0.1,9.7
			c0.1,2.9,0.4,5.9,0.8,8.8c0,0.2,0.1,0.4,0.1,0.6c0.5,1.3,1.8,2.4,2.9,2.4c2.2,0,2.9-0.3,3.4-2.4c0-0.1,0.1-0.2,0.1-0.3
			c0.7-2.8,0.9-5.6,0.6-8.5c-0.1-1.3-0.3-2.7-0.2-4c0.6-7,1.2-13.9,1.8-21.1c0.7,0,1.5,0.1,2.2,0c0.9-0.1,1.9-0.3,2.8-0.6
			c1-0.3,2-0.8,2.1-2c0.1-1.3-0.9-1.8-1.8-2.2c-1.1-0.5-2.3-1-3.4-1.6c-0.3-0.2-0.6-0.5-1.1-0.9c0.9-0.1,1.5-0.1,2.1-0.3
			c2.7-0.6,5.4-1,8-1.8c1.4-0.4,2.6-1.6,3.7-2.6c0.8-0.7,1-1.8,0.4-2.8c-0.6-1.1-1.1-2.2-1.8-3.2c-1.3-1.9-2.4-3.9-4.4-5.2
			c-0.2-0.1-0.3-0.5-0.4-0.7c0.4-0.7,0.7-1.4,1-2.1c0.3-0.7,0.5-1.4,1-2c1.4-2,0.7-2.3,3.6-1.8c2.3,0.4,4.3,0.1,5.6-2.4
			C-399.9,2550.8-400.5,2549.8-401.4,2549.4z M-413.9,2570.3c-2.4,0.6-4.9,1.3-7.4,1.2c-2.7,0-5.3,0.3-7.9,0.4c-0.5,0-1,0-1.6,0
			c-4.6-0.6-5.3-1.7-5.2-5.5c0-1.5-0.1-2.8-1.8-3.4c-1,0.3-1.8,1-2.1,2c-0.5,1.5-1.1,3.1-1.3,4.6c-0.3,2.2,1.1,3.9,3.3,4.6
			c0.8,0.3,1.6,0.7,2.6,1.1c-1.3,2.1-2.5,4.1-3.7,5.9c-4.7,7.2-10.1,13.8-16.1,19.9c-1.7,1.8-3.3,3.6-5.1,5.2
			c-1.5,1.3-3.3,2.5-5.1,3.4c-3.4,1.8-6.4,1.1-8.2-3.8c-0.3-0.9-0.5-1.9-0.5-2.8c0-0.7,0.2-1.4,0.6-2.1c0.2-0.4,0.8-1,1.3-1
			c0.4,0,0.9,0.7,1,1.2c0.3,1.1,0.4,2.2,0.5,3.3c0.1,0.7,0,1.4,0,2.2c1.9,0.1,3.1-0.4,3.6-2.1c0.5-1.5,1.1-3.1,1.3-4.6
			c0.4-2.3-1.1-4.4-3.4-5c-1.4-0.4-2.9-0.7-4.4-1c-0.6-1.1-0.5-2.3-0.4-3.4c0.5-6,1.1-12,1.7-18c0-0.5,0.1-1,0.3-1.5
			c0.4-1.6,0.8-2.1,2.4-2c2.9,0.1,5.8,0.1,8.6,0.4c4.5,0.4,9,0.9,13.5,0.4c0.4,0,0.8-0.1,1.1,0c1.4,0.4,2.5-0.1,3.5-1.1
			c0-0.8,0-1.6,0-2.5c0-1.8-0.3-2.2-2.2-2.2c-3.6,0-7.2,0.1-10.9,0c-3.2-0.1-6.3-0.4-9.5-0.5c-1.6-0.1-3.3-0.2-4.9,0
			c-1.1,0.1-2.1,0.6-3.3,1c-0.3-2.2,0.2-4.3,1.1-6.2c0.2-0.4,1.1-0.7,1.7-0.7c1.5,0.1,2.9,0.4,4.3,0.8c7.1,1.6,14.2,2,21.3,0.4
			c1.7-0.4,3.4-0.5,5-0.9c4.7-1.2,9.4-2.6,14.1-3.7c2-0.5,4.3-0.5,6.4-0.5c1.8,0,2.4,1,2,2.8c-0.2,1.1-0.7,2.1-1.1,3.1
			c-0.8,2.1-0.6,2.5,1.4,3.5c1.6,0.8,3.2,1.9,4.6,3c0.6,0.4,1,1.2,1.5,1.8C-411.9,2569.4-412.8,2570-413.9,2570.3z"
            />
            <path
              class="st0"
              d="M-426.1,2560.4c-2.2,0.2-4.5,2.5-4.6,4.4c-0.3,2.7,1.5,4.5,4.9,4.6c0.4-0.1,1.3-0.2,2.1-0.5
			c2.3-0.9,3.6-3.6,2.8-5.7C-421.8,2560.8-423.8,2560.2-426.1,2560.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-604.1,2616.3c0.1,0.8,0.3,1.6,0.8,2.2c1.2,1.9,3.3,1.9,4.7,0c1.3-1.8,1.7-3.9,1.8-6.1
			c0.1-4.6,0.2-9.2,0.4-13.7c0.4-7.6,0.9-15.2,1.2-22.8c0.2-4.9,0.3-9.8,0.4-14.6c0.1-2.2,0.2-4.3,0.1-6.5c0-0.8-0.3-1.7-0.7-2.4
			c-0.6-1.1-1.7-1.3-2.9-1.4c-1.1,0-1.6,0.8-1.9,1.7c-0.4,1.4-0.7,2.8-1,4.2c-0.9,4.6-1.5,9.3-1.6,14c-0.1,3.6-0.3,7.2-0.4,10.8
			c-0.1,1.4-0.2,2.7-0.2,4.1c-0.2,5.3-0.4,10.6-0.6,15.9c-0.1,3.7-0.3,7.3-0.4,11c0,0,0.1,0,0.1,0
			C-604.3,2613.8-604.3,2615-604.1,2616.3z"
            />
            <path
              class="st0"
              d="M-620.6,2610.2c-0.1,2.3-0.1,4.6,0,6.9c0.1,1.4,0.8,2,2,2c1,0.1,1.7-0.5,2.1-1.2c0.7-1.3,1.5-2.7,1.8-4.1
			c0.7-4,1.2-8,1.5-12.1c0.4-3.8,0.5-7.7,0.7-11.5c0.2-3.7,0.5-7.4,0.6-11.2c0.1-5.4,0.1-10.7,0.1-16.1c0-3.4-0.1-6.8-1.5-10
			c-0.8-1.8-3-2.9-4.9-2.3c-0.4,0.1-0.9,0.4-1,0.8c-0.3,1.2-0.7,2.5-0.7,3.8c0.1,2.3,0.6,4.5,0.7,6.8c0.2,4.8,0.1,9.6,0.2,14.3
			c-0.1,0-0.2,0-0.4,0c0,3.1,0.1,6.3,0.1,9.4c0,2.9-0.2,5.9-0.3,8.8C-619.9,2599.7-620.3,2605-620.6,2610.2z"
            />
            <path
              class="st0"
              d="M-602.4,2558.6c0-0.8-0.1-1.5-0.2-2.5c-1.5,0-2.8-0.2-4.1-0.1c-1.3,0.1-2.6-0.8-3.9,0c0.3,1.7,0.6,3.3,1,5.2
			c0.9,0,1.6,0,2.3,0c0.7,0,1.4-0.1,2.2,0C-602.5,2561.2-602.5,2561.2-602.4,2558.6z"
            />
            <path
              class="st0"
              d="M-606.2,2612.1c0.4-1,0.7-2.1,0.9-3.2c0.2-1-0.3-1.7-1.3-1.8c-1.4-0.2-2.9-0.3-4.3-0.4
			c-0.8,0-1.2,0.6-1.2,1.3c-0.1,1.4-0.2,2.7-0.4,4.4c1.8,0.1,3.5,0.3,5.1,0.4C-607,2612.8-606.3,2612.4-606.2,2612.1z"
            />
            <path
              class="st0"
              d="M-604.7,2581.6c-2.2-0.7-4.2-0.7-5.9-0.6c0,2,0,3.7,0,5.5c1.9,0.6,3.6,0.3,5.5,0.2
			C-605,2585-604.9,2583.4-604.7,2581.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-330.8,2545c-1.9,0.7-3.7,1.6-5.5,2.5c-3.5,1.9-7.2,3.4-11.2,4.1c-1.1,0.2-2.3,0.2-3.3,0.5
			c-3.4,0.8-6.8,1-10.2,0.6c-3.8-0.5-7.5-1.2-11.3-1.7c-1.2-0.2-2.3-0.3-3.5-0.3c-0.5,0-1.1,0-1.6,0c-1.3,0.1-2.7,0-3.7,0.9
			c-0.3,0.3-0.6,0.5-0.8,0.8c-1.1,1.1-2.3,2.2-3.3,3.4c-2.4,2.9-4,6.1-4.4,9.8c-0.4,3.9-0.3,7.6,3.1,10.6c3.1,2.7,7.4,1.9,9.1-1.3
			c0.8-1.4,1-3.2,1.1-4.8c0.3-3.1,0.3-6.2,0.5-9.3c0-0.3,0.1-0.6,0.2-0.9c0.1-0.4,0.3-0.7,0.4-1.1c0.3-0.8,0.9-1.2,1.8-1.1
			c1.1,0.1,2.2,0.2,3.3,0.4c0.2,0,0.5,0.1,0.7,0.1c3.6,0.6,7.1,1.4,10.7,1.8c3.2,0.3,6.5,0.5,9.8-0.3c5.4-1.2,10.5-3.2,14.9-6.5
			c2.7-2.1,4.7-4.8,6.6-7.7C-328.8,2544.5-329.2,2544.4-330.8,2545z M-381.9,2566.4c-0.3,1.3-0.7,2.6-1.1,3.9
			c-0.1,0.3-0.4,0.6-0.6,1c-1.8-0.6-1.9-1.9-1.9-3.1c0.2-2.9,1.1-5.5,3.1-8c0.2,0.4,0.5,0.7,0.5,1
			C-381.4,2562.9-381.5,2564.7-381.9,2566.4z"
            />
            <path
              class="st0"
              d="M-387.6,2619.6c0.3,0.8,0.9,1.2,1.8,1.2c1.8-0.1,3.5-0.1,5.3-0.1c5.4,0,10.8,0,16.2,0.2
			c5.3,0.2,10.6,0.5,16,0.9c2.7,0.2,5.4,0.5,8.1,0.7c3.3,0.2,6.7,0.5,10,0.6c2.4,0.1,4.7,0.3,7-0.8c0.9-0.5,1.3-1.1,1.3-1.9
			c0.1-1.6-1.7-3.6-3.4-3.8c-2.1-0.3-4.2-0.4-6.3-0.6c-4.5-0.4-8.9-0.8-13.4-1.1c-1.2-0.1-2.4-0.3-3.3-1c-2.7-1.9-5.3-3.8-8.1-5.6
			c-2.1-1.4-4.5-2.2-7-2.6c-0.7,0.9-0.3,1.7-0.1,2.4c0.4,1.3,0.9,2.5,1.2,3.7c0.3,1.3-0.1,1.9-1.5,2c-2.6,0.2-5.3,0.3-7.9,0.4
			c-5.2,0.1-10.4,0.2-15.6,0.4c-0.5,0-1,0.3-1.5,0.5c0.3,1.1,0.4,2,0.7,2.9C-388,2618.5-387.8,2619-387.6,2619.6z"
            />
            <path
              class="st0"
              d="M-326.9,2575.6c-1.6,0.7-3,1.3-4.4,2c-1.5,0.7-2.7,1.8-4.2,2.4c-3.5,1.4-7.1,2.6-10.7,3.8
			c-0.3,0.1-0.6,0.2-0.9,0.3c-2,0.6-4.1,1.3-6.1,1.8c-0.9,0.2-1.7,0.4-2.6,0.6c-4.3,0.9-8.7,1.6-13,2.1c-3.8,0.4-7.7,1.1-11.6,0.8
			c-1.4-0.1-2.9,0-4.3,0c-2.4,0.1-4,1.3-4.1,3.2c0,0.8,0.2,1.7,0.5,2.5c1.1,2.8,1.2,2.7,4.3,2.9c0.2,0,0.4-0.1,0.6-0.1
			c2.5-0.2,5-0.6,7.4-0.6c4.9,0,9.7-0.4,14.5-1.1c3-0.4,6-0.7,8.9-1.4c1.9-0.5,3.7-1,5.5-1.7c1.1-0.4,2.2-0.8,3.3-1.2
			c0.8-0.3,1.7-0.6,2.5-1c1.5-0.6,2.9-1.3,4.3-2.1c1.3-0.7,2.5-1.5,3.8-1.1c1.1-1.1,2-2.1,2.9-3.1c1.1-1.3,2.4-2.5,3.3-3.9
			C-326.2,2579.2-325.6,2577.6-326.9,2575.6z M-383,2593.7c-0.1-0.2,0-0.7,0.2-0.8c1-0.5,1.1,0.5,1.9,1
			C-382,2594.5-382.7,2594.5-383,2593.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-267.2,2410.5c-1.3,2.7-1.1,5.4,0.1,7.8c3.6,7,9.6,10.6,17.3,11.6c2.3,0.3,4.6,0.3,6.9,0.3
			c3.2,0,6.4,0.1,9.6-0.2c5.6-0.5,11.1-0.8,16.7-0.8c1.8,0,3.5-0.2,5.3-0.4c0.3,0,0.5-0.1,0.7-0.3c1-1,1-4.1-0.3-4.8
			c-1.2-0.6-2.5-1.4-3.7-1.5c-2.7-0.2-5.4-0.1-8.1-0.1c-1.9,0-3.8,0.2-5.7,0.2c-2.2-0.1-4.5-0.3-6.7-0.6c-4.8-0.5-9.7,0.1-14.4-1.2
			c-3-0.8-5.8-1.7-7.7-4.5c-2.3-3.4-1-8.4,2.7-9.9c0.6-0.3,1.2-0.5,2-0.8c-1.1-2.1-2.1-3.9-3.2-6c-2.6,0.5-4.5,1.9-6.2,3.4
			C-264.3,2405-265.8,2407.6-267.2,2410.5z"
            />
            <path
              class="st0"
              d="M-236.8,2380.3c-0.5,2.1-1.6,3.8-2.9,5.4c-0.6,0.6-1.1,1.2-1.8,1.7c-1,0.9-2.6,1.3-4,1.8
			c-0.4,0.1-1-0.1-1.7-0.3c0.4-0.9,0.6-1.4,0.8-1.9c0.5-1.4,0.8-2.8-0.1-4.1c-0.7-1.1-0.5-2.2,0.1-3.3c0.2-0.4,0.4-0.8,0.6-1.2
			c0.1-0.3,0.2-0.6,0.3-0.9c0.8-2.5,0.5-4.9-1.7-6.6c-0.9-0.8-1.5-1.8-1.7-2.9c-0.2-0.7,0-1.2,0.5-1.4
			C-244.6,2371.2-240.7,2375.8-236.8,2380.3z"
            />
            <path
              class="st0"
              d="M-250.3,2373c-1.2-1-1.5-2.2-1.6-3.6c-0.1-0.9-0.2-1.8-0.3-2.7c-2-0.2-3.1,0.1-4.3,1.2
			c-0.6,0.6-1.1,1.2-1.7,1.7c-2.1,1.9-3,4.3-2.7,7.1c0.2,1.7,0.5,3.5,1.1,5.1c1.3,3.6,4.1,5.8,7.7,7c0.3,0.1,0.6,0,1.1-0.1
			c0-0.6-0.1-1.1-0.1-1.6c-0.1-1.3-0.3-2.5-0.4-3.8c-0.1-2.3,0.9-4.3,2-6.3C-248.5,2375.2-248.7,2374.3-250.3,2373z"
            />
            <path
              class="st0"
              d="M-239.7,2399.9c-2.7-2.2-5.9-3.3-9.4-3.2c-0.9,0-1.4,0.3-1.6,1.2c-0.4,2-0.8,4-1.1,6c-0.1,1,0.4,1.8,1.5,2.1
			c1.6,0.4,3.1,0.8,4.7,1.1c2.1-0.1,3.9-0.8,5.7-1.6c1.5-0.7,2.1-1.9,1.6-3.5C-238.6,2401.3-239.1,2400.4-239.7,2399.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-100.7,2408.7c0,1.4-0.2,2.7-0.3,4.1c0,0.1,0,0.2-0.1,0.3c-0.2,0.7-0.8,1.1-1.5,1.1
			c-3.1,0.2-6.2,0.4-9.3,0.4c-1.3,0-3-1.7-3.2-3.1c-0.3-1.8-0.3-3.6-0.5-5.5c0-0.4,0-0.8,0-1.2c0-0.9-0.4-1.5-1.4-1.5
			c-1.4-0.1-2.8-0.2-4.1-0.3c-1.3-0.1-2.5,0-3.8-0.1c-5.8-0.6-11.6-0.6-17.3-0.2c-1.5,0.1-3,0-4.6,0c-0.9,0-1.8,0-2.7,0
			c-1.1,0-2.1,0.1-3.2,0.2c-2.4,0.1-4.8,0.4-7.2,0.6c-2,0.2-2.4,0.9-2.4,3.1c0,2,0.1,3.9,0,5.9c0,1.1-0.3,2.2-0.4,3.4
			c-3.6-0.2-6.8-0.5-9.9-1.4c-1-0.3-1.4-0.8-1.4-1.8c0-0.9,0-1.9,0.1-2.8c0-1.7,0.1-3.4,0.1-5c0-1.3,0-2.7,0-4
			c1.8-2.2,3.7-4.3,5.5-6.4c2.5,0,5,0.1,7.4,0.2c0.3,0,0.6,0,0.9,0c3.2,0.1,6.4,0.3,9.5,0.4c2,0.1,3.9,0.1,5.8,0.2
			c2.6,0.1,5.2,0.1,7.7,0.1c3.9,0,7.9,0.3,11.8,0.3c2.3,0,4.6-0.2,6.9-0.2c0.7,0,1.4,0,2.1,0c2.5,0,5,0,7.5,0c2.6,3,5.2,6.1,7.8,9.1
			C-100.8,2406-100.7,2407.4-100.7,2408.7z"
            />
            <path
              class="st0"
              d="M-115.1,2388c-0.6,0.3-1.2,0.6-1.8,0.8c-1.3,0.5-2.6,0.9-3.9,1.2c-3.3,0.9-6.6,1.4-10,1.2
			c-2.5-0.1-4.8-0.6-7-1.9c-1.3-0.8-2.8-1.1-4.3-1.2l-0.5,0h-0.1c-0.7,0-1.4-0.1-2.1-0.1c-0.7,0-1.4,0-2.2,0c-0.5,0-1,0-1.5,0
			c-2.3,0-4.6,0.1-6.9,0.2c-1.1,0-2.2,0.1-3.3,0.1c-1.5,0-3,0.1-4.4,0.1c2.8-3.2,5.5-6.5,8.3-9.7c1.1,0.1,2.1,0.2,3.2,0.3
			c1.3,0.1,2.5,0.3,3.7,0.6c0.6,0.1,1.1,0.3,1.7,0.5c1,0.3,2,0.6,3,1c0.8,0.3,1.6,0.5,2.4,0.7c2.3,0.5,4.7,0.7,7.1,0.2
			c1.6-0.3,3.2-0.8,4.8-1.3h0c0.4-0.1,0.8-0.2,1.2-0.2c1.9-0.1,2.8,1.1,2.2,2.8c-0.1,0.3-0.3,0.6-0.4,0.9c-0.1,0.1-0.1,0.2-0.2,0.4
			c0.2,0.1,0.4,0.1,0.6,0.1c2.6,0.6,5,0.5,7.3-0.2C-117.2,2385.5-116.2,2386.7-115.1,2388z"
            />
            <path
              class="st0"
              d="M-121.6,2418.7c-1.2-2.9-3.7-4.4-6.3-5.9c-4.4-2.5-8.7-0.8-11.7,2.6c-2.3,2.6-3.8,6-3.7,9.7
			c0.1,4,1.7,7.4,5.4,9.4c1.8,1,3.7,1.2,5.7,1.1c5,0,9-2.6,10.6-7c0.7-1.9,1-3.9,1.1-5.9C-120.5,2421.5-121,2420-121.6,2418.7z
			 M-125.3,2425c-0.5,3-3.1,5.5-6.8,5.4c-0.7,0-1.1,0-1.6,0c-2.7-0.2-4.5-2-4.7-4.7c-0.2-3.5,1.3-6.2,3.8-8.5c1.2-1.1,2.8-1,4.1-0.6
			c1.5,0.5,2.9,1.3,4.2,2.2c1.1,0.8,1.2,2.1,1.2,3.4C-125,2423.2-125.1,2424.1-125.3,2425z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-34.2,2482.7c2.1,2.5,4.3,5,6.4,7.5c-0.8-0.1-1.6-0.1-2.4-0.2c-1.3-0.1-2.5,0-3.8-0.2
			c-0.6-0.1-1.2-0.1-1.7-0.2c-5.2-0.4-10.4-0.4-15.6-0.1c-2.4,0.2-4.9-0.1-7.3,0c-3.5,0.2-7,0.4-10.4,0.8c-2,0.2-2.4,0.9-2.4,3.1
			c0,2,0.1,3.9,0.1,5.9c0,1.1-0.3,2.2-0.4,3.4c-3.6-0.2-6.8-0.5-9.9-1.3c-1-0.3-1.4-0.8-1.4-1.8c0.1-2.6,0.1-5.2,0.1-7.9
			c0-2.6,0-5.2,0-7.9c0-0.6,0.1-1.3,0.2-2.1c1.3-0.2,2.5-0.5,3.7-0.5c3.3,0,6.6,0.1,9.9,0.3c7.7,0.3,15.4,0.7,23.1,0.7
			c1.4,0,2.8,0,4.2,0.1C-39.3,2482.5-36.8,2482.6-34.2,2482.7L-34.2,2482.7z"
            />
            <path
              class="st0"
              d="M-18.1,2501.5c-1,0-2,0.1-3,0.1c-1.3,0-3-1.7-3.2-3.1c-0.2-1.5-0.3-3.1-0.4-4.6
			C-22.5,2496.4-20.3,2498.9-18.1,2501.5z"
            />
            <path
              class="st0"
              d="M-38,2478.2c-0.7,0-1.3,0-2,0c-2.5-0.1-4.8-0.6-7-1.9c-0.1-0.1-0.2-0.1-0.3-0.2l0,0c-1.2-0.7-2.5-0.9-4-1
			c-5.5-0.4-11.1,0-16.6,0.1c-1.7,0-3.4,0.1-5.1,0.1c-2.7,0-5-1.5-6-4c-0.4-1-0.6-2-0.9-3c-0.7-2.3-1.3-4.7-1.9-7
			c-0.1-0.4-0.1-0.8-0.1-1.2c1.4-1,3.1-1.4,4.8-1.7c1.4-0.3,2.8-0.4,4.1-0.5c1.6-0.1,2.5,0.5,2.9,2c0.3,1.1,0.3,2.2,0.3,3.3
			c0.1,1.6,0.2,1.8,1.8,1.9c2.4,0.3,4.7,0.6,7.1,0.7c2,0.1,3.9,0.5,5.7,1.1v0c0.9,0.3,1.8,0.6,2.7,0.9c2.2,0.8,4.3,1.2,6.6,1.2
			C-43.2,2472-40.6,2475.1-38,2478.2z"
            />
            <path
              class="st0"
              d="M-30.7,2505.6c-1.2-2.9-3.8-4.5-6.3-5.9c-4.4-2.5-8.6-0.8-11.7,2.6c-2.3,2.6-3.9,6-3.7,9.7
			c0.1,4,1.7,7.4,5.4,9.4c1.8,1,3.7,1.2,5.7,1.2c5-0.1,9-2.6,10.6-7.1c0.7-1.9,1-3.9,1.1-5.9C-29.6,2508.4-30.2,2506.9-30.7,2505.6z
			 M-34.4,2511.9c-0.5,3-3.1,5.5-6.8,5.4c-0.7,0-1.1,0-1.6,0c-2.7-0.2-4.5-2-4.7-4.7c-0.2-3.5,1.3-6.2,3.8-8.5
			c1.2-1.1,2.8-1,4.1-0.6c1.5,0.5,2.9,1.3,4.2,2.2c1.1,0.8,1.2,2.1,1.2,3.4C-34.1,2510-34.3,2511-34.4,2511.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-414.8,2325c1.3,1.1,1,2.5-0.6,3c-1.8,0.6-3.7,0.8-5.7,0.4c-1.3-0.3-2.2-1-2.7-2.3c-1.5-4-3-8-4.5-11.9
			c-0.2-0.6-0.5-1.1-0.9-1.8c-0.4,0.5-0.7,0.7-0.8,0.9c-1.2,3.6-2.5,7.2-2.9,11c-0.2,1.2,0.2,1.9,1.1,2.5c0.6,0.4,1.3,0.8,1.7,1.3
			c0.9,1,0.6,1.9-0.7,2.1c-0.3,0.1-0.5,0-0.8,0c-1.4-0.1-2.8-0.2-4.1-0.4c-3.6-0.6-3.5-2.1-2.9-4.3c0.4-1.6,0.9-3.2,1.3-4.8
			c0.2-0.7,0.4-1.4,0.5-2.1c0.5-1.8,0.8-3.7,1.2-5.6c0.2-0.8,0.3-1.5,0.5-2.3c0.2-1,0.8-1.6,1.8-1.9c1.8-0.5,2.5-1.8,2.5-3.5
			c0-0.4,0-0.8,0-1.2c1.4-1.6,2.7-3.2,4.1-4.8c0,0,0,0.1,0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.3
			c1,1.3,1.6,2.6,1.6,4.1c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.2c0,0.1,0,0.1-0.1,0.2l0,0
			c0,0.1,0,0.1-0.1,0.2c-0.2,0.6,0,1.4,0.2,1.9c2,4.1,3.2,8.4,4.6,12.7c0.3,1.1,0.8,1.7,2,2.1
			C-416.9,2323.4-415.7,2324.1-414.8,2325z"
            />
            <path
              class="st0"
              d="M-411.9,2302.4c-2.7,0.4-5.5,0.5-8.3,0.5c-0.4,0-0.9-0.4-1.4-0.8c-0.2-0.2-0.3-0.4-0.5-0.5
			c-0.9-1.2-1.6-2.5-2.5-3.8c-0.1-0.2-0.3-0.4-0.4-0.7c1.1-1.3,2.1-2.5,3.2-3.8c0.1,0.2,0.2,0.3,0.3,0.5c0.4,0.7,0.8,1.4,1,2.1
			c0.4,1.3,1.2,1.9,2.4,2c0.2,0,0.4,0,0.6,0c1.3,0,2.5,0.2,3.8,0.1c1.1,0,1.9,0.5,2.5,1.3C-410.2,2300.5-410.6,2302.2-411.9,2302.4z
			"
            />
            <path
              class="st0"
              d="M-386.2,2291c0.9-0.3,1.8-0.3,2.7-0.6c1.2-0.4,2.2-1.6,2.2-2.7c0-1.2-1-1.6-2-1.9c-0.5-0.2-1.1-0.4-1.7-0.4
			c-2.4-0.2-4.7-0.3-7.1,0.4c-0.6,0.2-1.3,0.2-2,0.1c-2.3-0.2-4.6-0.4-6.9-0.6c-0.6,0-1.3,0.1-2,0.1c-0.7,0-1.3,0.2-2,0.1
			c-1.5-0.4-2.8,0.2-4.2,0.6c-0.9,0.2-1.2,0.7-1.2,1.5c0,0.6,0,1.2,0,1.9c1.8,0.4,3.3,0.9,4.8,1.1c1.6,0.2,3.2,0.1,4.7,0.2
			c1.6,0.1,3.2,0.1,4.7,0.3c1.6,0.2,1.7,0.3,1.7,1.8c0.1,5.1,0.2,10.2,0.1,15.3c-0.1,7.2-0.4,14.4-0.6,21.6c0,1.7,0.3,2.2,2,2.4
			c2,0.3,3.9,0.4,5.9,0.4c0.9,0,1.8-0.4,2.6-0.8c0.8-0.4,1.2-1.2,1-2c-0.1-0.5-0.8-1-1.4-1.2c-0.6-0.3-1.3-0.4-1.9-0.5
			c-0.2-0.9,0.2-1.1,0.8-1.3c0.5-0.1,1-0.2,1.5-0.4c0.9-0.3,1.3-1.3,1.3-2.4c-0.1-1-0.7-1.3-1.6-1.5c-0.6-0.1-1.3-0.3-1.9-0.5
			c-1.2-0.4-1.4-1.4-0.6-2.2c0.3-0.3,0.8-0.4,1.2-0.7c0.7-0.5,1.3-1,1.9-1.6c0.5-0.5,0.3-1.1-0.3-1.5c-0.8-0.6-1.7-0.8-2.8-0.8
			c-1,0-1.3-0.3-1.2-1.3c0.1-0.9,0.4-1.5,1.4-1.6c0.6-0.1,1.3-0.3,1.8-0.7c0.5-0.4,1.2-1.2,1.1-1.6c-0.2-0.6-0.9-1.2-1.6-1.6
			c-0.4-0.3-1-0.2-1.5-0.3c-0.8-0.3-1.1-1-0.5-1.7c0.3-0.3,0.7-0.5,1.1-0.7c0.4-0.2,0.9-0.3,1.3-0.5c1.1-0.6,1.5-1.3,1.3-2.3
			c-0.2-1-0.9-1.4-1.8-1.5c-0.5-0.1-0.9-0.1-1.4-0.2c-0.8-0.2-0.9-0.7-0.7-1.4c1.1-0.4,2.2-0.8,3.3-1.3c0.9-0.4,1.6-1.9,1.4-2.6
			c-0.3-1.1-1.1-1.6-2.2-1.7c-0.4,0-0.8,0-1.2-0.1c-1.3-0.1-2-1-1.4-2.1C-387.4,2291.5-386.7,2291.1-386.2,2291z"
            />
            <path
              class="st0"
              d="M-413.5,2305.4c-0.8,0-1.6,0.1-1.8,1.2c-0.2,1.4,0.1,2.3,1.2,2.7c1,0.3,2.1,0.5,3.3,0.8
			c0.8,2.4,0.6,2.8-1.9,3.1c-0.3,0-0.7,0-1,0.1c-0.8,0.2-1.2,0.8-1.2,1.6c-0.1,1.2,0.7,1.6,1.6,1.9c0.7,0.3,1.8,0.4,2,0.9
			c0.4,0.6,0.1,1.6,0.1,2.5c0,0.2-0.1,0.5-0.2,0.8c-0.4,0.2-0.8,0.3-1.1,0.5c-1.7,0.9-1.7,2.2-0.1,3.2c0.7,0.4,1,1,1.1,1.7
			c0.1,0.7,0.1,1.4,0.2,2.2c0.2,1.4,1,2.1,2.4,1.9c0.6,0,1.2-0.1,1.8-0.2c1.5,0,1.7-0.2,1.8-1.6c0.1-3.9,0-7.8,0.2-11.8
			c0.2-6.4,0.5-12.8,0.8-19.2c0.1-1.2,0.2-2.3,1.4-3.1c0.4-0.2,0.6-0.8,0.8-1.2c-2.1-1.5-2.6-1.2-7.2,0c0,4,0.8,8.1-0.4,12.5
			c-0.9-0.1-1.5-0.2-2.1-0.3C-412.4,2305.5-413,2305.4-413.5,2305.4z"
            />
            <path
              class="st0"
              d="M-402.8,2336.4c1.4-0.1,2.9,0,4.3-0.1c1.7-0.1,2.2-0.9,1.9-2.5c-0.3-1.3-1-2-2.5-2c-2.6-0.1-5.3-0.1-7.9-0.1
			c-3.7,0.1-7.4-0.1-11.1,0.3c-6.7,0.9-13.4,0.6-20.1,0.5c-1.4,0-1.7,0.1-2.2,1.4c-0.6,1.9,0.6,3.8,2.6,3.8c3.8,0,7.6-0.1,11.5-0.1
			c0,0,0-0.1,0-0.1c4.5-0.2,8.9-0.4,13.4-0.6C-409.5,2336.7-406.2,2336.5-402.8,2336.4z"
            />
            <path
              class="st0"
              d="M-396.6,2296.6c-0.1-1.1-1-1.6-2.1-1.6c-1,0-1.6,0.6-1.7,1.8c-0.2,2.3-0.5,4.6-0.6,6.8
			c-0.4,6.3-0.7,12.7-1.1,19c0,0,0.1,0,0.1,0c0,1.8,0,3.5,0.1,5.3c0,1,1.1,1.9,2.1,2c1,0.1,1.3-0.7,1.6-1.5c0.1-0.4,0.3-0.9,0.3-1.3
			c0.4-4.4,0.9-8.7,1.2-13.1c0.3-3.8,0.5-7.6,0.6-11.4C-396.2,2300.6-396.4,2298.6-396.6,2296.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-251.8,2474.2c-1.3,1.7-2.7,3.4-3.9,5.2c-0.9,1.2-0.7,2.7,0.5,3.5c2.7,1.8,5.6,3.5,8.4,5.1
			c1.1,0.6,1.9,0.3,2.6-0.6c0.7-0.8,0.8-1.8-0.1-2.6c-0.8-0.9-1.8-1.7-2.9-2.4c-2.2-1.3-2.3-1.6-1.5-4c0.1-0.4,0.3-0.7,0.7-1.6
			c0.2,1.2,0.4,1.8,0.4,2.5c0,1,0.5,1.7,1.5,2.1c2.9,1,3.5,3.3,3.4,6.1c-0.1,1.9-0.8,3.2-2.7,3.8c-1.1,0.3-1.7,1-1.9,2
			c-0.2,0.8-0.4,1.6-0.5,2.4c-0.4,2-0.8,4-1.3,5.9c-0.6,2.4-1.3,4.8-2,7.3c-0.6,2.3-0.7,4,3.1,4.6c1.5,0.2,2.9,0.3,4.4,0.4
			c0.3,0,0.6,0,0.8,0c1.4-0.3,1.7-1.3,0.8-2.3c-0.5-0.5-1.2-0.9-1.8-1.4c-1-0.6-1.3-1.4-1.2-2.6c0.5-4.1,1.9-7.9,3.1-11.7
			c0.1-0.3,0.4-0.5,0.9-1c0.4,0.8,0.7,1.4,1,2c1.6,4.2,3.2,8.5,4.8,12.7c0.5,1.4,1.4,2.2,2.9,2.5c2.1,0.4,4.1,0.2,6-0.4
			c1.7-0.6,2-2.1,0.6-3.2c-1-0.9-2.2-1.7-3.5-2.2c-1.2-0.4-1.7-1.1-2.1-2.3c-1.4-4.6-2.7-9.2-4.8-13.6c-0.3-0.6-0.4-1.4-0.2-2
			c0.8-2.4,0.1-4.4-1.4-6.2c-1.3-1.6-1.2-3.6-1.4-5.7c1.7,0.7,2.4,1.9,3.1,3.1c0.9,1.3,1.6,2.8,2.6,4c0.5,0.6,1.3,1.4,2,1.4
			c2.9,0,5.9-0.2,8.8-0.6c1.4-0.2,1.8-2,0.9-3.1c-0.7-0.8-1.5-1.4-2.7-1.3c-1.3,0-2.7-0.1-4-0.1c-1.6,0-2.7-0.5-3.2-2.1
			c-0.2-0.8-0.7-1.5-1.1-2.3c-1.6-3.1-3.9-5.4-7.7-5.8c0.4-1.5,0.8-2.9,1-4.2c0.3-2.6-1.9-4.9-4.3-4.9c-3.8,0-5.8,3.4-4.2,6.9
			c0.4,0.8,0.5,1.4,0,2.1C-249.3,2471-250.5,2472.6-251.8,2474.2z"
            />
            <path
              class="st0"
              d="M-195.2,2472.2c0.9-0.3,1.9-0.4,2.9-0.6c1.3-0.4,2.4-1.7,2.3-2.9c0-1.3-1.1-1.7-2.1-2
			c-0.6-0.2-1.2-0.4-1.8-0.4c-2.5-0.2-5-0.3-7.5,0.5c-0.6,0.2-1.4,0.2-2.1,0.1c-2.4-0.2-4.9-0.4-7.3-0.6c-0.7,0-1.4,0.1-2.1,0.1
			c-0.7,0-1.4,0.2-2.1,0.1c-1.6-0.4-3,0.2-4.5,0.6c-0.9,0.2-1.3,0.8-1.3,1.6c0,0.6,0,1.2,0,2c1.9,0.4,3.5,0.9,5.1,1.1
			c1.7,0.2,3.4,0.1,5.1,0.2c1.7,0.1,3.4,0.1,5,0.3c1.7,0.2,1.8,0.3,1.8,2c0.1,5.4,0.2,10.9,0.2,16.3c-0.1,7.7-0.4,15.3-0.6,23
			c0,1.8,0.4,2.3,2.1,2.5c2.1,0.3,4.2,0.4,6.3,0.4c0.9,0,1.9-0.5,2.8-0.9c0.8-0.4,1.3-1.3,1.1-2.1c-0.1-0.5-0.9-1-1.4-1.3
			c-0.7-0.3-1.4-0.4-2.1-0.5c-0.2-0.9,0.3-1.2,0.8-1.4c0.5-0.2,1.1-0.3,1.6-0.5c0.9-0.4,1.4-1.4,1.4-2.6c-0.1-1.1-0.7-1.4-1.7-1.6
			c-0.7-0.1-1.4-0.3-2-0.5c-1.2-0.4-1.5-1.5-0.6-2.3c0.3-0.3,0.9-0.4,1.3-0.7c0.7-0.5,1.4-1.1,2-1.7c0.5-0.6,0.3-1.2-0.3-1.6
			c-0.9-0.7-1.9-0.9-3-0.8c-1.1,0-1.4-0.4-1.3-1.4c0.1-0.9,0.4-1.6,1.5-1.7c0.7-0.1,1.4-0.3,1.9-0.7c0.6-0.4,1.3-1.3,1.2-1.7
			c-0.2-0.7-1-1.3-1.7-1.7c-0.4-0.3-1.1-0.2-1.6-0.4c-0.9-0.3-1.1-1.1-0.5-1.8c0.3-0.3,0.8-0.6,1.2-0.8c0.4-0.2,0.9-0.3,1.4-0.5
			c1.2-0.6,1.6-1.4,1.4-2.5c-0.2-1.1-1-1.5-1.9-1.6c-0.5-0.1-1-0.1-1.5-0.2c-0.8-0.2-0.9-0.7-0.8-1.4c1.2-0.5,2.4-0.8,3.5-1.4
			c1-0.5,1.7-2,1.5-2.8c-0.3-1.2-1.2-1.7-2.3-1.8c-0.4,0-0.8,0-1.3-0.1c-1.4-0.1-2.1-1.1-1.5-2.3
			C-196.4,2472.8-195.7,2472.4-195.2,2472.2z"
            />
            <path
              class="st0"
              d="M-224.3,2487.6c-0.9,0-1.7,0.1-1.9,1.2c-0.3,1.5,0.1,2.4,1.3,2.8c1.1,0.4,2.3,0.6,3.5,0.9
			c0.8,2.6,0.6,3-2,3.3c-0.3,0-0.7,0.1-1,0.1c-0.8,0.2-1.2,0.8-1.3,1.7c-0.1,1.3,0.8,1.7,1.7,2c0.8,0.3,1.9,0.4,2.2,1
			c0.4,0.7,0.1,1.7,0.1,2.6c0,0.3-0.1,0.5-0.2,0.8c-0.4,0.2-0.8,0.3-1.2,0.5c-1.8,1-1.9,2.3-0.1,3.4c0.8,0.5,1.1,1,1.2,1.8
			c0.1,0.8,0.1,1.5,0.2,2.3c0.2,1.5,1,2.2,2.6,2.1c0.6,0,1.2-0.2,1.9-0.2c1.6,0,1.8-0.2,1.9-1.8c0.1-4.2,0.1-8.4,0.2-12.5
			c0.2-6.8,0.6-13.6,0.9-20.4c0.1-1.3,0.2-2.5,1.5-3.3c0.4-0.3,0.6-0.9,0.9-1.3c-2.2-1.6-2.8-1.3-7.7,0c0,4.3,0.9,8.7-0.4,13.3
			c-0.9-0.1-1.6-0.2-2.3-0.3C-223,2487.7-223.6,2487.6-224.3,2487.6z"
            />
            <path
              class="st0"
              d="M-212.9,2520.5c1.5-0.1,3.1,0,4.6-0.1c1.8-0.1,2.4-1,2-2.7c-0.3-1.4-1.1-2.1-2.7-2.1
			c-2.8-0.1-5.6-0.1-8.4-0.1c-3.9,0.1-7.9-0.1-11.8,0.4c-7.1,0.9-14.3,0.7-21.4,0.6c-1.5,0-1.9,0.1-2.3,1.5c-0.7,2.1,0.6,4,2.8,4.1
			c4.1,0,8.1-0.1,12.2-0.1c0-0.1,0-0.1,0-0.2c4.8-0.2,9.5-0.4,14.3-0.7C-220,2520.9-216.4,2520.7-212.9,2520.5z"
            />
            <path
              class="st0"
              d="M-206.2,2478.2c-0.1-1.2-1-1.7-2.3-1.7c-1.1,0.1-1.7,0.6-1.8,1.9c-0.2,2.4-0.5,4.8-0.7,7.3
			c-0.4,6.7-0.8,13.5-1.1,20.2c0,0,0.1,0,0.1,0c0,1.9,0,3.8,0.1,5.6c0,1.1,1.2,2,2.3,2.1c1.1,0.1,1.4-0.8,1.7-1.6
			c0.2-0.5,0.3-0.9,0.3-1.4c0.4-4.6,0.9-9.3,1.3-13.9c0.3-4,0.5-8.1,0.6-12.1C-205.8,2482.5-206,2480.3-206.2,2478.2z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-56.1,2591.5c-0.7,0.7-0.8,1.4-0.6,2.3c1,3.2,1.8,6.4,2.9,9.6c0.9,2.7,3.1,4,5.9,3.7
			c1.8-0.2,3.5-0.5,5.3-0.7c5-0.5,10-1,14.9-1.4c3.3-0.3,6.7-0.5,10-0.9c2.3-0.3,4.5-0.5,6.8,0.2c1.7,0.5,3.5,0.3,5.1-0.8
			c0.7-0.5,1.1-1.1,0.8-2c-0.1-0.5-0.2-1.1-0.1-1.5c0.6-1.4,0.3-2.5-1.2-3.2c0.3-0.6,0.6-1,0.8-1.5c0.8-1.6,0.2-2.7-1.6-2.9
			c-0.6-0.1-1.3-0.1-2.1-0.2c0.2-0.6,0.3-1,0.4-1.4c0.4-1.5,0-2.1-1.5-2.2c-1.3,0-2.5,0-3.8,0.1c-1.5,0.2-2.8,0.7-3.3,2.3
			c-0.3,1-0.9,1.9-1.4,2.8c-0.4,0.7-0.9,1.1-1.7,1.1c-2,0-3.9,0-5.9,0c-1.4,0-2.8-0.2-4.1-0.2c-3.9,0-7.8,0.1-11.7,0.1
			c-1.3,0-2.6-0.3-3.9-0.5c-1-0.2-1.6-0.6-2.1-1.6c-0.7-1.2-1.6-2.4-2.5-3.5c-1.1-1.3-2-1.3-3.2-0.2
			C-54.5,2589.9-55.2,2590.8-56.1,2591.5z"
            />
            <path
              class="st0"
              d="M-33.6,2560.7c-0.4-1-0.9-1.7-2.3-2.1c-2.5,3-2.6,7.1-5,10.4c-0.2-1-0.3-1.6-0.5-2.2c-0.4-2.2-1.6-4-3.4-5.3
			c-0.4-0.3-0.9-0.6-1.3-0.9c-1.5-1.4-3.3-1.6-5.2-1.5c-0.8,0-1.7,0-1.9,1c-0.2,0.9,0,1.8,0.9,2.4c1,0.7,1.9,1.4,2.8,2.1
			c0.9,0.6,1.4,1.5,1.7,2.6c0.8,3.2,0.7,6.5,0.2,9.8c-0.8,5.4,2.6,8.9,7.9,10.1c1.8,0.4,3.2-0.4,4.8-0.8c2-0.5,3.2-1.9,4.1-3.6
			c1.1-2.2,1.5-4.5,1.5-7c0-2.3,0.6-4.4,1.9-6.3c1-1.4,2.1-2.8,3.3-4.2c0.7-0.9,1.2-1.8,1.3-3.1c-1.2-0.5-2.4-0.5-3.4,0.2
			c-1.2,0.9-2.4,1.8-3.5,2.9c-1.1,1-2,2.1-3.2,3.5c0-1.2-0.1-2.1,0-3C-32.7,2563.9-33.1,2562.3-33.6,2560.7z"
            />
            <path
              class="st0"
              d="M-10,2567.3c-1.1,2.1-2.6,3.9-3.4,6.2c-1.4,4.5-1.3,9-0.8,13.8c1.5,0.1,2.8,0.2,4.1,0.3
			c0.2-0.4,0.4-0.5,0.4-0.7c0.1-0.8,0.3-1.6,0.3-2.3c0.2-5.7,1.8-11,4.9-15.9c1.1-1.7,2-3.7,2.5-5.7c0.7-2.6,0.8-5.4-1.2-7.6
			c-3.3-3.6-6.8-4.5-10.9-2.2c-2.4,1.3-3.5,5.1-1.5,7.7c0.9,1.1,2.2,1.4,3-0.4c0.2-0.5,0.3-1.1,0.4-1.7c0.3-1.3,1-1.7,2.1-1
			c0.6,0.4,1.4,0.9,1.6,1.6c0.7,1.6,0.9,3.3,0.1,5C-8.9,2565.2-9.5,2566.3-10,2567.3z"
            />
            <path
              class="st0"
              d="M-8.7,2605.6c-1.8,0.1-3.3,0.2-4.9,0.3c0.1,6,0.1,11.8,0.1,17.7c0.5,0.1,0.7,0.2,1,0.3c3.7,1,4.5,0.5,4.4-3.4
			c-0.1-3.5-0.3-7-0.5-10.6C-8.8,2608.5-8.7,2607.2-8.7,2605.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M53.8,2585.7c-2.4,0-4.9,0-7.3,0.2c-0.5,0-1,0.1-1.5,0.1c-2.4,0.2-4.9-0.1-7.3,0c-3.5,0.2-7,0.4-10.4,0.8
			c-2,0.2-2.4,0.9-2.4,3.1c0,2,0.1,3.9,0,5.9c0,1.1-0.3,2.2-0.4,3.4c-3.6-0.2-6.8-0.5-9.9-1.4c-1-0.3-1.4-0.8-1.4-1.8
			c0.1-2.6,0.1-5.2,0.1-7.8c0-2.6,0-5.2,0-7.9c0-0.6,0.1-1.3,0.2-2.1c1.3-0.2,2.5-0.5,3.7-0.5c3.3,0,6.6,0.1,9.9,0.3
			c4.4,0.2,8.7,0.4,13.1,0.5c2.5,0.1,5.1,0.2,7.6,0.2C49.8,2581,51.8,2583.3,53.8,2585.7z"
            />
            <path
              class="st0"
              d="M41.5,2571.3c-2.5-0.1-4.9,0-7.4,0.1c-1.9,0.1-3.8,0.1-5.7,0.2c-1.7,0-3.4,0.1-5.1,0.1c-2.7,0-5-1.5-6-4
			c-0.4-1-0.6-2-0.9-3c-0.6-2.3-1.3-4.6-1.9-7c-0.1-0.4-0.1-0.8-0.1-1.2c1.5-1,3.1-1.4,4.8-1.7c0.2,0,0.4-0.1,0.5-0.1
			c1.2-0.2,2.4-0.3,3.6-0.4c1.6-0.1,2.5,0.4,2.9,2c0.3,1.1,0.3,2.2,0.3,3.3c0.1,1.6,0.2,1.8,1.8,1.9c1.7,0.2,3.5,0.4,5.2,0.6
			C36.3,2565.1,38.9,2568.2,41.5,2571.3z"
            />
            <path
              class="st0"
              d="M65.7,2602c-1.2-2.9-3.7-4.4-6.3-5.9c-1.7-1-3.4-1.3-5-1.1c-2.5,0.2-4.8,1.7-6.7,3.8c-2.3,2.6-3.8,6-3.7,9.7
			c0.1,4,1.7,7.4,5.4,9.4c0.1,0.1,0.3,0.1,0.4,0.2c1.7,0.8,3.5,1,5.4,1c1.7,0,3.4-0.3,4.8-1c2.7-1.1,4.7-3.2,5.8-6.1
			c0.3-0.9,0.6-1.9,0.8-2.9c0.2-1,0.3-2,0.3-3C66.7,2604.7,66.2,2603.3,65.7,2602z M62.3,2605.5c0,0.9-0.1,1.8-0.3,2.7
			c-0.5,3-3.1,5.5-6.8,5.4c-0.7,0-1.1,0-1.6,0c-2.7-0.2-4.5-2-4.7-4.7c-0.2-3.5,1.3-6.2,3.8-8.5c1.2-1.1,2.8-1,4.1-0.6
			c1,0.3,1.9,0.8,2.9,1.3c0.5,0.3,0.9,0.5,1.3,0.8c0.7,0.5,1,1.2,1.1,2C62.2,2604.5,62.3,2605,62.3,2605.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-342.6,2326.3c-0.1-4.3-0.7-8.6-1.5-12.8c-0.8-3.7-2-7.3-4.1-10.5c-1.3-2-3-3.8-4.7-5.7
			c-1.4-1.6-2.8-3.3-4.6-4.5c-1.5-1-2.3-2.3-2.9-3.9c-0.1-0.3-0.2-0.7-0.3-1c-0.3-0.6-0.8-1.1-1.4-2.1c-0.6,1.4-1,2.3-1.5,3.4
			c-0.8-0.9-1.5-1.6-2.2-2.4c-1.4,0.7-1.6,1.8-1.8,2.9c-0.3,2.6-1.7,4.5-3.5,6.3c-1.2,1.2-2.4,2.4-3.5,3.6c-0.4,0.5-0.6,1.1-1,1.8
			c1.2,0.4,2.2,0.6,3.2,0.9c-0.2,0.9-0.4,1.7-0.6,2.7c1.8,0.3,3.3,0.5,4.9-0.1c2.1-0.8,4.2-1.5,6.2-2.4c1.9-0.8,3.5-0.1,4.6,1.3
			c1.3,1.7,2.3,3.5,3.4,5.3c0.2,0.4,0.3,0.9,0.2,1.4c-0.4,1.7-0.8,3.4-1.3,5c-0.9,3-1.7,6.1-2.1,9.2c2.6,1.8,3,1.8,5.6-0.1
			c-0.2,6.2-1,12.3-0.8,18.5c1.8,0.4,3.5,0.7,5.1,1c0.8,0.2,1.3-0.1,1.6-0.8c0.1-0.3,0.3-0.7,0.4-1
			C-343.3,2337.2-342.5,2331.8-342.6,2326.3z M-363.5,2298.1c-0.8,0.1-1.4-0.4-1.4-1c0-0.5,0.8-1.4,1.4-1.5c0.6-0.1,1.1,0.6,1.1,1.4
			C-362.3,2297.8-362.8,2298-363.5,2298.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-182.2,2412.2c0-0.5,0-1-0.1-1.5c-3.4,4-6.8,8-10.3,12c-0.2,2.7-0.2,5.4-0.1,8.1c0.2,0.1,0.5,0.1,0.7,0.1
			c1.7,0.3,3.3,0.7,4.9,1c0.8,0.2,1.4-0.1,1.7-0.9c0.1-0.4,0.3-0.7,0.5-1.1c1.1-3.3,1.8-6.6,2.3-9.9
			C-182.2,2417.4-182.1,2414.8-182.2,2412.2z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-308.8,2296.1c-1.2-0.4-2.4-0.7-3.3-2.2c0.4-0.1,0.7-0.2,1.1-0.4c-1.1-1.3-2.2-2.6-3.3-3.9
			c-1.9,0.7-3.9,1.3-5.8,2c-0.2,0.1-0.4,0.1-0.7,0.2c-0.3,0.1-0.6,0.3-0.8,0.5c-1.3,0.9-1.4,1.4-0.2,2.4c1.4,1.1,2.9,2,4.4,3
			c1.7,1.1,3.3,2.3,5.2,3.2c0.1,0,0.1,0.1,0.2,0.1c3.1,1.4,6.3,2.6,9.7,2.8C-304.4,2301.2-306.6,2298.7-308.8,2296.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-402.2,2372.7c-0.1,0-0.1-0.1-0.2-0.1c-1.7-1-3.4-1.9-5.2-2.7c-2.9-1.3-5.9-2.4-9-3.2
			c-5.9-1.6-11.8-0.7-17.7-0.2c-0.1,0-0.3,0.1-0.4,0.1c-2.8,0.3-5.6,1.1-8.2,2c-2.4,0.9-4.9,1.6-7.3,2.5c-0.3,0.1-0.6,0.3-0.8,0.5
			c-1.3,0.9-1.4,1.4-0.1,2.4c1.4,1.1,2.9,2,4.4,3c1.8,1.1,3.5,2.4,5.4,3.3c3.4,1.5,6.9,2.8,10.8,2.8c1.6,0,3.2,0.1,4.7,0.1
			c3.1,0.1,6.2-0.1,9.2-0.9c2.1-0.6,4.1-1.3,6.1-1.9c0.7-0.2,1.4-0.4,2.2-0.6c1.7-0.5,3.1-1.2,4.5-2.1c0.3-0.2,0.6-0.4,0.9-0.6
			c0.9-0.6,1.6-1.6,2.7-2.7C-401.1,2373.6-401.6,2373.1-402.2,2372.7z M-414.2,2376.3c-4.8,1-9.6,1.6-14.5,1.3
			c-2.3-0.1-4.6-1-6.9-1.5c-0.8-0.2-1.7-0.4-2.5-0.7c-1.2-0.4-2.5-0.7-3.4-2.2c10.3-3.4,20.3-4.2,30.2,1.3
			C-412.1,2375.8-413.2,2376.1-414.2,2376.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-275.9,2334.6c-1.1,1.6-2.1,3.3-3.8,4.3c0,0,0,0,0,0c-1.7-0.8-2-2.8-3.4-3.9c-1.3-1.1-2.4-2.3-3.7-3.6
			c-2,2.3-3.1,5-5.6,6.8c-2.9-1.6-4.1-5.1-7-6.9c-2.2,2.6-4.1,5.3-6.9,7.3c-1.6-0.8-2.7-2-3.7-3.5c-0.9-1.4-1.9-2.6-3-4.1
			c-1.1,1-2.1,1.9-3,2.8c-1.1,1.1-2.3,2.2-3.4,3.3c-0.8,0.7-1.3,0.8-1.9,0c-0.9-1.1-1.6-2.3-2.4-3.5c-0.3-0.4-0.5-0.9-0.8-1.5
			c-2.8,1.4-4.4,3.9-6.4,5.5c-1.6-1.4-3-2.7-4.4-3.9c1.5-5.3,4.8-9.6,8-14.1c2.7,1,4,3.8,6.7,4.9c1-0.8,2-1.8,3.1-2.6
			c1.1-0.8,2.4-1.5,3.8-2.4c1.3,1.1,2.9,2.1,4,3.6c0.5,0.7,1.1,1.4,1.7,2c0.8,0.9,1.3,0.9,2.3,0.1c0.7-0.6,1.3-1.2,1.9-1.8
			c1.1-1,2.1-2.1,3.2-3.1c1.1-1,1.4-1,2.5,0.1c1.2,1.3,2.4,2.6,3.6,3.9c0.2,0.2,0.6,0.4,0.9,0.7c0.5-0.3,0.9-0.6,1.3-0.9
			c0.9-0.7,1.7-1.6,2.5-2.5c0.5-0.5,1-1.1,1.5-1.6C-284.1,2325-280,2329.8-275.9,2334.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-113,2469.9c-4.5-2.6-9.3-4.7-14.4-6c-5.9-1.6-11.8-0.7-17.7-0.2c-0.1,0-0.3,0.1-0.4,0.1
			c-2.8,0.3-5.6,1.1-8.2,2c-2.4,0.9-4.9,1.6-7.3,2.5c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.4,0.3-0.6,0.4c-1.3,0.9-1.4,1.4-0.2,2.4
			c1.4,1.1,2.9,2,4.4,3c0.9,0.6,1.8,1.2,2.8,1.8c0.8,0.5,1.7,1.1,2.6,1.4c3.4,1.5,6.9,2.8,10.8,2.8c1.6,0,3.2,0.1,4.7,0.1
			c3.1,0.1,6.2-0.1,9.2-1c2.8-0.8,5.5-1.8,8.3-2.5c2-0.6,3.8-1.6,5.4-2.7c0.9-0.6,1.6-1.6,2.7-2.7
			C-111.9,2470.8-112.4,2470.3-113,2469.9z M-125,2473.5c-4.8,1-9.6,1.6-14.5,1.3c-2.3-0.1-4.6-1-6.9-1.5c-0.8-0.2-1.7-0.4-2.5-0.7
			c-1.2-0.4-2.5-0.7-3.4-2.2c10.3-3.4,20.3-4.2,30.2,1.3C-122.9,2472.9-123.9,2473.3-125,2473.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-171.5,2516.5c2.2-1.8,4.1-4.6,7.2-6.2c0.3,0.7,0.5,1.2,0.8,1.7c0.9,1.3,1.7,2.7,2.7,3.9
			c0.7,0.9,1.3,0.8,2.1,0c1.3-1.2,2.5-2.5,3.8-3.7c1-1,2.1-1.9,3.3-3.1c1.2,1.6,2.4,3.1,3.4,4.6c1.1,1.6,2.3,3,4.1,3.9
			c3-2.2,5.2-5.3,7.7-8.2c3.2,2.1,4.5,5.9,7.8,7.7c2.8-2,4-5,6.2-7.6c1.5,1.4,2.8,2.8,4.2,4c1.5,1.3,1.9,3.5,3.8,4.4
			c3.1-1.7,4.1-5.3,6.6-7.7c1.8,2,3.4,3.8,5.2,5.7c1.8-2,3.4-3.7,4.9-5.5c0.7-0.8,0.8-1.5,0.1-2.4c-2.9-3.5-5.8-7-8.7-10.4
			c-0.4-0.4-0.9-0.7-1.3-1c-2.5,2-4.9,3.9-7.4,5.9c-0.8-0.8-1.5-1.6-2.2-2.3c-1.2-1.3-2.2-2.6-3.4-3.8c-0.9-0.9-1.3-0.9-2.4-0.1
			c-0.9,0.8-1.7,1.6-2.5,2.5c-1.3,1.4-2.6,2.8-4.3,3.7c-0.4-0.3-0.7-0.5-1-0.7c-1.4-1.4-2.7-2.9-4.1-4.3c-1.2-1.2-1.6-1.3-2.8-0.2
			c-1.2,1.1-2.4,2.3-3.5,3.4c-0.7,0.7-1.4,1.4-2.1,2c-1.1,0.9-1.6,0.9-2.5-0.1c-0.7-0.7-1.3-1.5-1.9-2.3c-1.3-1.7-3-2.8-4.5-4
			c-1.6,1-2.9,1.8-4.2,2.7c-1.3,0.9-2.4,2-3.5,2.9c-3-1.3-4.5-4.4-7.5-5.4c-3.6,5-7.3,9.8-9,15.7
			C-174.9,2513.5-173.3,2514.9-171.5,2516.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-625.4,2418.6c3.8,1,7.7,0.9,11.6,1.1c8.1,0.3,16.2-0.2,24.3-1c3.2-0.3,6.4-1,9.4-2.2c2-0.7,3-2,3.1-4.1
			c0.2-3.5,0.4-6.9,0.6-10.4c0.1-1.2,0.5-2.4-0.2-3.6c-0.1-0.3-0.1-0.6-0.1-1c0.1-3.6,0.2-7.2,0.2-10.8c0-1.8-0.4-3.6-0.7-5.4
			c-0.1-0.6-0.8-1.3-1.3-1.5c-2.4-0.5-4.8-0.7-7.1,0.5c-1.1,0.6-1.7,1.3-1.7,2.6c-0.1,5.8-0.3,11.7-0.4,17.5c0,4.1-0.4,4.6-4.5,5.5
			c-0.2,0-0.4,0.1-0.6,0.1c-6.4,0.9-12.8,2-19.2,2.2c-4.6,0.2-9.2,0-13.8-1c-0.5-0.1-1-0.1-1.6-0.2c-0.1,0.5-0.2,0.9-0.2,1.2
			c0.3,2.9,0.6,5.9,0.8,8.8C-627,2417.9-626.4,2418.3-625.4,2418.6z"
            />
            <path
              class="st0"
              d="M-638.4,2433.7c0.2,1.5,1,2.6,2,2.6c3,0,5.2-0.5,5.9-4.2c0.6-3.5,0.8-7,0.6-10.6c-0.2-4.2-0.4-8.5-0.6-12.7
			c-0.1-1.4,0-2.9,0-4.4c-2.5-0.5-2.8-0.4-3,1.6c-0.2,1.5-0.1,3-0.1,4.5c0,0.9,0.2,1.9-0.1,2.7c-0.5,1.5-0.3,2.9,0.2,4.2
			c0.5,1.5,0,2.6-1,3.5c-0.6,0.6-1.4,1.1-2.2,1.3c-1.2,0.3-1.7,1.1-1.9,2.2c-0.1,0.7-0.1,1.4,0,2.2c0.1,1.8,0.3,3.5,0.4,5.3
			C-638.4,2432.5-638.4,2433.1-638.4,2433.7z"
            />
            <path
              class="st0"
              d="M-635,2407.6c-0.5,1.2-0.6,2.3-0.3,3.4c0.5,1.7-0.1,2.7-1.4,3.6c-0.1,0.1-0.2,0.2-0.4,0.4
			c0.8,0.6,1.2,1.2,1.4,1.9c0.1,0.5,0.1,1.1-0.2,1.7c-0.3,0.9-1.4,1.6-2.7,1.6c-0.1,0-0.2,0-0.3,0c-1.6-0.2-3.1-0.6-4.6-0.9
			c-0.5-0.1-0.9-0.4-1.2-0.8c3.3-3.8,6.6-7.7,9.8-11.5C-634.9,2407.1-635,2407.4-635,2407.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-176,2605c3.8,1,7.7,0.9,11.6,1.1c8.1,0.3,16.2-0.2,24.3-1c3.2-0.3,6.4-1,9.4-2.2c2-0.7,3-2,3.1-4.1
			c0.2-3.5,0.4-6.9,0.6-10.4c0.1-1.2,0.5-2.4-0.2-3.6c-0.1-0.3-0.1-0.6-0.1-1c0.1-3.6,0.2-7.2,0.2-10.8c0-1.8-0.4-3.6-0.7-5.4
			c-0.1-0.6-0.8-1.3-1.4-1.5c-2.4-0.5-4.8-0.7-7.1,0.5c-1.1,0.6-1.7,1.3-1.7,2.6c-0.1,5.8-0.3,11.7-0.4,17.5c0,4.1-0.4,4.6-4.5,5.5
			c-0.2,0-0.4,0.1-0.6,0.1c-6.4,0.9-12.8,2-19.2,2.2c-4.6,0.2-9.2,0-13.8-1c-0.5-0.1-1-0.1-1.6-0.2c-0.1,0.5-0.2,0.9-0.2,1.2
			c0.3,2.9,0.6,5.9,0.8,8.8C-177.6,2604.4-177,2604.8-176,2605z"
            />
            <path
              class="st0"
              d="M-167.4,2550.6c-2.2-1.6-5.2-1-6.6,1.5c-0.7,1.3-1.6,1.6-2.9,1.4c-0.6-0.1-1.2-0.1-1.8-0.2
			c-3.2-0.4-6.4-0.8-9.6-1.2c-2.3-0.3-4.4-0.4-6.2-2.4c-1.4-1.7-4.5-0.9-5.5,1c-0.2,0.4-0.1,1,0.1,1.4c0.9,1.4,1.8,2.9,2.8,4.2
			c0.9,1.1,1.3,2.2,1.3,3.6c0,2.8,0.1,5.6,0.4,8.4c0.3,2.5,0.9,5,1.6,7.5c0.3,1.1,1.1,2.1,1.9,2.9c1.5,1.6,2.9,3.1,3,5.3
			c0,0.3,0.1,0.5,0.3,1c2.5-0.6,4.8,0.8,7.5,0.3c0.1-2.3,1.4-3.7,3.1-5c1.7-1.4,3.3-3.1,4.6-4.8c1.6-2.1,2.5-4.6,2.9-7.2
			c0.4-2.5,0.6-5.1,0.8-7.6c0.1-1.6,0.6-2.9,1.9-3.9c0.4-0.3,0.8-0.6,1-0.9C-165.2,2553.7-165.4,2552.1-167.4,2550.6z
			 M-186.3,2571.1c1.6-0.9,4.7-1,6.6-0.1C-181.5,2574.1-184.5,2574.1-186.3,2571.1z M-177,2563.7c-0.1,0.5-0.3,1-0.4,1.5
			c-2.5,0-4.9,0.1-7.3,0.1c-0.9,0-1.8-0.1-2.8-0.1c-1.5-0.1-1.8-0.4-1.9-1.7c0-1,0.3-1.2,1.6-1.5c3.2-0.5,6.4-0.2,9.6,0.3
			C-177.2,2562.3-176.8,2562.8-177,2563.7z"
            />
            <path
              class="st0"
              d="M-189,2620.1c0.2,1.5,1,2.6,2,2.6c3,0,5.2-0.5,5.9-4.2c0.6-3.5,0.8-7,0.6-10.6c-0.2-4.2-0.4-8.5-0.6-12.7
			c-0.1-1.4,0-2.9,0-4.4c-2.5-0.5-2.8-0.4-3,1.6c-0.2,1.5-0.1,3-0.1,4.5c0,0.9,0.2,1.9-0.1,2.7c-0.5,1.5-0.3,2.9,0.2,4.2
			c0.5,1.5,0,2.6-1,3.5c-0.6,0.6-1.4,1.1-2.2,1.3c-1.2,0.3-1.7,1.1-1.9,2.2c-0.1,0.7-0.1,1.4,0,2.2c0.1,1.8,0.3,3.5,0.4,5.3
			C-188.9,2618.9-189,2619.5-189,2620.1z"
            />
            <path
              class="st0"
              d="M-185.3,2589.8c0-0.6-0.7-1.4-1.3-1.8c-1.1-0.7-2.4-1.1-3.6-1.6c-1.5-0.6-3,0-4.5,0.2c-0.3,0-0.7,0.4-0.9,0.7
			c-0.7,1.6-1.4,3.2-1.2,5.1c0.2,2.2,0.2,4.4,0.3,6.6c0.1,1.6,0.2,3.1,0.5,4.7c0.2,0.9,0.6,1.8,1.7,2c1.5,0.4,3.1,0.8,4.6,0.9
			c1.3,0.2,2.6-0.6,2.9-1.6c0.5-1.4,0.2-2.5-1.2-3.6c0.2-0.2,0.3-0.3,0.4-0.4c1.3-0.9,2-1.9,1.4-3.6c-0.3-1.1-0.2-2.2,0.3-3.4
			C-185.1,2592.8-185.2,2591.2-185.3,2589.8z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-557.6,2411.1c1.2,1,3.2,1.1,4.6,0.4c1-0.5,2-1,3-1.5c3.3-1.6,6.6-3.2,10-4.7c2.7-1.2,3.9-3.6,5.1-6
			c0.4-0.8,0.3-1.7-0.4-2.3c-0.7-0.6-1.6-0.6-2.4-0.2c-0.5,0.2-0.9,0.5-1.3,0.8c-1.8,1.3-3.5,2.7-5.4,4c-1,0.7-2,1.2-3,1.8
			c-2.3,1.3-5.1,1.3-7.2,3c-0.5,0.4-1,0.6-1.5,1c-0.8,0.5-1.5,0.6-2.4,0.2c-1.2-0.5-1.9-1.3-2.3-2.5c-0.4-1.3-0.8-2.6-1.2-3.9
			c-0.3-0.9-0.6-1.7-1.7-2c-1.4,1.2-1.4,2.8-1.6,4.3c-0.2,1.8-0.3,1.9-2,2.4c-1.4,0.4-1.7,0.8-1.4,2.3c0.9,4.4,1.3,8.8,1.3,13.3
			c0,2,0.2,3.9,1,5.8c1.3,3.1,5.6,4.5,8.3,0.9c1.3-1.8,2.3-3.8,3.5-5.7c1.6-2.5,3.7-4.6,6-6.4c0.1,0.1,0.3,0.2,0.4,0.3
			c-0.2,0.6-0.3,1.1-0.5,1.7c-1.3,3.4-2.6,6.8-4,10.2c-0.5,1.4-0.3,2,1.1,2.7c1.1,0.5,2.3,1.1,3.4,1.6c1.7,0.7,3.5,1.1,5.4,0.5
			c1.2-0.4,1.5-1,0.9-2.1c-0.5-0.8-1.1-1.6-1.8-2.3c-1.3-1.3-1.4-1.9-0.6-3.6c1.2-2.4,2.3-4.9,3.6-7.2c1.3-2.3,0.1-6.2-2.9-7
			c-2.8-0.8-5.4-0.2-7.7,1.3c-2.5,1.5-4.9,3.3-7.3,5c-0.4,0.3-0.9,0.5-1.7,0.9c0.1-1.8,0.2-3.1,0.3-4.5c0.1-1.3-0.6-2.6,0-4.2
			C-559.2,2409.8-558.4,2410.5-557.6,2411.1z"
            />
            <path
              class="st0"
              d="M-570.7,2401.5c-0.3,1.7,0.4,2.3,2.4,2.5c1.7,0.1,2-0.1,2-2c0-1.7,0.8-3,1.5-4.4c0.5-1,1.3-1,2.1-0.2
			c0.5,0.6,0.9,1.3,1.2,2c0.8,1.8,1.5,3.6,2.2,5.4c0.7,1.7,1.2,1.8,2.9,1c0.2-0.1,0.5-0.3,0.7-0.4c3.1-1.7,3.2-1.7,2.2-5.2
			c-0.5-1.6-1-3.2-1.8-4.5c-1.1-1.8-2.6-3.4-3.9-5.2c-0.4-0.6-0.9-1.2-1.1-1.8c-0.6-1.2-0.3-2,0.9-2.5c0.8-0.3,1.6-0.6,2.4-0.8
			c1.3-0.3,2.7-0.5,4.1-0.7c1.3-0.2,2.2-0.8,3-1.8c0.9-1.2,2-2.3,2.8-3.6c0.6-1,1-2.3,1.1-3.5c0.1-1.4-1.6-2.4-2.6-1.8
			c-1.1,0.7-2,1.6-3,2.5c-0.3,0.3-0.6,0.6-1.1,1c-0.3-1.6-0.4-2.9-0.8-4.1c-0.2-0.7-0.5-1.5-1-2.1c-0.5-0.7-1.8-1-2.7-0.6
			c-0.9,0.3-0.7,1.1-0.8,1.8c-0.1,2-0.3,4.1-0.5,6.1c0,0.5-0.3,1.1-0.7,1.3c-1.2,0.6-2.8,0-3.5-1.2c-1.1-1.6-2.1-3.3-3.4-4.8
			c-2.2-2.5-2.9-2.4-4.6,0.7c1,1.9,2,3.8,3,5.7c0.3,0.6,0.8,1.3,0.8,2c-0.1,3.4,0,6.9-1.6,10.2
			C-569.8,2395.3-570.1,2398.4-570.7,2401.5z"
            />
            <path
              class="st0"
              d="M-545.1,2387.2c-1.8-1-3.8-1.5-5.9-1.4c-1.9,0.1-3.8,0.4-5.7,0.6c-0.4,0-0.8,0.1-1.2,0.3
			c-1.2,0.5-1.6,1.6-0.8,2.7c0.4,0.6,0.9,1.1,1.4,1.6c1.4,1.2,2.5,2.5,3.1,4.2c0.4,1.1,1.2,1.5,2.3,1.5c0.5,0,0.9,0,1,0
			c1.2-0.1,2.1-0.2,2.9-0.3c2.2-0.2,3.5-1.6,4.4-3.5C-542.4,2390.5-542.9,2388.5-545.1,2387.2z M-550.1,2391.6
			c-0.8,0.1-1.4-0.4-1.5-1.8c0.8,0.1,1.5,0.1,2.5,0.2C-549.6,2390.8-549.8,2391.6-550.1,2391.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-94.2,2596.2c1.5,1.2,3.9,1.4,5.6,0.5c1.2-0.7,2.4-1.3,3.6-1.8c4-1.9,8.1-3.9,12.1-5.7
			c3.3-1.5,4.8-4.4,6.2-7.3c0.5-1,0.3-2.1-0.5-2.9c-0.9-0.7-1.9-0.8-2.9-0.3c-0.6,0.3-1.1,0.6-1.6,1c-2.2,1.6-4.3,3.3-6.5,4.9
			c-1.2,0.8-2.4,1.5-3.7,2.2c-2.8,1.6-6.2,1.5-8.8,3.6c-0.6,0.4-1.2,0.8-1.8,1.2c-0.9,0.7-1.9,0.7-2.9,0.2c-1.4-0.6-2.3-1.6-2.8-3.1
			c-0.5-1.6-1-3.2-1.5-4.8c-0.3-1.1-0.8-2.1-2-2.4c-1.8,1.4-1.7,3.4-1.9,5.3c-0.2,2.1-0.4,2.3-2.4,2.9c-1.7,0.5-2,1-1.7,2.8
			c1.1,5.3,1.6,10.7,1.5,16.1c0,2.4,0.3,4.7,1.3,7c1.6,3.8,6.8,5.4,10,1c1.6-2.2,2.7-4.6,4.2-6.9c1.9-3,4.5-5.5,7.3-7.8
			c0.2,0.1,0.3,0.2,0.5,0.3c-0.2,0.7-0.3,1.4-0.6,2c-1.6,4.2-3.2,8.3-4.8,12.5c-0.6,1.7-0.3,2.5,1.3,3.2c1.4,0.7,2.7,1.3,4.1,1.9
			c2.1,0.8,4.3,1.3,6.6,0.7c1.4-0.4,1.8-1.2,1.1-2.5c-0.6-1-1.4-2-2.2-2.8c-1.6-1.6-1.8-2.4-0.8-4.4c1.4-2.9,2.8-5.9,4.4-8.8
			c1.6-2.8,0.1-7.5-3.5-8.5c-3.4-1-6.5-0.2-9.4,1.5c-3.1,1.9-5.9,4-8.9,6c-0.5,0.4-1.1,0.6-2.1,1.1c0.1-2.2,0.2-3.8,0.4-5.5
			c0.1-1.6-0.7-3.2,0-5.1C-96.2,2594.7-95.2,2595.5-94.2,2596.2z"
            />
            <path
              class="st0"
              d="M-110.2,2584.5c-0.4,2.1,0.5,2.8,2.9,3c2.1,0.1,2.4-0.1,2.4-2.5c0-2,0.9-3.7,1.8-5.4c0.7-1.2,1.6-1.3,2.5-0.2
			c0.6,0.7,1.1,1.6,1.5,2.4c1,2.2,1.8,4.4,2.7,6.6c0.9,2,1.5,2.2,3.5,1.2c0.3-0.1,0.6-0.3,0.8-0.5c3.8-2.1,3.9-2.1,2.6-6.3
			c-0.6-1.9-1.2-3.8-2.2-5.5c-1.4-2.2-3.2-4.2-4.8-6.3c-0.5-0.7-1-1.4-1.4-2.2c-0.7-1.5-0.3-2.4,1.2-3.1c0.9-0.4,2-0.7,3-0.9
			c1.6-0.3,3.3-0.6,4.9-0.9c1.5-0.2,2.7-1,3.6-2.2c1.1-1.4,2.5-2.8,3.3-4.4c0.7-1.3,1.2-2.8,1.3-4.2c0.1-1.7-1.9-3-3.1-2.2
			c-1.3,0.8-2.4,2-3.6,3c-0.4,0.3-0.7,0.7-1.3,1.3c-0.3-1.9-0.5-3.5-0.9-5c-0.2-0.9-0.7-1.8-1.2-2.6c-0.6-0.9-2.2-1.2-3.3-0.8
			c-1.1,0.4-0.9,1.4-0.9,2.2c-0.2,2.5-0.3,4.9-0.6,7.4c-0.1,0.5-0.4,1.3-0.8,1.5c-1.5,0.7-3.3,0-4.3-1.4c-1.3-2-2.6-4.1-4.1-5.8
			c-2.7-3.1-3.5-2.9-5.6,0.9c1.2,2.3,2.5,4.6,3.7,6.9c0.4,0.8,1,1.6,1,2.4c-0.2,4.2-0.1,8.4-1.9,12.4
			C-109.1,2577.1-109.5,2580.8-110.2,2584.5z"
            />
            <path
              class="st0"
              d="M-79.1,2567.2c-2.1-1.2-4.5-1.8-7-1.7h-0.2c-2.3,0.1-4.6,0.5-6.9,0.7c-0.5,0.1-0.9,0.1-1.4,0.3
			c-1.5,0.6-1.9,1.9-1,3.3c0.5,0.7,1.1,1.4,1.8,2c1.7,1.4,3,3.1,3.8,5.1c0.5,1.3,1.5,1.8,2.8,1.8c0.6,0,1.1,0,1.2,0
			c1.5-0.1,2.5-0.2,3.5-0.3c2.2-0.2,3.7-1.4,4.7-3.1c0.2-0.3,0.4-0.7,0.6-1.1C-75.7,2571.2-76.4,2568.8-79.1,2567.2z M-85.2,2572.5
			c-1,0.1-1.7-0.5-1.8-2.1c1,0.1,1.8,0.2,3.1,0.3C-84.5,2571.6-84.8,2572.5-85.2,2572.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-516.1,2420.3c0.3,1.1,1.1,2.2,2,2.9c3.9,3,7.8,5.9,13.1,6.5c5.4,0.6,10.7,0.8,16-0.8c3.5-1,7-2,10.4-3
			c3.7-1.1,7.3-2.2,10.6-4.1c3.1-1.8,6-3.7,9.1-5.4c2.3-1.3,4.2-2.9,5.8-5c0.8-1,1.3-2.3,1.7-3.5c0.4-1.2-0.2-1.8-1.4-1.6
			c-1,0.1-2.1,0.5-2.9,1c-3.9,2.4-7.7,5.1-11.9,7c-2.9,1.3-5.6,2.9-8.5,4.3c-4.7,2.2-9.8,3.1-14.8,4.2c-3.8,0.8-7.6,1.3-11.4,0.7
			c-2.9-0.4-5.6-1.3-7.9-3.4c-2-1.9-2.8-4.4-3.7-6.8c-0.4-1.1-0.5-2.3-0.4-3.5c0-1.4,0.7-1.8,2.1-1.8c1.5,0.1,2.8,0.7,4.1,1.4
			c1.1,0.6,1.8,1.3,1.8,2.6c0,1.2,0.7,1.8,1.7,2.2c1.1,0.4,2,0,2.7-0.6c2.8-2.4,2.6-6.3-0.3-8.5c-1.7-1.3-3.3-2.6-5.4-3.1
			c-0.7-0.2-1.2-0.7-1.8-1c-0.1-1.2-0.1-2.3-0.3-3.4c-0.3-1.8-1.3-2.2-2.8-1.4c-0.9,0.4-1.7,0.9-2.8,1.4c0-0.7,0-1.1,0-1.5
			c-0.1-0.7-0.2-1.4-0.5-2.1c-0.4-1-1.9-1.9-2.9-1.8c-1.1,0.1-1.3,1.1-1.5,1.9c-0.3,1.5-0.4,2.9-0.5,4.4c0,1.6-1.1,2.4-2.6,1.8
			c-0.4-0.1-0.7-0.4-1-0.6c-1.4-1.1-2.8-2.1-4.3-3.2c-2.1-1.5-4.1-3.1-7.3-2.5c0.6,1.5,1,2.8,1.7,3.9c2.1,3,4.6,5.5,8.2,6.7
			c0.8,0.3,1.6,0.5,2.4,0.8c0.8,0.3,1.3,0.8,1.3,1.7c0,0.5,0,0.9,0,1.4C-518.2,2412.6-517.3,2416.4-516.1,2420.3z"
            />
            <path
              class="st0"
              d="M-523,2378.4c2.8,3.2,5.6,6.5,8.4,9.8c-0.6-0.1-1.2-0.2-1.8-0.3c-1.6-0.3-3.2-0.6-4.8-0.9
			c-0.8-0.1-1.6-0.3-2.3-0.4c-1.5-0.3-2.9-0.5-4.4-0.8c-0.3-0.1-0.6-0.2-1.2-0.5c0.6-1.2,1.1-2.3,1.6-3.4c0.4-0.7,0.7-1.4,1.1-2.1
			C-525.7,2378.5-524.4,2378.2-523,2378.4z"
            />
            <path
              class="st0"
              d="M-459.5,2376.7c-0.4,0.9-0.8,2-1.5,2.7c-2.2,2.5-4.8,4.4-7.5,6.3c-4,2.9-8.5,4.8-13.4,5.4
			c-3.9,0.5-7.7,0.5-11.6,0c-1.2-0.2-2.4-0.3-3.7-0.5c-1.2-0.1-2.4-0.2-3.6-0.3c-0.3,0-0.6,0-0.9-0.1c-0.3,0-0.6-0.1-0.9-0.1
			c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.5-0.1-0.8-0.1c0,0-0.1,0-0.1,0c2.3-2.6,4.5-5.3,6.7-7.9c2-0.1,4.1-0.1,6.1-0.2
			c0.6,0,1.1,0,1.7,0c2.7,0,5.3,0,7.9-0.7c2.7-0.7,5.5-1.5,8.1-2.4c3.8-1.4,7.6-3,11.4-4.5c0.7-0.3,1.4-0.5,2,0.1
			C-459.7,2375-459.2,2375.7-459.5,2376.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-427.9,2412.3c0-1.7-0.4-2.2-2.1-2.5c-0.8-0.1-1.7-0.2-2.6-0.2c-3.3,0.1-3.5,0.2-3.8,3.6
			c-0.1,1.5-0.1,3-0.2,4.5c-0.1,2.4-0.3,4.7-0.3,7.1c0,3.1,1.1,4.2,4.2,4.4c0.3,0,0.5,0,0.8,0c2.4,0.1,4.9,0.2,8,0.3
			c3.4,0,7.6-0.1,11.7-0.1c0.9,0,1.9,0,2.8-0.1c1.5-0.2,1.8-0.5,2.1-1.9c0.2-0.9,0.3-1.8,0.3-2.7c0.2-2.6-0.1-2.9-2.6-3.5
			c-0.4-0.1-0.8-0.1-1.2-0.1c-3.9-0.2-7.9-0.4-11.8-0.7c-1.3-0.1-2.6-0.2-3.9-0.3c-1.5-0.2-1.8-0.5-1.8-2c0-0.5,0-1,0.1-1.6
			C-428,2415-427.9,2413.7-427.9,2412.3z"
            />
            <path
              class="st0"
              d="M-414.4,2389.1c-1.9,0.4-2.7,2.1-4.1,2.8c-1.6,0-2.8-1.7-4.6-1.1c0.5,1.5,0.9,2.8,1.3,4.2
			c0.8,2.7,1.1,5.4,1,8.2c-0.1,2.3-0.4,4.6-0.6,6.9c-0.1,1.6,0.2,3.1,0.9,4.6c0.8,1.6,2.5,2.4,4.3,2.2c0.8-0.1,1.7-0.1,2.3-0.5
			c1.3-0.8,2.6-1.7,3.5-2.8c0.5-0.7,0.6-2,0.4-3c-0.4-1.9-1-3.8-1.8-5.6c-1.3-2.7-2.3-5.4-2.8-8.3c-0.3-1.4-0.3-2.7,0.1-4.1
			C-414.2,2391.5-413.8,2390.4-414.4,2389.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-674.9,2496.3c-0.4,1.2-0.9,2.5-1.7,3.5c-1.6,2-3.5,3.7-5.8,4.9c-3.1,1.7-6.1,3.7-9.2,5.4
			c-3.3,1.9-7,3.1-10.6,4.1c-3.5,1-7,1.9-10.4,3c-3.3,1-6.7,1.3-10.1,1.2c-2,0-3.9-0.2-5.9-0.4c-0.4,0-0.7-0.1-1.1-0.2
			c1.7-2,3.4-3.9,5.1-5.9c2.6,0.3,5.3,0,7.9-0.4c0.8-0.1,1.6-0.3,2.3-0.4c5-1.1,10.1-2,14.8-4.2c2.9-1.3,5.6-2.9,8.5-4.3
			c4.2-2,7.9-4.7,11.9-7.1c0.9-0.5,1.9-0.9,2.9-1C-675.1,2494.5-674.5,2495.1-674.9,2496.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-348.2,2396.3c-1.4-0.2-2.8-0.2-4.1-0.4c-3.8-0.6-7.7-0.7-11.5-1.8c-2.3-0.7-2.6-0.8-2.7-3.2
			c-0.2-4.2-0.2-8.4-0.3-12.6c-0.1-2.2-0.3-4.4-0.4-6.6c-0.1-0.6-0.1-1.3-1.3-1.5c-3.3,6.5-5.2,13.7-8.6,20.5
			c-2.6-0.1-4.8-1-7.1-1.9c-3.6-1.4-7.3-2.8-10.9-4.1c-1.5-0.5-3.2-0.9-4.8,0c0.3,1.6,2.1,4.6,3.5,6c3,2.9,6,5.9,9,8.8
			c0.7,0.7,1.3,1.5,2,2.3c-0.4,0.5-0.8,1-1.2,1.4c-3,3.3-6,6.6-8.8,10.1c-1,1.2-1.7,2.8-2.4,4.3c-0.2,0.4-0.2,1.1-0.3,1.8
			c8.7-1.3,15.7-5.6,22.9-9.7c0.5,0.7,1.1,1.3,1.6,2c2.6,3.4,4.5,7.2,6.2,11.1c0.7,1.5,1.6,2.9,3.1,3.8c1.5,0.9,3,0.2,3-1.5
			c0-1.1,0-2.3-0.2-3.3c-0.8-3.6-1.7-7.1-2.5-10.7c-0.5-2.3-1-4.6-1.5-6.9c0.5-0.2,0.7-0.3,1-0.4c5.1-0.8,9.8-2.6,14.6-4.2
			c1.4-0.4,2.7-1.2,3.7-2.8C-346.9,2396.6-347.5,2396.3-348.2,2396.3z M-369.3,2403.3c-1.5,2.1-3.7,2.1-5.8,1.1
			c-2.3-1.1-3.2-3.1-3.7-5.3c-0.4-1.9,0.7-3.9,2.5-4.6c0.9-0.4,2-0.5,3-0.8c2.9,0.1,4.7,1.5,5.1,4.3
			C-367.9,2399.9-368.2,2401.7-369.3,2403.3z"
            />
            <path
              class="st0"
              d="M-373,2401.7c1.3,0,2.2-1.5,2.1-3.5c0-1.6-0.8-2.3-2.5-2.3c-1.5,0-3,1.5-3,2.9
			C-376.2,2400.1-374.4,2401.7-373,2401.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-276.9,2392.1c0-1,0-2.1,0.1-3.1c0.1-1.4,0-1.6-1.4-1.8c-0.6-0.1-1.3-0.2-2-0.3c-0.2-1.7-0.3-3.2-0.5-4.8
			c-0.2-1.7-0.9-2.3-2.5-2.4c-1.1-0.1-1.6,0.6-1.8,2.3c-0.1,0.9-0.1,1.8-0.2,2.7c-0.1,0.6-0.2,1.2-0.3,2c-1.2-0.1-2.3-0.1-3.3-0.2
			c-1.4-0.2-1.5-0.3-1.7-1.7c-0.1-1.1-0.2-2.2-0.3-3.3c-0.2-1.3-1-2.3-2-2.4c-1.4-0.1-2.1,0.3-2.7,1.8c-0.2,0.6-0.4,1.2-0.5,1.9
			c-0.2,1.5-0.3,3-0.5,4.5c-1.5,0-3,0-4.4,0c-1.2,0-1.7-0.6-1.8-1.6c-0.2-1.2-0.3-2.3-0.3-3.5c0-1.3-0.6-1.9-1.9-2
			c-1.8-0.2-2.6,0.2-3,1.6c-0.3,1.1-0.5,2.2-0.7,3.3c-0.1,0.9-0.5,1.3-1.4,1.3c-1.2,0-2.5-0.1-3.9-0.2c-0.1-0.9-0.2-1.6-0.4-2.4
			c-0.2-1-0.2-2-0.5-2.9c-0.4-1.3-1.7-1.9-3-1.5c-1.2,0.3-1.9,1.2-2,2.4c-0.2,1.4-0.2,2.8-0.3,4.5c-0.3,0.1-0.8,0.4-1.3,0.4
			c-3.6,0.6-7.1,0-10.6-0.7c-1-0.2-2-1-2.8-1.7c-1.4-1.3-1.4-3-0.4-4.6c0.4-0.6,0.8-1.2,1.2-1.8c0.6-1,1-2.1,0.7-3.3
			c-1.5-0.8-2.9-0.4-3.9,0.5c-1.4,1.2-2.8,2.6-4,4c-1.3,1.5-1.6,3.3-1.2,5.2c0.3,1.1,0.7,2.2,1.3,3.2c1.7,3,4.1,5.2,7.6,5.7
			c3.3,0.5,6.7,0.8,10,1c1.7,0.1,3.4,0,5.1,0c2.7-0.1,5.4-0.3,8.1-0.4c3.7,0,7.4,0.5,11,0.5c3.9,0,7.8,1,11.7,0.6
			c2.8-0.2,5.5-0.1,8.3-0.2C-277.8,2394.7-276.9,2393.5-276.9,2392.1z"
            />
            <path
              class="st0"
              d="M-289,2420.4c-0.6-3.4-1.1-6.9-1.2-10.3c-0.1-2.5-0.4-4.8-1.4-7.1c-0.3-0.6-0.7-1.2-1.3-2
			c-1.4,1.7-2.3,3.4-3,5.1c-1.1,2.9-2.3,5.7-3.4,8.6c-0.5,1.3-1.2,2.6-1.8,3.9c-0.8,1.6-2,2.1-3.7,1.8c-2.4-0.3-4.8-0.6-7.2-0.9
			c-3.4-0.4-6.8-0.1-10.3,0.2c-1.8,0.1-3.7,0.3-5.5,0.3c-2.4,0-3-0.5-3.4-2.9c-0.1-0.6-0.1-1.3-0.3-1.9c-0.5-2.4-1-4.9-1.6-7.3
			c-0.4-1.4-1.6-2.1-3.2-2.1c-1.2,0-1.7,0.4-2.3,2c-0.2,0.4-0.3,0.9-0.3,1.3c-0.1,5.1-0.4,10.2,0.1,15.3c0.2,2.1,0.6,2.6,2.8,2.8
			c1.6,0.1,3.3-0.1,4.9-0.1c3.4-0.1,6.7-0.2,10.1-0.2c2.5,0,5,0.2,7.5,0.2c2.1,0,4.2-0.2,6.3-0.3c2.7-0.1,5.2,0.6,7.5,2.1
			c1,0.7,2,1.3,2.9,2c1.5,1,2.9,1.5,4.8,0.9c2.4-0.8,4.3-2.3,6-4c1.5-1.5,1.6-2.6,0.1-4.2c-0.3-0.4-0.9-0.6-1.2-0.9
			C-287.8,2422-288.9,2421.2-289,2420.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-657.2,2511.9c-0.1,2.3,1.6,3.8,4,3c2.8-1,4.9-2.7,5.5-5.8c0.4-1.9,1-3.8,1.6-5.6c1.2-3.3,1.7-3.4,4.1-0.9
			c1.5,1.5,3.2,1.9,5.2,1.7c2.1-0.3,3-1.2,3.4-3.4c0.3-2.1,0.8-4.3,0.7-6.4c-0.1-5.7-0.7-8.4-7.1-10.9c-0.8-0.3-1.5-0.8-2.3-1.1
			c-0.9-0.3-1.8-0.6-2.8-0.9c-1.7-0.5-3.6-0.9-4.6-2.4c-1.3-1.8-2.3-3.8-3.2-5.9c-1.6-3.7-2.9-7.4-4.5-11.1
			c-0.5-1.1-1.2-2.2-1.9-3.2c-0.6-0.8-1.5-1-2.5-0.7c-1,0.3-1.4,0.9-1.3,1.9c0,1.2-0.1,2.5,0,3.7c0.3,3.9,1.6,7.5,2.7,11.2
			c0.9,3,1.8,6,2.5,9.1c0.4,1.9,0.5,3.9,0.6,5.8c0.1,1.6,0.3,3.1,0.3,4.7c0,3.7-0.1,7.5-0.2,11.2
			C-656.9,2507.8-657.1,2509.6-657.2,2511.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-614.9,2479.7c-0.1,0.7-0.1,1.2-0.2,1.6c-0.2,4.8,0,9.6,0.6,14.3c0.5,4.7,1.6,9.1,4.4,13.1
			c1.4,2,3.1,3.4,5.3,4.3c2.1,0.8,4.1,0.2,5.6-1.6c1.1-1.3,1.7-2.8,1.9-4.5c0.4-3,0.8-6,1.1-9c0.4-3.5,1.1-6.9,3.2-9.8
			c1-1.4,2.1-2.9,3.1-4.3c0.4-0.6,0.8-1.3,1.4-2.2c-2.2-0.4-4.1-0.9-6-1.1c-2.7-0.2-5.5-0.6-8.2,0.3c-1.8,0.6-2.2,1.2-1.7,2.9
			c0.6,1.9,1.2,3.9,1.9,5.8c1.6,4.6,2,9.2,0.1,13.8c-0.2,0.5-0.5,1.1-0.9,1.5c-1,1.2-2.2,1.2-3.2,0c-0.6-0.8-1.3-1.7-1.5-2.7
			c-0.4-1.5-0.5-3.1-0.5-4.7c-0.2-5.6,0.2-11.2,0.9-17C-609.7,2478.6-612.3,2479.8-614.9,2479.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-571.6,2512c3.3,0.8,6.7,1.4,10.1,1.6c2,0.1,3.5-1.2,3.8-3.2c0.2-1,0.3-2.1,0.3-3.1
			c0.2-4.7,0.4-9.4,0.5-14.1c0.1-2.4,0-4.8-0.2-7.3c-0.1-1.7-0.9-2.6-2.2-2.8c-2.7-0.4-5.5-0.8-8.2-1c-2.1-0.1-4.2,0-6.2,1.2
			c-0.7,3.7-0.9,7.4-0.7,11.1c0.2,3.7,0.4,7.5,0.6,11.2c0.1,1.2,0.3,2.5,0.3,3.7C-573.6,2511.2-573.5,2511.5-571.6,2512z"
            />
            <path
              class="st0"
              d="M-539.8,2484.6c-0.8-1.8-4.9-2.3-6.3-0.8c-0.2,0.3-0.4,0.7-0.5,1c-0.1,0.8-0.3,1.7-0.3,2.5
			c0.1,4.5,0.1,9,0.3,13.5c0.2,3.8,0.5,7.6,0.7,11.4c0.1,0.9,0.4,1.7,1.1,2.2c1.7,1.2,4.6-0.1,5.1-2.1c0.1-0.4,0.1-0.8,0.1-1.2
			c0.3-5,0.6-9.9,0.8-14.9c-0.1,0-0.2,0-0.2,0c0-2.8,0-5.6-0.1-8.4C-539,2486.7-539.3,2485.6-539.8,2484.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-456.2,2465.2c0-0.6-0.3-1.3-0.5-1.8c-0.7-1.4-1.3-1.5-2.3-0.4c-1.4,1.6-2.6,3.3-3.9,4.9
			c-1,1.3-2,2.8-3.1,4.1c-0.3,0.4-0.9,0.7-1.5,0.8c-1.4,0.2-2.8,0.3-4.1,0.3c-3.5,0.2-7,0.3-10.5,0.5c-1.7,0.1-3.4,0-5.1-0.3
			c-2.6-0.5-5.2-0.6-7.8-0.9c-1.8-0.2-3.7-0.1-5.5-0.3c-2.1-0.1-4.2-0.4-6.3-0.5c-1.9-0.1-3.8-0.2-5.7-0.3c-0.9,0-1.7-0.3-2.1-1.2
			c-0.6-1.2-1.3-2.5-1.6-3.8c-0.6-2.5-1.7-4.7-2.9-6.8c-0.5-0.8-1-1.7-2.1-1.5c-1,0.2-1,1.3-1.1,2.1c-0.2,2.3-0.4,4.7-0.4,7
			c-0.1,3.7-0.1,7.3-0.2,11c0,0.2,0,0.4,0.1,0.6c0.3,0.5,0.6,1.3,1.1,1.5c1.4,0.7,2.8,1.4,4.5,1.3c3.3-0.2,6.7-0.4,10-0.4
			c3.3-0.1,6.6,0,9.9,0.1c3,0.1,6.1,0.2,9.1,0.3c3.1,0.1,6.2,0,9.3,0.1c3.6,0.1,7.1,0.2,10.7,0.3c0,0,0-0.1,0-0.1
			c1.6,0,3.3,0,4.9-0.1c1.8-0.1,3.5-0.1,5.3-0.3c1.5-0.2,1.7-0.3,1.7-1.8c0.1-1.7-0.1-3.4,0.1-5.1
			C-456,2471.3-456,2468.3-456.2,2465.2z"
            />
            <path
              class="st0"
              d="M-522.8,2511.4c0.4,0.9,0.9,1.4,1.8,1.5c1.9,0.3,3.8,0.7,5.7,0.7c8.3,0,16.6-0.1,24.9-0.2
			c8.2-0.1,16.5-0.1,24.7-0.2c2,0,4-0.2,5.9-0.6c2.8-0.7,3.7-2,3.8-4.9c0.1-2.2,0.1-4.5,0-6.7c-0.1-1.6-0.5-3.2-0.9-4.8
			c-0.2-0.8-0.7-1.6-1.8-1.6c-1.2-0.1-2.4-0.2-3.5-0.3c-2.5-0.1-4.7,1.5-5.4,3.9c-0.3,0.9-0.7,1.8-1.2,2.9c-4.7,0-9.3,0.1-13.9,0.1
			c-1,0-1.4-0.4-1.5-1.4c-0.1-1.2-0.2-2.3-0.5-3.5c-0.4-1.5-1.1-2.2-2.6-2.2c-3,0-5.9,0.2-8.8,1.3c-1.3,0.5-2.1,1.3-2.1,2.8
			c0,0.3,0,0.7-0.1,1c-0.2,1.9-0.8,2.6-2.8,2.5c-2.5,0-5-0.1-7.5-0.2c-0.6,0-1.3-0.1-2-0.2c-0.1-0.6-0.2-1-0.2-1.5
			c0-1.5,0.1-3,0-4.5c0-1.4-0.3-1.5-1.7-1.5c-1.4,0.1-2.9,0.1-4.4,0.2c-1.3,0-2.7,0-3.9,0.2c-2.8,0.4-3.7,1.6-3.7,4.4
			c0,2,0.3,4.1,0.2,6.1C-524.4,2507.1-523.9,2509.3-522.8,2511.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-442,2505.2c0.4,3.2,1.5,4.1,4,4.2c1.5,0.1,3,0.1,4.5,0.1c2.3,0,4.6,0,6.9,0c0.8,0,1.6,0.2,2.4,0.1
			c3-0.4,6-0.2,9.1,0.1c1.8,0.1,3.5,0.2,5.3,0c3.7-0.4,3.7-0.5,4-4.3c0.6-7.3,0.7-14.6,1-22c0.1-2.8-0.6-3.6-3.3-3.9
			c-3.5-0.4-6.9-0.6-10.4-1c-0.3,0-0.5,0-0.8,0c-1,0-2,0.1-2.3,1.2c-0.3,1.2-0.3,2.6-0.3,3.9c0,0.2,0.5,0.4,0.8,0.5
			c0.6,0.2,1.3,0.3,1.9,0.4c1.3,0.3,2.7,0.6,4,0.9c0.1,0.5,0.2,0.8,0.2,1.1c-0.3,3.8-0.7,7.7-1.1,11.5c-0.1,0.8-0.6,1.3-1.4,1.3
			c-0.7,0-1.3,0.1-2,0c-3.4-0.2-6.7-0.4-10.1-0.6c-2.1-0.1-2.2-0.1-2.3-2.3c-0.1-2.9-0.1-5.9-0.1-8.8c0-0.9,0.2-1.8,0.4-2.7
			c0.8,0,1.4,0,2,0c2,0,2.4-0.4,2.6-2.4c0.3-3.1-0.4-3.8-3.5-3.6c-3.4,0.2-6.8,0.6-10.6,0.9c-0.4,2.2-1.4,4.3-1.3,6.7
			c0.1,3.5,0.1,7.1,0.1,10.6C-442.1,2499.9-442.3,2502.6-442,2505.2z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-328.8,2465.7C-328.8,2465.6-328.8,2465.6-328.8,2465.7c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6,0-0.9
			c0-0.2,0-0.4-0.1-0.6c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.3-0.2-0.4
			c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.3-0.7-0.3c0-0.7-0.1-1.2-0.7-0.9c-1.2,0.4-2.4,0.9-3.4,1.6c-1.7,1.1-3.1,2.4-4.8,3.4
			c-1,0.6-1.4,1.5-1.6,2.6c-0.5,2.5-1.1,5-1.7,7.5c-0.6,2.8-0.6,2.9,1.8,4.5c0.2,0.1,0.5,0.2,0.8,0.3c0.7-0.9,1.3-1.8,2-2.6
			c0.3,1,0.3,2,0.1,2.9c-0.8,6.4-1.3,12.8-1,19.2c0,1.1,0,2.2-0.1,3.3c-0.2,2.1-0.5,2.3-2.7,2.1c-1.1-0.1-2.2-0.2-3.3-0.1
			c-1.6,0.1-3.3,0.3-4.9,0.4c-1.5,0.1-3-0.1-4.8-0.1c-0.1-1.9-0.3-3.6-0.3-5.2c0.1-3.9,0.3-7.7,0.5-11.6c0-0.8,0.2-1.5,0.3-2.3
			c0.5-0.1,0.8-0.1,1.1-0.1c1.1,0,2.2,0.1,3.4,0c1.6,0,1.8-0.3,2.1-1.7c0.2-1.2,0.4-2.5,0.4-3.7c0-1.8-0.2-2-2.1-2.1
			c-1.3,0-2.6-0.1-4-0.1c0.1-0.5,0.1-0.8,0.2-1.1c1.6-4.3,1.9-8.6,1-13.1c-0.5-2.8-3-5.2-5.2-5.5c-2.8-0.4-5.7,0.8-7.1,3.3
			c-0.9,1.6-1.6,3.3-1.6,5.2c0,4,0.1,8,2.1,11.7c0.1,0.2,0.1,0.5,0.2,0.9c-2.6-0.1-5.1-0.2-7.8-0.3c-0.1,2-0.3,3.7-0.4,5.5
			c2.5,0.4,4.9,0.8,7.2,1.2c1.8,0.3,1.9,0.4,1.8,2.3c-0.1,3.8-0.1,7.6-0.2,11.4c0,1.6,0,3.1-0.1,4.7c-0.1,1-0.6,1.6-1.7,1.6
			c-1.6,0-3.3,0.1-4.9-0.1c-3.1-0.3-6.1-0.7-9.2-1.1c-1.8-0.2-2.6-1-2.8-2.8c-0.2-2.4-0.4-4.7-0.6-7.1c-0.3-5.7-0.6-11.4-1-17.1
			c-0.1-1.9-0.2-3.9-0.3-5.9c0-0.7,0.2-1.3,0.4-2c1.7,0.3,2.3,1.1,2.3,2.5c0,1.4,0.2,2.7,0.3,4.1c0,0.9,0.4,1.5,1.2,1.9
			c0.8,0.4,1.5-0.2,1.9-0.7c0.5-0.6,1-1.3,1-2c0.1-2.1,0.1-4.2,0-6.3c-0.1-2-0.8-3.8-2.4-5.2c-1-0.9-1.8-1.9-2.7-2.9
			c-0.8-0.8-1.6-1.6-2.5-2.3c-1.7-1.2-3.6-0.5-4.2,1.5c-0.3,0.9-0.5,1.9-0.5,2.9c-0.2,4.7-0.4,9.4-0.5,14.1
			c-0.3,9.2,0.2,18.3,0.4,27.5c0.1,2.1,1,3.6,2.5,4.9c0.3,0.3,0.7,0.6,1.1,0.9c2.6,1.8,5.1,3.7,8,4.9c1.2,0.5,2.5,0.9,3.6,1.5
			c3.7,2,7.6,3.3,11.8,3.5c1.4,0.1,2.8,0.1,4.1,0c2.8-0.4,5.6-0.9,8.3-1.4c0.9-0.2,1.8-0.4,2.7-0.7c3.7-1.3,7-3.2,10.2-5.5
			c1.9-1.4,3.1-3.3,3.5-5.5c0.4-2,0.4-4,0.4-6.1c0-2.4-0.1-4.7-0.1-7.1c0.1-4.3,0.2-8.6,0.3-13c0.1-3.5,0.4-6.9,1.8-10.2
			c0.7-1.5,1.3-3,1.9-4.5C-327.7,2466.5-328.2,2466.1-328.8,2465.7z M-359,2476.3c-0.5-0.9-1.1-1.6-1.2-2.3
			c-0.4-2.4-0.6-4.8,0.2-7.2c0.3-0.7,0.7-1.4,1.2-2c0.7-0.8,1.4-0.6,1.8,0.3c0.1,0.3,0.3,0.6,0.3,0.9
			C-356.4,2469.6-357.1,2472.9-359,2476.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-271.7,2504.5c-0.2-0.8-0.4-1.6-0.8-2.3c-0.6-1.2-1.2-2.3-1.8-3.5c-1.8-3.5-3.5-7.2-5.5-10.5
			c-2.7-4.4-5.2-9-8.6-12.7c-2-2.2-3.5-5-6.4-5.8c-0.3-0.1-0.7-0.7-0.8-1.2c-0.2-0.8-0.2-1.6-0.2-2.5c0.1-2.2-0.7-3.8-2.4-4.5
			c-2.1-0.9-4.4-1.5-6.6-2.2c-0.8-0.2-1.4,0.2-1.8,1c-1.5,2.7-3,5.3-4.6,8.2c0.4,0.2,0.6,0.4,0.9,0.4c2.4,0.6,2.4,0.6,2.7,3.4
			c0.4,3,1.4,5.6,4.3,6.4c0.2,0,0.3,0.3,0.6,0.6c-0.2,1.1-0.5,2.2-0.7,3.4c-0.5,2.6-1.2,5.2-1.4,7.8c-0.3,3.3,1,6.2,2.4,9
			c1.3,2.5,2.6,5,3.9,7.5c-0.5,1.1-1.4,1.4-2.3,1.6c-0.8,0.2-1.5,0.4-2.3,0.5c-1.2,0.2-1.9,1.4-2.5,2.4c-0.8,1.5,0.1,3.1,1.8,3.4
			c3.6,0.7,7.2,0.6,10.8,0.3c1.1-0.1,2.1-0.7,2.5-2c0.5-1.7,1-3.4,1.5-5.2c0.3-1,0.4-2,0.7-3.5c0.7,1.1,1,1.7,1.4,2.3
			c1,1.6,1.9,3.2,2.9,4.9c0.9,1.6,1.7,3.2,2.6,4.8c0.3,0.5,0.5,1.1,0.8,1.6c1.4,2.2,3.1,2.6,5.2,1.3c1.4-0.9,2.1-2.8,1.7-4.9
			c-0.3-1.8-0.7-3.6-1-5.4C-272.1,2509.1-271.1,2507.4-271.7,2504.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-707.1,2551.6c-0.1-0.2-0.2-0.3-0.3-0.4c-2.5-2.4-5.5-3.7-9-3.8c-3.1-0.1-5.5,0.9-6.9,3.8
			c-1.2,2.4-2.4,4.9-3.5,7.4c-0.9,2-1.8,4.1-2.8,6.1c-1.1,2.4-2.2,4.7-3.2,7.1c-1.1,2.4-2.2,4.9-3.3,7.3c-1,2-2,4-2.9,6
			c-2.1,4.6-3.9,9.4-6.1,13.9c-1.6,3.3-2.6,6.9-3.8,10.4c-0.2,0.6-0.2,1.3-0.4,2.2c1.5-0.3,2.4-0.9,3-1.7c1.3-1.7,2.7-3.3,3.9-5
			c3.7-5.2,7.7-10.1,12.1-14.7c2.2-2.3,4.6-4.3,7.1-6.2c1.9-1.4,4-2.6,5.9-3.9c2.5-1.6,4.3-3.9,4.6-6.9c0.5-3.8,0.6-7.6,0.2-11.4
			c-0.2-1.6-0.3-3.3-0.4-4.9c-0.1-0.9,0.2-1.5,1-1.9c0.4-0.2,0.8-0.3,1.2-0.5c1.3-0.8,2.9-1.3,3.8-2.5
			C-707,2551.7-707.1,2551.7-707.1,2551.6z M-715.7,2551.8c-0.2-0.1-0.4-0.7-0.3-0.9c0.5-0.8,1.1-0.4,1.8,0
			C-714.6,2551.8-715,2552.3-715.7,2551.8z"
            />
            <path
              class="st0"
              d="M-721.2,2604.4c-0.4,2.4,0.6,4.3,2.9,5.8c0.2-1.1,0.4-2.1,0.5-3c0.4-2.3,0.7-4.6,1.7-6.8
			c1.4-3.2,2.8-6.3,3.2-9.8c0.4-3.7,0.7-7.5-0.9-11.2c-1.8,1.1-2.1,1.6-2.3,3.6c-0.1,0.9-0.1,1.8-0.3,2.7c-1,3.9-2.1,7.8-3.1,11.8
			C-720.1,2599.7-720.8,2602-721.2,2604.4z"
            />
            <path
              class="st0"
              d="M-719.8,2583.4c-2.2,1.3-4.5,2.4-6.3,4.3c-2.3,2.4-4.7,4.9-6.9,7.4c-0.9,1-1.9,2.1-2.6,3.2
			c-2.5,3.6-4.9,7.4-7.8,10.7c-1.7,1.9-2.2,4.3-3,6.9c0.6,0,1,0.2,1.4,0.1c2.8-0.5,5.6-0.9,8.5-0.5c0.7,0.1,1.1-0.4,1.4-1
			c0.9-2.2,1.9-4.4,2.8-6.7c0.6-1.4,1-2.9,1.5-4.4c0.1,0,0.3,0,0.4-0.1c0.6,1.5,1.3,3,2,4.6c1.6-1,3-1.9,4.4-2.7
			c1.6-0.9,3-1.9,2.8-4c0-0.2,0.1-0.4,0.1-0.5c0.7-2.7,1.4-5.5,2.1-8.2c0.2-0.8,0.4-1.6,0.6-2.5c0.7-2.2,1-4.5,0.8-7.1
			C-718.6,2583-719.3,2583-719.8,2583.4z"
            />
            <path
              class="st0"
              d="M-731.7,2617.2c0.9-0.1,1.9-0.4,2.8-0.8c2.6-1.2,5.2-1.4,8-0.9c2,0.3,4,0.6,5.9,0.8c1.2,0.1,2.3,0,3.3-0.9
			c-0.3-1.5-0.9-2.6-2.4-3.1c-1.3-0.4-2.6-0.9-3.9-1c-2.4-0.1-3.6-1.4-4.3-3.5c-0.2-0.7-0.5-1.4-0.8-2.1c-1.9,0.7-1.9,0.7-3.7,3.2
			c0.7,2.3,0.7,2.3-1.4,3.1c-0.2,0.1-0.5,0.1-0.7,0.2c-1.5,0.8-3,1.7-3.9,3.2C-733.4,2616.6-733,2617.3-731.7,2617.2z"
            />
            <path
              class="st0"
              d="M-706.2,2615.9c-0.2-2.6-2.9-4.6-5.2-3.7c0.4,0.7,0.9,1.3,1.3,1.9C-709.3,2615.9-708.4,2616.3-706.2,2615.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-750.3,2573.9c-1-0.1-1.9-0.5-2.8-0.9c-3-1.4-3.4-1.1-3.8,2.1c-0.7,4.7,0.1,9.3,0.2,13.9
			c0,1.7-0.3,3.4-0.5,5c-0.1,0.6-0.6,1.1-1,1.7c-1.5-1-2.4-2.1-2.5-3.8c-0.2-5-0.6-9.9-0.8-14.9c-0.2-2.9-0.4-5.7-0.5-8.6
			c0-1.3-0.1-2.6-0.1-3.8c0-1.1,0-2.3-0.1-3.4c-0.1-1.6-0.2-3.3-0.3-4.9c1.3-1.6,2.7-3.2,4-4.7c0.9,1.8,1.9,3.6,2.8,5.5
			c0.1,0.3,0.3,0.7,0.4,1c1.6,3.9,3.3,7.7,6.2,10.9c0.5,0.5,0.8,1.2,1.1,1.8C-747.1,2572.8-748.1,2574.2-750.3,2573.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-784.8,2592.7c-0.1-0.4-0.2-0.8-0.3-1.2c-0.3-1-0.5-1.9-0.7-2.9c-0.2-1.4-0.5-2.8-0.7-4.1
			c-2.1,2.5-4.2,4.9-6.3,7.4c0.8,0.2,1.6,0.4,2.4,0.7c1.2,0.4,2.3,0.8,3.5,1.1c0.3,0.1,0.7,0.2,1,0.3
			C-784.9,2594.3-784.5,2593.9-784.8,2592.7z M-771.5,2594.7c-2.8-2-3.7-5.1-4.5-8c-0.4-1.5-0.7-3.1-1.1-4.7
			c-0.5-2.4-0.9-4.8-1.3-7.2c-1.6,1.9-3.2,3.8-4.8,5.7c0.3,1.2,0.5,2.4,0.8,3.6c0.2,1.1,0.5,2.3,0.8,3.4c0.5,1.8,1.1,3.7,1.9,5.4
			c0.4,0.8,0.8,1.7,1.5,2.1c1.5,1.1,3.1,2,4.7,2.8c1.3,0.6,1.9,0.4,2.5-0.5C-770.5,2596.3-770.5,2595.3-771.5,2594.7z"
            />
            <path
              class="st0"
              d="M-771.1,2618c-0.1,0.4-0.3,0.7-0.9,0.8c-0.9,0.2-1.7,0.5-2.6,0.5c-4.9,0-9.7-0.1-14.6-0.2v0.2
			c-3.4,0-6.7,0.1-10.1,0.1c-2.9,0-5.7,0-8.5-1.2c-1.2-0.6-1.9-1.3-1.9-2.6c0-1.4,0.1-2.9,0.1-4.3c1.8-2.1,3.6-4.2,5.4-6.3
			c0,0.8,0,1.6,0,2.4c0,1.8,0.1,3.6,0.1,5.4c0,0.2,0,0.4,0.1,0.6c0.2,0.4,0.5,0.6,1,0.6c0.5,0,1,0,1.4,0c1.8,0,3.7,0,5.5,0.1
			c3.1,0.2,6.3,0.4,9.4,0.6c4.3,0.3,8.6,0.6,12.9,0.9c1.6,0.1,2.2,0.6,2.4,1.8C-771.1,2617.6-771.1,2617.8-771.1,2618z"
            />
            <path
              class="st0"
              d="M-786.8,2613.1c2.4,0.1,4.8,0.1,7.2,0.1c0.6,0,1.2-0.4,2.1-0.7c-1.2-2-2.3-3.1-4.2-3.7
			c-0.7-0.2-1.7-0.6-2-1.2c-1.3-2.9-2.1-6-2.1-9.2c0-1-0.2-2.1-0.4-3.1c-1.1-0.2-1.9-0.3-2.7-0.4c-0.8-0.1-1.6,0-2.4,0
			c-0.1,0.4-0.2,0.7-0.2,1c0.5,5.4,1.6,10.7,3.2,15.9C-788.1,2612.7-787.6,2613.1-786.8,2613.1z"
            />
            <path
              class="st0"
              d="M-796,2609.8c-1.1-0.8-1.4-1.8-1.2-3c0.1-0.5,0.2-1,0.3-1.5c0,0,0-0.1,0-0.1c1.1-3.9,2.1-7.7,3.3-11.9
			c-0.1,0-0.3-0.1-0.4-0.1c-2.3,2.6-4.5,5.3-6.8,7.9c-0.6,2.1-1.1,4.2-1.4,6.4c-0.2,1.1,0.1,2,0.8,2.9c0.1,0.1,0.2,0.2,0.2,0.3
			c1.1,1.2,2.5,1.9,4.1,2.3c1.2,0.3,2.4,0,3.4-1.2C-794.4,2611-795.2,2610.4-796,2609.8z"
            />
          </g>
          <g>
            <g>
              <path
                class="st0"
                d="M-697.5,2610.3c0.4,3.2,1.5,4.1,4,4.2c1.5,0.1,3,0.1,4.5,0.1c2.3,0,4.6,0,6.9,0c0.8,0,1.6,0.2,2.4,0.1
				c3-0.4,6-0.2,9.1,0.1c1.8,0.1,3.5,0.2,5.3,0c3.7-0.4,3.7-0.5,4-4.3c0.6-7.3,0.7-14.6,1-22c0.1-2.8-0.6-3.6-3.3-3.9
				c-3.5-0.4-6.9-0.6-10.4-1c-0.3,0-0.5,0-0.8,0c-1,0-2,0.1-2.3,1.2c-0.3,1.2-0.3,2.6-0.3,3.9c0,0.2,0.5,0.4,0.8,0.5
				c0.6,0.2,1.3,0.3,1.9,0.4c1.3,0.3,2.7,0.6,4,0.9c0.1,0.5,0.2,0.8,0.2,1.1c-0.3,3.8-0.7,7.7-1.1,11.5c-0.1,0.8-0.6,1.3-1.4,1.3
				c-0.7,0-1.3,0.1-2,0c-3.4-0.2-6.7-0.4-10.1-0.6c-2.1-0.1-2.2-0.1-2.3-2.3c-0.1-2.9-0.1-5.9-0.1-8.8c0-0.9,0.2-1.8,0.4-2.7
				c0.8,0,1.4,0,2,0c2,0,2.4-0.4,2.6-2.4c0.3-3.1-0.4-3.8-3.5-3.6c-3.4,0.2-6.8,0.6-10.6,0.9c-0.4,2.2-1.4,4.3-1.3,6.7
				c0.1,3.5,0.1,7.1,0.1,10.6C-697.6,2605-697.8,2607.7-697.5,2610.3z"
              />
            </g>
            <path
              class="st0"
              d="M-668.6,2560.8c-1.2-2.9-3.8-4.4-6.3-5.9c-4.4-2.5-8.6-0.8-11.7,2.6c-2.3,2.6-3.9,6-3.7,9.7
			c0.1,4,1.7,7.4,5.4,9.4c1.8,1,3.7,1.2,5.7,1.2c5-0.1,9-2.6,10.6-7.1c0.7-1.9,1-3.9,1.1-5.9
			C-667.6,2563.6-668.1,2562.1-668.6,2560.8z M-672.4,2567.1c-0.5,3-3.1,5.5-6.8,5.4c-0.7,0-1.1,0.1-1.6,0c-2.7-0.2-4.5-2-4.7-4.7
			c-0.2-3.5,1.3-6.2,3.8-8.5c1.2-1.1,2.8-1,4.1-0.6c1.5,0.5,2.9,1.3,4.2,2.2c1.1,0.8,1.2,2.1,1.2,3.4
			C-672.1,2565.3-672.2,2566.2-672.4,2567.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M-329.9,2271.4c-1,0-2,0-3,0c-1.5,0-2.9,0-4.4,0c-11.8,0.1-23.6,0.1-35.4,0.1c-0.5,0-0.9,0-1.4,0
			c-2,0-3.9,0-5.9,0c-5,0-10.1-0.1-15.1-0.2c-2.5,0-5-0.1-7.6-0.1c2.1-2.4,4.1-4.8,6.2-7.2c2.6,0,5.1,0,7.7-0.1
			c3.3,0,6.5-0.1,9.8-0.1c0.4,0,0.8,0,1.2,0c2,0,4,0,5.9,0c9.1-0.1,18.2-0.1,27.3-0.1h0.6c2.5,0,5,0,7.5,0
			C-334.3,2266.2-332.1,2268.8-329.9,2271.4z"
            />
            <path
              class="st0"
              d="M-548.2,2349c2,2.4,4.1,4.8,6.1,7.2c-2.5,0-5,0-7.5,0c-7,0-14.1,0-21.1,0.1c-2,0-4,0-5.9,0
			c-2.5,0-4.9,0-7.4,0c-2.6,0-5.1,0-7.6,0c2-2.3,4-4.7,6-7c2.6,0,5.2-0.1,7.7-0.1c1.1,0,2.2,0,3.3,0c2,0,4-0.1,5.9-0.1
			c4.3,0,8.7-0.1,13-0.1c1.2,0,2.4,0,3.6,0C-550.8,2349-549.5,2349-548.2,2349z"
            />
            <path
              class="st0"
              d="M-256.1,2357.8c-2.5,0-5,0-7.5,0c-7.2,0-14.3,0-21.4-0.3c-18.5-0.8-37.1-0.5-55.6-0.9
			c-11.3-0.3-22.6,0-33.9,0c-5.3,0-10.5-0.1-15.8,0c-2.6,0-5.1,0-7.7,0.1c-2,0-3.9,0-5.9,0c-7.3,0.1-14.7,0.1-22.1,0.2
			c-13.4,0-26.8,0-40.3-0.1c-0.6,0-1.3,0-1.9,0c-2.5,0-5.1,0-7.6-0.1c2-2.3,4-4.6,5.9-6.9c2.5,0.1,5,0.1,7.5,0.1
			c6.3,0.1,12.7,0.2,19,0.2c13.7,0.1,27.4,0.1,41.2,0.1c2,0,3.9,0,5.9,0c2.7,0,5.4,0,8.1,0c6.9,0,13.9,0.2,20.8,0.2
			c10.8,0,21.7-0.1,32.5-0.2c7.8-0.1,15.6-0.3,23.4-0.3c5.3,0,10.5,0.3,15.8,0.4c2.7,0.1,5.4,0.1,8.1,0.1c5.8,0,11.5-0.1,17.3-0.1
			c2.5,0,5,0,7.5,0C-260.4,2352.7-258.2,2355.2-256.1,2357.8z"
            />
            <path
              class="st0"
              d="M11,2535.5c-2.5,0-5-0.1-7.5-0.1c-1.1,0-2.2,0-3.3,0c-10.8,0-21.6-0.5-32.5-0.3c-13.4,0.2-26.8-0.1-40.2-0.2
			c-4.1,0-8.1,0.1-12.2,0.2c-6.4,0.1-12.9,0.2-19.3,0.3c-2.5,0-5,0.1-7.5,0.1c-5,0.1-10.1,0.1-15.2,0.2c-10.4,0.1-20.8-0.1-31.2-0.1
			c-14,0-28.1,0.1-42.1,0.1c-6.9,0-13.8-0.2-20.8-0.3c-11-0.1-22-0.2-33-0.3c-10.4-0.1-20.8,0.1-31.1-0.3
			c-18.5-0.8-37.1-0.5-55.6-0.9c-11.3-0.3-22.6,0-33.9,0c-5.2,0-10.4-0.1-15.6,0h-0.2c-2.5,0-5,0-7.5,0.1
			c-9.4,0.1-18.8,0.2-28.2,0.2c-13.4,0-26.8,0-40.3-0.1c-17.8-0.1-35.6-0.4-53.4-0.5c-25.2,0-50.4,0.1-75.6,0.2
			c-8.4,0-16.8,0.3-25.2,0.4c-1.8,0-3.7,0-5.5,0.1h-0.5c-8.4,0.1-16.8-0.1-25.3-0.1c-14-0.1-28-0.3-42-0.2
			c-10.8,0-21.7,0.5-32.5,0.3c-3.3-0.1-6.6-0.1-9.9-0.1c-2.5,0-5,0-7.5,0c1.7-2,3.5-4.1,5.2-6.1c2.5,0.1,4.9,0.1,7.4,0.2
			c7.8,0.1,15.6,0,23.3,0c10.5-0.1,21.1-0.3,31.6-0.5c4.7-0.1,9.3-0.2,14-0.3c5.4-0.1,10.9-0.2,16.3-0.1c7.1,0.2,14.2,0.2,21.3,0.2
			c2,0,4,0,6-0.1c8.8-0.1,17.7-0.3,26.6-0.5c13.3-0.3,26.5-0.4,39.8-0.4c12.2-0.1,24.4,0,36.6,0.1c8.9,0,17.8,0.1,26.7,0.3
			c15.2,0.2,30.4,0.5,45.6,0.6c13.3,0.1,26.6,0.1,39.9,0.1c2.6,0,5.2,0,7.7,0c2.5,0,5,0,7.6,0c6.9,0,13.9,0.2,20.8,0.2
			c10.8,0,21.7-0.1,32.5-0.2c7.8-0.1,15.6-0.3,23.4-0.3c5.3,0,10.5,0.3,15.8,0.4c2.7,0.1,5.4,0.1,8.1,0.1c8.8,0,17.7-0.1,26.6-0.1
			c9.4,0,18.7,0,28.1,0c6.3,0,12.6,0.1,19,0.1c5.1,0,10.2,0,15.3,0.1c5.3,0,10.5,0,15.8,0.1c6.8,0.2,13.5,0.5,20.2,0.7
			c8.9,0.2,17.8,0.4,26.6,0.5c6.2,0,12.4,0.1,18.6,0.1c2.5,0,5.1,0,7.6,0c9.3,0,18.7,0,28,0.1c7.8,0,15.7,0.1,23.5,0.3
			c11.1,0.3,22.3,0.7,33.4,0.9c7.4,0.1,14.7-0.1,22.1-0.1c0.5,0,1,0,1.5,0c2.5,0,5,0,7.5,0C8,2532,9.5,2533.8,11,2535.5z"
            />
            <path
              class="st0"
              d="M-59.9,2452.6c-2.5,0-5,0-7.5,0c-12.8,0-25.5,0-38.3-0.1c-18.3-0.2-36.5-0.4-54.8-0.6c-2,0-4,0-6-0.1
			c-3.1,0-6.1-0.1-9.2-0.1c-1.4,0-2.8,0-4.2-0.1c-1.2,0-2.4,0-3.6,0c-18.5-0.2-37.1-0.6-55.7-0.7c-13.1,0-26.2,0.5-39.3,0.6
			c-17,0.2-34.1,0.4-51.1,0.5c-13.3,0.1-26.6,0.1-39.8,0.1c-14.2-0.1-28.3-0.3-42.5-0.5c-4.2-0.1-8.4-0.2-12.6-0.3
			c-2,0-4-0.1-5.9-0.1c-8.1-0.2-16.2-0.4-24.4-0.5c-2.1,0-4.3,0-6.4,0c-2.6,0-5.2,0-7.7,0c-4.8,0-9.6,0-14.4,0
			c-16.1,0-32.3,0-48.4-0.1c-18.9-0.1-37.7-0.2-56.6-0.3c-2.9,0-5.8,0-8.8,0c-2,0-3.9,0-5.9,0c-13.7,0-27.3,0-41-0.1
			c-6.3,0-12.6-0.3-19-0.4c-0.4,0-0.8,0-1.1,0c-0.4,0-0.8,0-1.1,0c-2.1,0-4.3,0-6.4,0c1.7-2,3.4-4,5.1-6c2.5,0.1,5,0,7.6,0
			c1.2,0,2.3,0,3.5,0c5.9,0.1,11.7,0.2,17.6,0.3c3.3,0,6.7,0.2,9.9,0c8.6-0.4,17.2-0.2,25.8-0.1c0.3,0,0.6,0,0.9,0
			c2,0,3.9,0,5.9-0.1c1.5,0,3,0,4.4,0c9.6,0.2,19.2,0.4,28.8,0.6c5.6,0.1,11.2,0.2,16.7,0.2c7.5,0.1,15.1,0.1,22.6,0.1
			c14.2,0,28.4,0,42.5,0c2.1,0,4.2,0,6.2,0.1c2.6,0,5.2,0.1,7.8,0.1c3.5,0,6.9,0.1,10.4,0.1c9,0,18-0.1,27.1-0.2c2,0,4,0,6-0.1
			c1.8,0,3.6,0,5.4,0c2.4,0,4.8,0.1,7.2,0c11.4-0.1,22.9-0.2,34.3-0.3c11.5-0.1,22.9-0.2,34.4-0.2c17.2,0,34.4,0.2,51.6,0.2
			c21.1,0,42.2,0.2,63.3-0.1c12.5-0.2,24.7,0.6,37.1,0.6c0.9,0,1.8,0,2.6,0c1.7,0,3.4,0,5.1,0c4.8,0.1,9.5,0.4,14.3,0.5
			c0.9,0,1.7,0,2.6,0c2,0,4,0,6,0.1c5.6,0,11.2,0,16.8,0.1c17,0.1,34.1,0.2,51.1,0.2c5.8,0,11.7,0.1,17.5,0.1c2.5,0,5,0,7.5,0
			C-63.8,2448.1-61.8,2450.3-59.9,2452.6z"
            />
          </g>
        </g>
        <path
          d="M-88.8,2553.3c0.1,0.7,0.2,1.4,0.4,2.3c0.4-0.3,0.6-0.6,0.9-0.8C-88,2554.3-88.4,2553.8-88.8,2553.3z M-124.2,2511.9
	c-4.3-5-8.6-10.1-12.9-15.1c-5.9-6.9-11.8-13.8-17.7-20.8c-2.1-2.5-4.3-5.1-6.4-7.6c0,0-0.1-0.1-0.1-0.1c-1.3-1.6-2.3-4.6-1.8-6.5
	c0.9-3.3,1.8-6.7,2.7-10c0.6-2.2,1.2-4.3,1.8-6.5c3.9-14.3,7.9-28.5,11.9-42.7c0.7-2.5,1.4-4.9,2.1-7.4c0.7-2.4,1.3-4.8,2-7.2h-0.1
	c-0.7,0-1.4-0.1-2.1-0.1c-0.7,0-1.4,0-2.2,0c-0.5,0-1,0-1.5,0c-0.7,2.4-1.3,4.7-2,7.1c-0.7,2.6-1.4,5.1-2.2,7.7
	c-3.9,14-7.9,28.1-11.9,42.5c-0.6,2.1-1.2,4.3-1.8,6.5c-0.7,2.7-1.5,5.3-2.2,8c-2.4-2.8-4.7-5.5-7-8.1c-1.4,0-2.8,0-4.2-0.1
	c-1.2,0-2.4,0-3.6,0c14,16.4,28,32.8,42,49.2c4.5,5.2,9,10.5,13.4,15.7c3.6,4.2,7.2,8.4,10.8,12.6c2.5,0,5.1,0,7.6,0
	C-114.4,2523.4-119.3,2517.7-124.2,2511.9z M-56.6,2593.8c-0.1-0.3-0.1-0.6-0.1-0.8c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3
	c-9-10.6-18-21.1-27.1-31.7v0c-1.5-1.7-2.9-3.4-4.4-5.1c-0.2,0.2-0.5,0.5-0.9,0.8c-0.1-0.8-0.3-1.5-0.4-2.3c-1.7-2-3.4-4-5.2-6
	c-3.4-3.9-6.7-7.9-10.1-11.8c-1.8-2.1-3.6-4.2-5.4-6.3c-2.6,0-5.1,0-7.6,0c1.8,2.2,3.7,4.3,5.5,6.5c5.5,6.4,11,12.8,16.5,19.3
	c2.6,3,5.1,6,7.7,9.1c0.5,0.5,0.9,1.1,1.4,1.6c2.8,3.3,5.6,6.5,8.3,9.8c1.6,1.8,3.1,3.6,4.7,5.5c1.4,1.6,2.8,3.3,4.3,5
	c5,5.8,10,11.7,15,17.5C-54.9,2600.1-55.7,2597-56.6,2593.8z M-380,2271.4c-1.3,4.7-2.6,9.4-3.9,14.1l-1.5,5.3
	c-0.3,1.1-0.6,2.2-0.9,3.3c-2.7,9.8-5.5,19.5-8.2,29.3c0,0,0,0,0,0s0,0,0,0v0c-0.8,3-1.7,6.1-2.6,9.1c0,0,0,0,0,0
	c-0.3,1.2-0.7,2.4-1,3.6v0c-1.3,4.6-2.6,9.2-3.8,13.8c-0.6,2.2-1.2,4.4-1.8,6.6c-1.3,4.5-2.5,8.9-3.8,13.4c-1,3.5-1.9,7-2.9,10.4
	c-1.4,5.1-2.8,10.1-4.3,15.2v0c0,0,0,0,0,0c-1.8,6.4-3.6,12.9-5.4,19.3v0c-0.5,1.8-1,3.6-1.5,5.4c-0.9,3-1.7,6.1-2.5,9.1
	c-1.4,5.1-2.9,10.2-4.3,15.4c-0.6,2.1-1.2,4.3-1.8,6.4c2,0,4,0.1,5.9,0.1c0.6-2.2,1.2-4.4,1.8-6.6c1.4-5.1,2.8-10.2,4.3-15.3
	c0.8-2.9,1.6-5.8,2.4-8.7c0.4-1.4,0.8-2.7,1.1-4.1v0c1-3.7,2.1-7.4,3.1-11.1c2.6-9.3,5.2-18.5,7.8-27.8c0.5-1.7,0.9-3.4,1.4-5
	c1.5-5.4,3-10.8,4.5-16.1c0.6-2.2,1.2-4.4,1.8-6.5c1.6-5.9,3.3-11.7,4.9-17.6c1.6-5.7,3.2-11.4,4.8-17.2c0.3-1.1,0.6-2.2,0.9-3.3v0
	c0.3-1,0.6-2,0.8-3c3.5-12.5,7-24.9,10.5-37.4C-376.1,2271.4-378,2271.4-380,2271.4z M-430.4,2486.5c-0.3-0.4-0.7-0.9-0.9-1.4h-0.2
	c-0.1,1-0.4,1.9-0.4,2.7c0,1.4,0,2.9,0,4.3v0.2c0,0.1,0,0.2,0,0.3c0,0.4,0,0.9,0,1.3c1.4,1.7,2.8,3.3,4.3,5c2.7,0.1,5.4,0.3,8.1,0.5
	h0.1C-423,2495.1-426.7,2490.8-430.4,2486.5z M-369.4,2558.1c-2.2-2.5-4.3-5.1-6.5-7.6c-4.7-5.6-9.5-11.1-14.2-16.7
	c-1.8-2.1-3.7-4.3-5.5-6.5c-5-5.9-10-11.7-15-17.6c-2.9-3.4-5.9-6.9-8.8-10.3h-0.1c-2.7-0.2-5.4-0.3-8.1-0.5c3,3.5,6,7,9,10.5
	c5.1,6,10.2,11.9,15.3,17.9c1.9,2.2,3.7,4.4,5.6,6.5c5.2,6.1,10.4,12.2,15.7,18.3c2.1,2.5,4.3,5,6.4,7.5
	c7.4,8.7,14.8,17.4,22.3,26.1c2.1-0.5,4.1-1.1,6.1-1.8C-354.6,2575.4-362,2566.7-369.4,2558.1z M-432.2,2479.5
	c0-0.1,0.1-0.2,0.1-0.3c2.6-9.3,5.2-18.6,7.7-27.8c-2,0-4-0.1-5.9-0.1c-2.5,8.9-5,17.8-7.5,26.7c-7.8-9.2-15.6-18.3-23.3-27.3
	c-2.6,0-5.2,0-7.7,0c3.4,4,6.8,7.9,10.2,11.9c0.8,1,1.6,1.9,2.4,2.9v0l0,0c4.9,5.7,9.7,11.4,14.6,17.1c3.3,3.8,6.5,7.6,9.8,11.4
	c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0-0.3v-0.2c0-1.4,0-2.9,0-4.3c0-0.9,0.3-1.8,0.4-2.7h0.2C-432.2,2483.3-432.6,2481.1-432.2,2479.5z
	 M-590.2,2405.2c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.2,0.4-0.4
	c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.2
	c0.1-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8c0-0.2,0-0.4,0-0.6
	c0-1.1,0-2.1,0.1-3.2C-588.7,2399.8-589.4,2402.5-590.2,2405.2z M-588.4,2418.5c-0.4,0.1-0.8,0.1-1.2,0.1c-1.3,0.1-2.5,0.2-3.7,0.4
	c-0.3,0-0.5,0-0.8,0.1c-2.3,8.3-4.7,16.6-7,25c-0.6,2.1-1.2,4.2-1.8,6.3c-2.7,9.7-5.4,19.4-8.2,29.1c-1.3,4.5-2.5,9-3.8,13.4v0
	c-3.2,11.5-6.4,23-9.6,34.5c-0.6,2.3-1.3,4.5-1.9,6.8c-1.6,5.7-3.2,11.4-4.8,17.1c0,0,0,0,0,0c-0.1,0.5-0.3,1.1-0.4,1.6
	c-2.2,8-4.4,15.9-6.7,23.9c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.5l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0,0
	c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0.1,0c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.2,0.3,0.4,0.4,0.6
	c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
	c0.1,0.3,0.3,0.5,0.4,0.8c4.5-16.1,9-32.2,13.5-48.3c0.6-2.3,1.3-4.5,1.9-6.8c2.1-7.4,4.1-14.9,6.2-22.3c1.2-4.4,2.4-8.8,3.7-13.1
	c0,0,0,0,0,0s0,0,0,0c3.9-13.8,7.7-27.7,11.6-41.5c0.6-2.1,1.2-4.3,1.8-6.4c2.4-8.5,4.8-17,7.1-25.5
	C-588.1,2418.4-588.2,2418.5-588.4,2418.5z M-424.6,2305.1c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.2c0,0.1,0,0.1-0.1,0.2
	c1.1-1.3,2.1-2.5,3.2-3.8c-0.2-0.2-0.3-0.4-0.5-0.5c-0.9-1.2-1.6-2.5-2.5-3.8c-0.1-0.2-0.3-0.4-0.4-0.7c-0.6,0.7-1.2,1.4-1.8,2.1
	c0,0,0,0.1,0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.3c1,1.3,1.6,2.6,1.6,4.1c0,0.1,0,0.3,0,0.4
	C-424.5,2304.9-424.6,2305-424.6,2305.1z M-466,2445c-4.9-5.8-9.9-11.6-14.8-17.3c-1.5-1.8-3-3.5-4.5-5.3l-10.7-12.5
	c-2.2-2.5-4.3-5.1-6.5-7.6v0c-0.8-0.9-1.5-1.8-2.3-2.7c2.5-2.9,5-5.9,7.6-8.8c-1.2-0.1-2.4-0.2-3.6-0.3c-0.3,0-0.6,0-0.9-0.1
	c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.5-0.1-0.8-0.1c0,0-0.1,0-0.1,0c-1.5,1.7-2.9,3.5-4.4,5.2c-2-2.4-4-4.7-6-7
	l0,0c-0.6-0.1-1.2-0.2-1.8-0.3c-1.6-0.3-3.2-0.6-4.8-0.9c-0.8-0.1-1.6-0.3-2.3-0.4c2.4,2.8,4.8,5.6,7.2,8.5
	c4.1,4.8,8.2,9.6,12.2,14.3l0,0c0.6,0.7,1.2,1.4,1.8,2.1c0.8,0.9,1.6,1.9,2.4,2.8c2.6,3.1,5.3,6.2,7.9,9.3c1.7,2,3.3,3.9,5,5.9
	c4.4,5.2,8.8,10.3,13.2,15.5c1.7,1.9,3.3,3.9,5,5.8c2.6,0,5.2,0,7.7,0C-462.7,2448.8-464.3,2446.9-466,2445z M-88.8,2553.3
	c0.1,0.7,0.2,1.4,0.4,2.3c0.4-0.3,0.6-0.6,0.9-0.8C-88,2554.3-88.4,2553.8-88.8,2553.3z M-124.2,2511.9c-4.3-5-8.6-10.1-12.9-15.1
	c-5.9-6.9-11.8-13.8-17.7-20.8c-2.1-2.5-4.3-5.1-6.4-7.6c0,0-0.1-0.1-0.1-0.1c-1.3-1.6-2.3-4.6-1.8-6.5c0.9-3.3,1.8-6.7,2.7-10
	c0.6-2.2,1.2-4.3,1.8-6.5c3.9-14.3,7.9-28.5,11.9-42.7c0.7-2.5,1.4-4.9,2.1-7.4c0.7-2.4,1.3-4.8,2-7.2h-0.1c-0.7,0-1.4-0.1-2.1-0.1
	c-0.7,0-1.4,0-2.2,0c-0.5,0-1,0-1.5,0c-0.7,2.4-1.3,4.7-2,7.1c-0.7,2.6-1.4,5.1-2.2,7.7c-3.9,14-7.9,28.1-11.9,42.5
	c-0.6,2.1-1.2,4.3-1.8,6.5c-0.7,2.7-1.5,5.3-2.2,8c-2.4-2.8-4.7-5.5-7-8.1c-1.4,0-2.8,0-4.2-0.1c-1.2,0-2.4,0-3.6,0
	c14,16.4,28,32.8,42,49.2c4.5,5.2,9,10.5,13.4,15.7c3.6,4.2,7.2,8.4,10.8,12.6c2.5,0,5.1,0,7.6,0
	C-114.4,2523.4-119.3,2517.7-124.2,2511.9z M-56.6,2593.8c-0.1-0.3-0.1-0.6-0.1-0.8c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3
	c-9-10.6-18-21.1-27.1-31.7v0c-1.5-1.7-2.9-3.4-4.4-5.1c-0.2,0.2-0.5,0.5-0.9,0.8c-0.1-0.8-0.3-1.5-0.4-2.3c-1.7-2-3.4-4-5.2-6
	c-3.4-3.9-6.7-7.9-10.1-11.8c-1.8-2.1-3.6-4.2-5.4-6.3c-2.6,0-5.1,0-7.6,0c1.8,2.2,3.7,4.3,5.5,6.5c5.5,6.4,11,12.8,16.5,19.3
	c2.6,3,5.1,6,7.7,9.1c0.5,0.5,0.9,1.1,1.4,1.6c2.8,3.3,5.6,6.5,8.3,9.8c1.6,1.8,3.1,3.6,4.7,5.5c1.4,1.6,2.8,3.3,4.3,5
	c5,5.8,10,11.7,15,17.5C-54.9,2600.1-55.7,2597-56.6,2593.8z M-380,2271.4c-1.3,4.7-2.6,9.4-3.9,14.1l-1.5,5.3
	c-0.3,1.1-0.6,2.2-0.9,3.3c-2.7,9.8-5.5,19.5-8.2,29.3c0,0,0,0,0,0s0,0,0,0v0c-0.8,3-1.7,6.1-2.6,9.1c0,0,0,0,0,0
	c-0.3,1.2-0.7,2.4-1,3.6v0c-1.3,4.6-2.6,9.2-3.8,13.8c-0.6,2.2-1.2,4.4-1.8,6.6c-1.3,4.5-2.5,8.9-3.8,13.4c-1,3.5-1.9,7-2.9,10.4
	c-1.4,5.1-2.8,10.1-4.3,15.2v0c0,0,0,0,0,0c-1.8,6.4-3.6,12.9-5.4,19.3v0c-0.5,1.8-1,3.6-1.5,5.4c-0.9,3-1.7,6.1-2.5,9.1
	c-1.4,5.1-2.9,10.2-4.3,15.4c-0.6,2.1-1.2,4.3-1.8,6.4c2,0,4,0.1,5.9,0.1c0.6-2.2,1.2-4.4,1.8-6.6c1.4-5.1,2.8-10.2,4.3-15.3
	c0.8-2.9,1.6-5.8,2.4-8.7c0.4-1.4,0.8-2.7,1.1-4.1v0c1-3.7,2.1-7.4,3.1-11.1c2.6-9.3,5.2-18.5,7.8-27.8c0.5-1.7,0.9-3.4,1.4-5
	c1.5-5.4,3-10.8,4.5-16.1c0.6-2.2,1.2-4.4,1.8-6.5c1.6-5.9,3.3-11.7,4.9-17.6c1.6-5.7,3.2-11.4,4.8-17.2c0.3-1.1,0.6-2.2,0.9-3.3v0
	c0.3-1,0.6-2,0.8-3c3.5-12.5,7-24.9,10.5-37.4C-376.1,2271.4-378,2271.4-380,2271.4z M-430.4,2486.5c-0.3-0.4-0.7-0.9-0.9-1.4h-0.2
	c-0.1,1-0.4,1.9-0.4,2.7c0,1.4,0,2.9,0,4.3v0.2c0,0.1,0,0.2,0,0.3c0,0.4,0,0.9,0,1.3c1.4,1.7,2.8,3.3,4.3,5c2.7,0.1,5.4,0.3,8.1,0.5
	h0.1C-423,2495.1-426.7,2490.8-430.4,2486.5z M-369.4,2558.1c-2.2-2.5-4.3-5.1-6.5-7.6c-4.7-5.6-9.5-11.1-14.2-16.7
	c-1.8-2.1-3.7-4.3-5.5-6.5c-5-5.9-10-11.7-15-17.6c-2.9-3.4-5.9-6.9-8.8-10.3h-0.1c-2.7-0.2-5.4-0.3-8.1-0.5c3,3.5,6,7,9,10.5
	c5.1,6,10.2,11.9,15.3,17.9c1.9,2.2,3.7,4.4,5.6,6.5c5.2,6.1,10.4,12.2,15.7,18.3c2.1,2.5,4.3,5,6.4,7.5
	c7.4,8.7,14.8,17.4,22.3,26.1c2.1-0.5,4.1-1.1,6.1-1.8C-354.6,2575.4-362,2566.7-369.4,2558.1z M-432.2,2479.5
	c0-0.1,0.1-0.2,0.1-0.3c2.6-9.3,5.2-18.6,7.7-27.8c-2,0-4-0.1-5.9-0.1c-2.5,8.9-5,17.8-7.5,26.7c-7.8-9.2-15.6-18.3-23.3-27.3
	c-2.6,0-5.2,0-7.7,0c3.4,4,6.8,7.9,10.2,11.9c0.8,1,1.6,1.9,2.4,2.9v0l0,0c4.9,5.7,9.7,11.4,14.6,17.1c3.3,3.8,6.5,7.6,9.8,11.4
	c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0-0.3v-0.2c0-1.4,0-2.9,0-4.3c0-0.9,0.3-1.8,0.4-2.7h0.2C-432.2,2483.3-432.6,2481.1-432.2,2479.5z
	 M-590.2,2405.2c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.2,0.4-0.4
	c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.2
	c0.1-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8c0-0.2,0-0.4,0-0.6
	c0-1.1,0-2.1,0.1-3.2C-588.7,2399.8-589.4,2402.5-590.2,2405.2z M-588.4,2418.5c-0.4,0.1-0.8,0.1-1.2,0.1c-1.3,0.1-2.5,0.2-3.7,0.4
	c-0.3,0-0.5,0-0.8,0.1c-2.3,8.3-4.7,16.6-7,25c-0.6,2.1-1.2,4.2-1.8,6.3c-2.7,9.7-5.4,19.4-8.2,29.1c-1.3,4.5-2.5,9-3.8,13.4v0
	c-3.2,11.5-6.4,23-9.6,34.5c-0.6,2.3-1.3,4.5-1.9,6.8c-1.6,5.7-3.2,11.4-4.8,17.1c0,0,0,0,0,0c-0.1,0.5-0.3,1.1-0.4,1.6
	c-2.2,8-4.4,15.9-6.7,23.9c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.5l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0,0
	c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0.1,0c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.2,0.3,0.4,0.4,0.6
	c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
	c0.1,0.3,0.3,0.5,0.4,0.8c4.5-16.1,9-32.2,13.5-48.3c0.6-2.3,1.3-4.5,1.9-6.8c2.1-7.4,4.1-14.9,6.2-22.3c1.2-4.4,2.4-8.8,3.7-13.1
	c0,0,0,0,0,0s0,0,0,0c3.9-13.8,7.7-27.7,11.6-41.5c0.6-2.1,1.2-4.3,1.8-6.4c2.4-8.5,4.8-17,7.1-25.5
	C-588.1,2418.4-588.2,2418.5-588.4,2418.5z M-424.6,2305.1c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.2c0,0.1,0,0.1-0.1,0.2
	c1.1-1.3,2.1-2.5,3.2-3.8c-0.2-0.2-0.3-0.4-0.5-0.5c-0.9-1.2-1.6-2.5-2.5-3.8c-0.1-0.2-0.3-0.4-0.4-0.7c-0.6,0.7-1.2,1.4-1.8,2.1
	c0,0,0,0.1,0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.1,0.1,0.2,0.2,0.3c1,1.3,1.6,2.6,1.6,4.1c0,0.1,0,0.3,0,0.4
	C-424.5,2304.9-424.6,2305-424.6,2305.1z M-466,2445c-4.9-5.8-9.9-11.6-14.8-17.3c-1.5-1.8-3-3.5-4.5-5.3l-10.7-12.5
	c-2.2-2.5-4.3-5.1-6.5-7.6v0c-0.8-0.9-1.5-1.8-2.3-2.7c2.5-2.9,5-5.9,7.6-8.8c-1.2-0.1-2.4-0.2-3.6-0.3c-0.3,0-0.6,0-0.9-0.1
	c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.5-0.1-0.8-0.1c0,0-0.1,0-0.1,0c-1.5,1.7-2.9,3.5-4.4,5.2c-2-2.4-4-4.7-6-7
	l0,0c-0.6-0.1-1.2-0.2-1.8-0.3c-1.6-0.3-3.2-0.6-4.8-0.9c-0.8-0.1-1.6-0.3-2.3-0.4c2.4,2.8,4.8,5.6,7.2,8.5
	c4.1,4.8,8.2,9.6,12.2,14.3l0,0c0.6,0.7,1.2,1.4,1.8,2.1c0.8,0.9,1.6,1.9,2.4,2.8c2.6,3.1,5.3,6.2,7.9,9.3c1.7,2,3.3,3.9,5,5.9
	c4.4,5.2,8.8,10.3,13.2,15.5c1.7,1.9,3.3,3.9,5,5.8c2.6,0,5.2,0,7.7,0C-462.7,2448.8-464.3,2446.9-466,2445z M-88.8,2553.3
	c0.1,0.7,0.2,1.4,0.4,2.3c0.4-0.3,0.6-0.6,0.9-0.8C-88,2554.3-88.4,2553.8-88.8,2553.3z M-124.2,2511.9c-4.3-5-8.6-10.1-12.9-15.1
	c-5.9-6.9-11.8-13.8-17.7-20.8c-2.1-2.5-4.3-5.1-6.4-7.6c0,0-0.1-0.1-0.1-0.1c-1.3-1.6-2.3-4.6-1.8-6.5c0.9-3.3,1.8-6.7,2.7-10
	c0.6-2.2,1.2-4.3,1.8-6.5c3.9-14.3,7.9-28.5,11.9-42.7c0.7-2.5,1.4-4.9,2.1-7.4c0.7-2.4,1.3-4.8,2-7.2h-0.1c-0.7,0-1.4-0.1-2.1-0.1
	c-0.7,0-1.4,0-2.2,0c-0.5,0-1,0-1.5,0c-0.7,2.4-1.3,4.7-2,7.1c-0.7,2.6-1.4,5.1-2.2,7.7c-3.9,14-7.9,28.1-11.9,42.5
	c-0.6,2.1-1.2,4.3-1.8,6.5c-0.7,2.7-1.5,5.3-2.2,8c-2.4-2.8-4.7-5.5-7-8.1c-1.4,0-2.8,0-4.2-0.1c-1.2,0-2.4,0-3.6,0
	c14,16.4,28,32.8,42,49.2c4.5,5.2,9,10.5,13.4,15.7c3.6,4.2,7.2,8.4,10.8,12.6c1.8,2.2,3.7,4.3,5.5,6.5c2.5,0,5-0.1,7.5-0.1
	c-1.8-2.1-3.6-4.2-5.4-6.3C-114.4,2523.4-119.3,2517.7-124.2,2511.9z M-56.6,2593.8c-0.1-0.3-0.1-0.6-0.1-0.8c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
	c0.1-0.1,0.1-0.2,0.2-0.3c-9-10.6-18-21.1-27.1-31.7v0c-1.5-1.7-2.9-3.4-4.4-5.1c-0.2,0.2-0.5,0.5-0.9,0.8c-0.1-0.8-0.3-1.5-0.4-2.3
	c-1.7-2-3.4-4-5.2-6c-3.4-3.9-6.7-7.9-10.1-11.8c-2.5,0-5,0.1-7.5,0.1c5.5,6.4,11,12.8,16.5,19.3c2.6,3,5.1,6,7.7,9.1
	c0.5,0.5,0.9,1.1,1.4,1.6c2.8,3.3,5.6,6.5,8.3,9.8c1.6,1.8,3.1,3.6,4.7,5.5c1.4,1.6,2.8,3.3,4.3,5c5,5.8,10,11.7,15,17.5
	C-54.9,2600.1-55.7,2597-56.6,2593.8z M-384,2285.5l-1.5,5.3c-0.3,1.1-0.6,2.2-0.9,3.3c-2.7,9.8-5.5,19.5-8.2,29.3c0,0,0,0,0,0
	s0,0,0,0v0c-0.8,3-1.7,6.1-2.6,9.1c0,0,0,0,0,0c-0.3,1.2-0.7,2.4-1,3.6v0c-1.3,4.6-2.6,9.2-3.8,13.8c-0.6,2.2-1.2,4.4-1.8,6.6
	c-1.3,4.5-2.5,8.9-3.8,13.4c-1,3.5-1.9,7-2.9,10.4c-1.4,5.1-2.8,10.1-4.3,15.2v0c0,0,0,0,0,0c-1.8,6.4-3.6,12.9-5.4,19.3v0
	c-0.5,1.8-1,3.6-1.5,5.4c-0.9,3-1.7,6.1-2.5,9.1c-1.4,5.1-2.9,10.2-4.3,15.4c-0.6,2.1-1.2,4.3-1.8,6.4c-2.5,8.9-5,17.8-7.5,26.7
	c-7.8-9.2-15.6-18.3-23.3-27.3c-2.6,0-5.2,0-7.7,0c3.4,4,6.8,7.9,10.2,11.9c0.8,1,1.6,1.9,2.4,2.9v0l0,0c4.9,5.7,9.7,11.4,14.6,17.1
	c3.3,3.8,6.5,7.6,9.8,11.4c1.4,1.7,2.8,3.3,4.3,5c3,3.5,6,7,9,10.5c5.1,6,10.2,11.9,15.3,17.9c2.6,0,5.2,0,7.7,0
	c-5-5.9-10-11.7-15-17.6c-2.9-3.4-5.9-6.9-8.8-10.3c-3.7-4.3-7.4-8.6-11-13c-0.3-0.4-0.7-0.9-0.9-1.4c-0.9-1.7-1.3-4-0.9-5.5
	c0-0.1,0.1-0.2,0.1-0.3c2.6-9.3,5.2-18.6,7.7-27.8c0.6-2.2,1.2-4.4,1.8-6.6c1.4-5.1,2.8-10.2,4.3-15.3c0.8-2.9,1.6-5.8,2.4-8.7
	c0.4-1.4,0.8-2.7,1.1-4.1v0c1-3.7,2.1-7.4,3.1-11.1c2.6-9.3,5.2-18.5,7.8-27.8c0.5-1.7,0.9-3.4,1.4-5c1.5-5.4,3-10.8,4.5-16.1
	c0.6-2.2,1.2-4.4,1.8-6.5c1.6-5.9,3.3-11.7,4.9-17.6c1.6-5.7,3.2-11.4,4.8-17.2c0.3-1.1,0.6-2.2,0.9-3.3v0c0.3-1,0.6-2,0.8-3
	c3.5-12.5,7-24.9,10.5-37.4c-2,0-3.9,0-5.9,0C-381.3,2276.1-382.6,2280.8-384,2285.5z M-369.4,2558.1c-2.2-2.5-4.3-5.1-6.5-7.6
	c-4.7-5.6-9.5-11.1-14.2-16.7c-1.8-2.1-3.7-4.3-5.5-6.5c-2.6,0-5.1,0-7.7,0c1.9,2.2,3.7,4.4,5.6,6.5c5.2,6.1,10.4,12.2,15.7,18.3
	c2.1,2.5,4.3,5,6.4,7.5c7.4,8.7,14.8,17.4,22.3,26.1c2.1-0.5,4.1-1.1,6.1-1.8C-354.6,2575.4-362,2566.7-369.4,2558.1z M-583,2379.4
	c-0.7,2.4-1.3,4.8-2,7.2c-1,3.5-2,7.1-3,10.6c0,1.1,0,2.1-0.1,3.2c0,0.2,0,0.4,0,0.6c0,0.3,0,0.6,0,0.8c0,0.2,0,0.4,0,0.6
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2-0.1,0.2c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0
	c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1
	c-0.1,0.1-0.2,0.1-0.4,0.1v0c-1.3,4.6-2.6,9.2-3.9,13.9c-2.3,8.3-4.7,16.6-7,25c-0.6,2.1-1.2,4.2-1.8,6.3c2,0,3.9,0,5.9,0
	c0.6-2.1,1.2-4.3,1.8-6.4c2.4-8.5,4.8-17,7.1-25.5c3.5-12.5,7-25,10.5-37.6v0c2.3-8.2,4.6-16.4,6.9-24.6c-2,0-4,0-5.9,0
	C-578.7,2364-580.8,2371.7-583,2379.4z M-590.2,2405.2c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2
	c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1
	c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8
	c0-0.2,0-0.4,0-0.6c0-1.1,0-2.1,0.1-3.2C-588.7,2399.8-589.4,2402.5-590.2,2405.2z M-611,2479.4c-1.3,4.5-2.5,9-3.8,13.4v0
	c-3.2,11.5-6.4,23-9.6,34.5c-0.6,2.3-1.3,4.5-1.9,6.8c-1.6,5.7-3.2,11.4-4.8,17.1c0,0,0,0,0,0c-0.1,0.5-0.3,1.1-0.4,1.6
	c-2.2,8-4.4,15.9-6.7,23.9c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.5l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0,0
	c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0.1,0c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.2,0.3,0.4,0.4,0.6
	c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
	c0.1,0.3,0.3,0.5,0.4,0.8c4.5-16.1,9-32.2,13.5-48.3c0.6-2.3,1.3-4.5,1.9-6.8c2.1-7.4,4.1-14.9,6.2-22.3c1.2-4.4,2.4-8.8,3.7-13.1
	c0,0,0,0,0,0s0,0,0,0c3.9-13.8,7.7-27.7,11.6-41.5c-2,0-3.9,0-5.9,0C-605.5,2460-608.3,2469.7-611,2479.4z M-466,2445
	c-4.9-5.8-9.9-11.6-14.8-17.3c-1.5-1.8-3-3.5-4.5-5.3l-10.7-12.5c0,0.2,0,0.3-0.1,0.4c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.3
	c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.3,0.6-0.6,0.9c-0.1,0.1-0.2,0.2-0.3,0.3
	c-0.2,0.2-0.3,0.4-0.6,0.6c-0.3,0.3-0.6,0.5-1,0.6c0,0-0.1,0-0.2,0.1c-0.3,0.1-0.6,0.1-0.9,0.1c2.6,3.1,5.3,6.2,7.9,9.3
	c1.7,2,3.3,3.9,5,5.9c4.4,5.2,8.8,10.3,13.2,15.5c1.7,1.9,3.3,3.9,5,5.8c2.6,0,5.2,0,7.7,0C-462.7,2448.8-464.3,2446.9-466,2445z
	 M78.5,2614.6c-8.2-9.6-16.5-19.3-24.7-28.9c-2-2.3-4-4.7-6-7c-2.1-2.5-4.2-4.9-6.3-7.4c-2.6-3.1-5.3-6.2-7.9-9.3
	c-7.5-8.8-15.1-17.6-22.6-26.5c-1.5-1.8-3-3.5-4.5-5.3c-8.2-9.6-16.4-19.2-24.6-28.8c-2.2-2.5-4.4-5.1-6.5-7.7
	c-1.1-1.2-2.1-2.4-3.1-3.7c-2.1-2.5-4.3-5-6.4-7.5c-1.3-1.5-2.6-3-3.8-4.5c-2.6-3.1-5.2-6.1-7.8-9.2c-4.7-5.5-9.3-10.9-14-16.4
	c-1.9-2.3-3.9-4.6-5.8-6.8c-11.7-13.7-23.4-27.4-35.1-41.1c-2.6-3-5.2-6.1-7.8-9.1c-2.2-2.5-4.3-5.1-6.5-7.6
	c-1.1-1.2-2.1-2.5-3.2-3.7c-5.4-6.3-10.8-12.7-16.2-19c-4.2-4.9-4.8-4.9-9.1,0.1c-3.8,4.4-7.5,8.8-11.3,13.3
	c-2.8,3.2-5.5,6.5-8.3,9.7c-1.8,2-3.5,4.1-5.2,6.1c-1.8,2.1-3.7,4.3-5.5,6.4c-2.8,3.2-5.5,6.5-8.3,9.7c-3.4,4-6.8,8-10.3,12
	c-0.1,0.2-0.3,0.3-0.4,0.5c-1.1,1.3-2.4,2.6-3.7,4c-1.3-1.4-2.3-2.5-3.3-3.6c-12.3-14.4-24.6-28.8-36.9-43.2
	c-3.9-4.5-7.8-9.1-11.7-13.6c-2.5-3-5.1-5.9-7.6-8.9c-2.2-2.5-4.3-5.1-6.5-7.6c-4.4-5.2-8.9-10.4-13.3-15.6
	c-4.1-4.8-8.2-9.6-12.3-14.4c-4.7-5.5-9.4-10.9-14-16.4c-2.2-2.6-4.4-5.1-6.6-7.7c-0.7-0.8-1.4-1.7-2.2-2.5
	c-1.1-1.3-2.2-2.6-3.3-3.9c-5.2-6.1-10.4-12.2-15.6-18.3c-2.2-2.6-4.4-5.2-6.6-7.8c-8.5-9.9-16.9-19.8-25.4-29.7
	c-4.2-4.9-4.7-4.9-9,0.1c-8.5,10-17,19.9-25.5,29.9c-2.1,2.4-4.1,4.8-6.2,7.2c-6.4,7.4-12.7,14.8-19,22.3c-1.1,1.3-2.2,2.5-3.2,3.8
	c-0.6,0.7-1.2,1.4-1.8,2.1c-1.4,1.6-2.7,3.2-4.1,4.8c-9.6,11.2-19.2,22.5-28.8,33.7c-3.4,3.9-6.7,7.9-10.1,11.8
	c-2,2.3-4,4.6-5.9,6.9c-7.3,8.5-14.5,17-21.9,25.6c-2.2,2.6-4.5,5.2-6.7,7.9c-1.5,1.7-2.9,3.5-4.4,5.2c-2-2.4-4-4.7-6-7l0,0
	c-2.8-3.3-5.6-6.5-8.4-9.8c-6.4-7.5-12.7-14.9-19.1-22.3c-2-2.4-4.1-4.8-6.1-7.2c-4.7-5.5-9.5-11.1-14.2-16.6
	c-4.1-4.8-4.6-4.8-8.7,0c-4.8,5.6-9.7,11.3-14.5,16.9c-2,2.3-4,4.7-6,7c-13.6,15.9-27.2,31.8-40.8,47.8c-0.3,0.4-0.7,0.8-1,1.2
	c0,0,0,0,0,0c-0.5,0.5-0.9,1.1-1.4,1.6v0c0,0,0,0,0,0c-3.3,3.8-6.6,7.7-9.8,11.5c-7.2,8.4-14.4,16.9-21.7,25.3c-1.7,2-3.4,4-5.1,6
	c-17.7,20.8-35.5,41.5-53.2,62.3c-1.7,2-3.4,4-5.1,5.9c-2.9,3.4-5.7,6.7-8.6,10.1c-1.8,2-3.5,4.1-5.2,6.1c-5,5.8-10,11.7-14.9,17.5
	c-1.4,1.6-2.7,3.2-4,4.7c-5.3,6.2-10.5,12.3-15.8,18.5c-1.6,1.9-3.2,3.8-4.8,5.7c-1.1,1.3-2.3,2.7-3.4,4c-2.1,2.5-4.2,4.9-6.3,7.4
	c-0.4,0.4-0.8,0.9-1.1,1.3c-2.3,2.6-4.5,5.3-6.8,7.9c-1.1,1.3-2.2,2.6-3.3,3.9c-1.8,2.1-3.6,4.2-5.4,6.3c-1.4,1.6-2.8,3.3-4.2,5
	c-1.4,1.6-1.8,3.9-2.7,5.9c2.1,0.6,4.3,1.3,6.4,1.6c2,0.3,4,0.1,6,0.1c163.2-0.1,326.3-0.1,489.5-0.1h1.2c12.6,0,25.1,0,37.6,0
	c9.5,0,19,0,28.5,0h22.4c70.5,0,141,0,211.5,0h0c1.5,0,3.1,0,4.6,0h0c27.7,0,55.4,0,83.1,0c2.8,0,5.5-1.1,8.3-1.6
	C81.4,2619.5,80.4,2616.7,78.5,2614.6z M-182.6,2420c2.8-3.3,5.7-6.7,8.5-10c4.4-5.1,8.7-10.2,13-15.3c1.9-2.2,3.7-4.3,5.6-6.5
	c2.5-2.9,4.9-5.8,7.4-8.7c0.4-0.5,0.8-1,1.3-1.5c0.3,0.1,0.6,0.2,0.9,0.4c-0.1,0.5-0.3,1-0.4,1.6c-0.8,2.7-1.5,5.4-2.3,8.1
	c-0.7,2.4-1.3,4.7-2,7.1c-0.7,2.6-1.4,5.1-2.2,7.7c-3.9,14-7.9,28.1-11.9,42.5c-0.6,2.1-1.2,4.3-1.8,6.5c-0.7,2.7-1.5,5.3-2.2,8
	c-2.4-2.8-4.7-5.5-7-8.1c-2-2.3-3.9-4.6-5.8-6.9c-3.8-4.4-7.4-8.7-11.2-13.1c0.2-0.3,0.5-0.6,0.7-0.8
	C-188.8,2427.3-185.7,2423.6-182.6,2420z M-497.2,2390.7c2.5-2.9,5-5.8,7.5-8.7c7.2-8.5,14.4-16.9,21.7-25.4c2-2.3,3.9-4.6,5.9-6.9
	c8.3-9.7,16.5-19.4,24.8-29c4.2-4.9,8.4-9.8,12.6-14.7l0,0c1.1-1.3,2.1-2.5,3.2-3.8c1.2-1.4,2.4-2.8,3.5-4.1c2.6-3,5.1-6,7.7-9
	c1-1.2,1.9-2.3,2.9-3.4c4.1-4.8,8.2-9.6,12.3-14.3c2.1-2.5,4.2-4.9,6.3-7.4c4.9-5.8,9.8-11.5,14.8-17.3c0.3,0.2,0.6,0.3,0.8,0.5
	c-1.6,5.6-3.1,11.2-4.7,16.7c-0.7,2.6-1.4,5.1-2.1,7.7c-1.3,4.7-2.6,9.4-3.9,14.1l-1.5,5.3c-0.3,1.1-0.6,2.2-0.9,3.3
	c-2.7,9.8-5.5,19.5-8.2,29.3c0,0,0,0,0,0s0,0,0,0v0c-0.8,3-1.7,6.1-2.6,9.1c0,0,0,0,0,0c-0.3,1.2-0.7,2.4-1,3.6v0
	c-1.3,4.6-2.6,9.2-3.8,13.8c-0.6,2.2-1.2,4.4-1.8,6.6c-1.3,4.5-2.5,8.9-3.8,13.4c-1,3.5-1.9,7-2.9,10.4c-1.4,5.1-2.8,10.1-4.3,15.2
	v0c0,0,0,0,0,0c-1.8,6.4-3.6,12.9-5.4,19.3v0c-0.5,1.8-1,3.6-1.5,5.4c-0.9,3-1.7,6.1-2.5,9.1c-1.4,5.1-2.9,10.2-4.3,15.4
	c-0.6,2.1-1.2,4.3-1.8,6.4c-2.5,8.9-5,17.8-7.5,26.7c-7.8-9.2-15.6-18.3-23.3-27.3c-1.6-1.9-3.3-3.8-4.9-5.7
	c-4.9-5.8-9.9-11.6-14.8-17.3c-1.5-1.8-3-3.5-4.5-5.3l-10.7-12.5c-2.2-2.5-4.3-5.1-6.5-7.6v0c-0.8-0.9-1.5-1.8-2.3-2.7
	C-502.2,2396.6-499.7,2393.6-497.2,2390.7z M-647.8,2611.5c-1.4,5-3.2,6.7-8.6,6.7c-38.2-0.2-76.5-0.2-114.7-0.2
	c-9.6,0-19.2,0-28.7,0h-8c1.3-1.6,2.7-3.1,4-4.7c0.9-1,1.7-2,2.6-3c1.5-1.7,2.9-3.4,4.4-5.1c1.9-2.2,3.8-4.4,5.7-6.6
	c1-1.2,2.1-2.4,3.1-3.6c0.4-0.4,0.7-0.9,1.1-1.3c0.6-0.7,1.2-1.4,1.9-2.2c1.2-1.4,2.4-2.8,3.5-4.2c1.5-1.8,3.1-3.6,4.6-5.4
	c4.9-5.8,9.9-11.6,14.8-17.3c2.2-2.5,4.3-5.1,6.5-7.6c6.6-7.7,13.1-15.4,19.7-23c1.7-2,3.4-3.9,5.1-5.9c2.8-3.2,5.5-6.4,8.2-9.6
	c2-2.3,4-4.6,6-6.9c17.6-20.6,35.2-41.2,52.8-61.8c1.7-2,3.5-4,5.2-6.1c6.7-7.8,13.4-15.7,20.2-23.5c0.9-1.1,1.9-2.2,2.8-3.3
	c0.6-0.7,1.1-1.3,1.7-1.9l0,0v0c1.2-1.4,2.4-2.9,3.7-4.3c0.9-1,1.8-2.1,2.6-3.1c0.2-0.2,0.4-0.4,0.5-0.6
	c14.4-16.9,28.9-33.8,43.3-50.6c2-2.4,4.1-4.7,6.1-7.1c1.1-1.3,2.1-2.5,3.2-3.8c0.3,0.1,0.7,0.2,1,0.3c-0.3,1.1-0.6,2.3-1,3.4
	c-0.7,2.4-1.3,4.7-2,7.1c-2.1,7.7-4.3,15.4-6.4,23.1c-0.7,2.4-1.3,4.8-2,7.2c-1,3.5-2,7.1-3,10.6c-0.8,2.7-1.5,5.4-2.3,8v0
	c-1.3,4.6-2.6,9.2-3.9,13.9c-2.3,8.3-4.7,16.6-7,25c-0.6,2.1-1.2,4.2-1.8,6.3c-2.7,9.7-5.4,19.4-8.2,29.1c-1.3,4.5-2.5,9-3.8,13.4v0
	c-3.2,11.5-6.4,23-9.6,34.5c-0.6,2.3-1.3,4.5-1.9,6.8c-1.6,5.7-3.2,11.4-4.8,17.1c0,0,0,0,0,0c-0.1,0.5-0.3,1.1-0.4,1.6
	c-2.2,8-4.4,15.9-6.7,23.9c-3.1,11-6.1,22-9.2,32.9C-647.5,2610.3-647.6,2610.9-647.8,2611.5z M-643.6,2617.6
	c0.1-0.5,0.3-0.9,0.4-1.4c3.2-11.3,6.3-22.6,9.5-33.9c4.5-16.1,9-32.2,13.5-48.3c0.6-2.3,1.3-4.5,1.9-6.8c2.1-7.4,4.1-14.9,6.2-22.3
	c1.2-4.4,2.4-8.8,3.7-13.1c0,0,0,0,0,0s0,0,0,0c3.9-13.8,7.7-27.7,11.6-41.5c0.6-2.1,1.2-4.3,1.8-6.4c2.4-8.5,4.8-17,7.1-25.5
	c3.5-12.5,7-25,10.5-37.6v0c2.3-8.2,4.6-16.4,6.9-24.6c0.7-2.4,1.3-4.8,2-7.1c1.1-3.8,2.1-7.7,3.2-11.5c3.3,3.8,6.5,7.6,9.8,11.4
	c2,2.4,4.1,4.8,6.1,7.2c7.3,8.6,14.7,17.2,22,25.7c1.3,1.6,2.6,3.1,4,4.6c2.4,2.8,4.8,5.6,7.2,8.5c4.1,4.8,8.2,9.6,12.2,14.3l0,0
	c0.6,0.7,1.2,1.4,1.8,2.1c0.8,0.9,1.6,1.9,2.4,2.8c2.6,3.1,5.3,6.2,7.9,9.3c1.7,2,3.3,3.9,5,5.9c4.4,5.2,8.8,10.3,13.2,15.5
	c1.7,1.9,3.3,3.9,5,5.8c3.4,4,6.8,7.9,10.2,11.9c0.8,1,1.6,1.9,2.4,2.9v0l0,0c4.9,5.7,9.7,11.4,14.6,17.1c3.3,3.8,6.5,7.6,9.8,11.4
	c1.4,1.7,2.8,3.3,4.3,5c3,3.5,6,7,9,10.5c5.1,6,10.2,11.9,15.3,17.9c1.9,2.2,3.7,4.4,5.6,6.5c5.2,6.1,10.4,12.2,15.7,18.3
	c2.1,2.5,4.3,5,6.4,7.5c7.4,8.7,14.8,17.4,22.3,26.1c2,2.4,4.1,4.8,6.2,7.2c6.6,7.8,13.2,15.5,19.9,23.3c0.4,0.4,0.8,0.9,1.1,1.3
	H-643.6z M-47,2618h-56.1c-26,0-51.9,0-77.9,0h-8c-13.2,0-26.5,0-39.7,0h0c-1,0-2.1,0-3.1,0h0c-0.8,0-1.6,0-2.4,0h-7.4
	c-5.9,0-11.7,0-17.6,0c-1.7,0-3.4,0-5,0c-6.5,0-13.1,0-19.6,0h0c-2.3,0-4.7,0-7,0c0,0,0,0,0,0c-6.3,0-12.7,0-19,0h-3.6
	c-0.9,0-1.6-0.1-2.3-0.2c-2.3-0.4-3.9-1.6-5.6-3.6c-6.7-7.8-13.3-15.7-20-23.5c-1.9-2.3-3.9-4.5-5.8-6.8c-7.4-8.7-14.8-17.3-22.2-26
	c-2.2-2.5-4.3-5.1-6.5-7.6c-4.7-5.6-9.5-11.1-14.2-16.7c-1.8-2.1-3.7-4.3-5.5-6.5c-5-5.9-10-11.7-15-17.6c-2.9-3.4-5.9-6.9-8.8-10.3
	c-3.7-4.3-7.4-8.6-11-13c-0.3-0.4-0.7-0.9-0.9-1.4c-0.9-1.7-1.3-4-0.9-5.5c0-0.1,0.1-0.2,0.1-0.3c2.6-9.3,5.2-18.6,7.7-27.8
	c0.6-2.2,1.2-4.4,1.8-6.6c1.4-5.1,2.8-10.2,4.3-15.3c0.8-2.9,1.6-5.8,2.4-8.7c0.4-1.4,0.8-2.7,1.1-4.1v0c1-3.7,2.1-7.4,3.1-11.1
	c2.6-9.3,5.2-18.5,7.8-27.8c0.5-1.7,0.9-3.4,1.4-5c1.5-5.4,3-10.8,4.5-16.1c0.6-2.2,1.2-4.4,1.8-6.5c1.6-5.9,3.3-11.7,4.9-17.6
	c1.6-5.7,3.2-11.4,4.8-17.2c0.3-1.1,0.6-2.2,0.9-3.3v0c0.3-1,0.6-2,0.8-3c3.5-12.5,7-24.9,10.5-37.4c0.7-2.6,1.4-5.2,2.2-7.7
	c1.9-6.9,3.9-13.9,5.8-20.8c0.3-0.9,0.6-1.8,1.2-3.7c7,8.1,13.9,16.3,20.9,24.4c2.2,2.6,4.5,5.2,6.7,7.8
	c5.8,6.8,11.5,13.5,17.3,20.2c2.6,3.1,5.3,6.2,7.9,9.3c6.6,7.8,13.3,15.6,19.9,23.3c4.2,4.9,8.3,9.8,12.5,14.6
	c3.2,3.8,6.5,7.6,9.7,11.4c2.2,2.5,4.3,5,6.4,7.5c2.7,3.2,5.4,6.3,8.1,9.5c2.2,2.6,4.4,5.1,6.6,7.7l3,3.5c2.1,2.4,4.1,4.8,6.2,7.2
	c16.9,19.7,33.7,39.5,50.6,59.2c1.9,2.3,3.9,4.5,5.8,6.8c14,16.4,28,32.8,42,49.2c4.5,5.2,9,10.5,13.4,15.7
	c3.6,4.2,7.2,8.4,10.8,12.6c1.8,2.2,3.7,4.3,5.5,6.5c5.5,6.4,11,12.8,16.5,19.3c2.6,3,5.1,6,7.7,9.1c0.5,0.5,0.9,1.1,1.4,1.6
	c2.8,3.3,5.6,6.5,8.3,9.8c1.6,1.8,3.1,3.6,4.7,5.5c1.4,1.6,2.8,3.3,4.3,5c5,5.8,10,11.7,15,17.5c1,1.2,2,2.4,3.1,3.6l0,0
	c3.1,3.7,6.3,7.3,9.4,11C-43.9,2617.9-45.4,2618-47,2618z M15.3,2618.1c-7.8,0-15.7,0-23.5,0c-1.8,0-3.5,0-5.3,0
	c-5.2,0-10.4,0-15.7,0.1c-3.2,0-5.2-1-7.2-3.4c-2.3-2.7-4.6-5.4-7-8.2c-4.3-5-8.5-10-12.8-15c-9-10.6-18-21.1-27.1-31.7v0
	c-1.5-1.7-2.9-3.4-4.4-5.1c-0.4-0.5-0.8-1-1.2-1.4c-1.7-2-3.4-4-5.2-6c-3.4-3.9-6.7-7.9-10.1-11.8c-1.8-2.1-3.6-4.2-5.4-6.3
	c-4.9-5.7-9.8-11.5-14.7-17.2c-4.3-5-8.6-10.1-12.9-15.1c-5.9-6.9-11.8-13.8-17.7-20.8c-2.1-2.5-4.3-5.1-6.4-7.6
	c0,0-0.1-0.1-0.1-0.1c-1.3-1.6-2.3-4.6-1.8-6.5c0.9-3.3,1.8-6.7,2.7-10c0.6-2.2,1.2-4.3,1.8-6.5c3.9-14.3,7.9-28.5,11.9-42.7
	c0.7-2.5,1.4-4.9,2.1-7.4c0.7-2.4,1.3-4.8,2-7.2c0.6-2.2,1.2-4.4,1.8-6.5c0.7-2.6,1.4-5.2,2.2-7.8c0.3-0.9,0.7-1.8,1.3-3.2
	c2.8,3.3,5.6,6.5,8.4,9.8h0c1,1.2,2,2.3,3,3.5c0.1,0.2,0.3,0.3,0.4,0.5c1.6,1.8,3.2,3.7,4.7,5.5c1.6,1.8,3.1,3.7,4.7,5.5
	c5,5.9,10,11.7,15,17.6c9.3,10.9,18.6,21.8,27.9,32.6c1.9,2.3,3.9,4.6,5.9,6.8c4.1,4.8,8.2,9.5,12.2,14.3v0c2.6,3.1,5.2,6.1,7.8,9.2
	l0,0c1.8,2.1,3.6,4.2,5.4,6.3c2.1,2.4,4.1,4.8,6.2,7.2c11.6,13.5,23.2,27.1,34.8,40.6c1.5,1.7,2.9,3.5,4.4,5.2
	c5.4,6.3,10.8,12.7,16.3,19c4.8,5.6,9.6,11.2,14.4,16.8c2,2.4,4.1,4.8,6.1,7.1c2.1,2.5,4.2,4.9,6.3,7.4c2.6,3,5.2,6.1,7.8,9.1
	c1.8,2.1,3.6,4.2,5.4,6.3c0.8,0.9,1.6,1.9,2.4,2.8c1.4,1.6,2.8,3.3,4.2,5c2.6,3,5.1,6,7.7,9H15.3z M-403.8,2356.6
	c-1.3,4.5-2.5,8.9-3.8,13.4c-1,3.5-1.9,7-2.9,10.4c-1.4,5.1-2.8,10.1-4.3,15.2v0c0,0,0,0,0,0c-1.8,6.4-3.6,12.9-5.4,19.3v0
	c-0.5,1.8-1,3.6-1.5,5.4c-0.9,3-1.7,6.1-2.5,9.1c-1.4,5.1-2.9,10.2-4.3,15.4c-0.6,2.1-1.2,4.3-1.8,6.4c-2.5,8.9-5,17.8-7.5,26.7
	c-7.8-9.2-15.6-18.3-23.3-27.3c-1.6-1.9-3.3-3.8-4.9-5.7c-4.9-5.8-9.9-11.6-14.8-17.3c-1.5-1.8-3-3.5-4.5-5.3l-10.7-12.5
	c0,0.2,0,0.3-0.1,0.4c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.1,0.3
	c-0.1,0.3-0.3,0.6-0.6,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.2,0.2-0.3,0.4-0.6,0.6c-0.3,0.3-0.6,0.5-1,0.6c0,0-0.1,0-0.2,0.1
	c-0.3,0.1-0.6,0.1-0.9,0.1c2.6,3.1,5.3,6.2,7.9,9.3c1.7,2,3.3,3.9,5,5.9c4.4,5.2,8.8,10.3,13.2,15.5c1.7,1.9,3.3,3.9,5,5.8
	c3.4,4,6.8,7.9,10.2,11.9c0.8,1,1.6,1.9,2.4,2.9v0l0,0c4.9,5.7,9.7,11.4,14.6,17.1c3.3,3.8,6.5,7.6,9.8,11.4c0-0.4,0-0.9,0-1.3
	c0-0.1,0-0.2,0-0.3v-0.2c0-1.4,0-2.9,0-4.3c0-0.9,0.3-1.8,0.4-2.7h0.2c-0.9-1.7-1.3-4-0.9-5.5c0-0.1,0.1-0.2,0.1-0.3
	c2.6-9.3,5.2-18.6,7.7-27.8c0.6-2.2,1.2-4.4,1.8-6.6c1.4-5.1,2.8-10.2,4.3-15.3c0.8-2.9,1.6-5.8,2.4-8.7c0.4-1.4,0.8-2.7,1.1-4.1v0
	c1-3.7,2.1-7.4,3.1-11.1c2.6-9.3,5.2-18.5,7.8-27.8c0.5-1.7,0.9-3.4,1.4-5c1.5-5.4,3-10.8,4.5-16.1
	C-399.9,2356.5-401.9,2356.5-403.8,2356.6z M-602.8,2450.3c-2.7,9.7-5.4,19.4-8.2,29.1c-1.3,4.5-2.5,9-3.8,13.4v0
	c-3.2,11.5-6.4,23-9.6,34.5c-0.6,2.3-1.3,4.5-1.9,6.8c-1.6,5.7-3.2,11.4-4.8,17.1c0,0,0,0,0,0c-0.1,0.5-0.3,1.1-0.4,1.6
	c-2.2,8-4.4,15.9-6.7,23.9c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.5l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0,0
	c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0.1,0c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.2,0.3,0.4,0.4,0.6
	c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
	c0.1,0.3,0.3,0.5,0.4,0.8c4.5-16.1,9-32.2,13.5-48.3c0.6-2.3,1.3-4.5,1.9-6.8c2.1-7.4,4.1-14.9,6.2-22.3c1.2-4.4,2.4-8.8,3.7-13.1
	c0,0,0,0,0,0s0,0,0,0c3.9-13.8,7.7-27.7,11.6-41.5C-598.9,2450.3-600.9,2450.3-602.8,2450.3z M-590.2,2405.2
	c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.4,0.1-0.6
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8c0-0.2,0-0.4,0-0.6c0-1.1,0-2.1,0.1-3.2
	C-588.7,2399.8-589.4,2402.5-590.2,2405.2z M-576.6,2356.3c-2.1,7.7-4.3,15.4-6.4,23.1c-0.7,2.4-1.3,4.8-2,7.2c-1,3.5-2,7.1-3,10.6
	c0,1.1,0,2.1-0.1,3.2c0,0.2,0,0.4,0,0.6c0,0.3,0,0.6,0,0.8c0,0.2,0,0.4,0,0.6c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
	c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2-0.1,0.2c0,0,0,0,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2
	c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.1-0.4,0.1v0
	c-1.3,4.6-2.6,9.2-3.9,13.9c-2.3,8.3-4.7,16.6-7,25c-0.6,2.1-1.2,4.2-1.8,6.3c2,0,3.9,0,5.9,0c0.6-2.1,1.2-4.3,1.8-6.4
	c2.4-8.5,4.8-17,7.1-25.5c3.5-12.5,7-25,10.5-37.6v0c2.3-8.2,4.6-16.4,6.9-24.6C-572.6,2356.2-574.6,2356.3-576.6,2356.3z
	 M-369.4,2558.1c-2.2-2.5-4.3-5.1-6.5-7.6c-4.7-5.6-9.5-11.1-14.2-16.7c-1.8-2.1-3.7-4.3-5.5-6.5c-5-5.9-10-11.7-15-17.6
	c-2.9-3.4-5.9-6.9-8.8-10.3h-0.1c-2.7-0.2-5.4-0.3-8.1-0.5c3,3.5,6,7,9,10.5c5.1,6,10.2,11.9,15.3,17.9c1.9,2.2,3.7,4.4,5.6,6.5
	c5.2,6.1,10.4,12.2,15.7,18.3c2.1,2.5,4.3,5,6.4,7.5c7.4,8.7,14.8,17.4,22.3,26.1c2.1-0.5,4.1-1.1,6.1-1.8
	C-354.6,2575.4-362,2566.7-369.4,2558.1z M-430.4,2486.5c-0.3-0.4-0.7-0.9-0.9-1.4h-0.2c-0.1,1-0.4,1.9-0.4,2.7c0,1.4,0,2.9,0,4.3
	v0.2c0,0.1,0,0.2,0,0.3c0,0.4,0,0.9,0,1.3c1.4,1.7,2.8,3.3,4.3,5c2.7,0.1,5.4,0.3,8.1,0.5h0.1
	C-423,2495.1-426.7,2490.8-430.4,2486.5z M-380,2271.4c-1.3,4.7-2.6,9.4-3.9,14.1l-1.5,5.3c-0.3,1.1-0.6,2.2-0.9,3.3
	c-2.7,9.8-5.5,19.5-8.2,29.3c0,0,0,0,0,0s0,0,0,0v0c-0.8,3-1.7,6.1-2.6,9.1c0,0,0,0,0,0c-0.3,1.2-0.7,2.4-1,3.6v0
	c-1.3,4.6-2.6,9.2-3.8,13.8c-0.6,2.2-1.2,4.4-1.8,6.6c2,0,3.9,0,5.9,0c0.6-2.2,1.2-4.4,1.8-6.5c1.6-5.9,3.3-11.7,4.9-17.6
	c1.6-5.7,3.2-11.4,4.8-17.2c0.3-1.1,0.6-2.2,0.9-3.3v0c0.3-1,0.6-2,0.8-3c3.5-12.5,7-24.9,10.5-37.4
	C-376.1,2271.4-378,2271.4-380,2271.4z M-56.6,2593.8c-0.1-0.3-0.1-0.6-0.1-0.8c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3
	c-9-10.6-18-21.1-27.1-31.7v0c-1.5-1.7-2.9-3.4-4.4-5.1c-0.2,0.2-0.5,0.5-0.9,0.8c-0.1-0.8-0.3-1.5-0.4-2.3c-1.7-2-3.4-4-5.2-6
	c-3.4-3.9-6.7-7.9-10.1-11.8c-1.8-2.1-3.6-4.2-5.4-6.3c-2.6,0-5.1,0-7.6,0c1.8,2.2,3.7,4.3,5.5,6.5c5.5,6.4,11,12.8,16.5,19.3
	c2.6,3,5.1,6,7.7,9.1c0.5,0.5,0.9,1.1,1.4,1.6c2.8,3.3,5.6,6.5,8.3,9.8c1.6,1.8,3.1,3.6,4.7,5.5c1.4,1.6,2.8,3.3,4.3,5
	c5,5.8,10,11.7,15,17.5C-54.9,2600.1-55.7,2597-56.6,2593.8z M-124.2,2511.9c-4.3-5-8.6-10.1-12.9-15.1
	c-5.9-6.9-11.8-13.8-17.7-20.8c-2.1-2.5-4.3-5.1-6.4-7.6c0,0-0.1-0.1-0.1-0.1c-1.3-1.6-2.3-4.6-1.8-6.5c0.9-3.3,1.8-6.7,2.7-10
	c0.6-2.2,1.2-4.3,1.8-6.5c3.9-14.3,7.9-28.5,11.9-42.7c0.7-2.5,1.4-4.9,2.1-7.4c0.7-2.4,1.3-4.8,2-7.2h-0.1c-0.7,0-1.4-0.1-2.1-0.1
	c-0.7,0-1.4,0-2.2,0c-0.5,0-1,0-1.5,0c-0.7,2.4-1.3,4.7-2,7.1c-0.7,2.6-1.4,5.1-2.2,7.7c-3.9,14-7.9,28.1-11.9,42.5
	c-0.6,2.1-1.2,4.3-1.8,6.5c-0.7,2.7-1.5,5.3-2.2,8c-2.4-2.8-4.7-5.5-7-8.1c-1.4,0-2.8,0-4.2-0.1c-1.2,0-2.4,0-3.6,0
	c14,16.4,28,32.8,42,49.2c4.5,5.2,9,10.5,13.4,15.7c3.6,4.2,7.2,8.4,10.8,12.6c2.5,0,5.1,0,7.6,0
	C-114.4,2523.4-119.3,2517.7-124.2,2511.9z M-88.8,2553.3c0.1,0.7,0.2,1.4,0.4,2.3c0.4-0.3,0.6-0.6,0.9-0.8
	C-88,2554.3-88.4,2553.8-88.8,2553.3z M-466,2445c-4.9-5.8-9.9-11.6-14.8-17.3c-1.5-1.8-3-3.5-4.5-5.3l-10.7-12.5
	c-2.2-2.5-4.3-5.1-6.5-7.6v0c-0.8-0.9-1.5-1.8-2.3-2.7c2.5-2.9,5-5.9,7.6-8.8c-1.2-0.1-2.4-0.2-3.6-0.3c-0.3,0-0.6,0-0.9-0.1
	c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.5-0.1-0.8-0.1c0,0-0.1,0-0.1,0c-1.5,1.7-2.9,3.5-4.4,5.2c-2-2.4-4-4.7-6-7
	l0,0c-0.6-0.1-1.2-0.2-1.8-0.3c-1.6-0.3-3.2-0.6-4.8-0.9c-0.8-0.1-1.6-0.3-2.3-0.4c2.4,2.8,4.8,5.6,7.2,8.5
	c4.1,4.8,8.2,9.6,12.2,14.3l0,0c0.6,0.7,1.2,1.4,1.8,2.1c0.8,0.9,1.6,1.9,2.4,2.8c2.6,3.1,5.3,6.2,7.9,9.3c1.7,2,3.3,3.9,5,5.9
	c4.4,5.2,8.8,10.3,13.2,15.5c1.7,1.9,3.3,3.9,5,5.8c2.6,0,5.2,0,7.7,0C-462.7,2448.8-464.3,2446.9-466,2445z M-424.6,2305.1
	c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.2c0,0.1,0,0.1-0.1,0.2c1.1-1.3,2.1-2.5,3.2-3.8c-0.2-0.2-0.3-0.4-0.5-0.5
	c-0.9-1.2-1.6-2.5-2.5-3.8c-0.1-0.2-0.3-0.4-0.4-0.7c-0.6,0.7-1.2,1.4-1.8,2.1c0,0,0,0.1,0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6
	c0.1,0.1,0.1,0.2,0.2,0.3c1,1.3,1.6,2.6,1.6,4.1c0,0.1,0,0.3,0,0.4C-424.5,2304.9-424.6,2305-424.6,2305.1z M-588.4,2418.5
	c-0.4,0.1-0.8,0.1-1.2,0.1c-1.3,0.1-2.5,0.2-3.7,0.4c-0.3,0-0.5,0-0.8,0.1c-2.3,8.3-4.7,16.6-7,25c-0.6,2.1-1.2,4.2-1.8,6.3
	c-2.7,9.7-5.4,19.4-8.2,29.1c-1.3,4.5-2.5,9-3.8,13.4v0c-3.2,11.5-6.4,23-9.6,34.5c-0.6,2.3-1.3,4.5-1.9,6.8
	c-1.6,5.7-3.2,11.4-4.8,17.1c0,0,0,0,0,0c-0.1,0.5-0.3,1.1-0.4,1.6c-2.2,8-4.4,15.9-6.7,23.9c0,0,0,0,0.1,0.1
	c0.2,0.2,0.4,0.4,0.6,0.5l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0.1,0
	c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.2,0.3,0.4,0.4,0.6c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4
	c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.3,0.5,0.4,0.8c4.5-16.1,9-32.2,13.5-48.3
	c0.6-2.3,1.3-4.5,1.9-6.8c2.1-7.4,4.1-14.9,6.2-22.3c1.2-4.4,2.4-8.8,3.7-13.1c0,0,0,0,0,0s0,0,0,0c3.9-13.8,7.7-27.7,11.6-41.5
	c0.6-2.1,1.2-4.3,1.8-6.4c2.4-8.5,4.8-17,7.1-25.5C-588.1,2418.4-588.2,2418.5-588.4,2418.5z M-590.2,2405.2
	c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.4,0.1-0.6
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8c0-0.2,0-0.4,0-0.6c0-1.1,0-2.1,0.1-3.2
	C-588.7,2399.8-589.4,2402.5-590.2,2405.2z M-432.2,2479.5c0-0.1,0.1-0.2,0.1-0.3c2.6-9.3,5.2-18.6,7.7-27.8c-2,0-4-0.1-5.9-0.1
	c-2.5,8.9-5,17.8-7.5,26.7c-7.8-9.2-15.6-18.3-23.3-27.3c-2.6,0-5.2,0-7.7,0c3.4,4,6.8,7.9,10.2,11.9c0.8,1,1.6,1.9,2.4,2.9v0l0,0
	c4.9,5.7,9.7,11.4,14.6,17.1c3.3,3.8,6.5,7.6,9.8,11.4c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0-0.3v-0.2c0-1.4,0-2.9,0-4.3
	c0-0.9,0.3-1.8,0.4-2.7h0.2C-432.2,2483.3-432.6,2481.1-432.2,2479.5z M-369.4,2558.1c-2.2-2.5-4.3-5.1-6.5-7.6
	c-4.7-5.6-9.5-11.1-14.2-16.7c-1.8-2.1-3.7-4.3-5.5-6.5c-5-5.9-10-11.7-15-17.6c-2.9-3.4-5.9-6.9-8.8-10.3h-0.1
	c-2.7-0.2-5.4-0.3-8.1-0.5c3,3.5,6,7,9,10.5c5.1,6,10.2,11.9,15.3,17.9c1.9,2.2,3.7,4.4,5.6,6.5c5.2,6.1,10.4,12.2,15.7,18.3
	c2.1,2.5,4.3,5,6.4,7.5c7.4,8.7,14.8,17.4,22.3,26.1c2.1-0.5,4.1-1.1,6.1-1.8C-354.6,2575.4-362,2566.7-369.4,2558.1z
	 M-430.4,2486.5c-0.3-0.4-0.7-0.9-0.9-1.4h-0.2c-0.1,1-0.4,1.9-0.4,2.7c0,1.4,0,2.9,0,4.3v0.2c0,0.1,0,0.2,0,0.3c0,0.4,0,0.9,0,1.3
	c1.4,1.7,2.8,3.3,4.3,5c2.7,0.1,5.4,0.3,8.1,0.5h0.1C-423,2495.1-426.7,2490.8-430.4,2486.5z M-380,2271.4
	c-1.3,4.7-2.6,9.4-3.9,14.1l-1.5,5.3c-0.3,1.1-0.6,2.2-0.9,3.3c-2.7,9.8-5.5,19.5-8.2,29.3c0,0,0,0,0,0s0,0,0,0v0
	c-0.8,3-1.7,6.1-2.6,9.1c0,0,0,0,0,0c-0.3,1.2-0.7,2.4-1,3.6v0c-1.3,4.6-2.6,9.2-3.8,13.8c-0.6,2.2-1.2,4.4-1.8,6.6
	c-1.3,4.5-2.5,8.9-3.8,13.4c-1,3.5-1.9,7-2.9,10.4c-1.4,5.1-2.8,10.1-4.3,15.2v0c0,0,0,0,0,0c-1.8,6.4-3.6,12.9-5.4,19.3v0
	c-0.5,1.8-1,3.6-1.5,5.4c-0.9,3-1.7,6.1-2.5,9.1c-1.4,5.1-2.9,10.2-4.3,15.4c-0.6,2.1-1.2,4.3-1.8,6.4c2,0,4,0.1,5.9,0.1
	c0.6-2.2,1.2-4.4,1.8-6.6c1.4-5.1,2.8-10.2,4.3-15.3c0.8-2.9,1.6-5.8,2.4-8.7c0.4-1.4,0.8-2.7,1.1-4.1v0c1-3.7,2.1-7.4,3.1-11.1
	c2.6-9.3,5.2-18.5,7.8-27.8c0.5-1.7,0.9-3.4,1.4-5c1.5-5.4,3-10.8,4.5-16.1c0.6-2.2,1.2-4.4,1.8-6.5c1.6-5.9,3.3-11.7,4.9-17.6
	c1.6-5.7,3.2-11.4,4.8-17.2c0.3-1.1,0.6-2.2,0.9-3.3v0c0.3-1,0.6-2,0.8-3c3.5-12.5,7-24.9,10.5-37.4
	C-376.1,2271.4-378,2271.4-380,2271.4z M-56.6,2593.8c-0.1-0.3-0.1-0.6-0.1-0.8c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.2,0.2-0.3
	c-9-10.6-18-21.1-27.1-31.7v0c-1.5-1.7-2.9-3.4-4.4-5.1c-0.2,0.2-0.5,0.5-0.9,0.8c-0.1-0.8-0.3-1.5-0.4-2.3c-1.7-2-3.4-4-5.2-6
	c-3.4-3.9-6.7-7.9-10.1-11.8c-1.8-2.1-3.6-4.2-5.4-6.3c-2.6,0-5.1,0-7.6,0c1.8,2.2,3.7,4.3,5.5,6.5c5.5,6.4,11,12.8,16.5,19.3
	c2.6,3,5.1,6,7.7,9.1c0.5,0.5,0.9,1.1,1.4,1.6c2.8,3.3,5.6,6.5,8.3,9.8c1.6,1.8,3.1,3.6,4.7,5.5c1.4,1.6,2.8,3.3,4.3,5
	c5,5.8,10,11.7,15,17.5C-54.9,2600.1-55.7,2597-56.6,2593.8z M-124.2,2511.9c-4.3-5-8.6-10.1-12.9-15.1
	c-5.9-6.9-11.8-13.8-17.7-20.8c-2.1-2.5-4.3-5.1-6.4-7.6c0,0-0.1-0.1-0.1-0.1c-1.3-1.6-2.3-4.6-1.8-6.5c0.9-3.3,1.8-6.7,2.7-10
	c0.6-2.2,1.2-4.3,1.8-6.5c3.9-14.3,7.9-28.5,11.9-42.7c0.7-2.5,1.4-4.9,2.1-7.4c0.7-2.4,1.3-4.8,2-7.2h-0.1c-0.7,0-1.4-0.1-2.1-0.1
	c-0.7,0-1.4,0-2.2,0c-0.5,0-1,0-1.5,0c-0.7,2.4-1.3,4.7-2,7.1c-0.7,2.6-1.4,5.1-2.2,7.7c-3.9,14-7.9,28.1-11.9,42.5
	c-0.6,2.1-1.2,4.3-1.8,6.5c-0.7,2.7-1.5,5.3-2.2,8c-2.4-2.8-4.7-5.5-7-8.1c-1.4,0-2.8,0-4.2-0.1c-1.2,0-2.4,0-3.6,0
	c14,16.4,28,32.8,42,49.2c4.5,5.2,9,10.5,13.4,15.7c3.6,4.2,7.2,8.4,10.8,12.6c2.5,0,5.1,0,7.6,0
	C-114.4,2523.4-119.3,2517.7-124.2,2511.9z M-88.8,2553.3c0.1,0.7,0.2,1.4,0.4,2.3c0.4-0.3,0.6-0.6,0.9-0.8
	C-88,2554.3-88.4,2553.8-88.8,2553.3z M-466,2445c-4.9-5.8-9.9-11.6-14.8-17.3c-1.5-1.8-3-3.5-4.5-5.3l-10.7-12.5
	c-2.2-2.5-4.3-5.1-6.5-7.6v0c-0.8-0.9-1.5-1.8-2.3-2.7c2.5-2.9,5-5.9,7.6-8.8c-1.2-0.1-2.4-0.2-3.6-0.3c-0.3,0-0.6,0-0.9-0.1
	c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.3,0-0.5-0.1-0.8-0.1c0,0-0.1,0-0.1,0c-1.5,1.7-2.9,3.5-4.4,5.2c-2-2.4-4-4.7-6-7
	l0,0c-0.6-0.1-1.2-0.2-1.8-0.3c-1.6-0.3-3.2-0.6-4.8-0.9c-0.8-0.1-1.6-0.3-2.3-0.4c2.4,2.8,4.8,5.6,7.2,8.5
	c4.1,4.8,8.2,9.6,12.2,14.3l0,0c0.6,0.7,1.2,1.4,1.8,2.1c0.8,0.9,1.6,1.9,2.4,2.8c2.6,3.1,5.3,6.2,7.9,9.3c1.7,2,3.3,3.9,5,5.9
	c4.4,5.2,8.8,10.3,13.2,15.5c1.7,1.9,3.3,3.9,5,5.8c2.6,0,5.2,0,7.7,0C-462.7,2448.8-464.3,2446.9-466,2445z M-422,2301.6
	c-0.9-1.2-1.6-2.5-2.5-3.8c-0.1-0.2-0.3-0.4-0.4-0.7c-0.6,0.7-1.2,1.4-1.8,2.1c0,0,0,0.1,0.1,0.1c0.1,0.2,0.2,0.4,0.3,0.6
	c0.1,0.1,0.1,0.2,0.2,0.3c1,1.3,1.6,2.6,1.6,4.1c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.2
	c0,0.1-0.1,0.2-0.1,0.2l0,0c1.1-1.3,2.1-2.5,3.2-3.8C-421.7,2302-421.9,2301.8-422,2301.6z M-782.4,2584c-0.3-1.2-0.5-2.4-0.8-3.6
	c-1.1,1.3-2.3,2.7-3.4,4c0.2,1.4,0.4,2.8,0.7,4.1c0.2,1,0.4,2,0.7,2.9c1.2-1.4,2.4-2.8,3.5-4.2
	C-781.9,2586.3-782.1,2585.1-782.4,2584z M-588.4,2418.5c-0.4,0.1-0.8,0.1-1.2,0.1c-1.3,0.1-2.5,0.2-3.7,0.4c-0.3,0-0.5,0-0.8,0.1
	c-2.3,8.3-4.7,16.6-7,25c-0.6,2.1-1.2,4.2-1.8,6.3c-2.7,9.7-5.4,19.4-8.2,29.1c-1.3,4.5-2.5,9-3.8,13.4v0c-3.2,11.5-6.4,23-9.6,34.5
	c-0.6,2.3-1.3,4.5-1.9,6.8c-1.6,5.7-3.2,11.4-4.8,17.1c0,0,0,0,0,0c-0.1,0.5-0.3,1.1-0.4,1.6c-2.2,8-4.4,15.9-6.7,23.9
	c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.5l0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.6,0.6c0,0,0,0,0.1,0
	c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.3,0.4,0.5,0.6c0.1,0.2,0.3,0.4,0.4,0.6c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4
	c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.3,0.3,0.5,0.4,0.8c4.5-16.1,9-32.2,13.5-48.3
	c0.6-2.3,1.3-4.5,1.9-6.8c2.1-7.4,4.1-14.9,6.2-22.3c1.2-4.4,2.4-8.8,3.7-13.1c0,0,0,0,0,0s0,0,0,0c3.9-13.8,7.7-27.7,11.6-41.5
	c0.6-2.1,1.2-4.3,1.8-6.4c2.4-8.5,4.8-17,7.1-25.5C-588.1,2418.4-588.2,2418.5-588.4,2418.5z M-590.2,2405.2
	c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.1-0.1,0.2-0.2
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0-0.1,0.1-0.2,0.1-0.2c0.1-0.2,0.1-0.4,0.1-0.6
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.2,0-0.4,0-0.6c0-0.3,0-0.5,0-0.8c0-0.2,0-0.4,0-0.6c0-1.1,0-2.1,0.1-3.2
	C-588.7,2399.8-589.4,2402.5-590.2,2405.2z M-432.2,2479.5c0-0.1,0.1-0.2,0.1-0.3c2.6-9.3,5.2-18.6,7.7-27.8c-2,0-4-0.1-5.9-0.1
	c-2.5,8.9-5,17.8-7.5,26.7c-7.8-9.2-15.6-18.3-23.3-27.3c-2.6,0-5.2,0-7.7,0c3.4,4,6.8,7.9,10.2,11.9c0.8,1,1.6,1.9,2.4,2.9v0l0,0
	c4.9,5.7,9.7,11.4,14.6,17.1c3.3,3.8,6.5,7.6,9.8,11.4c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0-0.3v-0.2c0-1.4,0-2.9,0-4.3
	c0-0.9,0.3-1.8,0.4-2.7h0.2C-432.2,2483.3-432.6,2481.1-432.2,2479.5z M-410.5,2509.8c-2.9-3.4-5.9-6.9-8.8-10.3h-0.1
	c-2.7-0.2-5.4-0.3-8.1-0.5c3,3.5,6,7,9,10.5c5.1,6,10.2,11.9,15.3,17.9c2.6,0,5.2,0,7.7,0C-400.5,2521.5-405.5,2515.7-410.5,2509.8z
	 M-502.4,2411.2C-502.4,2411.2-502.4,2411.2-502.4,2411.2c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2
	c0-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.1
	c-0.2-0.1-0.4-0.3-0.7-0.4c0,0,0,0,0,0c0.6,0.7,1.2,1.4,1.8,2.1C-502.3,2411.3-502.3,2411.3-502.4,2411.2z M-432.2,2479.5
	c0-0.1,0.1-0.2,0.1-0.3c2.6-9.3,5.2-18.6,7.7-27.8c-2,0-4-0.1-5.9-0.1c-2.5,8.9-5,17.8-7.5,26.7c-7.8-9.2-15.6-18.3-23.3-27.3
	c-2.6,0-5.2,0-7.7,0c3.4,4,6.8,7.9,10.2,11.9c0.8,1,1.6,1.9,2.4,2.9v0l0,0c4.9,5.7,9.7,11.4,14.6,17.1c3.3,3.8,6.5,7.6,9.8,11.4
	c0-0.4,0-0.9,0-1.3c0-0.1,0-0.2,0-0.3v-0.2c0-1.4,0-2.9,0-4.3c0-0.9,0.3-1.8,0.4-2.7h0.2C-432.2,2483.3-432.6,2481.1-432.2,2479.5z
	 M-369.4,2558.1c-2.2-2.5-4.3-5.1-6.5-7.6c-4.7-5.6-9.5-11.1-14.2-16.7c-1.8-2.1-3.7-4.3-5.5-6.5c-5-5.9-10-11.7-15-17.6
	c-2.9-3.4-5.9-6.9-8.8-10.3h-0.1c-2.7-0.2-5.4-0.3-8.1-0.5c3,3.5,6,7,9,10.5c5.1,6,10.2,11.9,15.3,17.9c1.9,2.2,3.7,4.4,5.6,6.5
	c5.2,6.1,10.4,12.2,15.7,18.3c2.1,2.5,4.3,5,6.4,7.5c7.4,8.7,14.8,17.4,22.3,26.1c2.1-0.5,4.1-1.1,6.1-1.8
	C-354.6,2575.4-362,2566.7-369.4,2558.1z M-430.4,2486.5c-0.3-0.4-0.7-0.9-0.9-1.4h-0.2c-0.1,1-0.4,1.9-0.4,2.7c0,1.4,0,2.9,0,4.3
	v0.2c0,0.1,0,0.2,0,0.3c0,0.4,0,0.9,0,1.3c1.4,1.7,2.8,3.3,4.3,5c2.7,0.1,5.4,0.3,8.1,0.5h0.1
	C-423,2495.1-426.7,2490.8-430.4,2486.5z M-380,2271.4c-1.3,4.7-2.6,9.4-3.9,14.1l-1.5,5.3c-0.3,1.1-0.6,2.2-0.9,3.3
	c-2.7,9.8-5.5,19.5-8.2,29.3c0,0,0,0,0,0s0,0,0,0v0c-0.8,3-1.7,6.1-2.6,9.1c0,0,0,0,0,0c-0.3,1.2-0.7,2.4-1,3.6v0
	c-1.3,4.6-2.6,9.2-3.8,13.8c-0.6,2.2-1.2,4.4-1.8,6.6c-1.3,4.5-2.5,8.9-3.8,13.4c-1,3.5-1.9,7-2.9,10.4c-1.4,5.1-2.8,10.1-4.3,15.2
	v0c0,0,0,0,0,0c-1.8,6.4-3.6,12.9-5.4,19.3v0c-0.5,1.8-1,3.6-1.5,5.4c-0.9,3-1.7,6.1-2.5,9.1c-1.4,5.1-2.9,10.2-4.3,15.4
	c-0.6,2.1-1.2,4.3-1.8,6.4c2,0,4,0.1,5.9,0.1c0.6-2.2,1.2-4.4,1.8-6.6c1.4-5.1,2.8-10.2,4.3-15.3c0.8-2.9,1.6-5.8,2.4-8.7
	c0.4-1.4,0.8-2.7,1.1-4.1v0c1-3.7,2.1-7.4,3.1-11.1c2.6-9.3,5.2-18.5,7.8-27.8c0.5-1.7,0.9-3.4,1.4-5c1.5-5.4,3-10.8,4.5-16.1
	c0.6-2.2,1.2-4.4,1.8-6.5c1.6-5.9,3.3-11.7,4.9-17.6c1.6-5.7,3.2-11.4,4.8-17.2c0.3-1.1,0.6-2.2,0.9-3.3v0c0.3-1,0.6-2,0.8-3
	c3.5-12.5,7-24.9,10.5-37.4C-376.1,2271.4-378,2271.4-380,2271.4z M-56.6,2593.8c-0.1-0.3-0.1-0.6-0.1-0.8c0,0,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
	c0.1-0.1,0.1-0.2,0.2-0.3c-9-10.6-18-21.1-27.1-31.7v0c-1.5-1.7-2.9-3.4-4.4-5.1c-0.2,0.2-0.5,0.5-0.9,0.8c-0.1-0.8-0.3-1.5-0.4-2.3
	c-1.7-2-3.4-4-5.2-6c-3.4-3.9-6.7-7.9-10.1-11.8c-1.8-2.1-3.6-4.2-5.4-6.3c-2.6,0-5.1,0-7.6,0c1.8,2.2,3.7,4.3,5.5,6.5
	c5.5,6.4,11,12.8,16.5,19.3c2.6,3,5.1,6,7.7,9.1c0.5,0.5,0.9,1.1,1.4,1.6c2.8,3.3,5.6,6.5,8.3,9.8c1.6,1.8,3.1,3.6,4.7,5.5
	c1.4,1.6,2.8,3.3,4.3,5c5,5.8,10,11.7,15,17.5C-54.9,2600.1-55.7,2597-56.6,2593.8z M-124.2,2511.9c-4.3-5-8.6-10.1-12.9-15.1
	c-5.9-6.9-11.8-13.8-17.7-20.8c-2.1-2.5-4.3-5.1-6.4-7.6c0,0-0.1-0.1-0.1-0.1c-1.3-1.6-2.3-4.6-1.8-6.5c0.9-3.3,1.8-6.7,2.7-10
	c0.6-2.2,1.2-4.3,1.8-6.5c3.9-14.3,7.9-28.5,11.9-42.7c0.7-2.5,1.4-4.9,2.1-7.4c0.7-2.4,1.3-4.8,2-7.2h-0.1c-0.7,0-1.4-0.1-2.1-0.1
	c-0.7,0-1.4,0-2.2,0c-0.5,0-1,0-1.5,0c-0.7,2.4-1.3,4.7-2,7.1c-0.7,2.6-1.4,5.1-2.2,7.7c-3.9,14-7.9,28.1-11.9,42.5
	c-0.6,2.1-1.2,4.3-1.8,6.5c-0.7,2.7-1.5,5.3-2.2,8c-2.4-2.8-4.7-5.5-7-8.1c-1.4,0-2.8,0-4.2-0.1c-1.2,0-2.4,0-3.6,0
	c14,16.4,28,32.8,42,49.2c4.5,5.2,9,10.5,13.4,15.7c3.6,4.2,7.2,8.4,10.8,12.6c2.5,0,5.1,0,7.6,0
	C-114.4,2523.4-119.3,2517.7-124.2,2511.9z M-88.8,2553.3c0.1,0.7,0.2,1.4,0.4,2.3c0.4-0.3,0.6-0.6,0.9-0.8
	C-88,2554.3-88.4,2553.8-88.8,2553.3z"
        />
        <g>
          <g>
            <path
              class="st0"
              d="M485.9,547c-0.7-1.1-1.4-2.2-2.2-3.3c-1-1.3-1.1-2.6-0.4-4.1c0.6-1.1,1.1-2.3,0.6-3.7c-0.3-0.1-0.6-0.2-1-0.2
			c-1.7,0-3.4-0.1-5-0.1c-2.4,0-4-1.2-4.9-3.3c-0.3-0.8-0.6-1.6-0.8-2.4c-0.6-2.5-1.2-5-1.8-7.5c-0.3-1.2-1.2-1.6-2.4-1.4
			c-1.1,0.2-1.3,0.5-1.2,2c0.1,0.6,0.2,1.2,0.3,1.9c0.4,3.1,1,6.2,1.2,9.3c0.2,3.6,1.2,6.9,3,9.9c1,1.8,1.3,3.3,0.2,5
			c-0.9,1.5-1.6,3.4-3.4,4.2c-2.8,1.1-5.2,2.7-7.6,4.5c-2.7,2-5.5,3.9-7.9,6.2c-4,3.9-7.8,8.1-11.7,12.1c-2.5,2.6-4.9,5.2-6.6,8.4
			c-0.3,0.6-0.6,1.3-0.7,1.9c-0.2,0.8-0.1,1.6,0.8,1.9c0.9,0.3,1.5,0,2.1-0.9c2.9-4.2,6.8-7.3,11.5-9.2c4.9-2,10.1-3.4,15.2-5
			c0.3-0.1,0.7,0.1,1.3,0.2c-0.8,0.6-1.4,0.9-2,1.4c-1.1,0.9-2.2,1.6-3.7,1.9c-4.3,1-8.6,2-12.6,4.1c-2.8,1.5-5,3.5-6.9,6
			c-0.8,1.1-1.2,2.4-0.8,3.7c3.3,0.9,3.6,0.8,5.1-1.9c0-0.1,0.1-0.1,0.1-0.2c1.5-3.4,4.5-5,7.8-6.3c1-0.4,2.2-0.3,3.3,0.1
			c0.1,0.4,0.2,0.7,0.3,1.1c0.5,2,1.1,3.9,3.8,4c1.2,0.1,1.2,1.2,1.3,2.1c0.1,0.9,0,1.8-1.2,2.1c-0.9,0.2-1.8,0.3-2.6,0.8
			c-1,0.5-2,1.2-1.4,2.6c0.2,0.1,0.4,0.3,0.6,0.3c3.1,0.4,6.1,1.2,9.2,1c1.1-0.1,2.3,0.1,3.4,0c1,0,1.9-0.1,2.9-0.2
			c0.8-0.1,1.5-0.3,1.7-1.3c0.1-0.9-0.1-1.7-0.9-2.2c-0.8-0.5-1.7-1-2.6-1.4c-1.9-0.9-2.3-1.6-2.1-3.6c0-0.3,0.1-0.5,0.1-0.8
			c0.3-2.4,0-4.6-2-6.3c-0.9-0.8-1.1-1.7-0.8-2.8c0.3-1,0.7-2,0.8-3c0.1-1.9,1.3-2.8,2.9-3.3c0.4-0.1,0.8-0.2,1.2-0.4
			c1.7-0.6,3.5-1.1,5.2-1.7c3.9-1.3,6.2-3.9,6.8-8c0.3-2.1,0.4-4.1,0.5-6.2c0.1-1.3,0.6-2.1,1.6-2.7c0.4-0.3,0.9-0.5,1.3-0.7
			C486.8,550.4,487.1,548.9,485.9,547z M479.8,549.5c-0.9,0.1-1.9-0.7-2-1.5c-0.1-0.8,1.5-2.3,2.4-2.1c0.5,0.1,0.9,0.8,1.1,1
			C481.4,548.5,480.7,549.4,479.8,549.5z"
            />
            <path
              class="st0"
              d="M468.3,587c0,0.5,0.1,1.2,0.5,1.5c1,1.1,2,2.2,3.2,3c1.1,0.7,1.9,1.4,1.8,2.8c0,0.6,0.3,1.1,1,1.1
			c0.5,0,1.1-0.3,1.4-0.7c0.8-1,0.6-2.5-0.4-3.3c-0.9-0.7-1.8-1.3-2.8-1.8c-1.3-0.6-1.9-1.7-2.2-3.1c-0.4-2.3-0.2-4.5,0.6-6.7
			c0.6-1.7,1.1-3.4,1.5-5.2c0.2-0.8,0.1-1.7,0.2-2.6c-4.6-0.9-7.2,1.6-7,6.5c2.5,1.3,2.6,1.6,2.4,4.3
			C468.3,584.2,468.2,585.6,468.3,587z"
            />
            <path
              class="st0"
              d="M476.5,534.4c1.4,1,3.1,0.6,4.7,0.5c1.1-0.1,1.3-0.3,1.1-1.4c-0.4-1.7-1.3-3.2-2.7-4.4
			c-1.6-1.2-2.4-2.9-2.6-4.8c-0.1-0.5-0.2-1-0.4-1.4c-0.5-1.6-1.1-1.9-3.1-1.7c-0.1,0.3-0.2,0.4-0.2,0.5c0.6,3.8,1.1,7.6,1.8,11.3
			C475.2,533.5,476,534,476.5,534.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M171.2,521.7c-0.1-0.2-0.2-0.3-0.3-0.4c-1.6-1.4-3.3-2.5-5.2-3.2c-0.8,0.9-1.6,1.8-2.3,2.7
			c0.1,0.1,0.3,0.2,0.4,0.2c-0.3,0.9-0.7,1.4-1.5,1c-6.1,7.1-12.1,14.1-18.1,21.2c-1,2.3-2,4.6-3.1,6.9c-1,2.1-2.1,4.1-3,6.3
			c-2.1,4.8-4.1,9.7-6.4,14.4c-1.7,3.5-2.7,7.1-4,10.8c-0.2,0.6-0.3,1.3-0.4,2.3c1.5-0.3,2.5-0.9,3.2-1.8c1.4-1.7,2.8-3.4,4-5.2
			c3.8-5.4,8-10.5,12.5-15.3c2.2-2.4,4.8-4.4,7.4-6.4c1.9-1.5,4.1-2.7,6.2-4c2.6-1.7,4.4-4.1,4.8-7.1c0.5-3.9,0.6-7.9,0.2-11.8
			c-0.2-1.7-0.3-3.4-0.5-5.1c-0.1-0.9,0.2-1.6,1.1-2c0.4-0.2,0.8-0.3,1.2-0.5c1.4-0.9,3-1.3,4-2.6
			C171.2,521.8,171.2,521.8,171.2,521.7z"
            />
            <path
              class="st0"
              d="M156.5,576.4c-0.5,2.5,0.6,4.4,3,6c0.2-1.2,0.4-2.1,0.5-3.1c0.4-2.4,0.7-4.8,1.7-7.1
			c1.5-3.3,2.9-6.6,3.3-10.2c0.4-3.9,0.7-7.8-0.9-11.6c-1.9,1.1-2.2,1.7-2.3,3.7c-0.1,0.9-0.1,1.9-0.3,2.8c-1,4.1-2.1,8.1-3.2,12.2
			C157.7,571.6,157,574,156.5,576.4z"
            />
            <path
              class="st0"
              d="M158,554.6c-2.3,1.3-4.7,2.5-6.6,4.4c-2.4,2.5-4.8,5.1-7.2,7.7c-1,1.1-1.9,2.2-2.7,3.4
			c-2.6,3.8-5.1,7.6-8.1,11.1c-1.7,2-2.3,4.5-3.1,7.1c0.6,0,1,0.2,1.4,0.1c2.9-0.6,5.9-0.9,8.8-0.5c0.8,0.1,1.2-0.4,1.4-1
			c1-2.3,2-4.6,2.9-6.9c0.6-1.5,1-3,1.5-4.6c0.1,0,0.3,0,0.4-0.1c0.7,1.5,1.3,3.1,2.1,4.8c1.6-1,3.1-1.9,4.5-2.8
			c1.6-1,3.1-2,2.9-4.2c0-0.2,0.1-0.4,0.1-0.5c0.7-2.8,1.5-5.7,2.2-8.5c0.2-0.8,0.4-1.7,0.7-2.5c0.7-2.3,1.1-4.6,0.8-7.4
			C159.2,554.2,158.5,554.3,158,554.6z"
            />
            <path
              class="st0"
              d="M145.7,589.7c1-0.1,2-0.4,2.9-0.8c2.7-1.2,5.4-1.5,8.3-0.9c2,0.4,4.1,0.6,6.2,0.8c1.2,0.1,2.4,0,3.5-0.9
			c-0.3-1.5-1-2.7-2.5-3.2c-1.3-0.4-2.6-0.9-4-1c-2.5-0.1-3.8-1.4-4.5-3.6c-0.3-0.7-0.6-1.5-0.8-2.2c-2,0.7-2,0.7-3.8,3.3
			c0.7,2.4,0.7,2.4-1.4,3.2c-0.2,0.1-0.5,0.1-0.7,0.2c-1.6,0.8-3.1,1.8-4,3.3C143.9,589.1,144.4,589.8,145.7,589.7z"
            />
            <path
              class="st0"
              d="M172.1,588.3c-0.2-2.7-3-4.8-5.4-3.9c0.4,0.7,0.9,1.3,1.3,2C168.9,588.3,169.8,588.7,172.1,588.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M591.9,270.3c-0.1-0.1-0.2-0.3-0.3-0.4c-2.2-2.1-4.8-3.3-7.9-3.3c-2.7,0-4.8,0.8-6.1,3.3
			c-1.1,2.1-2.1,4.3-3.1,6.5c-0.8,1.8-1.6,3.6-2.4,5.4c-0.9,2.1-1.9,4.2-2.9,6.2c-1,2.1-1.9,4.3-2.9,6.4c-0.8,1.8-1.8,3.5-2.6,5.3
			c-1.8,4.1-3.5,8.3-5.4,12.3c-1.5,3-2.3,6.1-3.4,9.2c-0.2,0.5-0.2,1.1-0.4,2c1.3-0.3,2.1-0.8,2.7-1.5c1.2-1.5,2.3-2.9,3.4-4.5
			c3.2-4.6,6.8-8.9,10.7-13c1.9-2,4.1-3.8,6.3-5.5c1.6-1.3,3.5-2.3,5.2-3.4c2.2-1.4,3.8-3.5,4.1-6.1c0.4-3.3,0.5-6.7,0.1-10.1
			c-0.2-1.4-0.2-2.9-0.4-4.3c-0.1-0.8,0.2-1.4,0.9-1.7c0.3-0.2,0.7-0.3,1-0.5c1.2-0.7,2.5-1.1,3.4-2.2
			C591.9,270.4,591.9,270.4,591.9,270.3z M584.2,270.5c-0.2-0.1-0.3-0.6-0.2-0.8c0.4-0.7,1-0.3,1.6,0
			C585.3,270.5,584.9,270.9,584.2,270.5z"
            />
            <path
              class="st0"
              d="M579.4,316.9c-0.4,2.1,0.5,3.8,2.6,5.1c0.2-1,0.3-1.8,0.4-2.6c0.3-2.1,0.6-4.1,1.5-6c1.2-2.8,2.5-5.6,2.8-8.7
			c0.4-3.3,0.6-6.6-0.8-9.9c-1.6,1-1.9,1.4-2,3.1c0,0.8,0,1.6-0.2,2.4c-0.9,3.5-1.8,6.9-2.7,10.4
			C580.4,312.8,579.8,314.8,579.4,316.9z"
            />
            <path
              class="st0"
              d="M580.6,298.3c-2,1.1-4,2.1-5.6,3.7c-2.1,2.2-4.1,4.4-6.1,6.6c-0.8,0.9-1.6,1.8-2.3,2.9
			c-2.2,3.2-4.3,6.5-6.9,9.4c-1.5,1.7-2,3.8-2.7,6.1c0.5,0,0.9,0.2,1.2,0.1c2.5-0.5,5-0.8,7.5-0.5c0.7,0.1,1-0.3,1.2-0.9
			c0.8-2,1.7-3.9,2.5-5.9c0.5-1.3,0.9-2.6,1.3-3.9c0.1,0,0.3,0,0.4,0c0.6,1.3,1.1,2.6,1.8,4.1c1.4-0.9,2.6-1.6,3.8-2.4
			c1.4-0.8,2.6-1.7,2.5-3.6c0-0.2,0.1-0.3,0.1-0.5c0.6-2.4,1.3-4.8,1.9-7.3c0.2-0.7,0.4-1.5,0.6-2.2c0.6-2,0.9-3.9,0.7-6.3
			C581.7,298,581.1,298.1,580.6,298.3z"
            />
            <path
              class="st0"
              d="M570.2,328.2c0.8-0.1,1.7-0.3,2.5-0.7c2.3-1,4.6-1.2,7.1-0.8c1.7,0.3,3.5,0.5,5.2,0.7c1,0.1,2,0,2.9-0.8
			c-0.2-1.3-0.8-2.3-2.1-2.7c-1.1-0.4-2.3-0.8-3.4-0.9c-2.1-0.1-3.2-1.2-3.8-3c-0.2-0.6-0.5-1.3-0.7-1.9c-1.7,0.6-1.7,0.6-3.3,2.8
			c0.6,2,0.6,2-1.2,2.7c-0.2,0.1-0.4,0.1-0.6,0.2c-1.4,0.7-2.6,1.5-3.4,2.8C568.7,327.7,569.1,328.3,570.2,328.2z"
            />
            <path
              class="st0"
              d="M592.6,327c-0.2-2.3-2.6-4.1-4.6-3.3c0.4,0.6,0.8,1.1,1.1,1.7C590,327,590.7,327.4,592.6,327z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M205.6,585.1c-2.6-1.7-5.3-3.3-8.1-4.4c-2.4-1-3.4-2.6-3.4-5c0-0.4,0-0.8,0-1.1c-0.1-3.8-0.3-7.5-0.4-11.3
			c-0.2-7.5-0.3-15-0.5-22.5c-0.1-4.2-0.1-8.4-0.3-12.6c-0.1-2.5-0.3-5-0.5-7.5c0-0.5-0.2-1.1-0.3-1.7c-0.5-0.1-0.8-0.2-1.2-0.1
			c-2.2,0.2-4.4,0.2-6.6-0.1c-1.1-0.2-2.3,0-3.4,0c-1.5,0.1-2,0.9-1.7,2.4c0.4,1.9,0.7,3.8,1,5.7c0.6,4.2,0.2,8.4,1.2,12.5
			c0,0.2,0,0.4,0,0.6c0.5,5.6,0.9,11.1,1.6,16.6c0.8,6.3,1.4,12.6,1.5,18.9c0,3,0.1,6,1,8.9c0.6,1.9,1,2.3,2.9,2.2
			c3.4,0,6.6,0.7,9.8,1.8c2.6,0.9,5.3,1.6,8,2.4c0.6,0.2,1.2,0.4,1.8,0.4c0.5,0,1.3-0.2,1.4-0.6c0.2-0.5,0.1-1.3-0.2-1.7
			C208.1,587.5,207,586.1,205.6,585.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M216.2,541.6c2.1,0.3,3.1-1.1,2.2-3c-0.3-0.6-0.6-1.3-1.1-1.8c-2.7-3.1-4.3-6.7-5.9-10.4
			c-1-2.3-2.2-4.5-3.3-6.7c-0.4-0.8-1.1-1.3-2-1.2c-0.9,0-1.3,0.7-1.5,1.5c-0.2,0.6-0.3,1.3-0.2,2c0.1,2.5,0.3,5,0.4,7.5
			c0.1,2.3,0,4.6,0.1,6.9c0.1,2.7,0.3,5.5,0.5,8.2c0.3,4.7,0.6,9.5,0.8,14.2c0.1,1.6,1,2.6,2.4,3.6c0.4-0.6,0.9-1.1,1-1.6
			c0.3-1.6,0.5-3.2,0.5-4.8c-0.1-4.4-0.9-8.8-0.2-13.3c0.5-3,0.8-3.3,3.7-2C214.4,541.1,215.3,541.5,216.2,541.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M546.1,290.1c-0.9-0.1-1.8-0.5-2.7-0.9c-2.8-1.3-3.2-1-3.7,2c-0.7,4.5,0.1,8.9,0.2,13.3
			c0,1.6-0.2,3.2-0.5,4.8c-0.1,0.5-0.6,1-1,1.6c-1.4-1-2.3-2-2.4-3.6c-0.2-4.7-0.5-9.5-0.8-14.2c-0.2-2.8-0.4-5.5-0.5-8.3
			c-0.1-2.3-0.1-4.6-0.1-6.9c0-0.8-0.1-1.6-0.1-2.4c1.5-1.8,3-3.5,4.5-5.3c0.8,1.5,1.6,3.1,2.3,4.7c1.6,3.7,3.2,7.3,5.9,10.4
			c0.4,0.5,0.8,1.1,1.1,1.8C549.2,289,548.2,290.4,546.1,290.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M569.6,659.1c-2.6-1.8-3.4-4.7-4.1-7.4c-1.2-4.6-1.9-9.4-2.7-14.2c-0.5-2.7-0.8-5.3-1-8
			c-0.2-1.6-1-2.8-2.1-3.9c-0.5-0.5-1.2-0.8-1.9-1.2c0.1-1.1,0.3-2.1,0.3-3.2c-0.1-4-2.3-5.6-5.6-5.7c-1.7-0.1-2.8,0.6-3.5,2.1
			c-0.5,1.3-1,2.7-1.4,4.1c-0.4,1.5-1,2.7-2.6,3.3c-1.6,0.6-2.5,1.8-2.4,3.6c0.1,1.1,0,2.3-0.1,3.4c-0.1,3.7-0.2,7.4-0.4,11.1
			c-0.2,3.1-0.6,6.2-0.8,9.4c0,0.5,0,1,0.1,1.5c0.3,1.2,1.3,1.5,2.1,0.6c0.5-0.6,0.8-1.3,0.9-2c0.8-5.3,1.5-10.7,1.4-16.1
			c0-1.9,0.2-3.9,0.3-5.8c0.1-0.7,0.1-1.5,1-1.8c0.7,0.7,0.4,1.4,0.3,2.1c-0.1,2.1-0.3,4.1-0.3,6.2c0,0.7,0.5,1.4,0.8,2.3
			c-0.2,0.7-0.5,1.5-0.6,2.5c-0.5,3.5-1,7.1-1.5,10.6c-0.3,2.1,0.1,2.8,2.1,3.5c1.4,0.5,2.8,0.8,4.1,1.3c1.4,0.4,2.7,0.9,4.1,1.3
			c1.1,0.3,1.4-0.1,1.1-1.2c-0.3-1.3-0.7-2.5-1-3.8c-0.6-3.7-1.1-7.4-1.7-11.1c-0.1-0.7-0.4-1.4-0.7-2c1.2-1.7,1.1-3.9,1.5-5.9
			c0.2,0,0.3-0.1,0.4-0.1c0.4,1.2,0.9,2.3,1.2,3.5c0.9,3.8,1.7,7.6,2.5,11.4c0.6,2.8,1.2,5.5,2.4,8.1c0.4,0.7,0.8,1.5,1.4,2
			c1.4,1,2.9,1.8,4.4,2.6c1.2,0.6,1.7,0.4,2.3-0.5C570.5,660.6,570.5,659.7,569.6,659.1z M553.7,622.6c-0.2,1.7-0.5,3.5-0.7,5.2
			c-0.1,0.8-0.3,1.5-1.3,1.4c-0.8-0.1-1.1-0.5-1.2-1.8c0-0.2,0-0.5,0-0.7c-0.1,0-0.1,0-0.2-0.1c0.4-2.1,0.8-4.1,1.3-6.2
			c0.1-0.4,0.6-0.7,0.9-1c0.4,0.4,0.9,0.7,1,1.1C553.8,621.2,553.8,621.9,553.7,622.6z"
            />
            <path
              class="st0"
              d="M540.2,642.8c0.3-6.1,0.9-12.2,0.9-18.4c0-2.6,0.2-5.2,0.3-7.9c0-0.4,0-0.9-0.1-1.3c-0.2-0.7-0.6-1.3-1.5-1.3
			c-0.8,0-1.5,0.2-1.8,1.1c-0.3,1.1-0.7,2.2-0.8,3.3c-0.2,2.2-0.1,4.5-0.2,6.8c0,1.6-0.1,3.3-0.1,4.9c-0.2,3.4-0.4,6.9-0.5,10.3
			c-0.2,6.3-0.1,12.7-0.4,19c-0.2,3.7-0.9,7.5-1.2,11.2c-0.2,2.6-0.3,5.2-0.3,7.9c0,1.2,0.6,1.8,1.7,2.4c2.5,1.1,5.1,1.1,7.8,1.1
			c3.1,0,6.2,0,9.3-0.1c0-0.1,0-0.1,0-0.2c4.5,0.1,8.9,0.2,13.4,0.2c0.8,0,1.6-0.3,2.4-0.4c0.8-0.2,0.9-0.8,0.8-1.5
			c-0.2-1.1-0.7-1.5-2.2-1.6c-4-0.3-7.9-0.5-11.9-0.8c-2.9-0.2-5.8-0.4-8.7-0.6c-1.7-0.1-3.4-0.1-5.1-0.1c-0.4,0-0.9,0-1.3,0
			c-0.7,0-1-0.4-1-1.1c0-1.7-0.1-3.3-0.1-5c0-1.4,0-2.8,0-4.1C539.8,658.8,539.8,650.8,540.2,642.8z"
            />
            <path
              class="st0"
              d="M555.5,676.1c2.2,0,4.4,0.1,6.6,0.1c0.6,0,1.1-0.4,1.9-0.6c-1.1-1.8-2.2-2.9-3.9-3.4
			c-0.7-0.2-1.6-0.6-1.8-1.1c-1.2-2.7-1.9-5.5-2-8.5c0-1-0.2-1.9-0.3-2.8c-1-0.2-1.8-0.3-2.5-0.4c-0.7-0.1-1.5,0-2.2,0
			c-0.1,0.4-0.2,0.6-0.2,0.9c0.5,5,1.5,9.9,2.9,14.7C554.2,675.7,554.7,676.1,555.5,676.1z"
            />
            <path
              class="st0"
              d="M547,673c-1-0.7-1.3-1.6-1.1-2.8c0.1-0.5,0.2-1,0.3-1.5c1-3.5,2-7.1,3-11c-0.8-0.2-1.8-0.4-2.7-0.6
			c-0.6-0.1-1.1,0.1-1.4,0.7c-1.9,4.1-3.2,8.4-3.9,12.9c-0.2,1.1,0.2,2.1,1,2.9c1,1.1,2.4,1.7,3.8,2.1c1.1,0.3,2.2,0,3.2-1.1
			C548.4,674.2,547.7,673.6,547,673z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M427.3,570.7c-2.9-2-3.7-5.1-4.5-8.1c-1.3-5.1-2.1-10.3-3-15.5c-0.5-2.9-0.8-5.8-1.1-8.7
			c-0.2-1.8-1.1-3.1-2.3-4.2c-0.6-0.6-1.4-0.9-2-1.4c0.1-1.2,0.3-2.3,0.3-3.5c-0.1-4.4-2.5-6.1-6.1-6.2c-1.8-0.1-3.1,0.6-3.8,2.3
			c-0.6,1.4-1.1,2.9-1.5,4.4c-0.4,1.7-1.1,3-2.9,3.6c-1.8,0.6-2.7,2-2.6,4c0.1,1.2,0,2.4-0.1,3.7c-0.1,4-0.2,8-0.5,12.1
			c-0.2,3.4-0.6,6.8-0.9,10.2c0,0.5,0,1.1,0.1,1.6c0.3,1.3,1.5,1.6,2.3,0.6c0.5-0.6,0.9-1.4,1-2.2c0.8-5.8,1.6-11.6,1.5-17.5
			c0-2.1,0.2-4.2,0.4-6.3c0.1-0.7,0-1.7,1.1-2c0.8,0.7,0.4,1.6,0.4,2.3c-0.1,2.3-0.3,4.5-0.3,6.8c0,0.8,0.5,1.5,0.9,2.4
			c-0.2,0.7-0.5,1.7-0.7,2.7c-0.6,3.8-1.1,7.7-1.7,11.6c-0.3,2.3,0.1,3.1,2.3,3.8c1.5,0.5,3,0.9,4.5,1.4c1.5,0.5,3,1,4.5,1.4
			c1.2,0.3,1.5-0.1,1.2-1.3c-0.4-1.4-0.8-2.8-1-4.2c-0.7-4-1.2-8.1-1.9-12.1c-0.1-0.8-0.5-1.5-0.7-2.2c1.3-1.9,1.2-4.2,1.6-6.5
			c0.2,0,0.3-0.1,0.5-0.1c0.4,1.3,1,2.5,1.3,3.8c1,4.1,1.9,8.3,2.7,12.4c0.6,3,1.3,6,2.7,8.8c0.4,0.8,0.8,1.7,1.5,2.2
			c1.5,1.1,3.1,2,4.7,2.8c1.3,0.7,1.9,0.4,2.5-0.5C428.2,572.4,428.2,571.4,427.3,570.7z M410,530.9c-0.2,1.9-0.5,3.8-0.8,5.6
			c-0.1,0.8-0.3,1.7-1.4,1.6c-0.9-0.1-1.3-0.6-1.3-1.9c0-0.3,0-0.5,0-0.8c-0.1,0-0.1,0-0.2,0c0.5-2.3,0.9-4.5,1.4-6.8
			c0.1-0.4,0.6-0.8,1-1.1c0.4,0.4,1,0.8,1.1,1.2C410,529.4,410,530.2,410,530.9z"
            />
            <path
              class="st0"
              d="M395.3,553c0.4-6.7,1-13.3,1-20.1c0-2.9,0.2-5.7,0.3-8.6c0-0.5,0-1-0.1-1.4c-0.2-0.8-0.7-1.4-1.6-1.4
			c-0.9,0-1.7,0.2-1.9,1.2c-0.3,1.2-0.8,2.4-0.9,3.6c-0.2,2.5-0.2,4.9-0.2,7.4c0,1.8-0.1,3.6-0.2,5.3c-0.2,3.8-0.5,7.5-0.6,11.3
			c-0.2,6.9-0.1,13.8-0.4,20.7c-0.2,4.1-0.9,8.1-1.3,12.2c-0.3,2.9-0.3,5.7-0.4,8.6c0,1.3,0.7,2,1.9,2.6c2.7,1.2,5.6,1.2,8.5,1.2
			c3.4,0,6.7,0,10.1-0.1c0-0.1,0-0.1,0-0.2c4.9,0.1,9.8,0.2,14.6,0.2c0.9,0,1.8-0.3,2.6-0.5c0.9-0.2,1-0.8,0.9-1.6
			c-0.2-1.2-0.8-1.6-2.4-1.8c-4.3-0.3-8.6-0.6-13-0.9c-3.2-0.2-6.3-0.4-9.5-0.6c-1.8-0.1-3.7-0.1-5.5-0.1c-0.5,0-1,0-1.4,0
			c-0.8,0-1.1-0.4-1.1-1.2c0-1.8-0.1-3.6-0.1-5.4c0-1.5,0-3,0-4.5C394.8,570.3,394.8,561.7,395.3,553z"
            />
            <path
              class="st0"
              d="M411.9,589.2c2.4,0.1,4.8,0.1,7.2,0.1c0.6,0,1.2-0.4,2.1-0.7c-1.2-2-2.4-3.2-4.2-3.7c-0.7-0.2-1.7-0.7-2-1.3
			c-1.3-2.9-2.1-6-2.2-9.3c0-1-0.2-2.1-0.4-3.1c-1.1-0.2-1.9-0.3-2.7-0.4c-0.8-0.1-1.6,0-2.4,0c-0.1,0.4-0.2,0.7-0.2,1
			c0.5,5.4,1.6,10.8,3.2,16C410.5,588.8,411,589.2,411.9,589.2z"
            />
            <path
              class="st0"
              d="M402.6,585.9c-1.1-0.8-1.4-1.8-1.2-3c0.1-0.5,0.2-1.1,0.3-1.6c1.1-3.9,2.1-7.7,3.3-12
			c-0.9-0.2-1.9-0.4-2.9-0.6c-0.7-0.1-1.2,0.1-1.5,0.8c-2,4.5-3.5,9.2-4.2,14.1c-0.2,1.2,0.2,2.2,1.1,3.2c1.1,1.2,2.6,1.9,4.1,2.3
			c1.2,0.3,2.4,0,3.5-1.2C404.2,587.2,403.5,586.5,402.6,585.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M516.7,304.4c-0.3-1.2-0.7-2.4-0.9-3.6c-0.2-0.9-0.3-1.8-0.4-2.7c-0.4,0.4-0.8,0.9-1.1,1.3
			c-1.3,1.5-2.6,3-3.8,4.5c0.5,0.1,0.9,0.3,1.4,0.4c1.3,0.4,2.6,0.9,3.9,1.2C516.7,305.8,516.9,305.4,516.7,304.4z M528.4,306.1
			c-2.5-1.7-3.3-4.5-3.9-7.1c-0.8-3.1-1.4-6.3-1.9-9.5c-1.4,1.7-2.8,3.3-4.2,5c0.2,0.8,0.3,1.5,0.5,2.3c0.6,2.6,1.1,5.3,2.3,7.7
			c0.3,0.7,0.7,1.5,1.3,1.9c1.3,0.9,2.7,1.7,4.2,2.5c1.1,0.6,1.6,0.4,2.2-0.4C529.3,307.6,529.3,306.7,528.4,306.1z"
            />
            <path
              class="st0"
              d="M528,327.4c-0.8,0.2-1.5,0.4-2.3,0.4c-4.3,0-8.5-0.1-12.8-0.2v0.2c-3,0-5.9,0.1-8.9,0.1c-2.5,0-5,0-7.4-1.1
			c-1.1-0.5-1.7-1.1-1.7-2.2c0-0.9,0-1.8,0.1-2.7c1.6-1.9,3.2-3.7,4.8-5.6v1.1c0,1.6,0.1,3.2,0.1,4.8c0,0.6,0.3,1,1,1
			c0.4,0,0.8,0,1.3,0c1.6,0,3.2,0,4.8,0.1c2.8,0.1,5.5,0.3,8.3,0.5c3.8,0.3,7.6,0.5,11.4,0.8c1.4,0.1,1.9,0.5,2.1,1.5
			C528.8,326.7,528.7,327.2,528,327.4z"
            />
            <path
              class="st0"
              d="M515,322.3c2.1,0,4.2,0.1,6.3,0.1c0.5,0,1.1-0.3,1.8-0.6c-1-1.7-2.1-2.8-3.7-3.2c-0.6-0.2-1.5-0.6-1.7-1.1
			c-1.1-2.6-1.8-5.3-1.9-8.1c0-0.9-0.2-1.8-0.3-2.7c-1-0.1-1.7-0.3-2.4-0.3c-0.7,0-1.4,0-2.1,0c-0.1,0.4-0.2,0.6-0.1,0.8
			c0.4,4.8,1.4,9.4,2.8,14C513.8,321.9,514.2,322.3,515,322.3z"
            />
            <path
              class="st0"
              d="M506.9,319.4c-1-0.7-1.3-1.5-1.1-2.7c0.1-0.5,0.2-0.9,0.3-1.4c0.9-3.1,1.7-6.2,2.7-9.6
			c-2.2,2.5-4.3,5.1-6.5,7.6l0,0c-0.3,1.3-0.6,2.7-0.8,4.1c-0.2,1.1,0.2,2,1,2.8c1,1.1,2.2,1.7,3.6,2c1,0.3,2.1,0,3-1.1
			C508.2,320.5,507.6,319.9,506.9,319.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M307.1,523.5c-3.2-1.6-6.7-3-10.4-2.7c-5.6,0.5-11.3,1.3-16.8,2.3c-4.2,0.8-8.2,2.2-12.3,3.3
			c-1.8,0.5-3.7,1-5.6,1.1c-2.6,0.2-5.2-0.2-7.8-0.1c-4.4,0.2-8.6-0.6-12.8-1.7c-1.2-0.3-2.4-0.6-3.5-0.6c-2.4,0-3.9,1.3-4.2,3.6
			c-0.3,2.4-0.4,4.9-0.6,7.3c-0.1,2-0.4,4.1-0.3,6.1c0.1,4,0.5,8,0.4,12c0,2.8-0.3,5.6-0.9,8.4c-0.8,4-1.2,8.1-1,12.2
			c0.1,3.4,0.5,6.6,3.1,9.4c2.8,3,6,4,9.8,3.7c3.5-0.3,6.3-1.9,9.1-4.1c4.5-3.6,8.7-7.4,12.3-11.8c3-3.7,5.6-7.6,8.1-11.6
			c1.4-2.2,2.7-4.5,4.1-6.7c0.5-0.9,1.2-1.6,2.5-1.2c0.1,0.5,0.2,1,0.2,1.6c0,5.5,1.1,10.9,0.7,16.4c-0.2,3.1-0.1,6.2,0.1,9.3
			c0.1,2.8,0.4,5.6,0.7,8.4c0.2,1.5,1.7,2.9,2.9,2.9c2.2,0,2.8-0.4,3.4-2.6c0.6-2.7,0.9-5.4,0.6-8.2c-0.1-1.3-0.3-2.5-0.2-3.8
			c0.5-6.7,1.2-13.3,1.8-20.1c0.7,0,1.4,0.1,2.1,0c0.9-0.1,1.8-0.3,2.7-0.5c1-0.3,1.9-0.8,2-2c0.1-1.2-0.8-1.7-1.8-2.1
			c-1.1-0.5-2.2-1-3.3-1.5c-0.3-0.2-0.5-0.5-1-0.9c0.9-0.1,1.4-0.2,2-0.3c2.6-0.5,5.2-0.9,7.6-1.7c1.3-0.4,2.5-1.6,3.5-2.5
			c0.8-0.7,1-1.7,0.4-2.7c-0.6-1-1.1-2.1-1.7-3.1c-1.2-1.8-2.3-3.7-4.2-5c-0.2-0.1-0.3-0.5-0.4-0.7c0.3-0.7,0.6-1.3,0.9-2
			c0.3-0.6,0.5-1.3,0.9-1.9c1.4-1.9,0.7-2.2,3.5-1.7c2.2,0.4,4.1,0.1,5.4-2.3C308.5,524.8,308,523.9,307.1,523.5z M295.1,543.5
			c-2.3,0.6-4.6,1.2-7.1,1.2c-2.5,0-5.1,0.2-7.6,0.4c-0.5,0-1,0-1.5,0c-4.4-0.6-5.1-1.6-5-5.2c0-1.4-0.1-2.7-1.8-3.3
			c-1,0.3-1.7,1-2,1.9c-0.5,1.4-1.1,2.9-1.2,4.4c-0.2,2.2,1,3.7,3.1,4.4c0.8,0.3,1.5,0.7,2.5,1.1c-1.3,2-2.4,3.9-3.5,5.7
			c-4.5,6.8-9.7,13.2-15.4,19.1c-1.6,1.7-3.1,3.5-4.9,5c-1.5,1.3-3.2,2.4-4.9,3.3c-3.3,1.7-6.1,1-7.9-3.6c-0.3-0.8-0.5-1.8-0.5-2.7
			c0-0.7,0.2-1.4,0.5-2c0.2-0.4,0.8-0.9,1.2-0.9c0.4,0,0.8,0.7,1,1.2c0.3,1,0.3,2.1,0.4,3.1c0.1,0.7,0,1.4,0,2.1
			c1.8,0.2,3-0.4,3.4-2c0.4-1.5,1-2.9,1.3-4.4c0.4-2.2-1-4.2-3.2-4.8c-1.4-0.4-2.8-0.6-4.2-1c-0.6-1-0.5-2.2-0.4-3.3
			c0.5-5.7,1.1-11.5,1.6-17.2c0-0.5,0.1-1,0.3-1.4c0.4-1.5,0.8-2,2.3-1.9c2.8,0.1,5.5,0.1,8.3,0.4c4.3,0.4,8.6,0.8,12.9,0.3
			c0.3,0,0.7-0.1,1.1-0.1c1.3,0.4,2.4-0.1,3.3-1.1c0-0.7,0-1.5,0-2.4c0-1.8-0.3-2.1-2.1-2.1c-3.5,0-6.9,0.1-10.4,0
			c-3-0.1-6.1-0.4-9.1-0.5c-1.6-0.1-3.1-0.2-4.7,0c-1,0.1-2,0.6-3.1,1c-0.3-2.1,0.2-4.1,1.1-6c0.2-0.4,1.1-0.7,1.6-0.6
			c1.4,0.1,2.8,0.4,4.2,0.7c6.8,1.5,13.6,1.9,20.4,0.3c1.6-0.4,3.2-0.5,4.8-0.9c4.5-1.2,9-2.5,13.5-3.5c2-0.5,4.1-0.5,6.1-0.4
			c1.8,0,2.3,0.9,1.9,2.7c-0.2,1-0.7,2-1.1,3c-0.8,2-0.6,2.4,1.4,3.4c1.6,0.8,3,1.8,4.4,2.9c0.6,0.4,1,1.2,1.4,1.8
			C297,542.7,296.2,543.2,295.1,543.5z"
            />
            <path
              class="st0"
              d="M283.5,534.1c-2.1,0.2-4.3,2.4-4.4,4.2c-0.2,2.6,1.5,4.4,4.7,4.4c0.4-0.1,1.2-0.2,2-0.5
			c2.2-0.9,3.4-3.5,2.7-5.5C287.6,534.4,285.6,533.8,283.5,534.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M112.9,579.8c2.5-2.9,4.9-5.8,7.5-8.7c-0.2,4.2-0.2,8.4-0.3,12.6c-0.1,2.1-0.5,4.1-1.7,5.9
			c-1.3,1.8-3.3,1.9-4.5,0c-0.4-0.6-0.6-1.4-0.7-2.1c-0.2-1.2-0.2-2.4-0.3-3.5c0,0-0.1,0-0.1,0C112.8,582.6,112.8,581.2,112.9,579.8
			z"
            />
            <path
              class="st0"
              d="M111.9,581c-0.2,0.8-0.4,1.7-0.7,2.5c-0.1,0.3-0.8,0.6-1.2,0.6c-0.2,0-0.5,0-0.7,0
			C110.1,583.1,111,582.1,111.9,581z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M625.6,412.9c0.1,0.7,0.3,1.5,0.7,2.1c1.2,1.8,3.2,1.8,4.5,0c1.2-1.8,1.6-3.8,1.7-5.9
			c0.1-4.4,0.2-8.8,0.4-13.1c0.3-7.3,0.8-14.6,1.2-21.8c0.2-4.7,0.3-9.3,0.4-14c0.1-2.1,0.2-4.1,0.1-6.2c0-0.8-0.3-1.6-0.7-2.3
			c-0.5-1.1-1.7-1.3-2.7-1.3c-1.1,0-1.5,0.8-1.8,1.7c-0.4,1.3-0.7,2.7-0.9,4c-0.9,4.4-1.5,8.9-1.6,13.4c-0.1,3.4-0.2,6.9-0.4,10.3
			c-0.1,1.3-0.2,2.6-0.2,3.9c-0.2,5.1-0.4,10.1-0.6,15.2c-0.1,3.5-0.3,7-0.4,10.5c0,0,0.1,0,0.1,0
			C625.4,410.5,625.4,411.7,625.6,412.9z"
            />
            <path
              class="st0"
              d="M609.9,407.1c-0.1,2.2-0.1,4.4,0,6.6c0.1,1.4,0.8,1.9,1.9,1.9c0.9,0.1,1.6-0.4,2-1.2c0.7-1.3,1.5-2.6,1.7-4
			c0.6-3.8,1.1-7.7,1.5-11.5c0.3-3.7,0.5-7.4,0.7-11.1c0.2-3.6,0.5-7.1,0.6-10.7c0.1-5.1,0.1-10.3,0.1-15.4c0-3.3-0.1-6.5-1.5-9.6
			c-0.8-1.8-2.9-2.8-4.7-2.2c-0.4,0.1-0.9,0.4-1,0.7c-0.3,1.2-0.7,2.4-0.6,3.6c0.1,2.2,0.6,4.3,0.7,6.5c0.1,4.6,0.1,9.1,0.1,13.7
			c-0.1,0-0.2,0-0.4,0c0,3,0.1,6,0.1,9c0,2.8-0.2,5.6-0.3,8.4C610.5,397.1,610.1,402.1,609.9,407.1z"
            />
            <path
              class="st0"
              d="M627.2,357.7c0-0.7-0.1-1.5-0.2-2.4c-1.4,0-2.7-0.2-3.9-0.1c-1.3,0.1-2.5-0.8-3.7,0c0.3,1.6,0.6,3.2,0.9,4.9
			c0.8,0,1.5,0,2.2,0c0.7,0,1.4-0.1,2.1,0C627.1,360.2,627.1,360.2,627.2,357.7z"
            />
            <path
              class="st0"
              d="M623.7,408.9c0.4-1,0.6-2,0.8-3.1c0.2-1-0.3-1.6-1.3-1.7c-1.4-0.2-2.8-0.3-4.1-0.4c-0.8,0-1.1,0.5-1.2,1.2
			c-0.1,1.3-0.2,2.6-0.3,4.2c1.8,0.1,3.3,0.3,4.9,0.3C622.9,409.5,623.5,409.2,623.7,408.9z"
            />
            <path
              class="st0"
              d="M625,379.7c-2.1-0.6-4-0.7-5.7-0.5c0,1.9,0,3.5,0,5.3c1.8,0.5,3.4,0.3,5.3,0.2
			C624.8,383,624.9,381.4,625,379.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M374.6,519.3c-1.8,0.7-3.6,1.5-5.3,2.4c-3.4,1.8-6.9,3.2-10.7,3.9c-1.1,0.2-2.2,0.2-3.2,0.4
			c-3.2,0.8-6.5,1-9.8,0.6c-3.6-0.5-7.2-1.2-10.8-1.7c-1.6-0.2-3.3-0.4-4.9-0.3c-1.2,0.1-2.6,0-3.6,0.9c-1.4,1.3-2.8,2.6-4,4.1
			c-2.3,2.8-3.8,5.9-4.2,9.4c-0.4,3.7-0.3,7.3,3,10.1c3,2.6,7.1,1.8,8.7-1.3c0.7-1.4,0.9-3.1,1.1-4.6c0.3-3,0.3-5.9,0.5-8.9
			c0-0.6,0.3-1.3,0.5-1.9c0.3-0.8,0.8-1.2,1.7-1.1c1.1,0.1,2.1,0.2,3.2,0.3c3.6,0.6,7.3,1.4,11,1.8c3.1,0.3,6.3,0.4,9.4-0.3
			c5.2-1.2,10.1-3.1,14.2-6.3c2.6-2,4.5-4.6,6.3-7.3C376.6,518.8,376.2,518.7,374.6,519.3z M325.7,539.8c-0.3,1.3-0.7,2.5-1,3.8
			c-0.1,0.3-0.4,0.6-0.6,0.9c-1.8-0.6-1.9-1.8-1.8-3c0.2-2.8,1-5.3,3-7.6c0.2,0.4,0.4,0.7,0.5,0.9
			C326.2,536.5,326.1,538.1,325.7,539.8z"
            />
            <path
              class="st0"
              d="M320.3,590.6c0.3,0.8,0.8,1.1,1.7,1.1c1.7-0.1,3.4-0.1,5.1-0.1c5.2,0,10.3,0,15.5,0.2
			c5.1,0.2,10.2,0.5,15.3,0.8c2.6,0.2,5.1,0.5,7.7,0.7c3.2,0.2,6.4,0.5,9.6,0.6c2.2,0.1,4.5,0.3,6.7-0.8c0.9-0.5,1.2-1.1,1.3-1.8
			c0.1-1.5-1.7-3.4-3.3-3.6c-2-0.2-4-0.4-6-0.6c-4.3-0.4-8.5-0.7-12.8-1c-1.2-0.1-2.3-0.3-3.2-0.9c-2.6-1.8-5.1-3.7-7.7-5.4
			c-2-1.3-4.3-2.1-6.7-2.5c-0.7,0.8-0.3,1.6-0.1,2.3c0.4,1.2,0.8,2.4,1.2,3.6c0.3,1.3-0.1,1.8-1.4,1.9c-2.5,0.2-5,0.3-7.6,0.4
			c-5,0.1-9.9,0.2-14.9,0.3c-0.5,0-0.9,0.3-1.4,0.5c0.2,1.1,0.4,2,0.6,2.8C319.9,589.7,320.1,590.1,320.3,590.6z"
            />
            <path
              class="st0"
              d="M378.3,548.6c-1.5,0.7-2.9,1.2-4.2,1.9c-1.4,0.7-2.6,1.7-4.1,2.3c-3.4,1.3-6.8,2.5-10.3,3.6
			c-3,1-6.1,1.9-9.2,2.5c-4.1,0.8-8.3,1.5-12.5,2c-3.7,0.4-7.4,1-11.1,0.7c-1.4-0.1-2.8,0-4.2,0c-2.3,0.1-3.8,1.3-3.9,3.1
			c0,0.8,0.2,1.6,0.5,2.4c1,2.6,1.1,2.6,4.1,2.8c0.2,0,0.4,0,0.6-0.1c2.4-0.2,4.7-0.6,7.1-0.6c4.7,0,9.3-0.4,13.9-1.1
			c2.9-0.4,5.8-0.7,8.6-1.4c2.9-0.7,5.7-1.7,8.4-2.7c2.2-0.8,4.4-1.8,6.4-2.9c1.3-0.7,2.4-1.4,3.7-1.1c1-1.1,1.9-2,2.7-2.9
			c1.1-1.2,2.3-2.4,3.2-3.7C379.1,552,379.6,550.5,378.3,548.6z M324.7,565.9c-0.1-0.2,0-0.7,0.2-0.8c0.9-0.4,1,0.5,1.8,0.9
			C325.6,566.7,325,566.7,324.7,565.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M629.5,221c0.3,0.9-0.1,1.4-0.8,1.9c-1.5,1-3.3,1.2-4.9,0.7c-2.2-0.6-4.3-0.5-6.5-0.2
			c-3.2,0.4-6.4,0.6-9.6,0.9c-4.8,0.4-9.5,0.9-14.3,1.4c-1.7,0.2-3.4,0.5-5.1,0.6c-2.7,0.3-4.8-1-5.7-3.6c-0.3-0.8-0.6-1.7-0.8-2.6
			c2.1-2.5,4.3-5,6.4-7.5c0.5,0.9,1.1,1.3,2,1.5c1.2,0.2,2.5,0.5,3.7,0.5c3.7,0,7.4-0.1,11.2-0.1c1.3,0,2.6,0.2,4,0.2
			c1.9,0,3.8,0,5.7,0c0.8,0,1.3-0.4,1.7-1.1c0.5-0.9,1-1.7,1.4-2.7c0.5-1.5,1.8-2.1,3.2-2.2c1.2-0.1,2.4-0.1,3.6-0.1
			c1.4,0,1.8,0.6,1.4,2.1c-0.1,0.4-0.2,0.8-0.4,1.4c0.8,0.1,1.4,0.1,2,0.2c1.7,0.3,2.2,1.2,1.5,2.8c-0.2,0.4-0.5,0.9-0.8,1.4
			c1.4,0.6,1.7,1.7,1.1,3C629.3,220,629.4,220.6,629.5,221z"
            />
            <path
              class="st0"
              d="M611.6,185.4c-0.1,0.2-0.3,0.4-0.5,0.6c-1.1,1.3-2.1,2.6-3.1,4c-1.3,1.8-1.9,3.8-1.8,6c0,2.3-0.3,4.6-1.4,6.7
			c-0.9,1.7-2,3-4,3.5c-1.5,0.4-2.9,1.2-4.6,0.8c-0.9-0.2-1.7-0.4-2.4-0.8C599.7,199.3,605.6,192.4,611.6,185.4z"
            />
            <path
              class="st0"
              d="M624.5,188.2c-1.1,2-2.5,3.7-3.2,5.9c-1.4,4.3-1.2,8.6-0.8,13.2c1.4,0.1,2.7,0.2,3.9,0.3
			c0.2-0.4,0.4-0.5,0.4-0.7c0.1-0.7,0.2-1.5,0.3-2.2c0.2-5.5,1.7-10.6,4.7-15.2c1.1-1.7,1.9-3.6,2.4-5.5c0.7-2.5,0.8-5.2-1.2-7.3
			c-3.1-3.4-6.5-4.3-10.4-2.1c-2.3,1.3-3.4,4.9-1.5,7.4c0.8,1.1,2.1,1.4,2.9-0.4c0.2-0.5,0.2-1.1,0.4-1.6c0.3-1.2,1-1.6,2-1
			c0.6,0.4,1.3,0.9,1.6,1.5c0.6,1.5,0.9,3.2,0.1,4.7C625.6,186.2,625,187.2,624.5,188.2z"
            />
            <path
              class="st0"
              d="M625.8,224.8c-1.7,0.1-3.1,0.2-4.7,0.3c0,5.7,0.1,11.3,0.1,17c0.4,0.1,0.7,0.2,0.9,0.3c3.5,1,4.3,0.4,4.2-3.3
			c-0.1-3.4-0.3-6.7-0.5-10.1C625.7,227.6,625.8,226.3,625.8,224.8z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M97.6,659.8c0.3,0.9-0.1,1.4-0.8,1.9c-1.5,1-3.3,1.2-4.9,0.7c-2.2-0.6-4.3-0.5-6.5-0.2
			c-3.2,0.4-6.4,0.6-9.6,0.9c-4.8,0.4-9.5,0.9-14.3,1.4c-1.7,0.2-3.4,0.5-5.1,0.6c-2.7,0.3-4.8-1-5.7-3.6c-0.7-2.2-1.4-4.4-2-6.6
			c1.8-2.1,3.6-4.3,5.5-6.4c0.8,0.9,1.5,1.9,2.1,3c0.5,0.9,1.1,1.4,2,1.5c1.2,0.2,2.5,0.5,3.7,0.5c3.7,0,7.4-0.1,11.2-0.1
			c1.3,0,2.6,0.2,4,0.2c1.9,0,3.8,0,5.7,0c0.8,0,1.3-0.4,1.7-1.1c0.5-0.9,1-1.7,1.4-2.7c0.5-1.5,1.8-2.1,3.2-2.2
			c1.2-0.1,2.4-0.1,3.6-0.1c1.4,0,1.8,0.6,1.4,2.1c-0.1,0.4-0.2,0.8-0.4,1.4c0.8,0.1,1.4,0.1,2,0.2c1.7,0.3,2.2,1.2,1.5,2.8
			c-0.2,0.4-0.5,0.9-0.8,1.4c1.4,0.6,1.7,1.7,1.1,3C97.3,658.8,97.4,659.4,97.6,659.8z"
            />
            <path
              class="st0"
              d="M80.4,621.9c0,1.2-0.6,2.1-1.2,3c-1.1,1.3-2.1,2.6-3.1,4c-1.3,1.8-1.9,3.8-1.8,6c0,2.4-0.3,4.6-1.4,6.7
			c-0.9,1.7-2,3-4,3.5c-1.5,0.4-2.9,1.2-4.6,0.8c-2.2-0.5-4.1-1.4-5.4-2.7c5.8-6.8,11.6-13.5,17.3-20.3c0.3-0.3,0.7-0.5,1-0.7
			C78.1,621.4,79.2,621.4,80.4,621.9z"
            />
            <path
              class="st0"
              d="M92.6,627c-1.1,2-2.5,3.7-3.2,5.9c-1.4,4.3-1.2,8.6-0.8,13.2c1.4,0.1,2.7,0.2,3.9,0.3
			c0.2-0.4,0.4-0.5,0.4-0.7c0.1-0.7,0.2-1.5,0.3-2.2c0.2-5.5,1.7-10.6,4.7-15.2c1.1-1.7,1.9-3.5,2.4-5.4c0.7-2.5,0.8-5.2-1.2-7.3
			c-3.1-3.4-6.5-4.3-10.4-2.1c-2.3,1.3-3.4,4.9-1.5,7.4c0.9,1.1,2.1,1.4,2.9-0.4c0.2-0.5,0.2-1.1,0.4-1.6c0.3-1.2,1-1.6,2-1
			c0.6,0.4,1.3,0.9,1.6,1.5c0.6,1.5,0.9,3.2,0.1,4.7C93.6,625,93.1,626,92.6,627z"
            />
            <path
              class="st0"
              d="M93.8,663.6c-1.7,0.1-3.1,0.2-4.7,0.3c0,5.7,0.1,11.3,0.1,17c0.4,0.1,0.7,0.2,0.9,0.3c3.5,1,4.3,0.4,4.2-3.3
			c-0.1-3.4-0.3-6.7-0.5-10.1C93.8,666.4,93.8,665.1,93.8,663.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M435.5,390.6c-1.2,2.6-1.1,5.2,0.1,7.5c3.4,6.7,9.2,10.2,16.6,11.1c2.2,0.3,4.4,0.3,6.6,0.3
			c3.1,0,6.1,0.1,9.2-0.2c5.3-0.5,10.6-0.8,16-0.8c1.7,0,3.4-0.2,5.1-0.3c0.2,0,0.5-0.1,0.7-0.3c1-1,0.9-3.9-0.3-4.6
			c-1.2-0.6-2.4-1.3-3.6-1.4c-2.6-0.2-5.2-0.1-7.8-0.1c-1.8,0-3.6,0.2-5.5,0.2c-2.1-0.1-4.3-0.3-6.4-0.5c-4.6-0.5-9.3,0.1-13.8-1.2
			c-2.8-0.8-5.6-1.6-7.4-4.3c-2.2-3.2-1-8,2.6-9.5c0.6-0.2,1.2-0.5,2-0.8c-1.1-2-2-3.8-3.1-5.7c-2.5,0.4-4.3,1.8-5.9,3.3
			C438.2,385.3,436.8,387.9,435.5,390.6z"
            />
            <path
              class="st0"
              d="M464.6,361.6c-0.6,2.9-2.4,5-4.5,6.9c-1,0.9-2.5,1.2-3.9,1.7c-0.4,0.1-0.9-0.1-1.7-0.3
			c0.3-0.7,0.5-1.2,0.7-1.6c0,0,0,0,0,0c3.3-3.8,6.5-7.6,9.7-11.4C465.2,358.5,464.9,360,464.6,361.6z"
            />
            <path
              class="st0"
              d="M461.8,380.4c-2.6-2.1-5.7-3.1-9-3c-0.8,0-1.4,0.3-1.5,1.2c-0.4,1.9-0.8,3.8-1,5.7c-0.1,0.9,0.3,1.8,1.4,2
			c1.5,0.4,3,0.7,4.5,1.1c2-0.1,3.8-0.8,5.5-1.5c1.5-0.6,2-1.8,1.5-3.3C462.9,381.8,462.4,380.9,461.8,380.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M115.3,658.2c-1.2,2.5-1,5,0.1,7.3c3.3,6.5,8.9,9.8,16,10.8c2.1,0.3,4.3,0.2,6.4,0.3c3,0,5.9,0.1,8.9-0.2
			c5.2-0.4,10.3-0.8,15.5-0.8c1.6,0,3.3-0.2,4.9-0.3c0.2,0,0.5-0.1,0.7-0.3c0.9-0.9,0.9-3.8-0.3-4.4c-1.1-0.6-2.3-1.3-3.5-1.4
			c-2.5-0.2-5-0.1-7.5-0.1c-1.8,0-3.5,0.2-5.3,0.2c-2.1-0.1-4.1-0.3-6.2-0.5c-4.4-0.5-9,0.1-13.4-1.1c-2.8-0.7-5.4-1.5-7.2-4.1
			c-2.1-3.1-0.9-7.7,2.5-9.2c0.6-0.2,1.1-0.4,1.9-0.7c-1-1.9-2-3.6-3-5.5c-2.5,0.4-4.1,1.8-5.7,3.2
			C118,653.1,116.6,655.6,115.3,658.2z"
            />
            <path
              class="st0"
              d="M143.5,623.7c-1.4-4-6.2-6.5-10.3-6.2c-0.9,0.1-1.2,0.5-1,1.4c0.2,1.1,0.7,2,1.6,2.7c2,1.7,2.2,3.8,1.5,6.1
			c-0.2,0.7-0.5,1.3-0.8,1.9c-0.5,1-0.7,2,0,3c0.8,1.2,0.5,2.5,0.1,3.8c-0.2,0.5-0.4,0.9-0.7,1.8c0.7,0.1,1.2,0.4,1.6,0.3
			c1.3-0.5,2.8-0.8,3.7-1.6c2-1.8,3.8-3.9,4.4-6.7C144,628,144.2,625.8,143.5,623.7z"
            />
            <path
              class="st0"
              d="M131,623.5c-1.1-0.9-1.4-2-1.5-3.3c-0.1-0.8-0.2-1.7-0.3-2.5c-1.8-0.2-2.9,0.1-4,1.1
			c-0.6,0.5-1.1,1.1-1.6,1.6c-2,1.8-2.8,4-2.5,6.6c0.2,1.6,0.4,3.2,1,4.8c1.2,3.3,3.8,5.4,7.1,6.4c0.3,0.1,0.6,0,1.1-0.1
			c0-0.6-0.1-1-0.1-1.5c-0.1-1.2-0.3-2.4-0.3-3.5c-0.1-2.1,0.9-4,1.8-5.8C132.6,625.5,132.5,624.7,131,623.5z"
            />
            <path
              class="st0"
              d="M140.8,648.4c-2.5-2-5.5-3-8.8-3c-0.8,0-1.3,0.3-1.5,1.1c-0.4,1.8-0.8,3.7-1,5.5c-0.1,0.9,0.3,1.7,1.4,1.9
			c1.4,0.3,2.9,0.7,4.3,1.1c1.9-0.1,3.6-0.7,5.3-1.5c1.4-0.6,1.9-1.7,1.5-3.2C141.8,649.7,141.4,648.9,140.8,648.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M524.8,377.8c0,2.5,0,5,0,7.5c0,2.5-0.1,5-0.1,7.5c0,1,0.4,1.4,1.4,1.7c3,0.8,6.1,1.1,9.5,1.3
			c0.2-1.2,0.4-2.2,0.4-3.3c0-1.9-0.1-3.8,0-5.6c0-2.1,0.4-2.8,2.3-3c3.3-0.4,6.7-0.6,10-0.8c2.3-0.1,4.7,0.2,7,0
			c5.5-0.4,11.1-0.4,16.6,0.2c1.2,0.1,2.4,0.1,3.6,0.1c1.3,0.1,2.6,0.2,4,0.3c1,0.1,1.3,0.6,1.3,1.5c0,0.4,0,0.8,0,1.1
			c0.1,1.7,0.2,3.5,0.5,5.2c0.2,1.4,1.8,3,3,3c3,0,5.9-0.2,8.9-0.4c0.8,0,1.4-0.6,1.5-1.4c0.1-1.3,0.3-2.6,0.3-3.9
			c0-2.4-0.2-4.9-0.2-7.3c0-3.2-1.6-5.1-4.8-5.2c-3.9-0.1-7.8-0.1-11.7-0.1c-2.2,0-4.4,0.2-6.6,0.2c-3.8-0.1-7.6-0.3-11.3-0.3
			c-7.4,0-14.8-0.4-22.1-0.7c-3.2-0.1-6.3-0.2-9.5-0.3c-1.2,0-2.3,0.3-3.6,0.5C524.9,376.6,524.8,377.2,524.8,377.8z"
            />
            <path
              class="st0"
              d="M568.9,361.7c-0.4,0-0.7,0.1-1.1,0.2c-1.5,0.4-3,0.9-4.6,1.2c-3.1,0.7-6.1,0.2-9.1-0.8
			c-2.6-0.9-5.3-1.8-8.1-1.9c-2.3-0.1-4.5-0.4-6.8-0.7c-1.6-0.2-1.7-0.3-1.7-1.9c-0.1-1.1-0.1-2.1-0.3-3.2c-0.4-1.5-1.3-2.1-2.8-1.9
			c-1.3,0.1-2.6,0.2-3.9,0.4c-1.6,0.3-3.2,0.7-4.6,1.7c0,0.4,0,0.8,0.1,1.2c0.6,2.2,1.2,4.5,1.8,6.7c0.3,1,0.5,1.9,0.9,2.9
			c0.9,2.4,3.1,3.9,5.7,3.9c1.6,0,3.3-0.1,4.9-0.1c5.3-0.1,10.6-0.4,15.9-0.1c1.5,0.1,2.9,0.4,4.1,1.2c2,1.3,4.3,1.7,6.7,1.8
			c4.6,0.2,9-0.8,13.3-2.4c1.6-0.6,3.1-1.6,4.5-2.6c0.7-0.5,1.2-1.5,1.4-2.3c0.4-1.1-0.3-1.7-1.4-1.5c-0.9,0.2-1.9,0.4-2.7,0.9
			c-3.4,1.8-6.9,2.3-10.7,1.4c0.2-0.4,0.4-0.8,0.6-1.2C571.6,362.7,570.7,361.6,568.9,361.7z"
            />
            <path
              class="st0"
              d="M574.9,398.5c-1.1-2.8-3.6-4.3-6.1-5.6c-4.2-2.4-8.3-0.7-11.2,2.5c-2.2,2.5-3.7,5.8-3.6,9.3
			c0.1,3.9,1.6,7.1,5.1,9c1.7,0.9,3.6,1.1,5.5,1.1c4.8,0,8.6-2.5,10.1-6.8c0.6-1.8,0.9-3.8,1-5.7
			C575.9,401.1,575.4,399.7,574.9,398.5z M571.3,404.5c-0.5,2.9-2.9,5.3-6.5,5.2c-0.6,0-1.1,0-1.5,0c-2.6-0.2-4.3-1.9-4.5-4.5
			c-0.2-3.3,1.2-6,3.7-8.1c1.2-1,2.6-1,4-0.5c1.4,0.5,2.8,1.2,4,2.1c1.1,0.7,1.2,2,1.2,3.2C571.6,402.7,571.4,403.6,571.3,404.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M160.4,639.4c0,2.5,0,5,0,7.5c0,2.5-0.1,5-0.1,7.5c0,1,0.4,1.4,1.4,1.7c3,0.8,6.1,1.1,9.5,1.3
			c0.2-1.2,0.4-2.2,0.4-3.3c0-1.9-0.1-3.8,0-5.6c0-2.1,0.4-2.8,2.3-3c3.3-0.4,6.7-0.6,10-0.8c2.3-0.1,4.7,0.2,7,0
			c5.5-0.4,11.1-0.4,16.6,0.2c1.2,0.1,2.4,0.1,3.6,0.1c1.3,0.1,2.6,0.2,4,0.3c1,0.1,1.3,0.6,1.3,1.5c0,0.4,0,0.8,0,1.1
			c0.1,1.7,0.2,3.5,0.5,5.2c0.2,1.4,1.8,3,3,3c3,0,5.9-0.2,8.9-0.4c0.8,0,1.4-0.6,1.5-1.4c0.1-1.3,0.3-2.6,0.3-3.9
			c0-2.4-0.2-4.9-0.2-7.3c0-3.2-1.6-5.1-4.8-5.2c-3.9-0.1-7.8-0.1-11.7-0.1c-2.2,0-4.4,0.2-6.6,0.2c-3.8-0.1-7.6-0.3-11.3-0.3
			c-7.4,0-14.8-0.4-22.1-0.7c-3.2-0.1-6.3-0.2-9.5-0.3c-1.2,0-2.3,0.3-3.6,0.5C160.5,638.2,160.4,638.8,160.4,639.4z"
            />
            <path
              class="st0"
              d="M204.5,623.3c-0.4,0-0.7,0.1-1.1,0.2c-1.5,0.4-3,0.9-4.6,1.2c-3.1,0.7-6.1,0.2-9.1-0.8
			c-2.6-0.9-5.3-1.8-8.1-1.9c-2.3-0.1-4.5-0.4-6.8-0.7c-1.6-0.2-1.7-0.3-1.7-1.9c-0.1-1.1-0.1-2.1-0.3-3.2c-0.4-1.5-1.3-2.1-2.8-1.9
			c-1.3,0.1-2.6,0.2-3.9,0.5c-1.6,0.3-3.2,0.7-4.6,1.7c0,0.4,0,0.8,0.1,1.2c0.6,2.2,1.2,4.5,1.8,6.7c0.3,1,0.5,1.9,0.9,2.9
			c0.9,2.4,3.1,3.9,5.7,3.9c1.6,0,3.3-0.1,4.9-0.1c5.3-0.1,10.6-0.4,15.9-0.1c1.5,0.1,2.9,0.4,4.1,1.2c2,1.3,4.3,1.7,6.7,1.8
			c4.6,0.2,9-0.8,13.3-2.4c1.6-0.6,3.1-1.6,4.5-2.6c0.7-0.5,1.2-1.5,1.4-2.3c0.4-1.1-0.3-1.7-1.4-1.5c-0.9,0.2-1.9,0.4-2.7,0.9
			c-3.4,1.8-6.9,2.3-10.7,1.4c0.2-0.4,0.4-0.8,0.6-1.2C207.2,624.4,206.3,623.2,204.5,623.3z"
            />
            <path
              class="st0"
              d="M210.5,660.1c-1.1-2.8-3.6-4.3-6-5.6c-4.2-2.4-8.3-0.7-11.2,2.5c-2.2,2.5-3.7,5.8-3.6,9.3
			c0.1,3.8,1.6,7.1,5.1,9c1.7,0.9,3.6,1.1,5.5,1.1c4.7,0,8.6-2.5,10.1-6.8c0.6-1.8,1-3.8,1-5.7C211.5,662.8,211,661.4,210.5,660.1z
			 M206.9,666.1c-0.5,2.9-2.9,5.3-6.5,5.2c-0.6,0-1.1,0-1.5,0c-2.6-0.2-4.3-1.9-4.5-4.5c-0.2-3.3,1.2-6,3.7-8.1c1.2-1,2.6-1,4-0.5
			c1.4,0.5,2.8,1.2,4,2.1c1.1,0.7,1.2,2,1.2,3.2C207.2,664.4,207.1,665.2,206.9,666.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M611.7,460.9c0,2.5,0,5,0,7.5c0,2.5-0.1,5-0.1,7.5c0,1,0.4,1.4,1.4,1.7c3,0.8,6.1,1.1,9.5,1.3
			c0.2-1.2,0.4-2.2,0.4-3.3c0-1.9-0.1-3.8,0-5.6c0-2.1,0.4-2.8,2.3-3c3.3-0.4,6.7-0.6,10-0.8c2.3-0.1,4.7,0.2,7,0
			c5.5-0.4,11.1-0.4,16.6,0.2c1.2,0.1,2.4,0.1,3.6,0.1c1.3,0.1,2.6,0.2,4,0.3c1,0.1,1.3,0.6,1.3,1.5c0,0.4,0,0.8,0,1.1
			c0.1,1.7,0.2,3.5,0.5,5.2c0.2,1.4,1.8,3,3,3c3,0,5.9-0.2,8.9-0.4c0.8,0,1.4-0.6,1.5-1.4c0.1-1.3,0.3-2.6,0.3-3.9
			c0-2.4-0.2-4.9-0.2-7.3c0-3.2-1.6-5.1-4.8-5.2c-3.9-0.1-7.8-0.1-11.7-0.1c-2.2,0-4.4,0.2-6.6,0.2c-3.8,0-7.6-0.3-11.3-0.3
			c-7.4,0-14.8-0.4-22.1-0.7c-3.2-0.1-6.3-0.2-9.5-0.3c-1.2,0-2.3,0.3-3.6,0.5C611.9,459.7,611.7,460.3,611.7,460.9z"
            />
            <path
              class="st0"
              d="M655.8,444.8c-0.4,0-0.7,0.1-1.1,0.2c-1.5,0.4-3,0.9-4.6,1.2c-3.1,0.7-6.1,0.2-9.1-0.8
			c-2.6-0.9-5.3-1.8-8.1-1.9c-2.3-0.1-4.5-0.4-6.8-0.7c-1.6-0.2-1.7-0.3-1.7-1.9c-0.1-1.1-0.1-2.1-0.3-3.2c-0.4-1.5-1.3-2.1-2.8-1.9
			c-1.3,0.1-2.6,0.2-3.9,0.4c-1.6,0.3-3.2,0.7-4.6,1.7c0,0.4,0,0.8,0.1,1.2c0.6,2.2,1.2,4.5,1.8,6.7c0.3,1,0.5,1.9,0.9,2.9
			c0.9,2.4,3.1,3.9,5.7,3.9c1.6,0,3.3-0.1,4.9-0.1c5.3-0.1,10.6-0.4,15.9-0.1c1.5,0.1,2.9,0.4,4.1,1.2c2,1.3,4.3,1.7,6.7,1.8
			c4.6,0.2,9-0.8,13.3-2.4c1.6-0.6,3.1-1.6,4.5-2.6c0.7-0.5,1.2-1.5,1.4-2.3c0.4-1.1-0.3-1.7-1.4-1.5c-0.9,0.2-1.9,0.4-2.7,0.9
			c-3.4,1.8-6.9,2.3-10.7,1.4c0.2-0.4,0.4-0.8,0.6-1.2C658.5,445.9,657.6,444.7,655.8,444.8z"
            />
            <path
              class="st0"
              d="M661.9,481.6c-1.1-2.8-3.6-4.3-6.1-5.6c-4.2-2.4-8.3-0.7-11.2,2.5c-2.2,2.5-3.7,5.8-3.6,9.3
			c0.1,3.8,1.6,7.1,5.1,9c1.7,0.9,3.6,1.1,5.5,1.1c4.8,0,8.6-2.5,10.1-6.8c0.6-1.8,0.9-3.8,1-5.7
			C662.8,484.2,662.4,482.9,661.9,481.6z M658.3,487.6c-0.5,2.9-2.9,5.3-6.5,5.2c-0.6,0-1.1,0-1.5,0c-2.6-0.2-4.3-1.9-4.5-4.5
			c-0.2-3.3,1.2-6,3.7-8.1c1.2-1,2.6-1,4-0.5c1.4,0.5,2.8,1.2,4,2.1c1.1,0.7,1.2,2,1.2,3.2C658.6,485.9,658.4,486.7,658.3,487.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M258.5,671.4c-1.1-0.9-2.4-1.5-4-2.4c0.1-0.9,0-2.1,0.3-3.3c0.8-3.3,1.8-6.7,2.6-10c0.3-1.5,0.5-3.1,0.5-4.6
			c0-1.4-0.6-2.7-2.2-3.2c-1.4-0.5-2.6,0.2-3.5,1.2c-0.8,1-1.6,2-2.2,3c-1.1,1.8-2,3.6-3,5.4c-0.2,0.3-0.5,0.7-0.9,1.2
			c-0.1-0.5-0.2-0.8-0.2-1c0.4-2.1,0.7-4.3,1.4-6.4c0.4-1.4,0.8-2.7,0.7-4.2c-0.2-3.6-0.3-7.1-0.5-10.7c-0.1-1.1,0-2.2,0.5-3.3
			c0.9-1.9,1.4-3.9,1.3-6c-0.2-3.6-3.3-7.5-7.7-7.1c-2.4,0.2-5.8,2.8-6.7,5.2c-0.2,0.6-0.4,1.3-0.6,2c-0.8,2.9-0.3,4.6,2.3,6.3
			c0.9,0.6,1.5,1.4,1.3,2.5c-0.2,1.4-0.4,2.8-0.8,4.2c-0.7,3.5-1.4,7,0.8,10.4c0.3,0.5,0.3,1.2,0.3,1.8c-0.3,1.8-0.6,3.6-1.1,5.3
			c-0.8,3-1.5,6-0.4,9.2c1,2.9,2.9,4.6,5.9,5.1c1.8,0.3,3.4,0,4.3-1.9c0.7-1.3,1.5-2.5,2.1-3.8c0.8-1.5,1.4-3.1,2.1-4.6
			c0.2-0.3,0.4-0.6,0.7-0.9c0.1,0,0.3,0.1,0.4,0.1c-0.2,1.2-0.3,2.4-0.6,3.5c-0.5,1.6-1.1,3.2-1.8,4.7c-0.4,1-0.6,2-0.4,3.1
			c0.3,1.6,0.6,1.9,2.3,1.6c1.4-0.3,2.8-0.5,4.2,0.2c0.9,0.5,1.7,0.1,2.5-0.4C259.3,673,259.5,672.2,258.5,671.4z M244.7,629.2
			c-0.5,1.2-1.1,2.3-1.7,3.5c-0.1,0.2-0.4,0.4-0.6,0.4c-0.3-0.1-0.8-0.4-0.8-0.6c-0.1-1-0.8-1.6-1.3-2.4c-0.8-1-0.6-2.3-0.1-3.4
			c0.5-0.9,1-1.8,2.1-1.7C244.1,625.1,245.4,627.4,244.7,629.2z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M715.7,370.8c-1.2,1.5-2.4,3.1-3.5,4.7c-0.8,1.1-0.7,2.5,0.4,3.2c2.5,1.6,5,3.1,7.6,4.6
			c1,0.6,1.7,0.3,2.3-0.6c0.6-0.7,0.7-1.6-0.1-2.4c-0.8-0.8-1.6-1.5-2.6-2.1c-1.9-1.2-2.1-1.4-1.3-3.6c0.1-0.3,0.3-0.6,0.6-1.4
			c0.2,1,0.3,1.6,0.4,2.2c0,0.9,0.5,1.5,1.3,1.9c2.6,0.9,3.1,3,3,5.4c-0.1,1.7-0.7,2.9-2.4,3.4c-1,0.3-1.5,0.9-1.7,1.8
			c-0.2,0.7-0.3,1.5-0.5,2.2c-0.4,1.8-0.7,3.6-1.1,5.3c-0.5,2.2-1.2,4.4-1.8,6.5c-0.6,2.1-0.6,3.6,2.8,4.1c1.3,0.2,2.6,0.3,4,0.4
			c0.2,0,0.5,0,0.8,0c1.2-0.3,1.6-1.1,0.7-2c-0.5-0.5-1.1-0.8-1.7-1.2c-0.9-0.6-1.2-1.2-1.1-2.4c0.5-3.6,1.7-7.1,2.8-10.5
			c0.1-0.3,0.4-0.4,0.8-0.9c0.4,0.7,0.7,1.2,0.9,1.8c1.5,3.8,2.9,7.6,4.3,11.4c0.5,1.2,1.3,1.9,2.6,2.2c1.8,0.4,3.7,0.2,5.4-0.4
			c1.5-0.5,1.8-1.8,0.6-2.9c-0.9-0.8-2-1.6-3.2-2c-1.1-0.4-1.6-1-1.9-2.1c-1.3-4.1-2.4-8.3-4.4-12.2c-0.3-0.5-0.4-1.3-0.2-1.8
			c0.7-2.1,0.1-3.9-1.2-5.6c-1.1-1.5-1.1-3.2-1.3-5.1c1.5,0.6,2.2,1.7,2.8,2.8c0.8,1.2,1.5,2.5,2.4,3.6c0.4,0.6,1.2,1.2,1.8,1.2
			c2.6,0,5.3-0.2,7.9-0.5c1.3-0.2,1.7-1.8,0.8-2.8c-0.6-0.7-1.4-1.2-2.4-1.2c-1.2,0-2.4-0.1-3.6-0.1c-1.4,0-2.4-0.4-2.9-1.9
			c-0.2-0.7-0.6-1.4-1-2c-1.5-2.8-3.5-4.9-7-5.2c0.3-1.4,0.7-2.6,0.9-3.8c0.2-2.3-1.7-4.4-3.9-4.4c-3.4,0-5.2,3-3.7,6.2
			c0.3,0.7,0.5,1.3,0,1.9C718,368,716.9,369.4,715.7,370.8z"
            />
            <path
              class="st0"
              d="M766.6,369c0.8-0.3,1.7-0.3,2.6-0.6c1.2-0.3,2.1-1.6,2.1-2.6c0-1.2-1-1.5-1.9-1.8c-0.5-0.2-1.1-0.3-1.6-0.4
			c-2.3-0.2-4.5-0.3-6.8,0.4c-0.6,0.2-1.3,0.1-1.9,0.1c-2.2-0.2-4.4-0.4-6.6-0.5c-0.6,0-1.3,0.1-1.9,0.1c-0.6,0-1.3,0.2-1.9,0.1
			c-1.5-0.3-2.7,0.2-4.1,0.6c-0.8,0.2-1.1,0.7-1.2,1.5c0,0.6,0,1.1,0,1.8c1.7,0.4,3.1,0.8,4.6,1c1.5,0.2,3,0.1,4.5,0.2
			c1.5,0.1,3,0.1,4.5,0.3c1.5,0.2,1.6,0.3,1.6,1.8c0.1,4.9,0.2,9.8,0.1,14.7c-0.1,6.9-0.4,13.8-0.6,20.7c0,1.6,0.3,2.1,1.9,2.3
			c1.9,0.3,3.8,0.4,5.6,0.3c0.8,0,1.7-0.4,2.5-0.8c0.8-0.3,1.2-1.1,1-1.9c-0.1-0.5-0.8-0.9-1.3-1.2c-0.6-0.3-1.3-0.3-1.9-0.5
			c-0.2-0.8,0.2-1.1,0.8-1.2c0.5-0.1,1-0.2,1.5-0.4c0.8-0.3,1.3-1.2,1.2-2.3c0-1-0.7-1.3-1.5-1.4c-0.6-0.1-1.2-0.3-1.8-0.5
			c-1.1-0.3-1.4-1.3-0.5-2.1c0.3-0.3,0.8-0.4,1.1-0.6c0.6-0.5,1.3-1,1.8-1.6c0.4-0.5,0.3-1.1-0.2-1.5c-0.8-0.6-1.7-0.8-2.7-0.8
			c-1,0-1.2-0.3-1.1-1.3c0.1-0.8,0.4-1.4,1.3-1.6c0.6-0.1,1.3-0.3,1.7-0.7c0.5-0.4,1.2-1.1,1.1-1.5c-0.2-0.6-0.9-1.1-1.5-1.5
			c-0.4-0.3-1-0.2-1.5-0.3c-0.8-0.3-1-1-0.5-1.6c0.3-0.3,0.7-0.5,1.1-0.7c0.4-0.2,0.8-0.3,1.2-0.5c1.1-0.5,1.5-1.2,1.3-2.2
			c-0.2-1-0.9-1.3-1.7-1.5c-0.4-0.1-0.9-0.1-1.3-0.2c-0.7-0.1-0.8-0.7-0.7-1.3c1.1-0.4,2.1-0.8,3.2-1.2c0.9-0.4,1.5-1.8,1.3-2.5
			c-0.3-1-1.1-1.5-2.1-1.6c-0.4,0-0.8,0-1.1-0.1c-1.3-0.1-1.9-1-1.3-2C765.5,369.5,766.1,369.2,766.6,369z"
            />
            <path
              class="st0"
              d="M740.5,382.8c-0.8,0-1.5,0.1-1.7,1.1c-0.2,1.3,0.1,2.2,1.2,2.6c1,0.3,2,0.5,3.1,0.8c0.8,2.3,0.5,2.7-1.8,3
			c-0.3,0-0.6,0-0.9,0.1c-0.8,0.2-1.1,0.7-1.2,1.5c-0.1,1.2,0.7,1.5,1.5,1.8c0.7,0.3,1.7,0.4,2,0.9c0.3,0.6,0.1,1.6,0.1,2.4
			c0,0.2-0.1,0.5-0.2,0.7c-0.4,0.2-0.7,0.3-1,0.5c-1.6,0.9-1.7,2.1-0.1,3c0.7,0.4,1,0.9,1.1,1.7c0.1,0.7,0.1,1.4,0.2,2.1
			c0.2,1.4,0.9,2,2.3,1.9c0.6,0,1.1-0.1,1.7-0.2c1.5,0,1.7-0.2,1.7-1.6c0.1-3.8,0-7.5,0.2-11.3c0.2-6.1,0.5-12.2,0.8-18.4
			c0.1-1.1,0.2-2.2,1.4-2.9c0.4-0.2,0.5-0.8,0.8-1.2c-2-1.4-2.5-1.2-6.9,0c0,3.8,0.8,7.8-0.4,11.9c-0.8-0.1-1.4-0.2-2.1-0.3
			C741.6,382.9,741,382.9,740.5,382.8z"
            />
            <path
              class="st0"
              d="M750.7,412.5c1.4-0.1,2.8,0,4.2-0.1c1.6-0.1,2.1-0.9,1.8-2.4c-0.3-1.2-1-1.9-2.4-1.9
			c-2.5-0.1-5.1-0.1-7.6-0.1c-3.5,0-7.1-0.1-10.6,0.3c-6.4,0.8-12.8,0.6-19.2,0.5c-1.3,0-1.7,0.1-2.1,1.3c-0.6,1.8,0.6,3.6,2.5,3.6
			c3.7,0,7.3,0,11-0.1c0,0,0-0.1,0-0.1c4.3-0.2,8.6-0.4,12.8-0.6C744.3,412.8,747.5,412.6,750.7,412.5z"
            />
            <path
              class="st0"
              d="M756.7,374.4c-0.1-1.1-0.9-1.5-2-1.5c-1,0-1.5,0.6-1.7,1.7c-0.2,2.2-0.5,4.4-0.6,6.5
			c-0.4,6.1-0.7,12.1-1,18.2c0,0,0.1,0,0.1,0c0,1.7,0,3.4,0.1,5.1c0,0.9,1.1,1.8,2,1.9c1,0.1,1.2-0.7,1.5-1.4
			c0.1-0.4,0.3-0.8,0.3-1.3c0.4-4.2,0.8-8.3,1.1-12.5c0.3-3.6,0.5-7.2,0.5-10.9C757.1,378.2,756.9,376.3,756.7,374.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M450.3,451.5c-1.3,1.6-2.6,3.3-3.8,4.9c-0.8,1.1-0.7,2.6,0.5,3.4c2.6,1.7,5.3,3.3,8.1,4.9
			c1.1,0.6,1.8,0.3,2.5-0.6c0.6-0.8,0.7-1.7-0.1-2.5c-0.8-0.9-1.8-1.6-2.8-2.3c-2.1-1.3-2.2-1.5-1.4-3.8c0.1-0.4,0.3-0.7,0.7-1.5
			c0.2,1.1,0.4,1.7,0.4,2.4c0,1,0.5,1.6,1.4,2c2.8,1,3.4,3.2,3.2,5.8c-0.1,1.8-0.8,3.1-2.6,3.6c-1,0.3-1.6,0.9-1.8,1.9
			c-0.2,0.8-0.4,1.6-0.5,2.3c-0.4,1.9-0.7,3.8-1.2,5.7c-0.6,2.3-1.2,4.6-1.9,7c-0.6,2.2-0.7,3.8,3,4.4c1.4,0.2,2.8,0.3,4.2,0.4
			c0.3,0,0.5,0,0.8,0c1.3-0.3,1.7-1.2,0.7-2.2c-0.5-0.5-1.2-0.9-1.8-1.3c-0.9-0.6-1.3-1.3-1.1-2.5c0.5-3.9,1.8-7.5,3-11.2
			c0.1-0.3,0.4-0.5,0.8-0.9c0.4,0.8,0.7,1.3,0.9,1.9c1.6,4.1,3.1,8.1,4.6,12.2c0.5,1.3,1.4,2.1,2.7,2.4c2,0.4,3.9,0.2,5.8-0.4
			c1.6-0.5,1.9-2,0.6-3.1c-1-0.9-2.2-1.7-3.4-2.1c-1.2-0.4-1.7-1.1-2-2.2c-1.4-4.4-2.6-8.8-4.6-13c-0.3-0.6-0.4-1.4-0.2-1.9
			c0.7-2.3,0.1-4.2-1.3-6c-1.2-1.5-1.2-3.4-1.4-5.5c1.6,0.7,2.3,1.8,3,2.9c0.8,1.3,1.6,2.6,2.5,3.9c0.5,0.6,1.3,1.3,1.9,1.3
			c2.8,0,5.6-0.2,8.4-0.5c1.4-0.2,1.8-1.9,0.8-3c-0.7-0.8-1.5-1.3-2.6-1.3c-1.3,0-2.6-0.1-3.8-0.1c-1.5,0-2.6-0.5-3-2
			c-0.2-0.8-0.7-1.4-1-2.2c-1.5-3-3.7-5.2-7.4-5.6c0.4-1.5,0.8-2.7,0.9-4c0.3-2.5-1.8-4.7-4.1-4.6c-3.6,0-5.6,3.2-4,6.6
			c0.3,0.7,0.5,1.4,0,2C452.7,448.5,451.5,450,450.3,451.5z"
            />
            <path
              class="st0"
              d="M504.4,449.6c0.9-0.3,1.9-0.3,2.8-0.6c1.2-0.4,2.3-1.7,2.2-2.8c0-1.3-1.1-1.6-2-2c-0.6-0.2-1.1-0.4-1.7-0.4
			c-2.4-0.2-4.8-0.3-7.2,0.4c-0.6,0.2-1.3,0.2-2,0.1c-2.3-0.2-4.7-0.4-7-0.6c-0.7,0-1.3,0.1-2,0.1c-0.7,0-1.4,0.2-2,0.1
			c-1.5-0.4-2.9,0.2-4.3,0.6c-0.9,0.2-1.2,0.8-1.3,1.6c0,0.6,0,1.2,0,1.9c1.8,0.4,3.3,0.9,4.9,1.1c1.6,0.2,3.2,0.1,4.8,0.2
			c1.6,0.1,3.2,0.1,4.8,0.3c1.6,0.2,1.7,0.3,1.8,1.9c0.1,5.2,0.2,10.4,0.1,15.6c-0.1,7.3-0.4,14.7-0.6,22c0,1.7,0.3,2.2,2,2.4
			c2,0.3,4,0.4,6,0.4c0.9,0,1.8-0.4,2.7-0.8c0.8-0.4,1.2-1.2,1-2c-0.1-0.5-0.8-1-1.4-1.2c-0.6-0.3-1.4-0.4-2-0.5
			c-0.2-0.9,0.2-1.1,0.8-1.3c0.5-0.2,1.1-0.2,1.6-0.4c0.9-0.3,1.4-1.3,1.3-2.5c-0.1-1-0.7-1.3-1.6-1.5c-0.7-0.1-1.3-0.3-2-0.5
			c-1.2-0.4-1.5-1.4-0.6-2.2c0.3-0.3,0.8-0.4,1.2-0.7c0.7-0.5,1.4-1,1.9-1.7c0.5-0.5,0.3-1.1-0.3-1.6c-0.8-0.6-1.8-0.8-2.8-0.8
			c-1,0-1.3-0.3-1.2-1.3c0.1-0.9,0.4-1.5,1.4-1.7c0.6-0.1,1.3-0.3,1.9-0.7c0.5-0.4,1.3-1.2,1.2-1.6c-0.2-0.6-0.9-1.2-1.6-1.6
			c-0.4-0.3-1-0.2-1.6-0.4c-0.9-0.3-1.1-1-0.5-1.7c0.3-0.3,0.7-0.6,1.1-0.8c0.4-0.2,0.9-0.3,1.3-0.5c1.1-0.6,1.5-1.3,1.4-2.4
			c-0.2-1-0.9-1.4-1.8-1.6c-0.5-0.1-0.9-0.1-1.4-0.2c-0.8-0.2-0.9-0.7-0.7-1.4c1.1-0.4,2.3-0.8,3.4-1.3c0.9-0.5,1.6-1.9,1.4-2.7
			c-0.3-1.1-1.1-1.6-2.2-1.7c-0.4,0-0.8,0-1.2-0.1c-1.4-0.1-2-1-1.4-2.2C503.3,450.2,503.9,449.8,504.4,449.6z"
            />
            <path
              class="st0"
              d="M476.6,464.4c-0.9,0-1.6,0.1-1.8,1.2c-0.3,1.4,0.1,2.3,1.3,2.7c1.1,0.3,2.2,0.6,3.3,0.8
			c0.8,2.5,0.6,2.9-1.9,3.2c-0.3,0-0.7,0-1,0.1c-0.8,0.2-1.2,0.8-1.3,1.6c-0.1,1.2,0.8,1.6,1.6,1.9c0.7,0.3,1.8,0.4,2.1,0.9
			c0.4,0.7,0.1,1.7,0.1,2.5c0,0.3-0.1,0.5-0.2,0.8c-0.4,0.2-0.8,0.3-1.1,0.5c-1.7,0.9-1.8,2.2-0.1,3.2c0.7,0.4,1,1,1.1,1.8
			c0.1,0.7,0.1,1.5,0.2,2.2c0.2,1.5,1,2.1,2.5,2c0.6,0,1.2-0.1,1.8-0.2c1.6,0,1.8-0.2,1.8-1.7c0.1-4,0-8,0.2-12
			c0.2-6.5,0.5-13,0.8-19.6c0.1-1.2,0.2-2.4,1.4-3.1c0.4-0.2,0.6-0.8,0.9-1.2c-2.2-1.5-2.7-1.2-7.4,0c0,4.1,0.8,8.3-0.4,12.7
			c-0.9-0.1-1.5-0.2-2.2-0.3C477.8,464.5,477.2,464.4,476.6,464.4z"
            />
            <path
              class="st0"
              d="M487.5,495.9c1.5-0.1,3,0,4.4-0.1c1.7-0.1,2.3-0.9,1.9-2.5c-0.3-1.3-1-2-2.6-2c-2.7-0.1-5.4-0.1-8.1-0.1
			c-3.8,0-7.6-0.1-11.3,0.3c-6.8,0.9-13.7,0.6-20.5,0.5c-1.4,0-1.8,0.1-2.2,1.4c-0.7,2,0.6,3.9,2.7,3.9c3.9,0,7.8-0.1,11.7-0.1
			c0,0,0-0.1,0-0.1c4.6-0.2,9.1-0.4,13.7-0.6C480.7,496.3,484.1,496.1,487.5,495.9z"
            />
            <path
              class="st0"
              d="M493.8,455.4c-0.1-1.2-1-1.6-2.2-1.6c-1.1,0-1.6,0.6-1.8,1.8c-0.2,2.3-0.5,4.6-0.7,7
			c-0.4,6.5-0.7,12.9-1.1,19.4c0,0,0.1,0,0.1,0c0,1.8,0,3.6,0.1,5.4c0,1,1.1,1.9,2.2,2c1.1,0.1,1.3-0.7,1.6-1.5
			c0.2-0.4,0.3-0.9,0.3-1.4c0.4-4.4,0.9-8.9,1.2-13.3c0.3-3.9,0.5-7.7,0.6-11.6C494.3,459.5,494.1,457.4,493.8,455.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M265.6,632c-1.2,1.5-2.4,3.1-3.5,4.7c-0.8,1.1-0.7,2.5,0.4,3.2c2.5,1.6,5,3.1,7.6,4.6c1,0.6,1.7,0.3,2.3-0.6
			c0.6-0.7,0.7-1.6-0.1-2.4c-0.8-0.8-1.6-1.5-2.6-2.1c-1.9-1.2-2.1-1.4-1.3-3.6c0.1-0.3,0.3-0.6,0.6-1.4c0.2,1,0.3,1.6,0.4,2.2
			c0,0.9,0.5,1.5,1.3,1.9c2.6,0.9,3.1,3,3,5.4c-0.1,1.7-0.7,2.9-2.4,3.4c-1,0.3-1.5,0.9-1.7,1.8c-0.2,0.7-0.3,1.5-0.5,2.2
			c-0.4,1.8-0.7,3.6-1.1,5.3c-0.5,2.2-1.2,4.4-1.8,6.5c-0.6,2.1-0.6,3.6,2.8,4.1c1.3,0.2,2.6,0.3,4,0.4c0.3,0,0.5,0,0.8,0
			c1.2-0.3,1.6-1.1,0.7-2c-0.5-0.5-1.1-0.8-1.7-1.2c-0.9-0.6-1.2-1.2-1.1-2.4c0.5-3.6,1.7-7.1,2.8-10.5c0.1-0.3,0.4-0.4,0.8-0.9
			c0.4,0.7,0.7,1.2,0.9,1.8c1.5,3.8,2.9,7.6,4.3,11.4c0.5,1.2,1.3,1.9,2.6,2.2c1.8,0.4,3.7,0.2,5.4-0.4c1.5-0.5,1.8-1.8,0.6-2.9
			c-0.9-0.8-2-1.6-3.2-2c-1.1-0.4-1.6-1-1.9-2c-1.3-4.1-2.4-8.3-4.4-12.2c-0.3-0.5-0.4-1.3-0.2-1.8c0.7-2.1,0.1-3.9-1.2-5.6
			c-1.1-1.5-1.1-3.2-1.3-5.1c1.5,0.6,2.2,1.7,2.8,2.8c0.8,1.2,1.5,2.5,2.4,3.6c0.4,0.6,1.2,1.2,1.8,1.2c2.6,0,5.3-0.2,7.9-0.5
			c1.3-0.2,1.7-1.8,0.8-2.8c-0.6-0.7-1.4-1.2-2.4-1.2c-1.2,0-2.4-0.1-3.6-0.1c-1.4,0-2.4-0.4-2.9-1.9c-0.2-0.7-0.6-1.4-1-2
			c-1.5-2.8-3.5-4.9-7-5.2c0.3-1.4,0.7-2.6,0.9-3.8c0.2-2.3-1.7-4.4-3.9-4.4c-3.4,0-5.2,3-3.7,6.2c0.3,0.7,0.5,1.3,0,1.9
			C267.9,629.2,266.7,630.6,265.6,632z"
            />
            <path
              class="st0"
              d="M316.5,630.3c0.8-0.3,1.7-0.3,2.6-0.6c1.2-0.3,2.1-1.6,2.1-2.6c0-1.2-1-1.5-1.9-1.8c-0.5-0.2-1.1-0.3-1.6-0.4
			c-2.3-0.2-4.5-0.3-6.8,0.4c-0.6,0.2-1.3,0.1-1.9,0.1c-2.2-0.2-4.4-0.4-6.6-0.5c-0.6,0-1.3,0.1-1.9,0.1c-0.6,0-1.3,0.2-1.9,0.1
			c-1.5-0.3-2.7,0.2-4.1,0.6c-0.8,0.2-1.1,0.7-1.2,1.5c0,0.6,0,1.1,0,1.8c1.7,0.4,3.1,0.8,4.6,1c1.5,0.2,3,0.1,4.5,0.2
			c1.5,0.1,3,0.1,4.5,0.3c1.5,0.2,1.6,0.3,1.6,1.8c0.1,4.9,0.2,9.8,0.1,14.7c-0.1,6.9-0.4,13.8-0.6,20.7c0,1.6,0.3,2.1,1.9,2.3
			c1.9,0.3,3.8,0.4,5.6,0.3c0.8,0,1.7-0.4,2.5-0.8c0.8-0.3,1.2-1.1,1-1.9c-0.1-0.5-0.8-0.9-1.3-1.2c-0.6-0.3-1.3-0.3-1.9-0.5
			c-0.2-0.8,0.2-1.1,0.8-1.2c0.5-0.1,1-0.2,1.5-0.4c0.8-0.3,1.3-1.2,1.2-2.3c0-1-0.7-1.2-1.5-1.4c-0.6-0.1-1.2-0.3-1.8-0.5
			c-1.1-0.3-1.4-1.3-0.5-2.1c0.3-0.3,0.8-0.4,1.1-0.6c0.6-0.5,1.3-1,1.8-1.6c0.5-0.5,0.3-1.1-0.2-1.5c-0.8-0.6-1.7-0.8-2.7-0.8
			c-1,0-1.2-0.3-1.1-1.3c0.1-0.8,0.4-1.4,1.3-1.6c0.6-0.1,1.3-0.3,1.7-0.7c0.5-0.4,1.2-1.1,1.1-1.5c-0.2-0.6-0.9-1.1-1.5-1.5
			c-0.4-0.3-1-0.2-1.5-0.3c-0.8-0.3-1-1-0.5-1.6c0.3-0.3,0.7-0.5,1.1-0.7c0.4-0.2,0.8-0.3,1.2-0.4c1.1-0.5,1.5-1.3,1.3-2.2
			c-0.2-1-0.9-1.3-1.7-1.5c-0.4-0.1-0.9-0.1-1.3-0.2c-0.7-0.1-0.8-0.6-0.7-1.3c1.1-0.4,2.1-0.8,3.2-1.2c0.9-0.4,1.5-1.8,1.3-2.5
			c-0.3-1-1.1-1.5-2.1-1.6c-0.4,0-0.8,0-1.1-0.1c-1.3-0.1-1.9-1-1.3-2C315.4,630.8,316,630.4,316.5,630.3z"
            />
            <path
              class="st0"
              d="M290.4,644.1c-0.8,0-1.5,0.1-1.7,1.1c-0.2,1.3,0.1,2.2,1.2,2.6c1,0.3,2,0.5,3.1,0.8c0.8,2.3,0.5,2.7-1.8,3
			c-0.3,0-0.6,0-0.9,0.1c-0.8,0.2-1.1,0.7-1.2,1.5c-0.1,1.2,0.7,1.5,1.5,1.8c0.7,0.3,1.7,0.4,2,0.9c0.3,0.6,0.1,1.6,0.1,2.4
			c0,0.2-0.1,0.5-0.2,0.7c-0.4,0.2-0.7,0.3-1,0.5c-1.6,0.9-1.7,2.1-0.1,3c0.7,0.4,1,0.9,1.1,1.7c0.1,0.7,0.1,1.4,0.2,2.1
			c0.2,1.4,0.9,2,2.3,1.8c0.6,0,1.1-0.1,1.7-0.2c1.5,0,1.7-0.1,1.7-1.6c0.1-3.8,0-7.5,0.2-11.3c0.2-6.1,0.5-12.2,0.8-18.4
			c0.1-1.1,0.2-2.2,1.4-2.9c0.4-0.2,0.5-0.8,0.8-1.2c-2-1.4-2.5-1.2-6.9,0c0,3.8,0.8,7.8-0.4,11.9c-0.8-0.1-1.4-0.2-2.1-0.3
			C291.5,644.2,290.9,644.1,290.4,644.1z"
            />
            <path
              class="st0"
              d="M300.6,673.7c1.4-0.1,2.8,0,4.2-0.1c1.6-0.1,2.1-0.9,1.8-2.4c-0.3-1.2-1-1.9-2.4-1.9
			c-2.5-0.1-5.1-0.1-7.6-0.1c-3.5,0-7.1-0.1-10.6,0.3c-6.4,0.8-12.8,0.6-19.2,0.5c-1.3,0-1.7,0.1-2.1,1.3c-0.6,1.8,0.6,3.6,2.5,3.6
			c3.7,0,7.3,0,11-0.1c0,0,0-0.1,0-0.1c4.3-0.2,8.6-0.4,12.8-0.6C294.2,674.1,297.4,673.9,300.6,673.7z"
            />
            <path
              class="st0"
              d="M306.6,635.6c-0.1-1.1-0.9-1.5-2-1.5c-1,0-1.5,0.6-1.7,1.7c-0.2,2.2-0.5,4.4-0.6,6.5
			c-0.4,6.1-0.7,12.1-1,18.2c0,0,0.1,0,0.1,0c0,1.7,0,3.4,0.1,5.1c0,0.9,1.1,1.8,2,1.9c1,0.1,1.2-0.7,1.5-1.4
			c0.1-0.4,0.3-0.8,0.3-1.3c0.4-4.2,0.8-8.3,1.1-12.5c0.3-3.6,0.5-7.2,0.5-10.9C306.9,639.5,306.8,637.6,306.6,635.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M637.5,563.8c-0.7,0.6-0.8,1.4-0.6,2.2c0.9,3.1,1.7,6.1,2.8,9.2c0.9,2.6,3,3.9,5.7,3.6
			c1.7-0.2,3.4-0.5,5.1-0.6c4.8-0.5,9.5-0.9,14.3-1.4c3.2-0.3,6.4-0.5,9.6-0.9c2.2-0.3,4.3-0.4,6.5,0.2c1.6,0.5,3.4,0.3,4.9-0.7
			c0.7-0.5,1.1-1,0.8-1.9c-0.1-0.4-0.2-1,0-1.4c0.6-1.4,0.3-2.4-1.1-3c0.3-0.6,0.6-1,0.8-1.4c0.8-1.6,0.2-2.5-1.5-2.8
			c-0.6-0.1-1.2-0.1-2-0.2c0.2-0.5,0.3-0.9,0.4-1.3c0.4-1.4,0-2-1.4-2.1c-1.2,0-2.4,0-3.6,0.1c-1.4,0.1-2.6,0.7-3.2,2.2
			c-0.3,0.9-0.9,1.8-1.4,2.7c-0.4,0.6-0.9,1.1-1.7,1.1c-1.9,0-3.8,0-5.7,0c-1.3,0-2.6-0.2-4-0.2c-3.7,0-7.4,0.1-11.2,0.1
			c-1.2,0-2.5-0.3-3.7-0.5c-0.9-0.1-1.5-0.6-2-1.5c-0.7-1.2-1.5-2.3-2.4-3.4c-1.1-1.2-1.9-1.2-3.1-0.2
			C639,562.3,638.3,563.1,637.5,563.8z"
            />
            <path
              class="st0"
              d="M659.1,534.3c-0.3-1-0.9-1.6-2.2-2c-2.4,2.8-2.5,6.8-4.8,10c-0.2-0.9-0.3-1.5-0.4-2.1
			c-0.4-2.1-1.5-3.8-3.2-5.1c-0.4-0.3-0.9-0.5-1.2-0.9c-1.4-1.3-3.1-1.5-4.9-1.4c-0.8,0-1.6,0-1.9,1c-0.2,0.9,0,1.7,0.8,2.3
			c0.9,0.6,1.8,1.4,2.7,2c0.9,0.6,1.4,1.4,1.6,2.5c0.8,3.1,0.7,6.2,0.2,9.3c-0.8,5.2,2.5,8.5,7.6,9.7c1.7,0.4,3.1-0.4,4.6-0.8
			c1.9-0.5,3.1-1.8,4-3.5c1.1-2.1,1.5-4.3,1.4-6.7c0-2.2,0.6-4.2,1.8-6c1-1.4,2-2.7,3.1-4c0.7-0.8,1.2-1.7,1.2-3
			c-1.2-0.5-2.3-0.5-3.3,0.2c-1.2,0.8-2.3,1.8-3.3,2.8c-1,1-1.9,2.1-3.1,3.3c0-1.2-0.1-2.1,0-2.9
			C659.9,537.3,659.6,535.9,659.1,534.3z"
            />
            <path
              class="st0"
              d="M681.6,540.6c-1.1,2-2.5,3.7-3.2,5.9c-1.4,4.3-1.2,8.6-0.8,13.2c1.4,0.1,2.7,0.2,3.9,0.3
			c0.2-0.4,0.4-0.5,0.4-0.7c0.1-0.7,0.2-1.5,0.3-2.2c0.2-5.5,1.7-10.6,4.7-15.2c1.1-1.7,1.9-3.5,2.4-5.4c0.7-2.5,0.8-5.2-1.2-7.3
			c-3.1-3.4-6.5-4.3-10.4-2.1c-2.3,1.3-3.4,4.9-1.5,7.4c0.8,1.1,2.1,1.4,2.9-0.4c0.2-0.5,0.2-1.1,0.4-1.6c0.3-1.2,1-1.6,2-1
			c0.6,0.4,1.3,0.9,1.6,1.5c0.6,1.5,0.9,3.2,0.1,4.7C682.7,538.7,682.1,539.7,681.6,540.6z"
            />
            <path
              class="st0"
              d="M682.8,577.3c-1.7,0.1-3.1,0.2-4.7,0.3c0,5.7,0.1,11.3,0.1,17c0.4,0.1,0.7,0.2,0.9,0.3c3.5,1,4.3,0.4,4.2-3.3
			c-0.1-3.4-0.3-6.7-0.5-10.1C682.8,580.1,682.9,578.8,682.8,577.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M648.1,386.2c-0.7,0.6-0.8,1.4-0.6,2.2c0.9,3.1,1.7,6.1,2.8,9.2c0.9,2.6,3,3.9,5.7,3.6
			c1.7-0.2,3.4-0.5,5.1-0.6c4.8-0.5,9.5-0.9,14.3-1.4c3.2-0.3,6.4-0.5,9.6-0.9c2.2-0.3,4.3-0.4,6.5,0.2c1.6,0.5,3.4,0.3,4.9-0.7
			c0.7-0.5,1.1-1,0.8-1.9c-0.1-0.4-0.2-1,0-1.4c0.6-1.4,0.3-2.4-1.1-3c0.3-0.6,0.6-1,0.8-1.4c0.8-1.6,0.2-2.5-1.5-2.8
			c-0.6-0.1-1.2-0.1-2-0.2c0.2-0.5,0.3-0.9,0.4-1.3c0.4-1.4,0-2-1.4-2.1c-1.2,0-2.4,0-3.6,0.1c-1.4,0.1-2.6,0.7-3.2,2.2
			c-0.3,0.9-0.9,1.8-1.4,2.7c-0.4,0.6-0.9,1.1-1.7,1.1c-1.9,0-3.8,0-5.7,0c-1.3,0-2.6-0.2-4-0.2c-3.7,0-7.4,0.1-11.2,0.1
			c-1.2,0-2.5-0.3-3.7-0.5c-0.9-0.1-1.5-0.6-2-1.5c-0.7-1.2-1.5-2.3-2.4-3.4c-1.1-1.2-1.9-1.2-3.1-0.2
			C649.6,384.6,648.9,385.5,648.1,386.2z"
            />
            <path
              class="st0"
              d="M669.6,356.7c-0.3-1-0.9-1.6-2.2-2c-2.4,2.8-2.5,6.8-4.8,10c-0.2-0.9-0.3-1.5-0.4-2.1
			c-0.4-2.1-1.5-3.8-3.2-5.1c-0.4-0.3-0.9-0.5-1.2-0.9c-1.4-1.3-3.1-1.5-4.9-1.4c-0.8,0-1.6,0-1.9,1c-0.2,0.9,0,1.7,0.8,2.3
			c0.9,0.6,1.8,1.4,2.7,2c0.9,0.6,1.4,1.4,1.6,2.4c0.8,3.1,0.7,6.2,0.2,9.3c-0.8,5.2,2.5,8.5,7.6,9.7c1.7,0.4,3.1-0.4,4.6-0.8
			c1.9-0.5,3.1-1.8,4-3.5c1.1-2.1,1.5-4.3,1.4-6.7c0-2.2,0.6-4.2,1.8-6c1-1.4,2-2.7,3.1-4c0.7-0.8,1.2-1.7,1.2-3
			c-1.2-0.5-2.3-0.5-3.3,0.2c-1.2,0.8-2.3,1.8-3.3,2.8c-1,1-1.9,2.1-3.1,3.3c0-1.2-0.1-2.1,0-2.9
			C670.5,359.7,670.1,358.2,669.6,356.7z"
            />
            <path
              class="st0"
              d="M692.1,363c-1.1,2-2.5,3.7-3.2,5.9c-1.4,4.3-1.2,8.6-0.8,13.2c1.4,0.1,2.7,0.2,3.9,0.3
			c0.2-0.4,0.4-0.5,0.4-0.7c0.1-0.7,0.2-1.5,0.3-2.2c0.2-5.5,1.7-10.6,4.7-15.2c1.1-1.7,1.9-3.6,2.4-5.4c0.7-2.5,0.8-5.2-1.2-7.3
			c-3.1-3.4-6.5-4.3-10.4-2.1c-2.3,1.3-3.4,4.9-1.5,7.4c0.8,1.1,2.1,1.4,2.9-0.4c0.2-0.5,0.2-1.1,0.4-1.6c0.3-1.2,1-1.6,2-1
			c0.6,0.4,1.3,0.9,1.6,1.5c0.6,1.5,0.9,3.2,0.1,4.7C693.2,361,692.7,362,692.1,363z"
            />
            <path
              class="st0"
              d="M693.4,399.6c-1.7,0.1-3.1,0.2-4.7,0.3c0,5.7,0.1,11.3,0.1,17c0.4,0.1,0.7,0.2,0.9,0.3c3.5,1,4.3,0.4,4.2-3.3
			c-0.1-3.4-0.3-6.7-0.5-10.1C693.4,402.5,693.4,401.2,693.4,399.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M703.9,553.1c0,2.5,0,5,0,7.5c0,2.5-0.1,5-0.1,7.5c0,1,0.4,1.4,1.4,1.7c3,0.8,6.1,1.1,9.5,1.3
			c0.2-1.2,0.4-2.2,0.4-3.3c0-1.9-0.1-3.8,0-5.6c0-2.1,0.4-2.8,2.3-3c3.3-0.4,6.7-0.6,10-0.8c2.3-0.1,4.7,0.2,7,0
			c5.5-0.4,11.1-0.4,16.6,0.2c1.2,0.1,2.4,0.1,3.6,0.1c1.3,0.1,2.6,0.2,4,0.3c1,0.1,1.3,0.6,1.3,1.5c0,0.4,0,0.8,0,1.1
			c0.1,1.7,0.2,3.5,0.5,5.2c0.2,1.4,1.8,3,3,3c3,0,5.9-0.2,8.9-0.4c0.8,0,1.4-0.6,1.5-1.4c0.1-1.3,0.3-2.6,0.3-3.9
			c0-2.4-0.2-4.9-0.2-7.3c0-3.2-1.6-5.1-4.8-5.2c-3.9-0.1-7.8-0.1-11.7-0.1c-2.2,0-4.4,0.2-6.6,0.2c-3.8-0.1-7.6-0.3-11.3-0.3
			c-7.4,0-14.8-0.4-22.1-0.7c-3.2-0.1-6.3-0.2-9.5-0.3c-1.2,0-2.3,0.3-3.6,0.5C704.1,551.9,704,552.5,703.9,553.1z"
            />
            <path
              class="st0"
              d="M748,537c-0.4,0-0.7,0.1-1.1,0.2c-1.5,0.4-3,0.9-4.6,1.2c-3.1,0.7-6.1,0.2-9.1-0.8c-2.6-0.9-5.3-1.8-8.1-1.9
			c-2.3-0.1-4.5-0.4-6.8-0.7c-1.6-0.2-1.7-0.3-1.7-1.9c-0.1-1.1-0.1-2.1-0.3-3.2c-0.4-1.5-1.3-2.1-2.8-1.9c-1.3,0.1-2.6,0.2-3.9,0.4
			c-1.6,0.3-3.2,0.7-4.6,1.7c0,0.4,0,0.8,0.1,1.2c0.6,2.2,1.2,4.5,1.8,6.7c0.3,1,0.5,1.9,0.9,2.9c0.9,2.4,3.1,3.9,5.7,3.9
			c1.6,0,3.3-0.1,4.9-0.1c5.3-0.1,10.6-0.4,15.9-0.1c1.5,0.1,2.9,0.4,4.1,1.2c2,1.3,4.3,1.7,6.7,1.8c4.6,0.2,9-0.8,13.3-2.4
			c1.6-0.6,3.1-1.6,4.5-2.6c0.7-0.5,1.2-1.5,1.4-2.3c0.4-1.1-0.3-1.7-1.4-1.5c-0.9,0.2-1.9,0.4-2.7,0.9c-3.4,1.8-6.9,2.3-10.7,1.4
			c0.2-0.4,0.4-0.8,0.6-1.2C750.8,538,749.8,536.9,748,537z"
            />
            <path
              class="st0"
              d="M754.1,573.8c-1.1-2.8-3.6-4.3-6-5.6c-4.2-2.4-8.3-0.7-11.2,2.5c-2.2,2.5-3.7,5.7-3.6,9.3
			c0.1,3.9,1.6,7.1,5.1,9c1.7,0.9,3.6,1.1,5.5,1.1c4.7,0,8.6-2.5,10.1-6.8c0.6-1.8,1-3.8,1-5.7C755.1,576.4,754.6,575.1,754.1,573.8
			z M750.5,579.8c-0.5,2.9-2.9,5.3-6.5,5.2c-0.6,0-1.1,0-1.5,0c-2.6-0.2-4.3-1.9-4.5-4.5c-0.2-3.3,1.2-6,3.7-8.1c1.2-1,2.6-1,4-0.5
			c1.4,0.5,2.8,1.2,4,2.1c1.1,0.7,1.2,2,1.2,3.2C750.8,578,750.6,578.9,750.5,579.8z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M363.4,310c-0.1-4.1-0.6-8.2-1.5-12.2c-0.5-2.3-1.2-4.6-2.1-6.8c-0.2,0.3-0.5,0.6-0.8,0.9l-7.6,8.9
			c0,0,0,0,0,0c-0.8,2.6-1.4,5.3-1.8,7.9c2.5,1.8,2.8,1.8,5.4-0.1c-0.2,5.9-1,11.8-0.7,17.7c1.7,0.3,3.3,0.7,4.9,1
			c0.7,0.2,1.2-0.1,1.5-0.8c0.1-0.3,0.3-0.6,0.4-1C362.7,320.5,363.5,315.3,363.4,310z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M516.9,392.2c-0.1-4.5-0.7-9-1.6-13.4c-0.8-3.9-2.1-7.6-4.3-11c-1.4-2.1-3.2-4-4.9-5.9
			c-1.5-1.7-3-3.5-4.8-4.8c-1.6-1.1-2.4-2.4-3.1-4.1c-0.1-0.3-0.2-0.8-0.3-1.1c-0.3-0.6-0.8-1.2-1.4-2.2c-0.6,1.4-1,2.4-1.5,3.6
			c-0.9-1-1.6-1.7-2.3-2.5c-1.4,0.7-1.7,1.9-1.8,3c-0.3,2.7-1.8,4.7-3.7,6.6c-1.2,1.2-2.5,2.5-3.6,3.8c-0.4,0.5-0.6,1.1-1.1,1.9
			c1.3,0.4,2.3,0.7,3.4,1c-0.2,0.9-0.4,1.8-0.6,2.9c1.8,0.3,3.5,0.5,5.1-0.1c2.2-0.9,4.4-1.6,6.5-2.5c2-0.8,3.6-0.1,4.8,1.4
			c1.3,1.7,2.4,3.7,3.5,5.5c0.2,0.4,0.3,1,0.2,1.4c-0.4,1.8-0.9,3.5-1.4,5.3c-1,3.2-1.8,6.4-2.2,9.6c2.7,1.9,3.1,1.9,5.9-0.1
			c-0.2,6.5-1.1,12.9-0.8,19.4c1.9,0.4,3.6,0.7,5.4,1.1c0.8,0.2,1.4-0.1,1.7-0.9c0.1-0.4,0.3-0.7,0.4-1
			C516.2,403.7,517,398,516.9,392.2z M495,362.7c-0.8,0.1-1.5-0.4-1.5-1.1c0-0.6,0.9-1.5,1.5-1.5c0.6-0.1,1.2,0.6,1.2,1.4
			C496.2,362.3,495.7,362.6,495,362.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M362.2,660.1c-0.1-4.5-0.7-9-1.6-13.4c-0.8-3.9-2.1-7.6-4.3-11c-1.4-2.1-3.2-4-4.9-5.9
			c-1.5-1.7-3-3.5-4.8-4.8c-1.6-1.1-2.4-2.4-3.1-4.1c-0.1-0.3-0.2-0.8-0.3-1.1c-0.4-0.6-0.8-1.2-1.4-2.2c-0.6,1.4-1,2.4-1.5,3.6
			c-0.9-1-1.6-1.7-2.3-2.5c-1.4,0.7-1.7,1.9-1.8,3c-0.3,2.7-1.8,4.7-3.7,6.6c-1.2,1.2-2.5,2.5-3.6,3.8c-0.4,0.5-0.6,1.1-1,1.9
			c1.3,0.4,2.3,0.7,3.4,1c-0.2,0.9-0.4,1.8-0.6,2.9c1.9,0.3,3.5,0.5,5.1-0.1c2.2-0.9,4.4-1.6,6.5-2.5c2-0.8,3.6-0.1,4.8,1.4
			c1.3,1.7,2.4,3.7,3.5,5.5c0.2,0.4,0.3,1,0.2,1.4c-0.4,1.8-0.9,3.5-1.4,5.3c-1,3.2-1.8,6.4-2.2,9.6c2.7,1.9,3.1,1.9,5.9-0.1
			c-0.2,6.5-1.1,12.9-0.8,19.4c1.9,0.4,3.6,0.7,5.3,1.1c0.8,0.2,1.4-0.1,1.7-0.9c0.1-0.4,0.3-0.7,0.4-1
			C361.5,671.6,362.4,665.9,362.2,660.1z M340.3,630.6c-0.8,0.1-1.5-0.4-1.5-1.1c0-0.6,0.9-1.5,1.5-1.5c0.6-0.1,1.2,0.6,1.2,1.4
			C341.5,630.2,341,630.5,340.3,630.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M434.4,630.9c-4.3-2.5-8.9-4.4-13.8-5.7c-5.6-1.5-11.3-0.6-16.9-0.2c-0.1,0-0.3,0.1-0.4,0.1
			c-2.7,0.3-5.3,1-7.9,1.9c-2.3,0.8-4.6,1.6-7,2.4c-0.3,0.1-0.5,0.3-0.8,0.5c-1.3,0.9-1.4,1.4-0.2,2.3c1.4,1,2.8,1.9,4.2,2.8
			c1.7,1.1,3.3,2.4,5.1,3.1c3.3,1.4,6.7,2.7,10.3,2.7c1.5,0,3,0.1,4.5,0.1c3,0.1,5.9-0.1,8.8-0.9c2.7-0.8,5.3-1.7,7.9-2.4
			c1.9-0.5,3.6-1.5,5.2-2.6c0.9-0.6,1.6-1.6,2.5-2.6C435.4,631.7,434.9,631.2,434.4,630.9z M422.9,634.3c-4.6,1-9.2,1.5-13.9,1.2
			c-2.2-0.1-4.4-0.9-6.6-1.4c-0.8-0.2-1.6-0.4-2.4-0.7c-1.2-0.4-2.4-0.7-3.2-2.1c9.9-3.3,19.4-4,28.9,1.3
			C424.9,633.8,423.9,634.1,422.9,634.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M306.3,354.5c-0.2-0.1-0.4-0.2-0.6-0.3c-2.1,2.4-4.2,4.9-6.3,7.3c0.3-0.1,0.7-0.2,1-0.3
			c1.9-0.5,3.6-1.5,5.2-2.6c0.9-0.6,1.6-1.6,2.6-2.6C307.4,355.3,306.9,354.8,306.3,354.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M735.7,448.4c-4.1-2.4-8.4-4.2-13-5.4c-5.3-1.4-10.7-0.6-16-0.2c-0.1,0-0.2,0.1-0.3,0.1c-2.6,0.3-5,1-7.5,1.8
			c-2.2,0.8-4.4,1.5-6.6,2.3c-0.3,0.1-0.5,0.3-0.8,0.5c-1.2,0.8-1.3,1.3-0.1,2.2c1.3,1,2.6,1.8,4,2.7c1.6,1,3.1,2.2,4.9,3
			c3.1,1.4,6.3,2.6,9.8,2.6c1.4,0,2.9,0.1,4.3,0.1c2.8,0.1,5.6-0.1,8.4-0.8c2.5-0.7,5-1.6,7.5-2.3c1.8-0.5,3.4-1.4,4.9-2.5
			c0.8-0.6,1.5-1.5,2.4-2.4C736.7,449.2,736.2,448.8,735.7,448.4z M724.8,451.7c-4.3,0.9-8.7,1.4-13.2,1.2c-2.1-0.1-4.2-0.9-6.2-1.4
			c-0.8-0.2-1.5-0.4-2.2-0.6c-1.1-0.4-2.3-0.6-3.1-2c9.4-3.1,18.4-3.8,27.4,1.2C726.7,451.2,725.8,451.5,724.8,451.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M391,315.1c-0.8,0.8-1.5,1.4-2.3,2.1c-1.1,1.1-2.2,2.1-3.3,3.2c-0.7,0.7-1.2,0.8-1.8,0
			c-0.8-1-1.5-2.2-2.3-3.3c-0.3-0.4-0.4-0.9-0.7-1.4c-2.7,1.3-4.2,3.7-6.2,5.3c-1.5-1.3-2.9-2.5-4.2-3.7c1.5-5.1,4.6-9.2,7.7-13.5
			c2.5,0.9,3.8,3.6,6.4,4.7c0.2-0.1,0.3-0.3,0.5-0.4C387,310.3,389,312.7,391,315.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M378.4,675.5c2.1-1.7,3.9-4.4,6.9-5.9c0.3,0.6,0.5,1.2,0.8,1.6c0.8,1.3,1.6,2.6,2.5,3.7c0.7,0.9,1.2,0.8,2,0
			c1.2-1.2,2.4-2.4,3.6-3.6c1-0.9,2-1.9,3.2-3c1.2,1.6,2.3,2.9,3.3,4.4c1,1.5,2.2,2.8,3.9,3.7c2.9-2.1,4.9-5,7.3-7.8
			c3,2,4.3,5.6,7.5,7.4c2.6-1.9,3.9-4.8,6-7.2c1.4,1.3,2.6,2.6,4,3.8c1.4,1.2,1.8,3.4,3.6,4.2c3-1.7,3.9-5,6.3-7.3
			c1.7,1.9,3.3,3.6,5,5.4c1.7-1.9,3.2-3.5,4.7-5.2c0.7-0.7,0.8-1.5,0.1-2.3c-2.8-3.3-5.5-6.7-8.3-10c-0.3-0.4-0.8-0.7-1.2-1
			c-2.4,1.9-4.6,3.7-7,5.6c-0.7-0.8-1.4-1.5-2.1-2.2c-1.1-1.2-2.1-2.5-3.3-3.6c-0.9-0.9-1.3-0.9-2.3-0.1c-0.9,0.7-1.6,1.6-2.4,2.4
			c-1.3,1.3-2.5,2.7-4.1,3.6c-0.4-0.3-0.7-0.4-1-0.7c-1.3-1.4-2.6-2.8-3.9-4.1c-1.1-1.2-1.5-1.2-2.7-0.2c-1.2,1.1-2.3,2.2-3.4,3.3
			c-0.7,0.7-1.3,1.4-2,2c-1,0.8-1.5,0.8-2.4-0.1c-0.7-0.7-1.2-1.4-1.8-2.2c-1.2-1.6-2.9-2.7-4.3-3.9c-1.5,0.9-2.8,1.7-4,2.6
			c-1.2,0.9-2.3,1.9-3.3,2.8c-2.9-1.2-4.3-4.2-7.2-5.2c-3.5,4.8-6.9,9.4-8.6,15.1C375.2,672.6,376.7,674,378.4,675.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M583,447.5c-4.3-2.5-8.9-4.4-13.8-5.7c-5.6-1.5-11.3-0.6-16.9-0.2c-0.1,0-0.3,0.1-0.4,0.1
			c-2.7,0.3-5.3,1-7.9,1.9c-2.3,0.8-4.6,1.6-6.9,2.4c-0.3,0.1-0.5,0.3-0.8,0.5c-1.3,0.9-1.3,1.4-0.1,2.3c1.3,1,2.8,1.9,4.2,2.8
			c1.7,1.1,3.3,2.4,5.2,3.1c3.3,1.4,6.6,2.7,10.3,2.7c1.5,0,3,0.1,4.5,0.1c3,0.1,5.9-0.1,8.8-0.9c2.7-0.8,5.3-1.7,7.9-2.4
			c1.9-0.5,3.6-1.5,5.2-2.6c0.9-0.6,1.6-1.6,2.6-2.6C584.1,448.3,583.6,447.8,583,447.5z M571.5,450.9c-4.6,1-9.2,1.5-13.9,1.2
			c-2.2-0.1-4.4-0.9-6.6-1.4c-0.8-0.2-1.6-0.4-2.4-0.6c-1.2-0.4-2.4-0.7-3.2-2.1c9.9-3.3,19.4-4,28.9,1.3
			C573.6,450.3,572.6,450.7,571.5,450.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M527.1,492c2.1-1.7,3.9-4.4,6.9-5.9c0.3,0.6,0.5,1.2,0.8,1.6c0.8,1.3,1.6,2.6,2.5,3.7c0.7,0.9,1.2,0.8,2,0
			c1.2-1.2,2.4-2.4,3.6-3.6c1-0.9,2-1.9,3.2-3c1.2,1.6,2.3,2.9,3.3,4.4c1,1.5,2.2,2.8,3.9,3.7c2.9-2.1,4.9-5,7.3-7.8
			c3,2,4.3,5.6,7.5,7.4c2.6-1.9,3.9-4.8,6-7.2c1.4,1.3,2.6,2.6,4,3.8c1.4,1.2,1.8,3.4,3.6,4.2c3-1.7,3.9-5,6.3-7.3
			c1.7,1.9,3.3,3.6,5,5.4c1.7-1.9,3.2-3.5,4.7-5.2c0.7-0.7,0.8-1.5,0.1-2.3c-2.8-3.3-5.5-6.7-8.3-10c-0.3-0.4-0.8-0.7-1.2-1
			c-2.4,1.9-4.6,3.7-7,5.6c-0.7-0.8-1.4-1.5-2.1-2.2c-1.1-1.2-2.1-2.5-3.3-3.6c-0.9-0.9-1.3-0.9-2.3-0.1c-0.9,0.7-1.6,1.6-2.4,2.4
			c-1.3,1.3-2.5,2.7-4.1,3.6c-0.4-0.3-0.7-0.4-1-0.7c-1.3-1.4-2.6-2.8-3.9-4.1c-1.1-1.2-1.5-1.2-2.7-0.2c-1.2,1.1-2.3,2.2-3.4,3.3
			c-0.7,0.7-1.3,1.4-2,2c-1,0.8-1.5,0.8-2.4-0.1c-0.7-0.7-1.2-1.4-1.8-2.2c-1.2-1.6-2.9-2.7-4.3-3.9c-1.5,0.9-2.8,1.7-4,2.6
			c-1.2,0.9-2.3,1.9-3.3,2.8c-2.9-1.2-4.3-4.2-7.2-5.2c-3.5,4.8-6.9,9.4-8.6,15.1C523.9,489.2,525.4,490.5,527.1,492z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M522.7,576.7c3.6,0.9,7.4,0.9,11.1,1c7.8,0.3,15.5-0.2,23.2-1c3.1-0.3,6.1-1,9-2.1c1.9-0.7,2.8-2,2.9-3.9
			c0.2-3.3,0.4-6.6,0.5-9.9c0.1-1.1,0.4-2.3-0.2-3.4c-0.1-0.2-0.1-0.6-0.1-0.9c0.1-3.4,0.2-6.9,0.2-10.3c0-1.7-0.3-3.5-0.7-5.2
			c-0.1-0.6-0.8-1.3-1.3-1.4c-2.3-0.5-4.6-0.6-6.8,0.5c-1.1,0.5-1.6,1.3-1.6,2.5c-0.1,5.6-0.3,11.2-0.4,16.7c0,3.9-0.4,4.4-4.3,5.2
			c-0.2,0-0.4,0.1-0.6,0.1c-6.1,0.9-12.2,1.9-18.4,2.1c-4.4,0.2-8.8,0-13.2-1c-0.5-0.1-1-0.1-1.6-0.2c-0.1,0.5-0.2,0.8-0.2,1.2
			c0.2,2.8,0.5,5.6,0.8,8.4C521.3,576.1,521.8,576.5,522.7,576.7z"
            />
            <path
              class="st0"
              d="M531,524.7c-2.1-1.5-5-0.9-6.3,1.4c-0.7,1.2-1.5,1.5-2.7,1.4c-0.6-0.1-1.1-0.1-1.7-0.2
			c-3-0.4-6.1-0.8-9.2-1.2c-2.1-0.3-4.3-0.3-5.9-2.3c-1.3-1.6-4.3-0.9-5.3,1c-0.2,0.3-0.1,1,0.1,1.4c0.8,1.4,1.7,2.8,2.7,4.1
			c0.8,1,1.2,2.1,1.3,3.4c0,2.7,0.1,5.4,0.4,8.1c0.3,2.4,0.8,4.8,1.5,7.2c0.3,1,1,2,1.8,2.8c1.4,1.5,2.8,2.9,2.9,5.1
			c0,0.2,0.1,0.5,0.3,0.9c2.4-0.6,4.6,0.8,7.2,0.3c0.1-2.2,1.4-3.5,3-4.8c1.6-1.3,3.1-3,4.4-4.6c1.5-2,2.4-4.4,2.8-6.9
			c0.3-2.4,0.6-4.8,0.7-7.3c0.1-1.6,0.6-2.8,1.8-3.7c0.3-0.3,0.7-0.5,1-0.9C533.1,527.6,532.9,526.1,531,524.7z M512.9,544.3
			c1.6-0.9,4.5-0.9,6.3-0.1C517.5,547.1,514.6,547.2,512.9,544.3z M521.8,537.2c-0.1,0.5-0.2,0.9-0.4,1.4c-2.4,0-4.7,0.1-7,0.1
			c-0.9,0-1.8-0.1-2.6-0.1c-1.4-0.1-1.8-0.4-1.8-1.6c0-0.9,0.2-1.2,1.6-1.4c3.1-0.5,6.1-0.2,9.2,0.3
			C521.6,535.9,522,536.3,521.8,537.2z"
            />
            <path
              class="st0"
              d="M510.4,591.2c0.2,1.4,0.9,2.5,1.9,2.5c2.8,0,5-0.5,5.6-4c0.6-3.3,0.8-6.7,0.6-10.1c-0.2-4.1-0.4-8.1-0.6-12.2
			c-0.1-1.4,0-2.7,0-4.2c-2.4-0.5-2.7-0.4-2.9,1.6c-0.2,1.4-0.1,2.9-0.1,4.3c0,0.9,0.2,1.8-0.1,2.6c-0.5,1.4-0.3,2.7,0.1,4
			c0.5,1.4,0,2.5-1,3.4c-0.6,0.5-1.3,1-2.1,1.2c-1.2,0.3-1.7,1.1-1.8,2.1c-0.1,0.7-0.1,1.4,0,2.1c0.1,1.7,0.2,3.4,0.4,5.1
			C510.4,590,510.3,590.6,510.4,591.2z"
            />
            <path
              class="st0"
              d="M513.9,562.2c0-0.6-0.6-1.4-1.2-1.7c-1.1-0.7-2.3-1.1-3.4-1.6c-1.4-0.6-2.9,0-4.3,0.2c-0.3,0-0.7,0.4-0.8,0.7
			c-0.7,1.5-1.3,3.1-1.1,4.9c0.2,2.1,0.2,4.2,0.3,6.4c0.1,1.5,0.2,3,0.5,4.5c0.2,0.8,0.6,1.7,1.6,1.9c1.5,0.3,2.9,0.7,4.4,0.9
			c1.3,0.1,2.4-0.6,2.8-1.5c0.5-1.4,0.2-2.4-1.2-3.4c0.2-0.2,0.3-0.3,0.4-0.4c1.2-0.9,1.9-1.9,1.4-3.5c-0.3-1-0.2-2.1,0.3-3.2
			C514,565,514,563.5,513.9,562.2z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M601,568.3c1.4,1.2,3.7,1.3,5.4,0.4c1.1-0.6,2.3-1.2,3.5-1.8c3.9-1.8,7.7-3.7,11.6-5.5c3.1-1.4,4.6-4.2,5.9-7
			c0.5-1,0.3-2-0.5-2.7c-0.8-0.7-1.8-0.7-2.8-0.2c-0.5,0.3-1.1,0.6-1.6,1c-2.1,1.6-4.1,3.2-6.2,4.7c-1.1,0.8-2.3,1.4-3.5,2.1
			c-2.7,1.5-6,1.5-8.4,3.5c-0.5,0.4-1.2,0.7-1.7,1.1c-0.9,0.6-1.8,0.7-2.8,0.2c-1.4-0.6-2.2-1.5-2.7-2.9c-0.5-1.5-1-3-1.4-4.6
			c-0.3-1-0.7-2-1.9-2.3c-1.7,1.4-1.6,3.2-1.8,5c-0.2,2.1-0.4,2.2-2.3,2.8c-1.7,0.5-2,0.9-1.6,2.7c1,5.1,1.5,10.2,1.5,15.4
			c0,2.3,0.3,4.5,1.2,6.7c1.6,3.6,6.5,5.2,9.6,1c1.5-2.1,2.6-4.4,4-6.6c1.9-2.9,4.3-5.3,7-7.4c0.1,0.1,0.3,0.2,0.4,0.3
			c-0.2,0.6-0.3,1.3-0.6,1.9c-1.5,4-3.1,7.9-4.6,11.9c-0.6,1.6-0.3,2.4,1.2,3.1c1.3,0.6,2.6,1.3,4,1.8c2,0.8,4.1,1.3,6.3,0.6
			c1.4-0.4,1.8-1.2,1.1-2.4c-0.6-1-1.3-1.9-2.1-2.7c-1.5-1.5-1.7-2.3-0.7-4.2c1.4-2.8,2.6-5.7,4.2-8.4c1.5-2.7,0.1-7.2-3.4-8.2
			c-3.3-1-6.3-0.2-9,1.5c-2.9,1.8-5.7,3.9-8.5,5.8c-0.5,0.3-1.1,0.6-2,1c0.1-2.1,0.2-3.7,0.4-5.2c0.1-1.5-0.7-3.1,0-4.8
			C599.1,566.8,600.1,567.6,601,568.3z"
            />
            <path
              class="st0"
              d="M585.8,557.1c-0.4,2,0.5,2.7,2.8,2.9c2,0.1,2.3-0.1,2.3-2.4c0-2,0.9-3.5,1.7-5.1c0.6-1.2,1.6-1.2,2.4-0.2
			c0.6,0.7,1.1,1.5,1.4,2.3c0.9,2.1,1.7,4.2,2.6,6.3c0.8,1.9,1.4,2.1,3.4,1.2c0.3-0.1,0.5-0.3,0.8-0.5c3.6-2,3.7-2,2.5-6
			c-0.5-1.8-1.1-3.7-2.1-5.3c-1.3-2.1-3-4-4.6-6c-0.5-0.7-1-1.4-1.3-2.1c-0.6-1.4-0.3-2.3,1.1-2.9c0.9-0.4,1.9-0.7,2.8-0.9
			c1.6-0.3,3.1-0.6,4.7-0.8c1.5-0.2,2.6-0.9,3.4-2.1c1.1-1.4,2.3-2.7,3.2-4.2c0.7-1.2,1.2-2.7,1.2-4.1c0.1-1.6-1.8-2.8-3-2.1
			c-1.3,0.8-2.3,1.9-3.5,2.9c-0.4,0.3-0.7,0.7-1.3,1.2c-0.3-1.8-0.5-3.3-0.9-4.8c-0.2-0.9-0.6-1.7-1.1-2.5c-0.6-0.9-2.1-1.1-3.1-0.8
			c-1.1,0.4-0.8,1.3-0.9,2.1c-0.2,2.4-0.3,4.7-0.5,7.1c-0.1,0.5-0.4,1.3-0.8,1.5c-1.4,0.7-3.2,0-4.1-1.4c-1.3-1.9-2.4-3.9-3.9-5.6
			c-2.6-3-3.4-2.8-5.3,0.9c1.2,2.2,2.4,4.4,3.5,6.6c0.4,0.7,1,1.5,0.9,2.3c-0.2,4,0,8.1-1.8,11.9C586.8,550,586.4,553.6,585.8,557.1
			z"
            />
            <path
              class="st0"
              d="M615.5,540.6c-2.1-1.2-4.4-1.8-6.8-1.6c-2.2,0.1-4.4,0.5-6.6,0.7c-0.5,0.1-0.9,0.1-1.3,0.3
			c-1.4,0.6-1.9,1.8-1,3.1c0.5,0.7,1,1.3,1.7,1.9c1.6,1.4,2.9,3,3.6,4.9c0.5,1.3,1.4,1.7,2.7,1.7c0.5,0,1.1,0,1.2,0
			c1.4-0.1,2.4-0.2,3.4-0.3c2.6-0.3,4.1-1.9,5.1-4.1C618.7,544.4,618.1,542,615.5,540.6z M609.7,545.7c-0.9,0.1-1.7-0.5-1.7-2.1
			c1,0.1,1.7,0.2,3,0.3C610.4,544.8,610,545.6,609.7,545.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M281.8,392.3c0-1.6-0.4-2.1-2-2.4c-0.8-0.1-1.6-0.2-2.4-0.2c-3.2,0.1-3.3,0.2-3.6,3.4
			c-0.1,1.4-0.1,2.9-0.2,4.3c-0.1,2.3-0.3,4.5-0.3,6.8c0,3,1,4,4,4.2c0.3,0,0.5,0,0.8,0c2.3,0.1,4.7,0.2,7.7,0.3
			c3.3,0,7.2-0.1,11.2-0.1c0.9,0,1.8,0,2.6-0.1c1.4-0.2,1.7-0.5,2-1.8c0.2-0.9,0.3-1.7,0.3-2.6c0.2-2.4-0.1-2.8-2.5-3.3
			c-0.4-0.1-0.8-0.1-1.1-0.1c-3.8-0.2-7.6-0.4-11.3-0.7c-1.3-0.1-2.5-0.1-3.8-0.3c-1.4-0.2-1.7-0.5-1.7-1.9c0-0.5,0-1,0.1-1.5
			C281.6,394.9,281.7,393.6,281.8,392.3z"
            />
            <path
              class="st0"
              d="M294.6,370.1c-1.8,0.3-2.6,2-3.9,2.7c-1.5,0-2.7-1.6-4.4-1c0.5,1.4,0.9,2.7,1.3,4c0.8,2.5,1,5.2,0.9,7.8
			c-0.1,2.2-0.4,4.4-0.5,6.6c-0.1,1.5,0.2,3,0.9,4.4c0.8,1.5,2.4,2.3,4.1,2.1c0.7-0.1,1.6-0.1,2.2-0.5c1.2-0.8,2.5-1.6,3.3-2.7
			c0.5-0.7,0.6-2,0.4-2.9c-0.4-1.8-0.9-3.7-1.8-5.3c-1.3-2.5-2.2-5.2-2.7-8c-0.2-1.3-0.2-2.6,0.1-3.9
			C294.8,372.4,295.2,371.4,294.6,370.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M705.1,484.3c0-1.5-0.4-2-1.9-2.3c-0.8-0.1-1.5-0.2-2.3-0.2c-3,0.1-3.2,0.2-3.4,3.2c-0.1,1.4-0.1,2.7-0.1,4.1
			c-0.1,2.1-0.3,4.3-0.3,6.4c0,2.9,1,3.8,3.8,4c0.2,0,0.5,0,0.7,0c2.2,0.1,4.4,0.2,7.3,0.3c3.1,0,6.8-0.1,10.6-0.1
			c0.8,0,1.7,0,2.5-0.1c1.3-0.2,1.6-0.5,1.9-1.7c0.2-0.8,0.2-1.6,0.3-2.5c0.2-2.3-0.1-2.7-2.4-3.2c-0.3-0.1-0.7-0.1-1.1-0.1
			c-3.6-0.2-7.2-0.4-10.7-0.6c-1.2-0.1-2.4-0.1-3.6-0.3c-1.4-0.2-1.6-0.5-1.6-1.8c0-0.5,0-1,0-1.4
			C704.9,486.8,705,485.5,705.1,484.3z"
            />
            <path
              class="st0"
              d="M717.3,463.3c-1.7,0.3-2.4,1.9-3.7,2.5c-1.5,0-2.6-1.5-4.2-1c0.4,1.3,0.8,2.6,1.2,3.8c0.7,2.4,1,4.9,0.9,7.4
			c-0.1,2.1-0.3,4.1-0.5,6.2c-0.1,1.5,0.2,2.8,0.8,4.1c0.8,1.4,2.3,2.2,3.9,2c0.7-0.1,1.5-0.1,2.1-0.4c1.1-0.7,2.4-1.5,3.2-2.5
			c0.5-0.6,0.5-1.9,0.3-2.7c-0.4-1.7-0.9-3.5-1.7-5c-1.2-2.4-2-4.9-2.5-7.5c-0.2-1.2-0.2-2.4,0.1-3.7
			C717.4,465.5,717.8,464.4,717.3,463.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M358,377c-1.3-0.2-2.6-0.2-3.9-0.4c-3.7-0.5-7.4-0.7-11-1.8c-2.2-0.6-2.5-0.8-2.6-3c-0.2-4-0.2-8-0.3-12
			c-0.1-2.1-0.3-4.2-0.4-6.4c0-0.6-0.1-1.3-1.2-1.5c-3.2,6.2-5,13.1-8.2,19.6c-2.4-0.1-4.6-1-6.8-1.8c-3.5-1.4-6.9-2.7-10.4-3.9
			c-1.5-0.5-3-0.9-4.6,0c0.3,1.5,2,4.4,3.4,5.7c2.9,2.8,5.7,5.6,8.6,8.4c0.7,0.6,1.2,1.4,1.9,2.2c-0.4,0.5-0.8,0.9-1.1,1.4
			c-2.8,3.2-5.7,6.3-8.4,9.6c-1,1.2-1.6,2.7-2.3,4.1c-0.2,0.4-0.2,1-0.3,1.8c8.3-1.2,15-5.4,21.9-9.2c0.5,0.6,1,1.3,1.5,1.9
			c2.4,3.3,4.3,6.9,5.9,10.6c0.6,1.5,1.5,2.7,2.9,3.6c1.4,0.9,2.9,0.2,2.9-1.5c0-1.1,0-2.1-0.2-3.2c-0.8-3.4-1.6-6.8-2.4-10.2
			c-0.5-2.2-0.9-4.4-1.4-6.6c0.5-0.2,0.7-0.3,0.9-0.3c4.8-0.7,9.4-2.5,14-4c1.3-0.4,2.6-1.2,3.5-2.6
			C359.2,377.3,358.6,377.1,358,377z M337.8,383.7c-1.4,2-3.5,2-5.5,1.1c-2.2-1-3-2.9-3.5-5.1c-0.4-1.8,0.6-3.7,2.4-4.4
			c0.9-0.4,1.9-0.5,2.9-0.7c2.8,0.1,4.5,1.4,4.8,4.1C339.2,380.5,338.9,382.2,337.8,383.7z"
            />
            <path
              class="st0"
              d="M334.3,382.1c1.2,0,2.1-1.4,2.1-3.3c0-1.6-0.7-2.2-2.4-2.2c-1.4,0-2.9,1.5-2.9,2.8
			C331.2,380.6,332.9,382.2,334.3,382.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M629.1,648.6c-1.3-0.2-2.6-0.2-3.9-0.4c-3.7-0.5-7.4-0.7-11-1.8c-2.2-0.6-2.5-0.8-2.6-3c-0.2-4-0.2-8-0.3-12
			c-0.1-2.1-0.2-4.2-0.4-6.4c0-0.6-0.1-1.3-1.2-1.5c-3.2,6.2-5,13.1-8.2,19.6c-2.4-0.1-4.6-1-6.8-1.8c-3.5-1.4-6.9-2.7-10.4-3.9
			c-1.5-0.5-3-0.9-4.6,0c0.3,1.5,2,4.4,3.4,5.7c2.9,2.8,5.7,5.6,8.6,8.4c0.7,0.7,1.2,1.4,1.9,2.2c-0.4,0.5-0.8,0.9-1.1,1.4
			c-2.8,3.2-5.7,6.3-8.4,9.6c-1,1.2-1.6,2.7-2.3,4.1c-0.2,0.4-0.2,1-0.3,1.8c8.3-1.2,15-5.4,21.9-9.2c0.5,0.6,1,1.3,1.5,1.9
			c2.4,3.3,4.3,6.9,6,10.7c0.6,1.5,1.5,2.7,2.9,3.6c1.4,0.9,2.9,0.2,2.9-1.5c0-1.1,0-2.1-0.2-3.2c-0.8-3.4-1.6-6.8-2.4-10.2
			c-0.5-2.2-0.9-4.4-1.4-6.6c0.5-0.2,0.7-0.3,0.9-0.3c4.8-0.7,9.4-2.5,14-4c1.3-0.4,2.6-1.2,3.5-2.6
			C630.3,648.9,629.7,648.6,629.1,648.6z M608.9,655.3c-1.4,2-3.5,2-5.5,1.1c-2.2-1-3-2.9-3.5-5.1c-0.4-1.8,0.6-3.7,2.4-4.4
			c0.9-0.4,1.9-0.5,2.9-0.7c2.8,0.1,4.5,1.4,4.8,4.1C610.3,652.1,610,653.8,608.9,655.3z"
            />
            <path
              class="st0"
              d="M605.4,653.7c1.2,0,2.1-1.4,2.1-3.3c0-1.6-0.7-2.2-2.4-2.2c-1.4,0-2.9,1.5-2.9,2.8
			C602.3,652.2,604,653.8,605.4,653.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M426.2,373c0-1,0-2,0.1-3c0.1-1.3,0-1.5-1.3-1.7c-0.6-0.1-1.2-0.2-1.9-0.2c-0.2-1.6-0.3-3.1-0.5-4.6
			c-0.2-1.6-0.8-2.2-2.4-2.3c-1-0.1-1.5,0.5-1.7,2.2c-0.1,0.9-0.1,1.8-0.2,2.6c-0.1,0.6-0.2,1.2-0.3,1.9c-1.2-0.1-2.2-0.1-3.2-0.2
			c-1.3-0.2-1.4-0.3-1.6-1.6c-0.1-1.1-0.2-2.1-0.3-3.2c-0.2-1.3-1-2.2-1.9-2.3c-1.3-0.1-2.1,0.3-2.6,1.7c-0.2,0.6-0.4,1.2-0.5,1.8
			c-0.2,1.4-0.3,2.8-0.4,4.3c-1.5,0-2.8,0-4.2,0c-1.1,0-1.6-0.6-1.7-1.6c-0.1-1.1-0.3-2.2-0.3-3.4c0-1.3-0.6-1.8-1.8-1.9
			c-1.7-0.2-2.5,0.2-2.8,1.5c-0.3,1-0.5,2.1-0.6,3.1c-0.1,0.8-0.5,1.3-1.3,1.3c-1.2,0-2.4-0.1-3.7-0.2c-0.1-0.8-0.2-1.6-0.3-2.3
			c-0.1-0.9-0.2-1.9-0.5-2.8c-0.4-1.2-1.6-1.8-2.9-1.5c-1.2,0.3-1.8,1.1-2,2.3c-0.2,1.4-0.2,2.7-0.3,4.3c-0.3,0.1-0.8,0.3-1.2,0.4
			c-3.4,0.6-6.8,0-10.1-0.7c-1-0.2-1.9-1-2.7-1.7c-1.3-1.3-1.4-2.9-0.3-4.4c0.4-0.6,0.8-1.1,1.2-1.7c0.6-1,1-2,0.6-3.2
			c-1.4-0.7-2.7-0.4-3.8,0.5c-1.4,1.2-2.6,2.5-3.9,3.8c-1.3,1.4-1.5,3.2-1.1,4.9c0.2,1.1,0.7,2.1,1.2,3.1c1.6,2.9,3.9,4.9,7.3,5.4
			c3.2,0.5,6.4,0.7,9.6,1c1.6,0.1,3.3,0,4.9,0c2.6-0.1,5.2-0.3,7.7-0.4c3.5,0,7.1,0.5,10.6,0.5c3.8,0,7.5,0.9,11.2,0.6
			c2.6-0.2,5.3-0.1,7.9-0.2C425.4,375.5,426.2,374.4,426.2,373z"
            />
            <path
              class="st0"
              d="M414.6,400c-0.6-3.3-1-6.6-1.1-9.9c-0.1-2.4-0.4-4.6-1.3-6.8c-0.3-0.6-0.7-1.1-1.2-1.9
			c-1.4,1.6-2.2,3.2-2.8,4.9c-1.1,2.7-2.2,5.5-3.3,8.2c-0.5,1.3-1.2,2.5-1.8,3.7c-0.7,1.5-1.9,2-3.6,1.7c-2.3-0.3-4.6-0.6-6.9-0.8
			c-3.3-0.4-6.5-0.1-9.8,0.2c-1.8,0.1-3.5,0.3-5.3,0.3c-2.3,0-2.9-0.5-3.2-2.7c-0.1-0.6-0.1-1.2-0.3-1.9c-0.5-2.3-0.9-4.7-1.5-7
			c-0.4-1.4-1.5-2-3.1-2c-1.1,0-1.6,0.4-2.2,2c-0.1,0.4-0.3,0.8-0.3,1.3c-0.1,4.9-0.3,9.8,0.1,14.6c0.2,2,0.6,2.5,2.7,2.6
			c1.6,0.1,3.1-0.1,4.7-0.1c3.2-0.1,6.4-0.2,9.6-0.2c2.4,0,4.8,0.2,7.2,0.2c2,0,4-0.2,6-0.2c2.6-0.1,5,0.6,7.1,2.1
			c0.9,0.6,1.9,1.3,2.8,1.9c1.4,1,2.8,1.4,4.6,0.9c2.3-0.8,4.1-2.2,5.7-3.8c1.5-1.4,1.5-2.5,0.1-4c-0.3-0.4-0.9-0.5-1.2-0.9
			C415.8,401.6,414.8,400.9,414.6,400z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M254.5,451.8c-0.2,1.6-0.1,3.2-0.1,4.9c-0.1,1.4-0.2,1.6-1.7,1.8c-1.7,0.2-3.4,0.3-5.1,0.3
			c-1.6,0.1-3.1,0-4.7,0v0.1c-3.4-0.1-6.8-0.2-10.2-0.3c-3-0.1-5.9,0-8.9-0.1c-2.4,0-4.7-0.1-7.1-0.2c2.2-2.6,4.5-5.2,6.7-7.8
			c0.5,0.1,1,0.2,1.5,0.3c1.6,0.3,3.2,0.4,4.9,0.3c3.3-0.2,6.7-0.3,10-0.5c1.3-0.1,2.6-0.1,4-0.3c0.5-0.1,1.1-0.4,1.4-0.8
			c1-1.3,2-2.6,3-3.9c1.2-1.6,2.4-3.2,3.7-4.7c1-1.1,1.5-1,2.2,0.3c0.3,0.5,0.5,1.2,0.5,1.8C254.9,445.9,254.9,448.8,254.5,451.8z"
            />
            <path
              class="st0"
              d="M254.9,483.6c-0.1,2.8-1,4.1-3.7,4.7c-1.8,0.4-3.7,0.6-5.6,0.6c-7.9,0.1-15.8,0.1-23.7,0.2
			c-7.9,0.1-15.9,0.2-23.8,0.2c-1.8,0-3.6-0.4-5.4-0.7c-0.6-0.1-1-0.3-1.3-0.7c3.7-4.3,7.4-8.7,11.1-13c0,0.4,0,0.8,0,1.2
			c0,0.4,0.1,0.9,0.2,1.4c0.7,0.1,1.3,0.2,1.9,0.2c2.4,0.1,4.8,0.2,7.2,0.2c1.9,0,2.4-0.6,2.6-2.4c0-0.3,0.1-0.6,0.1-0.9
			c0-1.4,0.8-2.2,2-2.7c2.7-1,5.5-1.3,8.4-1.2c1.5,0,2.1,0.6,2.5,2.1c0.3,1.1,0.4,2.2,0.5,3.3c0.1,0.9,0.4,1.4,1.4,1.3
			c4.4-0.1,8.8-0.1,13.3-0.1c0.4-1,0.8-1.9,1.1-2.7c0.7-2.3,2.8-3.8,5.2-3.8c1.1,0,2.3,0.2,3.4,0.3c1,0.1,1.5,0.8,1.7,1.6
			c0.4,1.5,0.8,3,0.9,4.6C255,479.3,254.9,481.5,254.9,483.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M523.4,626.4c0-0.6-0.3-1.2-0.5-1.8c-0.7-1.4-1.2-1.5-2.2-0.3c-1.3,1.5-2.5,3.1-3.7,4.7c-1,1.3-1.9,2.6-3,3.9
			c-0.3,0.4-0.9,0.7-1.4,0.8c-1.3,0.2-2.6,0.2-4,0.3c-3.3,0.2-6.7,0.3-10,0.5c-1.6,0.1-3.2,0-4.9-0.3c-2.5-0.5-5-0.6-7.5-0.8
			c-1.8-0.1-3.5-0.1-5.3-0.3c-2-0.1-4-0.4-6-0.5c-1.8-0.1-3.7-0.2-5.5-0.2c-0.9,0-1.6-0.3-2-1.1c-0.6-1.2-1.2-2.4-1.5-3.6
			c-0.6-2.4-1.6-4.5-2.8-6.5c-0.4-0.8-0.9-1.7-2-1.4c-1,0.2-1,1.2-1.1,2c-0.2,2.2-0.4,4.5-0.4,6.7c-0.1,3.5-0.1,7-0.2,10.5
			c0,0.2,0,0.4,0.1,0.6c0.3,0.5,0.6,1.2,1,1.5c1.3,0.7,2.7,1.3,4.3,1.2c3.2-0.2,6.4-0.3,9.6-0.4c3.1-0.1,6.3,0,9.5,0.1
			c2.9,0.1,5.8,0.2,8.7,0.3c3,0.1,5.9,0,8.9,0.1c3.4,0.1,6.8,0.2,10.2,0.3c0,0,0-0.1,0-0.1c1.6,0,3.2,0,4.7-0.1
			c1.7-0.1,3.4-0.1,5.1-0.3c1.5-0.2,1.6-0.3,1.7-1.7c0.1-1.6-0.1-3.3,0.1-4.9C523.6,632.3,523.6,629.3,523.4,626.4z"
            />
            <path
              class="st0"
              d="M459.7,670.6c0.4,0.8,0.9,1.3,1.8,1.5c1.8,0.3,3.6,0.7,5.4,0.7c7.9,0,15.9-0.1,23.8-0.2
			c7.9-0.1,15.8-0.1,23.7-0.2c1.9,0,3.8-0.2,5.6-0.6c2.7-0.7,3.6-1.9,3.7-4.7c0.1-2.1,0.1-4.3,0-6.4c-0.1-1.5-0.5-3.1-0.9-4.6
			c-0.2-0.8-0.7-1.5-1.7-1.6c-1.1-0.1-2.3-0.2-3.4-0.3c-2.4-0.1-4.5,1.5-5.2,3.8c-0.3,0.9-0.7,1.7-1.1,2.7c-4.5,0-8.9,0.1-13.3,0.1
			c-1,0-1.3-0.4-1.4-1.3c-0.1-1.1-0.2-2.2-0.5-3.3c-0.4-1.5-1-2.1-2.5-2.1c-2.8,0-5.7,0.2-8.4,1.2c-1.2,0.4-2,1.3-2,2.7
			c0,0.3,0,0.6-0.1,0.9c-0.2,1.9-0.8,2.4-2.6,2.4c-2.4,0-4.8-0.1-7.2-0.2c-0.6,0-1.2-0.1-1.9-0.2c-0.1-0.6-0.2-1-0.2-1.4
			c0-1.4,0.1-2.9,0-4.3c0-1.3-0.3-1.5-1.6-1.4c-1.4,0.1-2.8,0.1-4.2,0.1c-1.3,0-2.5,0-3.8,0.2c-2.7,0.4-3.6,1.5-3.5,4.2
			c0,1.9,0.3,3.9,0.2,5.8C458.1,666.5,458.6,668.6,459.7,670.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M268.3,481.3c0.4,3.1,1.4,3.9,3.8,4.1c1.4,0.1,2.9,0.1,4.3,0.1c2.2,0,4.4,0,6.6,0c0.8,0,1.5,0.2,2.3,0.1
			c2.9-0.4,5.8-0.2,8.7,0.1c1.7,0.1,3.4,0.2,5.1,0c3.6-0.4,3.6-0.5,3.9-4.1c0.6-7,0.7-14,1-21c0.1-2.6-0.5-3.4-3.1-3.7
			c-3.3-0.4-6.6-0.6-10-0.9c-0.3,0-0.5,0-0.8,0c-0.9,0-2,0.1-2.2,1.1c-0.3,1.2-0.2,2.5-0.3,3.7c0,0.2,0.5,0.4,0.8,0.5
			c0.6,0.2,1.2,0.3,1.8,0.4c1.3,0.3,2.6,0.6,3.8,0.9c0.1,0.5,0.2,0.8,0.2,1.1c-0.3,3.7-0.7,7.4-1,11c-0.1,0.8-0.5,1.2-1.4,1.3
			c-0.6,0-1.3,0.1-1.9,0c-3.2-0.2-6.4-0.4-9.6-0.6c-2-0.1-2.1-0.1-2.2-2.2c-0.1-2.8-0.1-5.6-0.1-8.5c0-0.8,0.2-1.7,0.4-2.6
			c0.8,0,1.3,0,1.9,0c1.9,0,2.3-0.4,2.5-2.3c0.3-2.9-0.4-3.7-3.3-3.4c-3.3,0.2-6.5,0.5-10.1,0.8c-0.4,2.1-1.3,4.1-1.2,6.4
			c0.1,3.4,0.1,6.8,0.1,10.2C268.1,476.1,268,478.7,268.3,481.3z"
            />
          </g>
          <g>
            <g>
              <path
                class="st0"
                d="M606.4,321.9c0.4,3.1,1.4,3.9,3.8,4.1c1.4,0.1,2.9,0.1,4.3,0.1c2.2,0,4.4,0,6.6,0c0.8,0,1.5,0.2,2.3,0.1
				c2.9-0.4,5.8-0.2,8.7,0.1c1.7,0.1,3.4,0.2,5.1,0c3.6-0.4,3.6-0.5,3.9-4.1c0.6-7,0.7-14,1-21c0.1-2.6-0.5-3.4-3.1-3.7
				c-3.3-0.4-6.6-0.6-10-0.9c-0.3,0-0.5,0-0.8,0c-0.9,0-2,0.1-2.2,1.1c-0.3,1.2-0.2,2.5-0.3,3.7c0,0.2,0.5,0.4,0.8,0.5
				c0.6,0.2,1.2,0.3,1.8,0.4c1.3,0.3,2.6,0.6,3.8,0.9c0.1,0.5,0.2,0.8,0.2,1.1c-0.3,3.7-0.7,7.4-1,11c-0.1,0.8-0.5,1.2-1.4,1.3
				c-0.6,0-1.3,0.1-1.9,0c-3.2-0.2-6.4-0.4-9.6-0.6c-2-0.1-2.1-0.1-2.2-2.2c-0.1-2.8-0.1-5.6-0.1-8.5c0-0.8,0.2-1.7,0.4-2.6
				c0.8,0,1.3,0,1.9,0c1.9,0,2.3-0.4,2.5-2.3c0.3-2.9-0.4-3.7-3.3-3.4c-3.3,0.2-6.5,0.5-10.1,0.8c-0.4,2.1-1.3,4.1-1.2,6.4
				c0.1,3.4,0.1,6.8,0.1,10.2C606.3,316.8,606.1,319.3,606.4,321.9z"
              />
            </g>
            <path
              class="st0"
              d="M634,274.5c-1.1-2.8-3.6-4.3-6.1-5.6c-4.2-2.4-8.3-0.7-11.2,2.5c-2.2,2.5-3.7,5.7-3.6,9.3
			c0.1,3.8,1.6,7.1,5.1,9c1.7,0.9,3.6,1.1,5.5,1.1c4.7-0.1,8.6-2.5,10.1-6.8c0.6-1.8,1-3.8,1-5.7C635,277.1,634.5,275.8,634,274.5z
			 M630.4,280.5c-0.5,2.9-2.9,5.3-6.5,5.2c-0.6,0-1.1,0-1.5,0c-2.6-0.2-4.3-1.9-4.5-4.5c-0.2-3.3,1.2-6,3.7-8.1c1.2-1,2.6-1,4-0.5
			c1.4,0.5,2.8,1.2,4,2.1c1.1,0.7,1.2,2,1.2,3.2C630.7,278.7,630.6,279.6,630.4,280.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M376.6,443.4L376.6,443.4c0-0.3,0.1-0.6,0.1-0.8c0-0.3,0-0.6,0-0.8c0-0.2,0-0.4-0.1-0.6c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.2-0.4c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.3-0.7-0.3
			c0-0.7-0.1-1.1-0.7-0.9c-1.1,0.4-2.3,0.8-3.2,1.5c-1.6,1-3,2.3-4.6,3.3c-1,0.6-1.3,1.4-1.5,2.4c-0.5,2.4-1,4.8-1.6,7.2
			c-0.6,2.7-0.6,2.8,1.7,4.3c0.2,0.1,0.4,0.2,0.7,0.3c0.7-0.9,1.3-1.7,1.9-2.5c0.3,1,0.2,1.9,0.1,2.8c-0.8,6.1-1.2,12.2-1,18.4
			c0,1.1,0,2.1-0.1,3.2c-0.2,2-0.5,2.2-2.5,2c-1.1-0.1-2.1-0.2-3.2-0.1c-1.6,0.1-3.1,0.3-4.7,0.3c-1.4,0-2.9-0.1-4.6-0.1
			c-0.1-1.8-0.3-3.4-0.3-5c0.1-3.7,0.3-7.4,0.4-11.1c0-0.7,0.2-1.4,0.2-2.2c0.5-0.1,0.8-0.1,1.1-0.1c1.1,0,2.1,0.1,3.2,0
			c1.5,0,1.8-0.2,2-1.7c0.2-1.2,0.4-2.4,0.4-3.5c0-1.7-0.2-1.9-2-2c-1.2,0-2.5-0.1-3.8-0.1c0.1-0.5,0.1-0.8,0.2-1.1
			c1.6-4.1,1.8-8.3,1-12.6c-0.5-2.7-2.9-5-4.9-5.3c-2.6-0.4-5.4,0.8-6.8,3.1c-0.9,1.5-1.5,3.2-1.5,5c0,3.9,0.1,7.7,2,11.2
			c0.1,0.2,0.1,0.5,0.2,0.8c-2.5-0.1-4.9-0.2-7.5-0.3c-0.1,1.9-0.2,3.6-0.4,5.3c2.4,0.4,4.7,0.7,6.9,1.1c1.8,0.3,1.8,0.4,1.8,2.2
			c-0.1,3.6-0.1,7.3-0.2,10.9c0,1.5,0,3-0.1,4.5c0,0.9-0.6,1.5-1.6,1.5c-1.6,0-3.2,0.1-4.7,0c-2.9-0.3-5.8-0.7-8.8-1
			c-1.7-0.2-2.5-1-2.7-2.7c-0.2-2.3-0.4-4.5-0.6-6.8c-0.3-5.4-0.6-10.9-0.9-16.3c-0.1-1.9-0.2-3.7-0.3-5.6c0-0.6,0.2-1.3,0.3-1.9
			c1.6,0.3,2.2,1,2.2,2.4c0,1.3,0.2,2.6,0.3,3.9c0.1,0.8,0.4,1.4,1.2,1.8c0.8,0.4,1.4-0.2,1.8-0.6c0.5-0.5,0.9-1.3,1-1.9
			c0.1-2,0.1-4,0-6c-0.1-1.9-0.8-3.6-2.3-5c-0.9-0.8-1.7-1.9-2.5-2.8c-0.8-0.8-1.5-1.6-2.4-2.2c-1.7-1.2-3.4-0.5-4,1.4
			c-0.3,0.9-0.4,1.8-0.5,2.8c-0.2,4.5-0.4,9-0.5,13.5c-0.3,8.8,0.2,17.6,0.4,26.3c0,2,0.9,3.5,2.4,4.7c0.3,0.3,0.7,0.6,1,0.8
			c2.4,1.8,4.9,3.5,7.7,4.7c1.2,0.5,2.4,0.9,3.5,1.5c3.5,1.9,7.2,3.2,11.3,3.4c1.3,0.1,2.6,0.1,3.9,0c2.7-0.4,5.3-0.9,8-1.4
			c0.9-0.2,1.7-0.4,2.5-0.7c3.5-1.3,6.7-3.1,9.7-5.3c1.8-1.3,3-3.1,3.4-5.2c0.3-1.9,0.3-3.9,0.4-5.8c0-2.3-0.1-4.5-0.1-6.8
			c0.1-4.1,0.2-8.3,0.3-12.4c0.1-3.3,0.3-6.6,1.7-9.8c0.6-1.4,1.2-2.9,1.8-4.3C377.6,444.2,377.1,443.8,376.6,443.4z M347.7,453.6
			c-0.5-0.9-1-1.5-1.1-2.2c-0.3-2.3-0.6-4.6,0.2-6.9c0.2-0.7,0.7-1.4,1.1-1.9c0.6-0.7,1.3-0.6,1.8,0.3c0.1,0.3,0.3,0.6,0.3,0.9
			C350.1,447.2,349.5,450.4,347.7,453.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M431.2,480.6c-0.2-0.8-0.4-1.5-0.7-2.2c-0.5-1.1-1.2-2.2-1.7-3.3c-1.7-3.4-3.3-6.9-5.3-10
			c-2.6-4.2-5-8.6-8.2-12.2c-1.9-2.1-3.4-4.8-6.2-5.5c-0.3-0.1-0.6-0.7-0.7-1.1c-0.2-0.8-0.2-1.6-0.2-2.4c0.1-2.1-0.7-3.6-2.3-4.3
			c-2.1-0.9-4.2-1.4-6.3-2.1c-0.8-0.2-1.4,0.2-1.8,0.9c-1.4,2.5-2.9,5.1-4.4,7.8c0.4,0.2,0.6,0.3,0.8,0.4c2.3,0.6,2.3,0.6,2.6,3.3
			c0.4,2.8,1.3,5.3,4.1,6.1c0.2,0,0.3,0.3,0.6,0.6c-0.2,1-0.5,2.1-0.7,3.3c-0.5,2.5-1.1,4.9-1.3,7.5c-0.2,3.1,1,5.9,2.3,8.6
			c1.2,2.4,2.5,4.8,3.7,7.2c-0.5,1.1-1.4,1.3-2.2,1.5c-0.7,0.2-1.5,0.4-2.2,0.5c-1.2,0.2-1.8,1.3-2.4,2.3c-0.7,1.4,0.1,2.9,1.7,3.3
			c3.4,0.7,6.9,0.6,10.4,0.3c1.1-0.1,2-0.6,2.4-1.9c0.5-1.6,1-3.3,1.4-4.9c0.2-0.9,0.4-1.9,0.6-3.3c0.6,1.1,1,1.6,1.3,2.2
			c0.9,1.6,1.9,3.1,2.7,4.7c0.9,1.5,1.7,3.1,2.5,4.6c0.3,0.5,0.4,1,0.7,1.5c1.3,2.1,3,2.5,5,1.2c1.4-0.9,2-2.7,1.7-4.7
			c-0.3-1.7-0.6-3.5-1-5.2C430.8,484.9,431.8,483.3,431.2,480.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M718.3,275.7L718.3,275.7c0-0.3,0.1-0.6,0.1-0.8c0-0.3,0-0.6,0-0.8c0-0.2,0-0.4-0.1-0.6c0-0.1,0-0.2,0-0.2
			c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.3-0.2-0.4c0,0,0,0-0.1-0.1c-0.2-0.2-0.4-0.3-0.7-0.3
			c0-0.7-0.1-1.1-0.7-0.9c-1.1,0.4-2.3,0.8-3.2,1.5c-1.6,1-3,2.3-4.6,3.3c-1,0.6-1.3,1.4-1.5,2.4c-0.5,2.4-1,4.8-1.6,7.1
			c-0.6,2.7-0.6,2.8,1.7,4.3c0.2,0.1,0.4,0.2,0.7,0.3c0.7-0.9,1.3-1.7,1.9-2.5c0.3,1,0.2,1.9,0.1,2.8c-0.8,6.1-1.2,12.2-1,18.4
			c0,1.1,0,2.1-0.1,3.2c-0.2,2-0.5,2.2-2.5,2c-1.1-0.1-2.1-0.2-3.2-0.1c-1.6,0.1-3.1,0.3-4.7,0.3c-1.4,0-2.9-0.1-4.6-0.1
			c-0.1-1.8-0.3-3.4-0.3-4.9c0.1-3.7,0.3-7.4,0.4-11.1c0-0.7,0.2-1.5,0.2-2.2c0.5-0.1,0.8-0.1,1.1-0.1c1.1,0,2.1,0.1,3.2,0
			c1.5,0,1.8-0.3,2-1.7c0.2-1.2,0.4-2.4,0.4-3.5c0-1.7-0.2-1.9-2-2c-1.2,0-2.5-0.1-3.8-0.1c0.1-0.5,0.1-0.8,0.2-1.1
			c1.6-4.1,1.8-8.2,1-12.6c-0.5-2.7-2.9-5-4.9-5.3c-2.6-0.4-5.4,0.8-6.8,3.1c-0.9,1.5-1.5,3.2-1.5,5c0,3.9,0.1,7.7,2,11.1
			c0.1,0.2,0.1,0.5,0.2,0.8c-2.5-0.1-4.9-0.2-7.5-0.2c-0.1,1.9-0.2,3.6-0.3,5.3c2.4,0.4,4.7,0.7,6.9,1.1c1.8,0.3,1.8,0.4,1.7,2.2
			c-0.1,3.6-0.1,7.3-0.2,10.9c0,1.5,0,3-0.1,4.5c0,0.9-0.6,1.5-1.6,1.5c-1.6,0-3.2,0.1-4.7,0c-2.9-0.3-5.9-0.7-8.8-1
			c-1.7-0.2-2.5-1-2.7-2.7c-0.2-2.2-0.4-4.5-0.6-6.7c-0.3-5.4-0.6-10.9-0.9-16.3c-0.1-1.9-0.2-3.7-0.3-5.6c0-0.6,0.2-1.3,0.3-1.9
			c1.6,0.3,2.2,1.1,2.2,2.4c0,1.3,0.2,2.6,0.3,3.9c0.1,0.8,0.4,1.4,1.2,1.8c0.8,0.4,1.4-0.2,1.8-0.6c0.5-0.5,0.9-1.2,1-1.9
			c0.1-2,0.1-4,0-6c-0.1-1.9-0.8-3.6-2.3-5c-0.9-0.8-1.7-1.9-2.6-2.8c-0.8-0.8-1.5-1.6-2.4-2.2c-1.7-1.2-3.4-0.5-4,1.4
			c-0.3,0.9-0.4,1.9-0.5,2.8c-0.2,4.5-0.4,9-0.5,13.5c-0.3,8.8,0.2,17.6,0.4,26.3c0,2,0.9,3.5,2.4,4.7c0.3,0.3,0.7,0.6,1,0.8
			c2.4,1.8,4.9,3.5,7.7,4.7c1.2,0.5,2.4,0.9,3.5,1.5c3.5,1.9,7.2,3.2,11.3,3.4c1.3,0.1,2.6,0.2,3.9,0c2.7-0.4,5.3-0.9,8-1.4
			c0.8-0.2,1.7-0.4,2.5-0.7c3.5-1.3,6.7-3.1,9.7-5.3c1.8-1.3,3-3.1,3.4-5.2c0.3-1.9,0.3-3.9,0.4-5.8c0-2.3-0.1-4.5-0.1-6.8
			c0.1-4.1,0.2-8.3,0.3-12.4c0.1-3.3,0.3-6.6,1.7-9.8c0.6-1.4,1.2-2.9,1.8-4.3C719.2,276.5,718.8,276.1,718.3,275.7z M689.3,285.9
			c-0.5-0.9-1-1.5-1.1-2.2c-0.3-2.3-0.6-4.6,0.2-6.9c0.3-0.7,0.7-1.3,1.1-1.9c0.6-0.7,1.3-0.6,1.8,0.3c0.1,0.3,0.3,0.6,0.3,0.9
			C691.8,279.5,691.2,282.7,689.3,285.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M776,624.8c-0.1-0.1-0.2-0.3-0.3-0.4c-2.2-2.1-4.8-3.3-7.9-3.3c-2.7-0.1-4.8,0.8-6.1,3.3
			c-1.1,2.1-2.1,4.3-3.1,6.5c-0.8,1.8-1.6,3.6-2.4,5.4c-0.9,2.1-1.9,4.2-2.9,6.2c-1,2.1-1.9,4.3-2.9,6.4c-0.8,1.8-1.8,3.5-2.6,5.3
			c-1.8,4.1-3.5,8.3-5.4,12.3c-1.4,3-2.3,6.1-3.4,9.2c-0.2,0.5-0.2,1.1-0.4,2c1.3-0.3,2.1-0.8,2.7-1.5c1.2-1.5,2.3-2.9,3.4-4.5
			c3.2-4.6,6.8-8.9,10.7-13c1.9-2,4.1-3.8,6.3-5.5c1.6-1.3,3.5-2.3,5.2-3.4c2.2-1.4,3.8-3.5,4.1-6.1c0.4-3.3,0.5-6.7,0.1-10.1
			c-0.2-1.4-0.2-2.9-0.4-4.3c-0.1-0.8,0.2-1.4,0.9-1.7c0.3-0.2,0.7-0.3,1-0.5c1.2-0.7,2.5-1.1,3.4-2.2
			C776,624.9,776,624.8,776,624.8z M768.3,625c-0.2-0.1-0.3-0.6-0.2-0.8c0.4-0.7,1-0.3,1.6,0C769.4,625,769,625.4,768.3,625z"
            />
            <path
              class="st0"
              d="M763.5,671.4c-0.4,2.1,0.5,3.8,2.6,5.1c0.2-1,0.3-1.8,0.4-2.6c0.3-2.1,0.6-4.1,1.5-6c1.2-2.8,2.5-5.6,2.8-8.6
			c0.4-3.3,0.6-6.6-0.8-9.9c-1.6,1-1.9,1.4-2,3.1c0,0.8,0,1.6-0.2,2.4c-0.9,3.5-1.8,6.9-2.7,10.4
			C764.5,667.2,763.9,669.3,763.5,671.4z"
            />
            <path
              class="st0"
              d="M764.7,652.8c-2,1.1-4,2.1-5.6,3.7c-2.1,2.2-4.1,4.4-6.1,6.6c-0.8,0.9-1.6,1.8-2.3,2.9
			c-2.2,3.2-4.3,6.5-6.9,9.4c-1.5,1.7-2,3.8-2.7,6.1c0.5,0,0.9,0.2,1.2,0.1c2.5-0.5,5-0.8,7.5-0.5c0.7,0.1,1-0.3,1.2-0.9
			c0.8-2,1.7-3.9,2.5-5.9c0.5-1.3,0.9-2.6,1.3-3.9c0.1,0,0.3,0,0.4,0c0.6,1.3,1.1,2.6,1.8,4.1c1.4-0.9,2.6-1.6,3.8-2.4
			c1.4-0.8,2.6-1.7,2.5-3.6c0-0.2,0.1-0.3,0.1-0.5c0.6-2.4,1.3-4.8,1.9-7.3c0.2-0.7,0.4-1.5,0.6-2.2c0.6-2,0.9-3.9,0.7-6.3
			C765.8,652.5,765.2,652.5,764.7,652.8z"
            />
            <path
              class="st0"
              d="M754.2,682.7c0.8-0.1,1.7-0.3,2.5-0.7c2.3-1,4.6-1.2,7.1-0.8c1.7,0.3,3.5,0.5,5.2,0.7c1,0.1,2,0,2.9-0.8
			c-0.2-1.3-0.8-2.3-2.1-2.7c-1.1-0.4-2.3-0.8-3.4-0.9c-2.1-0.1-3.2-1.2-3.8-3c-0.2-0.6-0.5-1.3-0.7-1.9c-1.7,0.6-1.7,0.6-3.3,2.8
			c0.6,2,0.6,2-1.2,2.7c-0.2,0.1-0.4,0.1-0.6,0.2c-1.4,0.7-2.6,1.5-3.4,2.8C752.8,682.1,753.1,682.8,754.2,682.7z"
            />
            <path
              class="st0"
              d="M776.7,681.5c-0.2-2.3-2.6-4.1-4.6-3.3c0.4,0.6,0.8,1.1,1.1,1.7C774.1,681.5,774.8,681.9,776.7,681.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M739.2,644.5c2.1,0.3,3.1-1.1,2.2-3c-0.3-0.6-0.6-1.3-1.1-1.8c-2.7-3.1-4.3-6.7-5.9-10.4
			c-1-2.3-2.2-4.5-3.3-6.7c-0.4-0.8-1.1-1.3-2-1.2c-0.9,0-1.3,0.7-1.5,1.5c-0.2,0.6-0.3,1.3-0.2,2c0.1,2.5,0.3,5,0.4,7.5
			c0.1,2.3,0,4.6,0.1,6.9c0.1,2.8,0.3,5.5,0.5,8.2c0.3,4.7,0.6,9.5,0.8,14.2c0.1,1.6,1,2.6,2.4,3.6c0.4-0.6,0.9-1.1,1-1.6
			c0.3-1.6,0.5-3.2,0.5-4.8c-0.1-4.4-0.9-8.8-0.2-13.3c0.5-3,0.8-3.3,3.7-2C737.4,644,738.3,644.4,739.2,644.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M721.6,660.6c-2.5-1.7-3.3-4.5-3.9-7.1c-1.1-4.5-1.8-9-2.6-13.6c-0.4-2.5-0.7-5.1-1-7.6c-0.1-1.6-1-2.7-2-3.7
			c-0.5-0.5-1.2-0.8-1.8-1.2c0.1-1,0.3-2,0.3-3c-0.1-3.9-2.2-5.3-5.3-5.5c-1.6-0.1-2.7,0.5-3.3,2c-0.5,1.3-1,2.6-1.3,3.9
			c-0.4,1.5-0.9,2.6-2.5,3.2c-1.5,0.5-2.4,1.7-2.3,3.5c0,1.1,0,2.1-0.1,3.2c-0.1,3.5-0.2,7.1-0.4,10.6c-0.2,3-0.5,6-0.8,8.9
			c0,0.5,0,1,0.1,1.4c0.3,1.1,1.3,1.4,2,0.5c0.4-0.5,0.8-1.3,0.9-1.9c0.8-5.1,1.4-10.2,1.3-15.4c0-1.8,0.1-3.7,0.3-5.5
			c0.1-0.6,0-1.5,1-1.7c0.7,0.6,0.4,1.4,0.3,2.1c-0.1,2-0.3,3.9-0.3,5.9c0,0.7,0.5,1.3,0.8,2.1c-0.2,0.6-0.5,1.5-0.6,2.4
			c-0.5,3.4-1,6.8-1.5,10.1c-0.3,2,0.1,2.7,2,3.4c1.3,0.5,2.6,0.8,4,1.2c1.3,0.4,2.6,0.9,3.9,1.2c1,0.3,1.3-0.1,1.1-1.1
			c-0.3-1.2-0.7-2.4-0.9-3.6c-0.6-3.5-1.1-7.1-1.7-10.6c-0.1-0.7-0.4-1.3-0.6-1.9c1.1-1.6,1-3.7,1.4-5.7c0.1,0,0.3,0,0.4-0.1
			c0.4,1.1,0.9,2.2,1.2,3.3c0.8,3.6,1.6,7.2,2.4,10.9c0.6,2.6,1.1,5.3,2.3,7.7c0.3,0.7,0.7,1.5,1.3,1.9c1.3,0.9,2.7,1.7,4.2,2.5
			c1.1,0.6,1.7,0.4,2.2-0.4C722.4,662,722.4,661.2,721.6,660.6z M706.4,625.7c-0.2,1.7-0.4,3.3-0.7,4.9c-0.1,0.7-0.2,1.5-1.3,1.4
			c-0.8-0.1-1.1-0.5-1.1-1.7c0-0.2,0-0.5,0-0.7c-0.1,0-0.1,0-0.2,0c0.4-2,0.8-4,1.3-5.9c0.1-0.4,0.5-0.7,0.8-1
			c0.3,0.3,0.9,0.7,1,1.1C706.5,624.4,706.5,625.1,706.4,625.7z"
            />
            <path
              class="st0"
              d="M693.6,645.1c0.3-5.9,0.9-11.7,0.8-17.6c0-2.5,0.2-5,0.2-7.5c0-0.4,0-0.9-0.1-1.2c-0.2-0.7-0.6-1.2-1.4-1.2
			c-0.7,0-1.5,0.2-1.7,1c-0.3,1-0.7,2.1-0.8,3.1c-0.2,2.1-0.1,4.3-0.2,6.5c0,1.6-0.1,3.1-0.1,4.7c-0.2,3.3-0.4,6.6-0.5,9.9
			c-0.2,6-0.1,12.1-0.4,18.1c-0.2,3.6-0.8,7.1-1.1,10.7c-0.2,2.5-0.3,5-0.3,7.5c0,1.1,0.6,1.8,1.7,2.2c2.4,1.1,4.9,1,7.4,1.1
			c3,0,5.9,0,8.9-0.1c0-0.1,0-0.1,0-0.2c4.3,0.1,8.5,0.2,12.8,0.2c0.8,0,1.5-0.3,2.3-0.4c0.8-0.2,0.9-0.7,0.7-1.4
			c-0.2-1-0.7-1.4-2.1-1.5c-3.8-0.3-7.6-0.5-11.4-0.8c-2.8-0.2-5.5-0.4-8.3-0.5c-1.6-0.1-3.2-0.1-4.8-0.1c-0.4,0-0.8,0-1.3,0
			c-0.7,0-1-0.4-1-1c0-1.6-0.1-3.2-0.1-4.8c0-1.3,0-2.6,0-4C693.1,660.2,693.1,652.6,693.6,645.1z"
            />
            <path
              class="st0"
              d="M708.1,676.8c2.1,0,4.2,0.1,6.3,0.1c0.5,0,1.1-0.3,1.8-0.6c-1-1.7-2.1-2.8-3.7-3.2c-0.6-0.2-1.5-0.6-1.7-1.1
			c-1.1-2.6-1.8-5.3-1.9-8.1c0-0.9-0.2-1.8-0.3-2.7c-1-0.1-1.7-0.3-2.4-0.3c-0.7,0-1.4,0-2.1,0c-0.1,0.4-0.2,0.6-0.1,0.8
			c0.4,4.8,1.4,9.4,2.8,14C706.9,676.4,707.4,676.8,708.1,676.8z"
            />
            <path
              class="st0"
              d="M700,673.9c-1-0.7-1.3-1.5-1.1-2.7c0.1-0.5,0.2-0.9,0.3-1.4c0.9-3.4,1.9-6.8,2.9-10.5
			c-0.8-0.2-1.7-0.4-2.5-0.5c-0.6-0.1-1,0.1-1.3,0.7c-1.8,4-3.1,8.1-3.7,12.4c-0.2,1.1,0.2,2,0.9,2.8c1,1,2.2,1.7,3.6,2
			c1,0.3,2.1,0,3-1.1C701.4,675,700.7,674.4,700,673.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M679.5,665c-0.2-0.8-0.4-1.6-0.8-2.3c-0.5-1.2-1.2-2.3-1.8-3.4c-1.8-3.4-3.4-7-5.4-10.2
			c-2.6-4.2-5-8.7-8.3-12.4c-1.9-2.1-3.4-4.9-6.3-5.6c-0.3-0.1-0.6-0.7-0.7-1.2c-0.2-0.8-0.2-1.6-0.2-2.4c0.1-2.1-0.7-3.7-2.3-4.4
			c-2.1-0.9-4.3-1.5-6.4-2.1c-0.8-0.2-1.4,0.2-1.8,0.9c-1.5,2.6-2.9,5.2-4.5,8c0.4,0.2,0.6,0.4,0.8,0.4c2.4,0.6,2.3,0.6,2.7,3.3
			c0.4,2.9,1.4,5.4,4.2,6.2c0.2,0,0.3,0.3,0.6,0.6c-0.2,1-0.5,2.2-0.7,3.3c-0.5,2.5-1.1,5-1.3,7.6c-0.2,3.2,1,6,2.4,8.7
			c1.2,2.5,2.5,4.9,3.8,7.3c-0.5,1.1-1.4,1.3-2.3,1.5c-0.8,0.2-1.5,0.4-2.3,0.5c-1.2,0.2-1.9,1.3-2.4,2.4c-0.7,1.4,0.1,3,1.8,3.3
			c3.5,0.7,7,0.6,10.5,0.3c1.1-0.1,2-0.7,2.4-1.9c0.5-1.7,1-3.3,1.5-5c0.2-0.9,0.4-1.9,0.6-3.4c0.6,1.1,1,1.7,1.3,2.3
			c0.9,1.6,1.9,3.2,2.8,4.8c0.9,1.5,1.7,3.1,2.5,4.7c0.3,0.5,0.5,1.1,0.8,1.5c1.4,2.1,3,2.5,5,1.2c1.4-0.9,2-2.7,1.7-4.8
			c-0.3-1.8-0.7-3.5-1-5.3C679.1,669.5,680.1,667.9,679.5,665z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M649.9,190.4c-1.4,1.7-2.7,3.5-4,5.2c-0.9,1.2-0.7,2.8,0.5,3.6c2.8,1.8,5.6,3.5,8.5,5.2
			c1.1,0.6,1.9,0.3,2.6-0.6c0.7-0.8,0.8-1.8-0.1-2.7c-0.9-0.9-1.9-1.7-2.9-2.4c-2.2-1.3-2.4-1.6-1.5-4.1c0.1-0.4,0.3-0.7,0.7-1.6
			c0.2,1.2,0.4,1.8,0.4,2.5c0,1.1,0.5,1.7,1.5,2.1c2.9,1.1,3.6,3.4,3.4,6.1c-0.1,1.9-0.8,3.3-2.7,3.8c-1.1,0.3-1.7,1-1.9,2.1
			c-0.2,0.8-0.4,1.7-0.5,2.5c-0.4,2-0.8,4-1.3,6c-0.6,2.5-1.3,4.9-2,7.4c-0.6,2.4-0.7,4,3.1,4.7c1.5,0.2,3,0.3,4.5,0.4
			c0.3,0,0.6,0,0.9,0c1.4-0.3,1.8-1.3,0.8-2.3c-0.5-0.6-1.2-1-1.9-1.4c-1-0.6-1.4-1.4-1.2-2.7c0.5-4.1,1.9-8,3.2-11.9
			c0.1-0.3,0.4-0.5,0.9-1c0.4,0.8,0.7,1.4,1,2c1.6,4.3,3.3,8.6,4.9,12.9c0.5,1.4,1.5,2.2,2.9,2.5c2.1,0.4,4.1,0.2,6.1-0.4
			c1.7-0.6,2-2.1,0.7-3.3c-1.1-0.9-2.3-1.8-3.6-2.2c-1.2-0.4-1.8-1.1-2.1-2.3c-1.5-4.6-2.7-9.4-4.9-13.7c-0.3-0.6-0.4-1.4-0.2-2
			c0.8-2.4,0.1-4.4-1.4-6.3c-1.3-1.6-1.3-3.6-1.4-5.8c1.7,0.7,2.4,2,3.2,3.1c0.9,1.4,1.7,2.8,2.7,4.1c0.5,0.6,1.3,1.4,2,1.4
			c3,0,6-0.2,8.9-0.6c1.5-0.2,1.9-2,0.9-3.2c-0.7-0.8-1.5-1.4-2.7-1.4c-1.3,0-2.7-0.1-4.1-0.1c-1.6,0-2.7-0.5-3.2-2.1
			c-0.2-0.8-0.7-1.5-1.1-2.3c-1.6-3.2-3.9-5.5-7.8-5.9c0.4-1.6,0.8-2.9,1-4.3c0.3-2.6-1.9-4.9-4.4-4.9c-3.9,0-5.9,3.4-4.2,6.9
			c0.4,0.8,0.6,1.4,0,2.2C652.5,187.1,651.2,188.7,649.9,190.4z"
            />
            <path
              class="st0"
              d="M710.6,230.2c0.2,0.8-0.2,1.7-1.1,2.1c-0.9,0.4-1.9,0.9-2.8,0.9c-2.1,0-4.3-0.1-6.4-0.4
			c-1.8-0.3-2.2-0.8-2.1-2.6c0.2-6.2,0.4-12.5,0.6-18.7c2.4,2.9,4.9,5.7,7.4,8.6c-0.2,0.7,0.2,1.4,1.1,1.6c0.1,0,0.3,0.1,0.4,0.1
			c0.5,0.2,1.1,0.3,1.6,0.4c0.9,0.2,1.6,0.5,1.7,1.6c0,0.6-0.1,1.1-0.3,1.6c-0.2,0.5-0.6,0.8-1.1,1c-0.5,0.2-1.1,0.3-1.6,0.5
			c-0.6,0.2-1.1,0.5-0.8,1.4c0.7,0.2,1.4,0.2,2.1,0.5C709.7,229.2,710.4,229.7,710.6,230.2z"
            />
            <path
              class="st0"
              d="M687.7,198.7c-0.3,5.9-0.5,11.7-0.7,17.6c-0.1,4.2-0.1,8.5-0.2,12.7c0,1.6-0.2,1.8-1.9,1.8
			c-0.6,0-1.3,0.1-1.9,0.2c-1.6,0.1-2.4-0.5-2.6-2.1c-0.1-0.8-0.1-1.5-0.2-2.3c-0.1-0.8-0.4-1.4-1.2-1.9c-1.8-1.1-1.7-2.5,0.1-3.4
			c0.4-0.2,0.8-0.4,1.2-0.5c0.1-0.3,0.2-0.6,0.2-0.8c0-0.9,0.3-2-0.1-2.7c-0.3-0.6-1.4-0.7-2.2-1c-0.9-0.4-1.9-0.8-1.7-2.1
			c0.1-0.9,0.5-1.5,1.3-1.7c0.3-0.1,0.7-0.1,1.1-0.2c2.6-0.3,2.9-0.8,2-3.4c-1.2-0.3-2.4-0.5-3.5-0.9c-1.2-0.4-1.6-1.4-1.3-2.9
			c0.2-1.1,1-1.3,1.9-1.3c0.6,0,1.3,0.1,1.9,0.2c0.7,0.1,1.4,0.2,2.3,0.3c1.1-4,0.6-7.9,0.5-11.7C684.2,194.7,686,196.7,687.7,198.7
			z"
            />
            <path
              class="st0"
              d="M689.4,237.3c1.6-0.1,3.1,0,4.7-0.1c1.8-0.1,2.4-1,2-2.7c-0.3-1.4-1.1-2.1-2.7-2.2c-2.8-0.1-5.7-0.1-8.5-0.1
			c-4,0.1-8-0.1-11.9,0.4c-7.2,0.9-14.5,0.7-21.7,0.6c-1.5,0-1.9,0.1-2.3,1.5c-0.7,2.1,0.6,4.1,2.8,4.1c4.1,0,8.3-0.1,12.4-0.1
			c0-0.1,0-0.1,0-0.2c4.8-0.2,9.7-0.4,14.5-0.7C682.2,237.7,685.8,237.5,689.4,237.3z"
            />
            <path
              class="st0"
              d="M696.2,208.7c-0.1,1.5-0.2,3-0.3,4.5c-0.3,4.7-0.8,9.4-1.3,14.1c0,0.5-0.2,1-0.3,1.4
			c-0.3,0.8-0.6,1.7-1.7,1.6c-1.1-0.1-2.2-1-2.3-2.1c-0.1-1.9-0.1-3.8-0.1-5.7h-0.1c0.4-6.6,0.7-13.1,1.1-19.7
			C692.8,204.8,694.5,206.7,696.2,208.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M763.6,307.4c-0.1-4.6-0.7-9.3-1.6-13.8c-0.8-4-2.2-7.9-4.4-11.3c-1.4-2.2-3.3-4.1-5-6.1
			c-1.6-1.7-3.1-3.6-5-4.9c-1.7-1.1-2.5-2.5-3.2-4.2c-0.1-0.3-0.2-0.8-0.4-1.1c-0.4-0.6-0.8-1.2-1.5-2.2c-0.6,1.5-1.1,2.5-1.6,3.7
			c-0.9-1-1.6-1.8-2.3-2.6c-1.5,0.7-1.7,1.9-1.9,3.1c-0.3,2.8-1.9,4.9-3.8,6.8c-1.3,1.3-2.5,2.6-3.7,3.9c-0.4,0.5-0.7,1.2-1.1,2
			c1.3,0.4,2.4,0.7,3.5,1c-0.2,1-0.4,1.9-0.7,3c1.9,0.3,3.6,0.5,5.3-0.1c2.2-0.9,4.5-1.7,6.7-2.5c2.1-0.8,3.8-0.1,5,1.4
			c1.4,1.8,2.5,3.8,3.6,5.7c0.2,0.4,0.3,1,0.2,1.5c-0.4,1.8-0.9,3.6-1.4,5.4c-1,3.3-1.8,6.6-2.3,9.9c2.8,2,3.2,2,6-0.1
			c-0.2,6.7-1.1,13.3-0.8,19.9c1.9,0.4,3.7,0.7,5.5,1.1c0.8,0.2,1.4-0.1,1.7-0.9c0.1-0.4,0.3-0.7,0.4-1.1
			C762.9,319.2,763.7,313.3,763.6,307.4z M741,277c-0.8,0.1-1.5-0.4-1.5-1.1c0-0.6,0.9-1.5,1.5-1.6c0.6-0.1,1.2,0.6,1.2,1.5
			C742.3,276.6,741.7,276.9,741,277z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M780.6,481.7c-0.1-4.7-0.7-9.3-1.7-13.8c-0.8-4-2.2-7.9-4.4-11.3c-1.4-2.2-3.3-4.2-5-6.1
			c-1.5-1.7-3.1-3.6-5-4.9c-1.7-1.1-2.5-2.5-3.1-4.2c-0.1-0.4-0.2-0.8-0.4-1.1c-0.4-0.6-0.8-1.2-1.5-2.2c-0.6,1.5-1.1,2.5-1.6,3.7
			c-0.9-1-1.6-1.8-2.3-2.6c-1.5,0.7-1.7,1.9-1.9,3.1c-0.3,2.8-1.9,4.9-3.8,6.8c-1.3,1.3-2.5,2.6-3.7,3.9c-0.4,0.5-0.6,1.2-1.1,2
			c1.3,0.4,2.4,0.7,3.5,1c-0.2,1-0.4,1.9-0.7,3c1.9,0.3,3.6,0.5,5.3-0.1c2.2-0.9,4.5-1.7,6.7-2.5c2.1-0.8,3.8-0.1,5,1.4
			c1.4,1.8,2.5,3.8,3.6,5.7c0.2,0.4,0.3,1,0.2,1.5c-0.4,1.8-0.9,3.6-1.4,5.4c-1,3.3-1.9,6.6-2.3,9.9c2.8,2,3.2,2,6.1-0.1
			c-0.3,6.7-1.1,13.3-0.8,19.9c2,0.4,3.7,0.7,5.5,1.1c0.8,0.2,1.4-0.1,1.7-0.9c0.1-0.4,0.3-0.7,0.4-1.1
			C779.9,493.5,780.8,487.7,780.6,481.7z M758.1,451.3c-0.9,0.1-1.5-0.4-1.5-1.1c0-0.6,0.9-1.5,1.5-1.6c0.6-0.1,1.2,0.6,1.2,1.5
			C759.3,450.9,758.8,451.2,758.1,451.3z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              class="st0"
              d="M972.1,529.6c0.7-2,0.2-4.5-1.5-6c-3.2-2.7-6.8-3-10.4-0.8c-0.7-0.4-1.3-0.8-1.9-1.1
			c-1.8-1.2-3.9-1.3-5.8-0.4c-2,1-3.6,2.5-4.6,4.6c-0.6,1.2-0.6,1.2,0.9,2.3c0.4-0.3,0.8-0.6,1.1-0.8c1.3-1,2.7-1.5,4.4-1.6
			c1.8-0.1,2.6,0.3,3.1,2.1c0.4,1.5,0.6,3.1,0.6,4.6c0.1,3.5,0.1,7,0.2,10.5c0.1,2.4,0.2,4.9,0.4,7.3c0.1,1.5,1.4,2.4,2.9,2.2
			c1.3-0.1,2.9-1.8,2.7-2.9c-0.1-0.9-0.4-1.8-0.4-2.8c-0.3-3.7-0.6-7.4-0.8-11c-0.1-2.3-0.1-4.6-0.1-7c0-2.2,1.3-3,3.3-2.1
			c0.9,0.4,1.8,1.1,2.6,1.9C969.7,529.5,970.7,530,972.1,529.6z"
            />
            <path
              class="st0"
              d="M964.3,551.5c-0.5,0.6-0.9,1-1.3,1.4c-1.5,1.7-2.5,2.1-4.7,1.2c-1.2-0.5-2-0.1-2.8,0.7
			c-2.7,2.7-3.5,6.1-3.3,9.7c0.2,3.7,1.3,7.3,2.5,10.8c1.2,3.5,2,7.1,2.3,10.8c0.3,3.1,1.5,4.1,4.7,4.2c0.4,0,0.9,0,1.3,0
			c2.2-0.2,2.8-0.7,3.4-2.8c0.8-2.9,1.7-5.8,2.3-8.7c0.8-4.2,1.6-8.5,2.1-12.8C971.6,559.9,969.3,555.2,964.3,551.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1056.2,524.5c-0.1-0.2-0.2-0.3-0.3-0.4c-2.6-2.4-5.6-3.9-9.3-3.9c-3.2-0.1-5.7,1-7.2,3.9
			c-1.3,2.5-2.5,5.1-3.7,7.6c-1,2.1-1.9,4.2-2.9,6.3c-1.1,2.4-2.2,4.9-3.4,7.3c-1.1,2.5-2.2,5-3.4,7.5c-1,2.1-2.1,4.1-3,6.3
			c-2.1,4.8-4.1,9.7-6.4,14.4c-1.7,3.5-2.7,7.1-4,10.8c-0.2,0.6-0.3,1.3-0.4,2.3c1.5-0.3,2.5-0.9,3.2-1.8c1.4-1.7,2.8-3.4,4-5.2
			c3.8-5.4,8-10.5,12.6-15.3c2.2-2.4,4.8-4.4,7.4-6.4c1.9-1.5,4.1-2.7,6.2-4c2.6-1.7,4.4-4.1,4.8-7.1c0.5-3.9,0.6-7.9,0.2-11.8
			c-0.2-1.7-0.3-3.4-0.5-5.1c-0.1-0.9,0.2-1.6,1.1-2c0.4-0.2,0.8-0.3,1.2-0.5c1.3-0.9,3-1.3,4-2.6
			C1056.2,524.6,1056.2,524.5,1056.2,524.5z M1047.2,524.7c-0.2-0.1-0.4-0.7-0.3-0.9c0.5-0.8,1.1-0.4,1.8,0
			C1048.4,524.7,1048,525.2,1047.2,524.7z"
            />
            <path
              class="st0"
              d="M1041.5,579.2c-0.5,2.5,0.6,4.4,3,6c0.2-1.2,0.4-2.1,0.5-3.1c0.4-2.4,0.7-4.8,1.7-7.1
			c1.5-3.3,2.9-6.6,3.3-10.2c0.4-3.9,0.7-7.8-0.9-11.6c-1.9,1.1-2.2,1.7-2.3,3.7c-0.1,0.9-0.1,1.9-0.3,2.8c-1,4.1-2.1,8.1-3.2,12.2
			C1042.8,574.4,1042,576.8,1041.5,579.2z"
            />
            <path
              class="st0"
              d="M1043,557.4c-2.3,1.3-4.7,2.5-6.6,4.4c-2.4,2.5-4.8,5.1-7.2,7.7c-1,1.1-1.9,2.2-2.7,3.4
			c-2.6,3.8-5.1,7.6-8.1,11.1c-1.7,2-2.3,4.5-3.1,7.1c0.6,0,1,0.2,1.4,0.1c2.9-0.6,5.9-0.9,8.8-0.5c0.8,0.1,1.2-0.4,1.4-1
			c1-2.3,2-4.6,2.9-6.9c0.6-1.5,1-3,1.5-4.6c0.1,0,0.3,0,0.4-0.1c0.7,1.5,1.3,3.1,2.1,4.8c1.6-1,3.1-1.9,4.5-2.8
			c1.6-1,3.1-2,2.9-4.2c0-0.2,0.1-0.4,0.1-0.5c0.7-2.8,1.5-5.7,2.2-8.5c0.2-0.8,0.4-1.7,0.7-2.5c0.7-2.3,1.1-4.6,0.8-7.4
			C1044.3,557,1043.5,557.1,1043,557.4z"
            />
            <path
              class="st0"
              d="M1030.7,592.5c1-0.1,2-0.4,2.9-0.8c2.7-1.2,5.4-1.5,8.3-0.9c2,0.4,4.1,0.6,6.2,0.8c1.2,0.1,2.4,0,3.5-0.9
			c-0.3-1.5-1-2.7-2.5-3.2c-1.3-0.4-2.6-0.9-4-1c-2.5-0.1-3.8-1.4-4.5-3.6c-0.3-0.7-0.6-1.5-0.8-2.2c-2,0.7-2,0.7-3.8,3.3
			c0.7,2.4,0.7,2.4-1.4,3.2c-0.2,0.1-0.5,0.1-0.7,0.2c-1.6,0.8-3.1,1.8-4,3.3C1028.9,591.8,1029.4,592.6,1030.7,592.5z"
            />
            <path
              class="st0"
              d="M1057.1,591.1c-0.2-2.7-3-4.8-5.4-3.9c0.4,0.7,0.9,1.3,1.3,2C1054,591.1,1054.8,591.5,1057.1,591.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1090.6,587.9c-2.6-1.7-5.3-3.3-8.1-4.4c-2.4-1-3.4-2.6-3.4-5c0-0.4,0-0.8,0-1.1c-0.1-3.8-0.3-7.5-0.4-11.3
			c-0.2-7.5-0.3-15-0.5-22.5c-0.1-4.2-0.1-8.4-0.3-12.6c-0.1-2.5-0.3-5-0.5-7.5c0-0.5-0.2-1.1-0.3-1.7c-0.5-0.1-0.8-0.2-1.2-0.1
			c-2.2,0.2-4.4,0.2-6.6-0.1c-1.1-0.2-2.3,0-3.4,0c-1.5,0.1-2,0.9-1.7,2.4c0.4,1.9,0.7,3.8,1,5.7c0.6,4.2,0.2,8.4,1.2,12.5
			c0,0.2,0,0.4,0,0.6c0.5,5.6,0.9,11.1,1.6,16.6c0.8,6.3,1.4,12.6,1.5,18.9c0,3,0.1,6,1,8.9c0.6,1.9,1,2.3,2.9,2.2
			c3.4,0,6.6,0.7,9.8,1.8c2.6,0.9,5.3,1.6,8,2.4c0.6,0.2,1.2,0.4,1.8,0.4c0.5,0,1.3-0.2,1.4-0.6c0.2-0.5,0.1-1.3-0.2-1.7
			C1093.2,590.3,1092,588.8,1090.6,587.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1101.2,544.4c2.1,0.3,3.1-1.1,2.2-3c-0.3-0.6-0.6-1.3-1.1-1.8c-2.7-3.1-4.3-6.7-5.9-10.4
			c-1-2.3-2.2-4.5-3.3-6.7c-0.4-0.8-1.1-1.3-2-1.2c-0.9,0-1.3,0.7-1.5,1.5c-0.2,0.6-0.3,1.3-0.2,2c0.1,2.5,0.3,5,0.4,7.5
			c0.1,2.3,0,4.6,0.1,6.9c0.1,2.7,0.3,5.5,0.5,8.2c0.3,4.7,0.6,9.5,0.8,14.2c0.1,1.6,1,2.6,2.4,3.6c0.4-0.6,0.9-1.1,1-1.6
			c0.3-1.6,0.5-3.2,0.5-4.8c-0.1-4.4-0.9-8.8-0.2-13.3c0.5-3,0.8-3.3,3.7-2C1099.4,543.9,1100.3,544.3,1101.2,544.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1155.1,558.1c-4.3,6.4-9.2,12.3-14.5,17.8c-1.6,1.7-3.1,3.5-4.9,5c-1.5,1.3-3.2,2.4-4.9,3.3
			c-3.2,1.7-6.1,1-7.9-3.6c-0.3-0.8-0.5-1.8-0.5-2.7c0-0.7,0.2-1.4,0.5-2c0.2-0.4,0.8-0.9,1.2-0.9c0.4,0,0.8,0.7,1,1.2
			c0.3,1,0.3,2.1,0.4,3.1c0.1,0.7,0,1.4,0,2.1c1.8,0.2,3-0.4,3.4-2c0.4-1.5,1-2.9,1.3-4.4c0.4-2.2-1-4.2-3.2-4.8
			c-1.4-0.4-2.7-0.6-4.2-1c-0.6-1-0.5-2.2-0.4-3.3c0.5-5.7,1.1-11.5,1.6-17.2c0-0.5,0.1-1,0.3-1.4c0.4-1.5,0.8-2,2.3-1.9
			c2.8,0.1,5.5,0.1,8.3,0.4c3.3,0.3,6.7,0.6,10,0.5c-1.7-1.9-3.3-3.9-5-5.8h-0.2c-3-0.1-6.1-0.4-9.1-0.5c-1.6-0.1-3.1-0.2-4.7,0
			c-1,0.1-2,0.6-3.1,1c-0.3-2.1,0.2-4.1,1.1-6c0.2-0.4,1.1-0.7,1.6-0.6c1.4,0.1,2.8,0.4,4.2,0.7c2.2,0.5,4.4,0.9,6.6,1.1
			c-1.9-2.2-3.8-4.4-5.6-6.6c-1.4-0.3-2.8-0.6-4.2-0.9c-1.2-0.3-2.4-0.6-3.5-0.6c-2.4,0-3.9,1.3-4.2,3.6c-0.3,2.4-0.4,4.9-0.6,7.3
			c-0.1,2-0.4,4.1-0.3,6.1c0.1,4,0.5,8,0.4,12c0,2.8-0.3,5.6-0.9,8.4c-0.8,4-1.2,8.1-1,12.2c0.1,3.4,0.5,6.6,3.1,9.4
			c2.8,3,6,4,9.8,3.7c3.5-0.3,6.3-1.9,9.1-4.1c4.5-3.6,8.7-7.4,12.3-11.8c3-3.7,5.6-7.6,8.1-11.6c0.1-0.2,0.2-0.3,0.3-0.5
			C1157.7,561.1,1156.4,559.6,1155.1,558.1z M1173.9,583.1c-0.1-1.1-0.3-2.2-0.2-3.2c-2.5-2.9-4.9-5.8-7.4-8.7c0,0.7,0,1.4-0.1,2.1
			c-0.2,3.1-0.1,6.2,0.1,9.3c0.1,2.8,0.4,5.6,0.7,8.4c0.2,1.5,1.7,2.9,2.9,2.9c2.2,0,2.8-0.4,3.4-2.6
			C1174,588.6,1174.2,585.8,1173.9,583.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M998.1,590.3c0.1,0.7,0.3,1.5,0.7,2.1c1.2,1.8,3.2,1.8,4.5,0c1.2-1.8,1.6-3.8,1.7-5.9
			c0.1-4.4,0.2-8.8,0.4-13.1c0.3-7.3,0.8-14.6,1.2-21.8c0.2-4.7,0.3-9.3,0.4-14c0.1-2.1,0.2-4.1,0.1-6.2c0-0.8-0.3-1.6-0.7-2.3
			c-0.5-1.1-1.7-1.3-2.7-1.3c-1.1,0-1.5,0.8-1.8,1.7c-0.4,1.3-0.7,2.7-0.9,4c-0.9,4.4-1.5,8.9-1.6,13.4c-0.1,3.4-0.2,6.9-0.4,10.3
			c-0.1,1.3-0.2,2.6-0.2,3.9c-0.2,5.1-0.4,10.1-0.6,15.2c-0.1,3.5-0.3,7-0.4,10.5c0,0,0.1,0,0.1,0
			C997.9,587.9,998,589.1,998.1,590.3z"
            />
            <path
              class="st0"
              d="M982.4,584.5c-0.1,2.2-0.1,4.4,0,6.6c0.1,1.4,0.8,1.9,1.9,1.9c0.9,0.1,1.6-0.4,2-1.2c0.6-1.3,1.5-2.6,1.7-4
			c0.6-3.8,1.1-7.7,1.5-11.5c0.3-3.7,0.5-7.4,0.7-11.1c0.2-3.6,0.5-7.1,0.5-10.7c0.1-5.1,0.1-10.3,0.1-15.4c0-3.3-0.1-6.5-1.5-9.6
			c-0.8-1.8-2.9-2.8-4.7-2.2c-0.4,0.1-0.9,0.4-1,0.7c-0.3,1.2-0.7,2.4-0.6,3.6c0.1,2.2,0.6,4.3,0.7,6.5c0.1,4.6,0.1,9.2,0.1,13.7
			c-0.1,0-0.2,0-0.4,0c0,3,0.1,6,0.1,9c0,2.8-0.2,5.6-0.3,8.4C983,574.5,982.6,579.5,982.4,584.5z"
            />
            <path
              class="st0"
              d="M999.7,535.1c0-0.7-0.1-1.5-0.2-2.4c-1.4,0-2.7-0.2-3.9-0.1c-1.3,0.1-2.5-0.8-3.7,0c0.3,1.6,0.6,3.2,0.9,4.9
			c0.8,0,1.5,0,2.2,0c0.7,0,1.4-0.1,2.1,0C999.6,537.6,999.6,537.6,999.7,535.1z"
            />
            <path
              class="st0"
              d="M996.2,586.3c0.4-1,0.6-2,0.8-3.1c0.2-1-0.3-1.6-1.3-1.7c-1.4-0.2-2.8-0.3-4.1-0.4c-0.8,0-1.1,0.5-1.2,1.2
			c-0.1,1.3-0.2,2.6-0.3,4.2c1.8,0.1,3.3,0.3,4.9,0.3C995.4,586.9,996,586.6,996.2,586.3z"
            />
            <path
              class="st0"
              d="M997.5,557.1c-2.1-0.6-4-0.7-5.7-0.5c0,1.9,0,3.5,0,5.3c1.8,0.5,3.4,0.3,5.3,0.2
			C997.3,560.4,997.4,558.8,997.5,557.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M869.9,411.9c-0.3,0.7-0.6,1.3-1,1.9c-1.3,1.8-3.3,1.9-4.5,0c-0.4-0.6-0.6-1.4-0.7-2.1
			c-0.2-1.2-0.2-2.4-0.3-3.5c0,0-0.1,0-0.1,0c0-1.2,0.1-2.5,0.1-3.7C865.6,406.9,867.8,409.4,869.9,411.9z"
            />
            <path
              class="st0"
              d="M855.3,394.8c-0.1,1-0.1,2-0.2,2.9c-0.4,3.9-0.8,7.7-1.5,11.5c-0.2,1.4-1,2.7-1.7,4c-0.4,0.7-1,1.2-2,1.2
			c-1.1-0.1-1.8-0.6-1.9-1.9c-0.1-2.2-0.1-4.4,0-6.6c0.2-5,0.6-10,0.9-15c0.1-1.2,0.1-2.3,0.2-3.5
			C851.1,389.9,853.2,392.3,855.3,394.8z"
            />
            <path
              class="st0"
              d="M861.8,407.7c0.4-1,0.6-2,0.8-3.1c0.2-1-0.3-1.6-1.3-1.7c-1.4-0.2-2.8-0.3-4.1-0.4c-0.8,0-1.1,0.5-1.2,1.2
			c-0.1,1.3-0.2,2.6-0.3,4.2c1.8,0.1,3.3,0.3,4.9,0.3C861,408.4,861.6,408.1,861.8,407.7z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M933.5,652.9c-0.7,0.6-0.8,1.4-0.6,2.2c0.9,3.1,1.7,6.1,2.8,9.2c0.9,2.6,3,3.9,5.7,3.6
			c1.7-0.2,3.4-0.5,5.1-0.6c4.8-0.5,9.5-0.9,14.3-1.3c3.2-0.3,6.4-0.5,9.6-0.9c2.2-0.3,4.3-0.4,6.5,0.2c1.6,0.5,3.4,0.3,4.9-0.7
			c0.7-0.5,1.1-1,0.8-1.9c-0.1-0.4-0.2-1,0-1.4c0.6-1.4,0.3-2.4-1.1-3c0.3-0.6,0.6-1,0.8-1.4c0.8-1.6,0.2-2.5-1.5-2.8
			c-0.6-0.1-1.2-0.1-2-0.2c0.2-0.5,0.3-0.9,0.4-1.3c0.4-1.4,0-2-1.4-2.1c-1.2,0-2.4,0-3.6,0.1c-1.4,0.1-2.6,0.7-3.2,2.2
			c-0.3,0.9-0.9,1.8-1.4,2.7c-0.4,0.6-0.9,1.1-1.7,1.1c-1.9,0-3.8,0-5.7,0c-1.3,0-2.6-0.2-4-0.2c-3.7,0-7.4,0.1-11.2,0.1
			c-1.2,0-2.5-0.3-3.7-0.5c-0.9-0.1-1.5-0.6-2-1.5c-0.7-1.2-1.5-2.3-2.4-3.4c-1.1-1.2-1.9-1.2-3.1-0.2
			C935,651.4,934.3,652.2,933.5,652.9z"
            />
            <path
              class="st0"
              d="M955,623.5c-0.3-1-0.9-1.6-2.2-2c-2.4,2.8-2.5,6.8-4.8,10c-0.2-0.9-0.3-1.5-0.4-2.1c-0.4-2.1-1.5-3.8-3.2-5.1
			c-0.4-0.3-0.9-0.5-1.2-0.9c-1.4-1.3-3.1-1.5-4.9-1.4c-0.8,0-1.6,0-1.9,1c-0.2,0.9,0,1.7,0.8,2.3c0.9,0.6,1.8,1.4,2.7,2
			c0.9,0.6,1.4,1.4,1.6,2.5c0.8,3.1,0.7,6.2,0.2,9.3c-0.8,5.2,2.5,8.5,7.6,9.7c1.7,0.4,3.1-0.4,4.6-0.8c1.9-0.5,3.1-1.8,4-3.5
			c1.1-2.1,1.5-4.3,1.4-6.7c0-2.2,0.6-4.2,1.8-6c1-1.4,2-2.7,3.1-4c0.7-0.8,1.2-1.7,1.2-3c-1.2-0.5-2.3-0.5-3.3,0.2
			c-1.2,0.8-2.3,1.8-3.3,2.8c-1,1-1.9,2.1-3.1,3.3c0-1.2-0.1-2.1,0-2.9C955.9,626.5,955.6,625,955,623.5z"
            />
            <path
              class="st0"
              d="M977.6,629.8c-1.1,2-2.5,3.7-3.2,5.9c-1.4,4.3-1.2,8.6-0.8,13.2c1.4,0.1,2.7,0.2,3.9,0.3
			c0.2-0.4,0.4-0.5,0.4-0.7c0.1-0.7,0.2-1.5,0.3-2.2c0.2-5.5,1.7-10.6,4.7-15.2c1.1-1.7,1.9-3.5,2.4-5.4c0.7-2.5,0.8-5.2-1.2-7.3
			c-3.1-3.4-6.5-4.3-10.4-2.1c-2.3,1.3-3.4,4.9-1.5,7.4c0.9,1.1,2.1,1.4,2.9-0.4c0.2-0.5,0.2-1.1,0.4-1.6c0.3-1.2,1-1.6,2-1
			c0.6,0.4,1.3,0.9,1.6,1.5c0.6,1.5,0.9,3.2,0.1,4.7C978.7,627.8,978.1,628.8,977.6,629.8z"
            />
            <path
              class="st0"
              d="M978.8,666.4c-1.7,0.1-3.1,0.2-4.7,0.3c0,5.7,0.1,11.3,0.1,17c0.4,0.1,0.7,0.2,0.9,0.3c3.5,1,4.3,0.4,4.2-3.3
			c-0.1-3.4-0.3-6.7-0.5-10.1C978.8,669.2,978.8,667.9,978.8,666.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1000.3,661c-1.2,2.5-1,5,0.1,7.3c3.3,6.5,8.9,9.8,16,10.8c2.1,0.3,4.3,0.2,6.4,0.3c3,0,5.9,0.1,8.9-0.2
			c5.2-0.4,10.3-0.8,15.5-0.8c1.6,0,3.3-0.2,4.9-0.3c0.2,0,0.5-0.1,0.7-0.3c0.9-0.9,0.9-3.8-0.3-4.4c-1.1-0.6-2.3-1.3-3.5-1.4
			c-2.5-0.2-5-0.1-7.5-0.1c-1.8,0-3.5,0.2-5.3,0.2c-2.1-0.1-4.1-0.3-6.2-0.5c-4.4-0.5-9,0.1-13.4-1.1c-2.8-0.7-5.4-1.5-7.2-4.1
			c-2.1-3.1-0.9-7.7,2.5-9.2c0.6-0.2,1.1-0.4,1.9-0.7c-1-1.9-2-3.6-3-5.5c-2.5,0.4-4.1,1.8-5.7,3.2
			C1003,655.9,1001.6,658.4,1000.3,661z"
            />
            <path
              class="st0"
              d="M1028.5,626.5c-1.4-4-6.2-6.5-10.3-6.2c-0.9,0.1-1.2,0.5-1,1.4c0.2,1.1,0.7,2,1.6,2.7c2,1.7,2.2,3.8,1.5,6.1
			c-0.2,0.7-0.5,1.3-0.8,1.9c-0.5,1-0.7,2,0,3c0.8,1.2,0.5,2.5,0.1,3.8c-0.2,0.5-0.4,0.9-0.7,1.8c0.7,0.1,1.2,0.4,1.6,0.3
			c1.3-0.5,2.8-0.8,3.7-1.6c2-1.8,3.8-3.9,4.4-6.7C1029,630.8,1029.3,628.6,1028.5,626.5z"
            />
            <path
              class="st0"
              d="M1016,626.2c-1.1-0.9-1.4-2-1.5-3.3c-0.1-0.8-0.2-1.7-0.3-2.5c-1.8-0.2-2.9,0.1-4,1.1
			c-0.6,0.5-1.1,1.1-1.6,1.6c-2,1.8-2.8,4-2.5,6.6c0.2,1.6,0.4,3.2,1,4.8c1.2,3.3,3.8,5.4,7.1,6.4c0.3,0.1,0.6,0,1.1-0.1
			c0-0.6-0.1-1-0.1-1.5c-0.1-1.2-0.3-2.4-0.3-3.5c-0.1-2.1,0.9-4,1.8-5.8C1017.6,628.3,1017.5,627.5,1016,626.2z"
            />
            <path
              class="st0"
              d="M1025.8,651.2c-2.5-2-5.5-3-8.8-3c-0.8,0-1.3,0.3-1.5,1.1c-0.4,1.8-0.8,3.7-1,5.5c-0.1,0.9,0.3,1.7,1.4,1.9
			c1.4,0.3,2.9,0.7,4.3,1.1c1.9-0.1,3.6-0.7,5.3-1.5c1.4-0.6,1.9-1.7,1.5-3.2C1026.8,652.5,1026.4,651.7,1025.8,651.2z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1045.4,642.2c0,2.5,0,5,0,7.5c0,2.5-0.1,5-0.1,7.5c0,1,0.4,1.4,1.4,1.7c3,0.8,6.1,1.1,9.5,1.3
			c0.2-1.2,0.4-2.2,0.4-3.3c0-1.9-0.1-3.8,0-5.6c0-2.1,0.4-2.8,2.3-3c3.3-0.4,6.7-0.6,10-0.8c2.3-0.1,4.7,0.2,7,0
			c5.5-0.4,11.1-0.4,16.6,0.2c1.2,0.1,2.4,0.1,3.6,0.1c1.3,0.1,2.6,0.2,4,0.3c1,0.1,1.3,0.6,1.3,1.5c0,0.4,0,0.8,0,1.1
			c0.1,1.7,0.2,3.5,0.5,5.2c0.2,1.4,1.8,3,3,3c3,0,5.9-0.2,8.9-0.4c0.8,0,1.4-0.6,1.5-1.4c0.1-1.3,0.3-2.6,0.3-3.9
			c0-2.4-0.2-4.9-0.2-7.3c0-3.2-1.6-5.1-4.8-5.2c-3.9-0.1-7.8-0.1-11.7-0.1c-2.2,0-4.4,0.2-6.6,0.2c-3.8-0.1-7.6-0.3-11.3-0.3
			c-7.4,0-14.8-0.4-22.1-0.7c-3.2-0.1-6.3-0.2-9.5-0.3c-1.2,0-2.3,0.3-3.6,0.5C1045.5,641,1045.4,641.6,1045.4,642.2z"
            />
            <path
              class="st0"
              d="M1089.5,626.1c-0.4,0-0.7,0.1-1.1,0.2c-1.5,0.4-3,0.9-4.6,1.2c-3.1,0.7-6.1,0.2-9.1-0.8
			c-2.6-0.9-5.3-1.8-8.1-1.9c-2.3-0.1-4.5-0.4-6.8-0.7c-1.6-0.2-1.7-0.3-1.7-1.9c-0.1-1.1-0.1-2.1-0.3-3.2c-0.4-1.5-1.3-2.1-2.8-1.9
			c-1.3,0.1-2.6,0.2-3.9,0.5c-1.6,0.3-3.2,0.7-4.6,1.7c0,0.4,0,0.8,0.1,1.2c0.6,2.2,1.2,4.5,1.8,6.7c0.3,1,0.5,1.9,0.9,2.9
			c0.9,2.4,3.1,3.9,5.7,3.9c1.6,0,3.3-0.1,4.9-0.1c5.3-0.1,10.6-0.4,15.9-0.1c1.5,0.1,2.9,0.4,4.1,1.2c2,1.3,4.3,1.7,6.7,1.8
			c4.6,0.2,9-0.8,13.3-2.4c1.6-0.6,3.1-1.6,4.5-2.6c0.7-0.5,1.2-1.5,1.4-2.3c0.4-1.1-0.3-1.7-1.4-1.5c-0.9,0.2-1.9,0.4-2.7,0.9
			c-3.4,1.8-6.9,2.3-10.7,1.4c0.2-0.4,0.4-0.8,0.6-1.2C1092.2,627.2,1091.3,626,1089.5,626.1z"
            />
            <path
              class="st0"
              d="M1095.5,662.9c-1.1-2.8-3.6-4.3-6-5.6c-4.2-2.4-8.3-0.7-11.2,2.5c-2.2,2.5-3.7,5.8-3.6,9.3
			c0.1,3.8,1.6,7.1,5.1,9c1.7,0.9,3.6,1.1,5.5,1.1c4.7,0,8.6-2.5,10.1-6.8c0.6-1.8,1-3.8,1-5.7
			C1096.5,665.5,1096,664.2,1095.5,662.9z M1091.9,668.9c-0.5,2.9-2.9,5.3-6.5,5.2c-0.6,0-1.1,0-1.5,0c-2.6-0.2-4.3-1.9-4.5-4.5
			c-0.2-3.3,1.2-6,3.7-8.1c1.2-1,2.7-1,4-0.5c1.4,0.5,2.8,1.2,4,2.1c1.1,0.7,1.2,2,1.2,3.2C1092.2,667.2,1092.1,668,1091.9,668.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1143.5,674.2c-1.1-0.9-2.4-1.5-4-2.4c0.1-0.9,0-2.1,0.3-3.3c0.8-3.3,1.8-6.7,2.6-10c0.3-1.5,0.5-3.1,0.5-4.6
			c0-1.4-0.6-2.7-2.2-3.2c-1.4-0.5-2.6,0.2-3.5,1.2c-0.8,1-1.6,2-2.2,3c-1.1,1.8-2,3.6-3,5.4c-0.2,0.3-0.5,0.7-0.9,1.2
			c-0.1-0.5-0.2-0.8-0.2-1c0.4-2.1,0.7-4.3,1.4-6.4c0.4-1.4,0.8-2.7,0.7-4.2c-0.2-3.6-0.3-7.1-0.5-10.7c-0.1-1.1,0-2.2,0.5-3.3
			c0.9-1.9,1.4-3.9,1.3-6c-0.2-3.6-3.3-7.5-7.7-7.1c-2.4,0.2-5.8,2.8-6.6,5.2c-0.2,0.6-0.4,1.3-0.6,2c-0.8,2.9-0.3,4.6,2.3,6.3
			c0.9,0.6,1.5,1.4,1.3,2.5c-0.2,1.4-0.4,2.8-0.7,4.2c-0.8,3.5-1.4,7,0.8,10.4c0.3,0.5,0.3,1.2,0.3,1.8c-0.3,1.8-0.6,3.6-1.1,5.3
			c-0.8,3-1.5,6-0.4,9.2c1,2.9,2.9,4.6,5.9,5.1c1.8,0.3,3.4,0,4.3-1.9c0.7-1.3,1.5-2.5,2.1-3.8c0.8-1.5,1.4-3.1,2.1-4.6
			c0.2-0.3,0.4-0.6,0.7-0.9c0.1,0,0.3,0.1,0.4,0.1c-0.2,1.2-0.2,2.4-0.6,3.5c-0.5,1.6-1.1,3.2-1.8,4.7c-0.4,1-0.6,2-0.4,3.1
			c0.3,1.6,0.6,1.9,2.3,1.6c1.4-0.3,2.8-0.5,4.2,0.2c0.9,0.5,1.7,0.1,2.5-0.4C1144.4,675.8,1144.5,675,1143.5,674.2z M1129.7,631.9
			c-0.5,1.2-1.1,2.3-1.7,3.5c-0.1,0.2-0.4,0.4-0.6,0.4c-0.3-0.1-0.8-0.4-0.8-0.6c-0.1-1-0.8-1.6-1.3-2.4c-0.8-1-0.6-2.3-0.1-3.4
			c0.5-0.9,1-1.8,2.1-1.7C1129.1,627.8,1130.4,630.2,1129.7,631.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1150.6,634.8c-1.2,1.5-2.4,3.1-3.5,4.7c-0.8,1.1-0.7,2.5,0.4,3.2c2.5,1.6,5,3.1,7.6,4.6
			c1,0.6,1.7,0.3,2.3-0.6c0.6-0.7,0.7-1.6-0.1-2.4c-0.8-0.8-1.6-1.5-2.6-2.1c-1.9-1.2-2.1-1.4-1.3-3.6c0.1-0.3,0.3-0.6,0.6-1.4
			c0.2,1,0.3,1.6,0.4,2.2c0,0.9,0.5,1.5,1.3,1.9c2.6,0.9,3.1,3,3,5.4c-0.1,1.7-0.7,2.9-2.4,3.4c-1,0.3-1.5,0.9-1.7,1.8
			c-0.2,0.7-0.3,1.5-0.5,2.2c-0.4,1.8-0.7,3.6-1.1,5.3c-0.5,2.2-1.2,4.4-1.8,6.5c-0.6,2.1-0.6,3.6,2.8,4.1c1.3,0.2,2.6,0.3,4,0.4
			c0.3,0,0.5,0,0.8,0c1.2-0.3,1.6-1.1,0.7-2c-0.5-0.5-1.1-0.8-1.7-1.2c-0.9-0.6-1.2-1.2-1.1-2.4c0.5-3.6,1.7-7.1,2.8-10.5
			c0.1-0.3,0.4-0.4,0.8-0.9c0.4,0.7,0.7,1.2,0.9,1.8c1.5,3.8,2.9,7.6,4.3,11.4c0.5,1.2,1.3,1.9,2.6,2.2c1.8,0.4,3.7,0.2,5.4-0.4
			c1.5-0.5,1.8-1.8,0.6-2.9c-0.9-0.8-2-1.6-3.2-2c-1.1-0.4-1.6-1-1.9-2c-1.3-4.1-2.4-8.3-4.4-12.2c-0.3-0.5-0.4-1.3-0.2-1.8
			c0.7-2.1,0.1-3.9-1.2-5.6c-1.1-1.5-1.1-3.2-1.3-5.1c1.5,0.6,2.2,1.7,2.8,2.8c0.8,1.2,1.5,2.5,2.4,3.6c0.4,0.6,1.2,1.2,1.8,1.2
			c2.6,0,5.3-0.2,7.9-0.5c1.3-0.2,1.7-1.8,0.8-2.8c-0.6-0.7-1.4-1.2-2.4-1.2c-1.2,0-2.4-0.1-3.6-0.1c-1.4,0-2.4-0.4-2.9-1.9
			c-0.2-0.7-0.6-1.4-1-2c-1.5-2.8-3.5-4.9-7-5.2c0.3-1.4,0.7-2.6,0.9-3.8c0.2-2.3-1.7-4.4-3.9-4.4c-3.4,0-5.2,3-3.7,6.2
			c0.3,0.7,0.5,1.3,0,1.9C1152.9,632,1151.8,633.4,1150.6,634.8z"
            />
            <path
              class="st0"
              d="M1201.5,633c0.8-0.3,1.7-0.3,2.6-0.6c1.2-0.3,2.1-1.6,2.1-2.6c0-1.2-1-1.5-1.9-1.8c-0.5-0.2-1.1-0.3-1.6-0.4
			c-2.3-0.2-4.5-0.3-6.8,0.4c-0.6,0.2-1.3,0.1-1.9,0.1c-2.2-0.2-4.4-0.4-6.6-0.5c-0.6,0-1.3,0.1-1.9,0.1c-0.6,0-1.3,0.2-1.9,0.1
			c-1.5-0.3-2.7,0.2-4.1,0.6c-0.8,0.2-1.1,0.7-1.2,1.5c0,0.6,0,1.1,0,1.8c1.7,0.4,3.1,0.8,4.6,1c1.5,0.2,3,0.1,4.5,0.2
			c1.5,0.1,3,0.1,4.5,0.3c1.5,0.2,1.6,0.3,1.6,1.8c0.1,4.9,0.2,9.8,0.1,14.7c-0.1,6.9-0.4,13.8-0.6,20.7c0,1.6,0.3,2.1,1.9,2.3
			c1.9,0.3,3.8,0.4,5.6,0.3c0.8,0,1.7-0.4,2.5-0.8c0.8-0.3,1.2-1.1,1-1.9c-0.1-0.5-0.8-0.9-1.3-1.2c-0.6-0.3-1.3-0.3-1.9-0.5
			c-0.2-0.8,0.2-1.1,0.8-1.2c0.5-0.1,1-0.2,1.5-0.4c0.8-0.3,1.3-1.2,1.2-2.3c0-1-0.7-1.2-1.5-1.4c-0.6-0.1-1.2-0.3-1.8-0.5
			c-1.1-0.3-1.4-1.3-0.5-2.1c0.3-0.3,0.8-0.4,1.1-0.6c0.6-0.5,1.3-1,1.8-1.6c0.5-0.5,0.3-1.1-0.2-1.5c-0.8-0.6-1.7-0.8-2.7-0.8
			c-1,0-1.2-0.3-1.1-1.3c0.1-0.8,0.4-1.4,1.3-1.6c0.6-0.1,1.3-0.3,1.7-0.7c0.5-0.4,1.2-1.1,1.1-1.5c-0.2-0.6-0.9-1.1-1.5-1.5
			c-0.4-0.3-1-0.2-1.5-0.3c-0.8-0.3-1-1-0.5-1.6c0.3-0.3,0.7-0.5,1.1-0.7c0.4-0.2,0.8-0.3,1.2-0.4c1.1-0.5,1.5-1.3,1.3-2.2
			c-0.2-1-0.9-1.3-1.7-1.5c-0.4-0.1-0.9-0.1-1.3-0.2c-0.7-0.1-0.8-0.6-0.7-1.3c1.1-0.4,2.1-0.8,3.2-1.2c0.9-0.4,1.5-1.8,1.3-2.5
			c-0.3-1-1.1-1.5-2.1-1.6c-0.4,0-0.8,0-1.1-0.1c-1.3-0.1-1.9-1-1.3-2C1200.4,633.5,1201,633.2,1201.5,633z"
            />
            <path
              class="st0"
              d="M1175.4,646.9c-0.8,0-1.5,0.1-1.7,1.1c-0.2,1.3,0.1,2.2,1.2,2.6c1,0.3,2,0.5,3.1,0.8c0.8,2.3,0.5,2.7-1.8,3
			c-0.3,0-0.6,0-0.9,0.1c-0.8,0.2-1.1,0.7-1.2,1.5c-0.1,1.2,0.7,1.5,1.5,1.8c0.7,0.3,1.7,0.4,2,0.9c0.3,0.6,0.1,1.6,0.1,2.4
			c0,0.2-0.1,0.5-0.2,0.7c-0.4,0.2-0.7,0.3-1,0.5c-1.6,0.9-1.7,2.1-0.1,3c0.7,0.4,1,0.9,1.1,1.7c0.1,0.7,0.1,1.4,0.2,2.1
			c0.2,1.4,0.9,2,2.3,1.8c0.6,0,1.1-0.1,1.7-0.2c1.5,0,1.7-0.1,1.7-1.6c0.1-3.8,0-7.5,0.2-11.3c0.2-6.1,0.5-12.2,0.8-18.4
			c0.1-1.1,0.2-2.2,1.4-2.9c0.4-0.2,0.5-0.8,0.8-1.2c-2-1.4-2.5-1.2-6.9,0c0,3.8,0.8,7.8-0.4,11.9c-0.8-0.1-1.4-0.2-2.1-0.3
			C1176.5,647,1175.9,646.9,1175.4,646.9z"
            />
            <path
              class="st0"
              d="M1185.6,676.5c1.4-0.1,2.8,0,4.2-0.1c1.6-0.1,2.1-0.9,1.8-2.4c-0.3-1.2-1-1.9-2.4-1.9
			c-2.5-0.1-5.1-0.1-7.6-0.1c-3.5,0-7.1-0.1-10.6,0.3c-6.4,0.8-12.8,0.6-19.2,0.5c-1.3,0-1.7,0.1-2.1,1.3c-0.6,1.8,0.6,3.6,2.5,3.6
			c3.7,0,7.3,0,11-0.1c0,0,0-0.1,0-0.1c4.3-0.2,8.6-0.4,12.8-0.6C1179.2,676.8,1182.4,676.6,1185.6,676.5z"
            />
            <path
              class="st0"
              d="M1191.6,638.4c-0.1-1.1-0.9-1.5-2-1.5c-1,0-1.5,0.6-1.7,1.7c-0.2,2.2-0.5,4.4-0.6,6.5
			c-0.4,6.1-0.7,12.1-1,18.2c0,0,0.1,0,0.1,0c0,1.7,0,3.4,0.1,5.1c0,0.9,1.1,1.8,2,1.9c1,0.1,1.2-0.7,1.5-1.4
			c0.1-0.4,0.3-0.8,0.3-1.3c0.4-4.2,0.8-8.3,1.1-12.5c0.3-3.6,0.5-7.2,0.5-10.9C1192,642.3,1191.8,640.3,1191.6,638.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1217.6,631.2L1217.6,631.2c-1.3,1.3-2.5,2.6-3.7,3.9c-0.4,0.5-0.6,1.1-1,1.9c1.3,0.4,2.3,0.7,3.4,1
			c-0.2,0.9-0.4,1.8-0.6,2.9c1.9,0.3,3.5,0.5,5.1-0.1c1.3-0.5,2.5-1,3.8-1.4C1222.2,636.6,1219.9,633.9,1217.6,631.2z M1234.6,651.1
			c-0.1,0.3-0.1,0.5-0.2,0.7c-1,3.2-1.8,6.4-2.2,9.6c2.7,1.9,3.1,1.9,5.9-0.1c-0.2,6.5-1.1,12.9-0.8,19.4c1.9,0.4,3.6,0.7,5.3,1.1
			c0.8,0.2,1.4-0.1,1.7-0.9c0.1-0.4,0.3-0.7,0.4-1c1.5-4.6,2.4-9.2,2.5-14C1243,661,1238.8,656.1,1234.6,651.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1019.6,399.6c-2.4,0.8-5,1.3-7.5,1.6c-7.7,0.8-15.5,1.3-23.2,1c-3.7-0.1-7.4-0.1-11.1-1
			c-0.9-0.2-1.5-0.6-1.5-1.6c-0.3-2.8-0.5-5.6-0.8-8.4c0-0.3,0.1-0.7,0.2-1.2c0.6,0.1,1.1,0.1,1.6,0.2c4.3,1,8.7,1.1,13.2,1
			c6.2-0.2,12.3-1.2,18.4-2.1c0.2,0,0.4,0,0.6-0.1c0.3-0.1,0.7-0.1,0.9-0.2C1013.3,392.3,1016.5,395.9,1019.6,399.6z"
            />
            <path
              class="st0"
              d="M978.6,351.6c-0.5,0.3-1,0.3-1.7,0.3c-0.6-0.1-1.1-0.1-1.7-0.2c-3-0.4-6.1-0.8-9.2-1.2
			c-2.2-0.3-4.3-0.3-5.9-2.3c-1.3-1.6-4.3-0.9-5.3,1c-0.2,0.3-0.1,1,0.1,1.4c0.8,1.4,1.7,2.8,2.7,4.1c0.8,1,1.2,2.1,1.3,3.4
			c0,2.7,0.1,5.4,0.4,8.1c0.3,2.4,0.8,4.8,1.5,7.2c0.3,1,1,2,1.8,2.8c1.4,1.5,2.8,2.9,2.9,5.1c0,0.2,0.1,0.5,0.3,0.9
			c2.4-0.6,4.6,0.8,7.2,0.3c0.1-2.2,1.4-3.5,3-4.8c1.6-1.3,3.1-3,4.4-4.6c1.5-2,2.4-4.4,2.8-6.9c0.3-2.4,0.6-4.8,0.7-7.3
			c0-0.3,0.1-0.6,0.1-0.9C982.1,355.8,980.4,353.7,978.6,351.6z M967.9,368.7c1.6-0.9,4.5-0.9,6.3-0.1
			C972.5,371.5,969.6,371.6,967.9,368.7z M976.8,361.5c-0.1,0.5-0.2,0.9-0.4,1.4c-2.4,0-4.7,0.1-7,0.1c-0.9,0-1.8-0.1-2.6-0.1
			c-1.4-0.1-1.8-0.4-1.8-1.6c0-0.9,0.3-1.2,1.6-1.4c3.1-0.5,6.1-0.2,9.2,0.3C976.6,360.2,977,360.7,976.8,361.5z"
            />
            <path
              class="st0"
              d="M965.3,415.5c0.2,1.4,0.9,2.5,1.9,2.5c2.8,0,5-0.5,5.6-4c0.6-3.3,0.8-6.7,0.6-10.1c-0.2-4.1-0.4-8.1-0.6-12.2
			c-0.1-1.4,0-2.7,0-4.2c-2.4-0.5-2.7-0.4-2.9,1.6c-0.2,1.4-0.1,2.9-0.1,4.3c0,0.9,0.2,1.8-0.1,2.6c-0.5,1.4-0.3,2.7,0.1,4
			c0.5,1.4,0,2.5-1,3.4c-0.6,0.5-1.3,1-2.1,1.2c-1.2,0.3-1.7,1.1-1.8,2.1c-0.1,0.7-0.1,1.4,0,2.1c0.1,1.7,0.2,3.4,0.4,5.1
			C965.4,414.4,965.3,415,965.3,415.5z"
            />
            <path
              class="st0"
              d="M968.9,386.5c0-0.6-0.6-1.4-1.2-1.7c-1.1-0.7-2.3-1.1-3.4-1.6c-1.4-0.6-2.9,0-4.3,0.2c-0.3,0-0.7,0.4-0.8,0.7
			c-0.7,1.5-1.3,3.1-1.1,4.9c0.2,2.1,0.2,4.2,0.3,6.4c0.1,1.5,0.2,3,0.5,4.5c0.2,0.8,0.6,1.7,1.6,1.9c1.5,0.3,2.9,0.7,4.4,0.9
			c1.3,0.1,2.4-0.6,2.8-1.5c0.5-1.4,0.2-2.4-1.2-3.4c0.2-0.2,0.3-0.3,0.4-0.4c1.2-0.9,1.9-1.9,1.4-3.5c-0.3-1-0.2-2.1,0.3-3.2
			C969,389.4,969,387.9,968.9,386.5z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M816.1,476.1c1.2,1,3.1,1.1,4.4,0.4c0.9-0.5,1.9-1,2.9-1.5c3.2-1.5,6.4-3,9.6-4.5c2.6-1.2,3.7-3.4,4.9-5.8
			c0.4-0.8,0.3-1.6-0.4-2.2c-0.7-0.6-1.5-0.6-2.3-0.2c-0.4,0.2-0.9,0.5-1.3,0.8c-1.7,1.3-3.4,2.6-5.1,3.8c-0.9,0.7-1.9,1.2-2.9,1.7
			c-2.2,1.2-4.9,1.2-6.9,2.9c-0.4,0.4-1,0.6-1.4,0.9c-0.7,0.5-1.5,0.6-2.3,0.2c-1.1-0.5-1.8-1.3-2.2-2.4c-0.4-1.3-0.8-2.5-1.2-3.8
			c-0.3-0.9-0.6-1.6-1.6-1.9c-1.4,1.1-1.4,2.7-1.5,4.1c-0.2,1.7-0.3,1.8-1.9,2.3c-1.4,0.4-1.6,0.8-1.3,2.2c0.8,4.2,1.2,8.4,1.2,12.7
			c0,1.9,0.2,3.7,1,5.5c1.3,3,5.4,4.3,7.9,0.8c1.3-1.7,2.2-3.7,3.3-5.4c1.5-2.4,3.5-4.4,5.7-6.1c0.1,0.1,0.2,0.2,0.4,0.2
			c-0.2,0.5-0.3,1.1-0.5,1.6c-1.3,3.3-2.5,6.5-3.8,9.8c-0.5,1.3-0.3,1.9,1,2.6c1.1,0.5,2.2,1,3.3,1.5c1.7,0.7,3.4,1,5.2,0.5
			c1.1-0.3,1.5-1,0.9-2c-0.5-0.8-1.1-1.5-1.7-2.2c-1.2-1.2-1.4-1.9-0.6-3.4c1.1-2.3,2.2-4.7,3.4-6.9c1.3-2.2,0.1-5.9-2.8-6.7
			c-2.7-0.8-5.2-0.2-7.4,1.2c-2.4,1.5-4.7,3.2-7,4.7c-0.4,0.3-0.9,0.5-1.6,0.9c0.1-1.7,0.2-3,0.3-4.3c0.1-1.3-0.5-2.5,0-4
			C814.6,474.9,815.4,475.5,816.1,476.1z"
            />
            <path
              class="st0"
              d="M803.6,466.9c-0.3,1.6,0.4,2.2,2.3,2.4c1.6,0.1,1.9-0.1,1.9-1.9c0-1.6,0.7-2.9,1.4-4.2c0.5-0.9,1.3-1,2-0.2
			c0.5,0.6,0.9,1.2,1.2,1.9c0.8,1.7,1.4,3.5,2.1,5.2c0.7,1.6,1.2,1.7,2.8,1c0.2-0.1,0.4-0.3,0.7-0.4c3-1.6,3-1.7,2.1-4.9
			c-0.4-1.5-0.9-3-1.7-4.3c-1.1-1.8-2.5-3.3-3.7-5c-0.4-0.5-0.8-1.1-1.1-1.7c-0.5-1.2-0.2-1.9,0.9-2.4c0.7-0.3,1.5-0.6,2.3-0.7
			c1.3-0.3,2.6-0.5,3.9-0.7c1.2-0.2,2.1-0.8,2.8-1.7c0.9-1.1,1.9-2.2,2.6-3.4c0.6-1,1-2.2,1-3.3c0.1-1.3-1.5-2.3-2.5-1.7
			c-1,0.7-1.9,1.6-2.9,2.4c-0.3,0.3-0.6,0.5-1.1,1c-0.3-1.5-0.4-2.7-0.7-3.9c-0.2-0.7-0.5-1.4-0.9-2c-0.5-0.7-1.7-0.9-2.6-0.6
			c-0.9,0.3-0.7,1.1-0.7,1.7c-0.1,1.9-0.2,3.9-0.4,5.8c0,0.4-0.3,1-0.7,1.2c-1.2,0.6-2.6,0-3.4-1.1c-1-1.6-2-3.2-3.2-4.6
			c-2.1-2.4-2.8-2.3-4.4,0.7c1,1.8,1.9,3.6,2.9,5.5c0.3,0.6,0.8,1.3,0.8,1.9c-0.1,3.3,0,6.6-1.5,9.8
			C804.4,461,804.1,464,803.6,466.9z"
            />
            <path
              class="st0"
              d="M828.1,453.2c-1.7-1-3.6-1.5-5.6-1.4c-1.8,0.1-3.6,0.4-5.4,0.6c-0.4,0-0.8,0.1-1.1,0.3
			c-1.2,0.5-1.5,1.5-0.8,2.6c0.4,0.6,0.8,1.1,1.4,1.5c1.3,1.1,2.4,2.4,3,4c0.4,1,1.2,1.4,2.2,1.4c0.4,0,0.9,0,1,0
			c1.2-0.1,2-0.2,2.8-0.3c2.1-0.2,3.4-1.5,4.2-3.3C830.7,456.4,830.2,454.5,828.1,453.2z M823.2,457.4c-0.8,0.1-1.4-0.4-1.4-1.7
			c0.8,0.1,1.4,0.1,2.4,0.2C823.8,456.7,823.6,457.4,823.2,457.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M864.6,487.3c0.3,1,1,2.1,1.9,2.8c3.7,2.9,7.5,5.7,12.5,6.2c5.2,0.6,10.3,0.8,15.3-0.7c3.3-1,6.7-1.9,10-2.9
			c3.5-1,7-2.1,10.2-4c2.9-1.7,5.8-3.6,8.8-5.2c2.2-1.2,4-2.8,5.5-4.7c0.7-1,1.2-2.2,1.6-3.3c0.4-1.1-0.2-1.7-1.4-1.6
			c-1,0.1-2,0.5-2.8,1c-3.8,2.3-7.3,4.9-11.4,6.7c-2.7,1.3-5.4,2.8-8.1,4.1c-4.5,2.1-9.3,3-14.2,4c-3.6,0.8-7.2,1.3-10.9,0.7
			c-2.7-0.4-5.4-1.3-7.5-3.2c-2-1.8-2.7-4.2-3.6-6.5c-0.4-1-0.4-2.2-0.4-3.3c0-1.3,0.7-1.7,2-1.7c1.4,0.1,2.7,0.7,3.9,1.3
			c1.1,0.5,1.7,1.2,1.7,2.5c0,1.1,0.6,1.7,1.6,2.1c1,0.4,1.9,0,2.6-0.6c2.6-2.3,2.5-6-0.3-8.1c-1.6-1.2-3.2-2.5-5.2-3
			c-0.6-0.2-1.2-0.6-1.8-1c-0.1-1.2-0.1-2.2-0.3-3.3c-0.3-1.7-1.2-2.1-2.7-1.3c-0.8,0.4-1.6,0.8-2.6,1.4c0-0.7,0-1,0-1.4
			c-0.1-0.7-0.2-1.4-0.4-2c-0.4-0.9-1.9-1.8-2.7-1.7c-1,0.1-1.3,1.1-1.4,1.8c-0.3,1.4-0.4,2.8-0.4,4.2c0,1.5-1.1,2.3-2.5,1.7
			c-0.3-0.1-0.7-0.4-1-0.6c-1.4-1-2.7-2-4.1-3c-2-1.5-3.9-3-7-2.4c0.6,1.4,1,2.7,1.7,3.8c2,2.9,4.4,5.3,7.9,6.4
			c0.8,0.3,1.6,0.5,2.3,0.8c0.8,0.3,1.3,0.8,1.3,1.7c0,0.4,0,0.9,0,1.3C862.5,480,863.4,483.7,864.6,487.3z"
            />
            <path
              class="st0"
              d="M872,457.6c2.4,0.4,4.8,1.2,7.3,1.2c2.3,0,4.6,0.4,6.9,0.7c3.7,0.5,7.4,0.5,11.1,0c4.7-0.6,8.9-2.5,12.8-5.2
			c2.6-1.8,5.1-3.7,7.2-6c0.6-0.7,1-1.7,1.4-2.6c0.4-0.9-0.1-1.6-0.7-2.2c-0.6-0.6-1.3-0.4-1.9-0.1c-3.6,1.5-7.2,3-10.9,4.4
			c-2.5,0.9-5.1,1.6-7.8,2.3c-3,0.8-6.1,0.6-9.2,0.7c-3.9,0.1-7.8,0.3-11.7,0.1c-3.9-0.3-7.8-1.2-11.7-1.8c-0.1,0-0.1,0-0.2-0.1
			c-1.9-1.1-4.2-0.9-6.2-1.5c-1.4-0.4-2.9-0.1-3.7,1.3c-0.9,1.6-1.7,3.3-2.6,5.2c0.6,0.2,0.9,0.4,1.2,0.4c4.3,0.8,8.5,1.6,12.8,2.4
			C868,457,870,457.3,872,457.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M829.7,364.9c-4.3,0.9-8.6,1.4-13,1.1c-2.1-0.1-4.2-0.9-6.2-1.4c-0.8-0.2-1.5-0.4-2.2-0.6
			c-1.1-0.4-2.3-0.6-3.1-2c7.1-2.3,14-3.3,20.8-1.5c-1.5-1.7-2.9-3.4-4.4-5.1c-3.3-0.1-6.6,0.3-9.9,0.6c-0.1,0-0.2,0.1-0.3,0.1
			c-2.6,0.3-5,1-7.5,1.8c-2.2,0.8-4.4,1.5-6.6,2.2c-0.3,0.1-0.5,0.3-0.8,0.5c-1.2,0.8-1.3,1.3-0.1,2.1c1.3,1,2.6,1.8,4,2.7
			c1.6,1,3.1,2.2,4.9,3c3.1,1.4,6.3,2.6,9.8,2.6c1.4,0,2.9,0.1,4.3,0.1c2.8,0.1,5.6-0.1,8.4-0.9c1.8-0.5,3.5-1.1,5.2-1.6
			C831.8,367.4,830.8,366.1,829.7,364.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M810.2,397.4c0-1.5-0.4-2-1.9-2.3c-0.8-0.1-1.5-0.2-2.3-0.2c-3,0-3.2,0.2-3.4,3.2c-0.1,1.4-0.1,2.7-0.1,4.1
			c-0.1,2.1-0.3,4.3-0.3,6.4c0,2.9,1,3.8,3.8,4c0.2,0,0.5,0,0.7,0c2.2,0.1,4.4,0.2,7.3,0.3c3.1,0,6.8-0.1,10.6-0.1
			c0.8,0,1.7,0,2.5-0.1c1.3-0.2,1.6-0.5,1.9-1.7c0.2-0.8,0.2-1.6,0.3-2.5c0.2-2.3-0.1-2.7-2.4-3.2c-0.3-0.1-0.7-0.1-1.1-0.1
			c-3.6-0.2-7.2-0.4-10.7-0.6c-1.2-0.1-2.4-0.1-3.6-0.3c-1.4-0.2-1.6-0.5-1.6-1.8c0-0.5,0-0.9,0-1.4
			C810,399.9,810.1,398.7,810.2,397.4z"
            />
            <path
              class="st0"
              d="M822.4,376.4c-1.7,0.3-2.4,1.9-3.7,2.5c-1.5,0-2.6-1.5-4.2-1c0.4,1.3,0.8,2.6,1.2,3.8c0.7,2.4,1,4.9,0.9,7.4
			c-0.1,2.1-0.3,4.1-0.5,6.2c-0.1,1.5,0.2,2.8,0.8,4.1c0.8,1.4,2.3,2.2,3.9,2c0.7-0.1,1.5-0.1,2.1-0.4c1.1-0.7,2.4-1.5,3.2-2.5
			c0.5-0.6,0.5-1.9,0.3-2.7c-0.4-1.7-0.9-3.5-1.7-5c-1.2-2.4-2-4.9-2.5-7.5c-0.2-1.2-0.2-2.4,0.1-3.7
			C822.5,378.6,822.9,377.6,822.4,376.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M848.6,647.9c-1.3-0.2-2.6-0.2-4-0.4c-3.7-0.5-7.4-0.7-11-1.8c-2.2-0.6-2.5-0.8-2.6-3c-0.2-4-0.2-8-0.3-12
			c-0.1-2.1-0.3-4.2-0.4-6.4c0-0.6-0.1-1.3-1.2-1.5c-3.2,6.3-5,13.1-8.2,19.6c-2.5-0.1-4.6-0.9-6.8-1.8c-3.5-1.4-6.9-2.7-10.4-3.9
			c-1.5-0.5-3-0.9-4.6,0c0.3,1.5,2,4.4,3.4,5.7c2.9,2.8,5.7,5.6,8.6,8.4c0.7,0.7,1.2,1.4,1.9,2.2c-0.4,0.5-0.8,0.9-1.1,1.4
			c-2.8,3.2-5.7,6.3-8.5,9.6c-1,1.2-1.6,2.7-2.3,4.1c-0.2,0.4-0.2,1-0.3,1.8c8.3-1.2,15-5.4,21.9-9.2c0.5,0.6,1,1.3,1.5,1.9
			c2.4,3.3,4.3,6.9,6,10.6c0.6,1.5,1.5,2.7,2.9,3.6c1.4,0.9,2.9,0.2,2.9-1.5c0-1.1,0-2.2-0.2-3.2c-0.8-3.4-1.6-6.8-2.4-10.2
			c-0.5-2.2-0.9-4.4-1.4-6.6c0.4-0.2,0.7-0.3,0.9-0.3c4.8-0.7,9.4-2.5,14-4c1.3-0.4,2.6-1.2,3.5-2.6
			C849.8,648.2,849.2,648,848.6,647.9z M828.4,654.6c-1.4,2-3.5,2-5.5,1.1c-2.2-1-3-2.9-3.5-5c-0.4-1.8,0.6-3.7,2.4-4.4
			c0.9-0.4,1.9-0.5,2.9-0.8c2.8,0.1,4.5,1.4,4.8,4.1C829.7,651.4,829.5,653.1,828.4,654.6z"
            />
            <path
              class="st0"
              d="M824.9,653.1c1.2,0,2.1-1.4,2.1-3.3c0-1.6-0.7-2.2-2.4-2.2c-1.4,0-2.9,1.5-2.9,2.8
			C821.8,651.5,823.5,653.1,824.9,653.1z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M921.7,643.9c0-1,0-2,0.1-3c0.1-1.3,0-1.5-1.3-1.7c-0.6-0.1-1.2-0.2-1.9-0.2c-0.2-1.6-0.3-3.1-0.5-4.6
			c-0.2-1.6-0.8-2.2-2.4-2.3c-1-0.1-1.5,0.5-1.7,2.2c-0.1,0.9-0.1,1.8-0.2,2.6c-0.1,0.6-0.2,1.2-0.3,1.9c-1.2-0.1-2.2-0.1-3.2-0.2
			c-1.3-0.2-1.4-0.3-1.6-1.6c-0.1-1.1-0.2-2.1-0.3-3.2c-0.2-1.3-1-2.2-1.9-2.3c-1.3-0.1-2.1,0.3-2.6,1.7c-0.2,0.6-0.4,1.2-0.5,1.8
			c-0.2,1.4-0.3,2.8-0.4,4.3c-1.5,0-2.8,0-4.2,0c-1.1,0-1.6-0.6-1.7-1.6c-0.1-1.1-0.3-2.2-0.3-3.4c0-1.3-0.6-1.8-1.8-1.9
			c-1.7-0.2-2.5,0.2-2.8,1.5c-0.3,1-0.5,2.1-0.6,3.1c-0.1,0.8-0.5,1.3-1.3,1.3c-1.2,0-2.4-0.1-3.7-0.2c-0.1-0.8-0.2-1.6-0.3-2.3
			c-0.1-0.9-0.2-1.9-0.5-2.8c-0.4-1.2-1.6-1.8-2.9-1.5c-1.2,0.3-1.8,1.1-2,2.3c-0.2,1.4-0.2,2.7-0.3,4.3c-0.3,0.1-0.8,0.3-1.2,0.4
			c-3.4,0.6-6.8,0-10.1-0.7c-1-0.2-1.9-1-2.7-1.7c-1.3-1.3-1.4-2.9-0.3-4.4c0.4-0.6,0.8-1.1,1.2-1.7c0.6-1,1-2,0.6-3.2
			c-1.4-0.7-2.7-0.4-3.8,0.5c-1.4,1.2-2.6,2.5-3.9,3.8c-1.3,1.4-1.5,3.2-1.1,4.9c0.2,1.1,0.7,2.1,1.2,3.1c1.6,2.9,3.9,4.9,7.3,5.4
			c3.2,0.5,6.4,0.7,9.6,1c1.6,0.1,3.3,0,4.9,0c2.6-0.1,5.2-0.3,7.7-0.4c3.5,0,7.1,0.5,10.6,0.5c3.8,0,7.5,0.9,11.2,0.6
			c2.6-0.2,5.3-0.1,7.9-0.2C920.9,646.4,921.7,645.3,921.7,643.9z"
            />
            <path
              class="st0"
              d="M910.1,670.9c-0.6-3.3-1-6.6-1.1-9.9c-0.1-2.4-0.4-4.6-1.3-6.8c-0.3-0.6-0.7-1.1-1.2-1.9
			c-1.4,1.6-2.2,3.2-2.8,4.9c-1.1,2.7-2.2,5.5-3.3,8.2c-0.5,1.3-1.2,2.5-1.8,3.7c-0.7,1.5-1.9,2-3.6,1.7c-2.3-0.3-4.6-0.6-6.9-0.8
			c-3.3-0.4-6.5-0.1-9.8,0.2c-1.8,0.1-3.5,0.3-5.3,0.3c-2.3,0-2.9-0.5-3.2-2.7c-0.1-0.6-0.1-1.2-0.3-1.9c-0.5-2.3-0.9-4.7-1.5-7
			c-0.4-1.4-1.5-2-3.1-2c-1.1,0-1.6,0.4-2.2,2c-0.1,0.4-0.3,0.8-0.3,1.3c-0.1,4.9-0.3,9.8,0.1,14.6c0.2,2,0.6,2.5,2.7,2.6
			c1.6,0.1,3.1-0.1,4.7-0.1c3.2-0.1,6.4-0.2,9.6-0.2c2.4,0,4.8,0.2,7.2,0.2c2,0,4-0.2,6-0.2c2.6-0.1,5,0.6,7.1,2.1
			c0.9,0.6,1.9,1.3,2.8,1.9c1.4,1,2.8,1.4,4.6,0.9c2.3-0.8,4.1-2.2,5.7-3.8c1.5-1.4,1.5-2.5,0.1-4c-0.3-0.4-0.9-0.5-1.2-0.9
			C911.3,672.5,910.3,671.8,910.1,670.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M947.3,490.4c-0.1,2.2,1.6,3.7,3.8,2.9c2.7-1,4.7-2.5,5.2-5.6c0.3-1.8,0.9-3.6,1.6-5.4c1.1-3.1,1.7-3.2,4-0.9
			c1.4,1.4,3.1,1.9,5,1.6c2-0.3,2.9-1.2,3.2-3.3c0.3-2,0.8-4.1,0.7-6.1c-0.1-5.5-0.7-8-6.7-10.4c-0.8-0.3-1.5-0.7-2.2-1
			c-0.9-0.3-1.8-0.6-2.7-0.9c-1.6-0.5-3.4-0.8-4.4-2.3c-1.2-1.7-2.2-3.6-3.1-5.6c-1.5-3.5-2.8-7.1-4.3-10.6c-0.5-1.1-1.1-2.1-1.8-3
			c-0.6-0.8-1.4-0.9-2.4-0.7c-0.9,0.3-1.3,0.9-1.3,1.8c0,1.2-0.1,2.4,0,3.5c0.3,3.7,1.6,7.2,2.6,10.7c0.9,2.9,1.7,5.8,2.4,8.7
			c0.4,1.8,0.4,3.7,0.6,5.6c0.1,1.5,0.3,3,0.3,4.5c0,3.6-0.1,7.1-0.2,10.7C947.6,486.4,947.4,488.2,947.3,490.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M987.8,459.6c-0.1,0.7-0.1,1.1-0.2,1.6c-0.2,4.6,0,9.2,0.5,13.7c0.5,4.5,1.5,8.7,4.2,12.5
			c1.3,1.9,2.9,3.2,5,4.1c2,0.8,3.9,0.2,5.3-1.5c1-1.3,1.6-2.7,1.8-4.3c0.4-2.9,0.8-5.7,1.1-8.6c0.4-3.3,1-6.6,3-9.4
			c1-1.4,2-2.8,2.9-4.1c0.4-0.6,0.7-1.2,1.3-2.1c-2.1-0.4-3.9-0.9-5.7-1c-2.6-0.2-5.3-0.5-7.9,0.3c-1.7,0.6-2.1,1.1-1.6,2.8
			c0.5,1.9,1.2,3.7,1.8,5.5c1.5,4.4,1.9,8.8,0.1,13.2c-0.2,0.5-0.5,1.1-0.8,1.5c-1,1.1-2.1,1.2-3,0c-0.6-0.8-1.2-1.7-1.4-2.6
			c-0.3-1.5-0.5-3-0.5-4.5c-0.2-5.4,0.2-10.8,0.9-16.2C992.7,458.5,990.3,459.7,987.8,459.6z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M1029.2,490.5c3.2,0.8,6.4,1.3,9.7,1.5c1.9,0.1,3.4-1.1,3.7-3c0.2-1,0.2-2,0.3-3c0.2-4.5,0.4-9,0.5-13.5
			c0.1-2.3,0-4.6-0.2-6.9c-0.1-1.7-0.8-2.5-2.1-2.7c-2.6-0.4-5.2-0.8-7.9-1c-2-0.1-4,0-5.9,1.1c-0.7,3.5-0.8,7.1-0.7,10.6
			c0.1,3.6,0.3,7.1,0.5,10.7c0.1,1.2,0.3,2.4,0.3,3.6C1027.3,489.7,1027.4,490,1029.2,490.5z"
            />
            <path
              class="st0"
              d="M1059.7,464.3c-0.8-1.7-4.7-2.2-6-0.8c-0.2,0.3-0.4,0.6-0.4,1c-0.1,0.8-0.3,1.6-0.3,2.4
			c0.1,4.3,0.1,8.6,0.3,13c0.1,3.6,0.5,7.2,0.7,10.9c0,0.9,0.4,1.6,1.1,2.1c1.6,1.1,4.4-0.1,4.8-2c0.1-0.4,0.1-0.8,0.1-1.1
			c0.3-4.8,0.5-9.5,0.8-14.3c-0.1,0-0.2,0-0.2,0c0-2.7,0-5.4-0.1-8.1C1060.4,466.3,1060.1,465.2,1059.7,464.3z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M920.4,377.2c0.7,0,1.3,0,1.9,0c1.6-0.1,1.9-0.3,2.2-2c0.2-1.1,0.3-2.2,0.4-3.3
			C923.4,373.7,921.9,375.5,920.4,377.2z M943.8,353.4L943.8,353.4c0-0.4,0.1-0.7,0.1-1c0-0.3,0-0.7,0-1c0-0.3,0-0.5-0.1-0.7
			c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c-4.7,5.5-9.3,10.9-14,16.4c-0.3,2,0,2.4,2.1,3.9c0.2,0.2,0.5,0.2,0.8,0.3
			c0.7-1,1.4-2,2.1-3c0.3,1.2,0.3,2.3,0.1,3.4c-0.8,7.3-1.3,14.7-1,22c0.1,1.3,0,2.6-0.1,3.8c-0.2,2.4-0.5,2.6-2.8,2.4
			c-1.2-0.1-2.3-0.2-3.5-0.1c-1.7,0.1-3.4,0.4-5.1,0.4c-1.6,0-3.1-0.1-5-0.2c-0.1-2.2-0.4-4.1-0.3-5.9c0.1-4.2,0.3-8.4,0.4-12.6
			c-2.9,3.4-5.8,6.8-8.7,10.2c0,0.7,0,1.3,0,2c0,1.8,0,3.6-0.1,5.4c0,1.1-0.6,1.8-1.7,1.8c-1.7,0-3.4,0.1-5.1,0
			c-0.3,0-0.5-0.1-0.8-0.1c-3,3.5-6,7-9,10.5c0.7,0.4,1.4,0.8,2.1,1.1c1.3,0.6,2.6,1,3.8,1.8c3.8,2.3,7.8,3.8,12.2,4
			c1.4,0.1,2.9,0.2,4.3,0c2.9-0.4,5.8-1.1,8.7-1.7c0.9-0.2,1.9-0.5,2.8-0.8c3.8-1.5,7.3-3.7,10.6-6.3c2-1.6,3.2-3.7,3.7-6.3
			c0.4-2.3,0.4-4.6,0.4-6.9c0-2.7-0.2-5.4-0.1-8.1c0.1-5,0.2-9.9,0.3-14.9c0.1-4,0.4-7.9,1.8-11.7c0.7-1.7,1.4-3.5,1.9-5.2
			C944.8,354.4,944.3,354,943.8,353.4z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M899.6,528.4c-0.1-0.1-0.2-0.3-0.3-0.4c-2.4-2.3-5.2-3.6-8.6-3.6c-3,0-5.3,0.9-6.6,3.6
			c-1.2,2.3-2.3,4.7-3.4,7c-0.9,1.9-1.8,3.9-2.6,5.9c-1,2.3-2.1,4.5-3.1,6.8c-1,2.3-2.1,4.6-3.1,7c-0.9,1.9-1.9,3.8-2.8,5.8
			c-2,4.4-3.7,9-5.9,13.3c-1.6,3.2-2.5,6.6-3.6,9.9c-0.2,0.6-0.2,1.2-0.4,2.1c1.4-0.3,2.3-0.8,2.9-1.7c1.3-1.6,2.5-3.2,3.7-4.8
			c3.5-5,7.4-9.7,11.6-14.1c2.1-2.2,4.4-4.1,6.8-5.9c1.8-1.4,3.8-2.5,5.7-3.7c2.4-1.6,4.1-3.8,4.4-6.6c0.4-3.6,0.6-7.3,0.1-10.9
			c-0.2-1.5-0.3-3.1-0.4-4.7c-0.1-0.9,0.2-1.5,1-1.8c0.4-0.2,0.8-0.3,1.1-0.5c1.3-0.8,2.8-1.2,3.7-2.4
			C899.6,528.5,899.6,528.5,899.6,528.4z M891.3,528.6c-0.2-0.1-0.4-0.7-0.3-0.9c0.5-0.7,1-0.3,1.7,0
			C892.4,528.6,892,529.1,891.3,528.6z"
            />
            <path
              class="st0"
              d="M886,578.9c-0.4,2.3,0.6,4.1,2.8,5.5c0.2-1.1,0.3-2,0.5-2.9c0.3-2.2,0.7-4.4,1.6-6.5c1.3-3,2.7-6.1,3-9.4
			c0.4-3.6,0.6-7.2-0.8-10.7c-1.7,1.1-2.1,1.6-2.2,3.4c-0.1,0.9-0.1,1.8-0.3,2.6c-0.9,3.8-2,7.5-3,11.3
			C887.2,574.5,886.5,576.7,886,578.9z"
            />
            <path
              class="st0"
              d="M887.4,558.8c-2.1,1.2-4.3,2.3-6.1,4.1c-2.2,2.3-4.5,4.7-6.6,7.1c-0.9,1-1.8,2-2.5,3.1
			c-2.4,3.5-4.7,7-7.5,10.2c-1.6,1.8-2.1,4.2-2.9,6.6c0.5,0,0.9,0.2,1.3,0.1c2.7-0.5,5.4-0.8,8.1-0.5c0.7,0.1,1.1-0.4,1.3-0.9
			c0.9-2.1,1.8-4.2,2.7-6.4c0.5-1.4,0.9-2.8,1.4-4.2c0.1,0,0.3,0,0.4,0c0.6,1.4,1.2,2.8,1.9,4.4c1.5-0.9,2.8-1.8,4.2-2.6
			c1.5-0.9,2.8-1.9,2.7-3.9c0-0.2,0.1-0.3,0.1-0.5c0.7-2.6,1.4-5.3,2-7.9c0.2-0.8,0.4-1.6,0.6-2.3c0.6-2.1,1-4.3,0.7-6.8
			C888.6,558.5,887.9,558.5,887.4,558.8z"
            />
            <path
              class="st0"
              d="M876,591.2c0.9-0.1,1.8-0.4,2.7-0.7c2.5-1.1,5-1.3,7.7-0.9c1.9,0.3,3.8,0.5,5.7,0.8c1.1,0.1,2.2,0,3.2-0.8
			c-0.3-1.4-0.9-2.5-2.3-2.9c-1.2-0.4-2.4-0.9-3.7-0.9c-2.3-0.1-3.5-1.3-4.1-3.3c-0.2-0.7-0.5-1.4-0.8-2c-1.8,0.7-1.8,0.7-3.5,3
			c0.7,2.2,0.7,2.2-1.3,3c-0.2,0.1-0.4,0.1-0.7,0.2c-1.5,0.7-2.8,1.6-3.7,3C874.4,590.6,874.8,591.3,876,591.2z"
            />
            <path
              class="st0"
              d="M900.4,589.9c-0.2-2.5-2.8-4.4-5-3.6c0.4,0.6,0.9,1.2,1.2,1.9C897.5,589.9,898.3,590.3,900.4,589.9z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M858.2,549.8c2.1,0.3,3.1-1.1,2.2-3c-0.3-0.6-0.6-1.3-1.1-1.8c-2.7-3.1-4.3-6.7-5.9-10.4
			c-1-2.3-2.2-4.5-3.3-6.7c-0.4-0.8-1.1-1.3-2-1.2c-0.9,0-1.3,0.7-1.5,1.5c-0.2,0.6-0.3,1.3-0.2,2c0.1,2.5,0.3,5,0.4,7.5
			c0.1,2.3,0,4.6,0.1,6.9c0.1,2.8,0.3,5.5,0.5,8.2c0.3,4.7,0.6,9.5,0.8,14.2c0.1,1.6,1,2.6,2.4,3.6c0.4-0.6,0.9-1.1,1-1.6
			c0.3-1.6,0.5-3.2,0.5-4.8c-0.1-4.4-0.9-8.8-0.2-13.3c0.5-3,0.8-3.3,3.7-2C856.4,549.3,857.3,549.7,858.2,549.8z"
            />
          </g>
          <g>
            <path
              class="st0"
              d="M838,569.6c-2.7-1.9-3.5-4.9-4.3-7.7c-1.3-4.8-2-9.8-2.8-14.7c-0.5-2.8-0.8-5.5-1.1-8.3
			c-0.2-1.7-1.1-2.9-2.2-4c-0.5-0.5-1.3-0.8-2-1.3c0.1-1.1,0.3-2.2,0.3-3.3c-0.1-4.2-2.4-5.8-5.8-6c-1.7-0.1-3,0.6-3.6,2.2
			c-0.6,1.4-1.1,2.8-1.4,4.2c-0.4,1.6-1,2.9-2.8,3.5c-1.7,0.6-2.6,1.9-2.5,3.8c0.1,1.2,0,2.3-0.1,3.5c-0.1,3.8-0.2,7.7-0.4,11.5
			c-0.2,3.3-0.6,6.5-0.8,9.7c0,0.5,0,1.1,0.1,1.5c0.3,1.2,1.4,1.5,2.2,0.6c0.5-0.6,0.8-1.4,0.9-2.1c0.8-5.5,1.5-11.1,1.4-16.7
			c0-2,0.2-4,0.3-6c0.1-0.7,0-1.6,1.1-1.9c0.7,0.7,0.4,1.5,0.4,2.2c-0.1,2.1-0.3,4.3-0.3,6.4c0,0.7,0.5,1.4,0.8,2.3
			c-0.2,0.7-0.5,1.6-0.6,2.6c-0.6,3.7-1.1,7.3-1.6,11c-0.3,2.2,0.1,3,2.2,3.7c1.4,0.5,2.9,0.9,4.3,1.3c1.4,0.4,2.8,1,4.3,1.4
			c1.1,0.3,1.4-0.1,1.1-1.2c-0.3-1.3-0.8-2.6-1-4c-0.6-3.8-1.2-7.7-1.8-11.6c-0.1-0.7-0.5-1.4-0.7-2c1.2-1.8,1.1-4.1,1.6-6.2
			c0.2,0,0.3-0.1,0.4-0.1c0.4,1.2,1,2.4,1.3,3.6c0.9,3.9,1.8,7.9,2.6,11.8c0.6,2.9,1.2,5.7,2.5,8.4c0.4,0.7,0.8,1.6,1.4,2.1
			c1.4,1,3,1.9,4.5,2.7c1.2,0.6,1.8,0.4,2.3-0.5C838.9,571.2,838.9,570.3,838,569.6z M821.5,531.7c-0.2,1.8-0.5,3.6-0.7,5.4
			c-0.1,0.8-0.3,1.6-1.4,1.5c-0.9-0.1-1.2-0.5-1.2-1.8V536c-0.1,0-0.1,0-0.2-0.1c0.4-2.1,0.8-4.3,1.4-6.4c0.1-0.4,0.6-0.7,0.9-1.1
			c0.4,0.4,0.9,0.7,1.1,1.2C821.5,530.2,821.6,531,821.5,531.7z"
            />
            <path
              class="st0"
              d="M807.5,552.7c0.4-6.4,1-12.7,0.9-19.1c0-2.7,0.2-5.5,0.3-8.2c0-0.5,0-0.9-0.1-1.3c-0.2-0.7-0.6-1.3-1.5-1.3
			c-0.8,0-1.6,0.2-1.8,1.1c-0.3,1.1-0.7,2.3-0.8,3.4c-0.2,2.3-0.1,4.7-0.2,7c0,1.7-0.1,3.4-0.2,5.1c-0.2,3.6-0.5,7.2-0.5,10.7
			c-0.2,6.6-0.1,13.2-0.4,19.7c-0.2,3.9-0.9,7.8-1.2,11.6c-0.2,2.7-0.3,5.5-0.4,8.2c0,1.2,0.6,1.9,1.8,2.5c2.6,1.2,5.3,1.1,8.1,1.2
			c3.2,0,6.4,0,9.6-0.1c0-0.1,0-0.1,0-0.2c4.6,0.1,9.3,0.2,13.9,0.2c0.8,0,1.7-0.3,2.5-0.5c0.8-0.2,1-0.8,0.8-1.5
			c-0.2-1.1-0.7-1.6-2.3-1.7c-4.1-0.3-8.2-0.6-12.4-0.8c-3-0.2-6-0.4-9-0.6c-1.8-0.1-3.5-0.1-5.3-0.1c-0.5,0-0.9,0-1.4,0
			c-0.7,0-1.1-0.4-1.1-1.1c0-1.7-0.1-3.5-0.1-5.2c0-1.4,0-2.9,0-4.3C807,569.3,807,561,807.5,552.7z"
            />
            <path
              class="st0"
              d="M823.3,587.3c2.3,0,4.6,0.1,6.8,0.1c0.6,0,1.2-0.4,2-0.6c-1.1-1.9-2.2-3-4-3.5c-0.7-0.2-1.6-0.6-1.9-1.2
			c-1.2-2.8-2-5.7-2.1-8.8c0-1-0.2-2-0.3-2.9c-1.1-0.2-1.8-0.3-2.6-0.4c-0.8-0.1-1.5,0-2.3,0c-0.1,0.4-0.2,0.7-0.2,0.9
			c0.5,5.2,1.5,10.3,3,15.3C822,586.8,822.5,587.2,823.3,587.3z"
            />
            <path
              class="st0"
              d="M814.5,584.1c-1.1-0.7-1.4-1.7-1.2-2.9c0.1-0.5,0.2-1,0.3-1.5c1-3.7,2-7.4,3.2-11.4c-0.9-0.2-1.8-0.4-2.8-0.6
			c-0.7-0.1-1.1,0.1-1.4,0.8c-1.9,4.3-3.4,8.8-4,13.4c-0.2,1.2,0.2,2.1,1,3c1.1,1.2,2.4,1.8,3.9,2.2c1.1,0.3,2.3,0,3.3-1.2
			C816,585.3,815.3,584.6,814.5,584.1z"
            />
          </g>
          <g>
            <g>
              <path
                class="st0"
                d="M908.8,584.6c0.4,3.1,1.4,3.9,3.8,4.1c1.4,0.1,2.9,0.1,4.3,0.1c2.2,0,4.4,0,6.6,0c0.8,0,1.5,0.2,2.3,0.1
				c2.9-0.4,5.8-0.2,8.7,0.1c1.7,0.1,3.4,0.2,5.1,0c3.6-0.4,3.6-0.5,3.9-4.1c0.6-7,0.7-14,1-21c0.1-2.6-0.5-3.4-3.1-3.7
				c-3.3-0.4-6.6-0.6-10-0.9c-0.3,0-0.5,0-0.8,0c-0.9,0-2,0.1-2.2,1.1c-0.3,1.2-0.2,2.5-0.3,3.7c0,0.2,0.5,0.4,0.8,0.5
				c0.6,0.2,1.2,0.3,1.8,0.4c1.3,0.3,2.6,0.6,3.8,0.9c0.1,0.4,0.2,0.8,0.2,1.1c-0.3,3.7-0.7,7.4-1,11c-0.1,0.8-0.5,1.2-1.4,1.3
				c-0.6,0-1.3,0.1-1.9,0c-3.2-0.2-6.4-0.4-9.6-0.6c-2-0.1-2.1-0.1-2.2-2.2c-0.1-2.8-0.1-5.6-0.1-8.5c0-0.8,0.2-1.7,0.4-2.6
				c0.8,0,1.3,0,1.9,0c1.9,0,2.3-0.4,2.5-2.3c0.3-2.9-0.4-3.7-3.3-3.4c-3.3,0.2-6.5,0.5-10.1,0.8c-0.4,2.1-1.3,4.1-1.2,6.4
				c0.1,3.4,0.1,6.8,0.1,10.2C908.6,579.5,908.5,582.1,908.8,584.6z"
              />
            </g>
            <path
              class="st0"
              d="M936.4,537.3c-1.1-2.8-3.6-4.3-6.1-5.6c-4.2-2.4-8.3-0.7-11.2,2.5c-2.2,2.5-3.7,5.8-3.6,9.3
			c0.1,3.9,1.6,7.1,5.1,9c1.7,0.9,3.6,1.1,5.5,1.1c4.7,0,8.6-2.5,10.1-6.8c0.6-1.8,1-3.8,1-5.7C937.4,539.9,936.9,538.5,936.4,537.3
			z M932.8,543.2c-0.5,2.9-2.9,5.3-6.5,5.2c-0.6,0-1.1,0-1.5,0c-2.6-0.2-4.3-1.9-4.5-4.5c-0.2-3.3,1.2-6,3.7-8.1c1.2-1,2.6-1,4-0.5
			c1.4,0.5,2.8,1.2,4,2.1c1.1,0.7,1.2,2,1.2,3.2C933.1,541.5,932.9,542.4,932.8,543.2z"
            />
          </g>
        </g>
        <path
          class="st0"
          d="M1504,2580.4c-71-83.1-142-166.2-213.1-249.3c-4.2-4.9-4.8-4.9-9.1,0.1c-16.4,19.3-32.9,38.5-49.3,57.8
	c-1.1,1.3-2.4,2.6-3.7,4c-1.3-1.4-2.3-2.5-3.3-3.6c-54-63.2-108.1-126.4-162.1-189.6c-4.2-4.9-4.7-4.9-9,0.1
	c-29.6,34.6-59.1,69.2-88.7,103.8c-16.2,19-32.4,38-49,57.4c-18.2-21.3-36-42.1-53.7-62.8c-4.1-4.8-4.6-4.8-8.7,0
	c-80.9,94.6-161.8,189.2-242.6,283.9c-1.3,1.6-1.8,3.9-2.7,5.9c2.1,0.6,4.3,1.3,6.4,1.6c2,0.3,4,0.1,6,0.1
	c163.2-0.1,326.3-0.1,489.5-0.2c129.7,0,259.3,0,389-0.1c2.8,0,5.5-1.1,8.3-1.7C1506.8,2585.3,1505.8,2582.6,1504,2580.4z
	 M1278.7,2343.9c0.3,0.1,0.6,0.2,0.9,0.4c-7.5,26.8-15,53.6-22.8,81.5c-8.4-9.8-16.1-18.8-24-28.1
	C1248.3,2379.6,1263.5,2361.7,1278.7,2343.9z M1051.4,2212.4c0.3,0.2,0.6,0.3,0.8,0.5c-21.4,76.7-42.9,153.3-64.6,230.9
	c-22.7-26.6-44.7-52.4-66.9-78.4C964.4,2314.3,1007.9,2263.4,1051.4,2212.4z M769,2584.1c-47.8-0.3-95.6-0.1-143.4-0.1h-8
	c78.2-91.4,155.7-182,233.2-272.6c0.3,0.1,0.7,0.2,1,0.3c-3.8,13.6-7.5,27.2-11.3,40.8c-21,75-42,150-62.8,225
	C776.2,2582.4,774.4,2584.1,769,2584.1z M781.8,2583.4c26-93,52-186,78.2-280c80.1,93.7,159.4,186.5,239.2,280H781.8z
	 M1378.4,2583.9c-88.8,0-177.6,0-266.4,0.1c-3.6,0-5.7-1.2-8-3.8c-36.3-42.6-72.7-85.1-108.9-127.8c-1.4-1.7-2.4-4.9-1.8-6.9
	c21.9-78.9,44-157.8,66.1-236.7c0.3-0.9,0.6-1.8,1.2-3.7c108.1,126.5,215.5,252.3,323.6,378.7
	C1381.6,2583.8,1380,2583.9,1378.4,2583.9z M1440.7,2583.9c-14.8,0-29.7-0.1-44.5,0.1c-3.2,0-5.2-1-7.2-3.4
	c-41.6-48.8-83.3-97.5-124.9-146.4c-1.3-1.6-2.4-4.6-1.8-6.5c8-29.4,16.2-58.7,24.4-88c0.3-0.9,0.7-1.8,1.3-3.2
	c70.4,82.4,140.5,164.3,211.5,247.3H1440.7z"
        />
        <path
          class="st0"
          d="M1271.5,682.9C1171.3,565.6,1071,448.4,970.8,331.2c-5.9-6.9-6.8-6.9-12.8,0.1c-23.2,27.2-46.4,54.4-69.6,81.6
	c-1.6,1.9-3.3,3.6-5.2,5.6c-1.8-2-3.2-3.6-4.6-5.2C802.4,324.2,726.1,235,649.9,145.8c-5.9-6.9-6.7-6.9-12.7,0.2
	c-41.7,48.8-83.4,97.6-125.1,146.4c-22.9,26.8-45.8,53.6-69.2,81c-25.7-30.1-50.8-59.4-75.8-88.7c-5.8-6.7-6.5-6.8-12.3,0
	c-114.1,133.5-228.3,267-342.3,400.6c-1.9,2.2-2.6,5.5-3.8,8.3c3,0.8,6,1.8,9.1,2.3c2.8,0.4,5.6,0.1,8.5,0.1
	c230.2-0.1,460.4-0.2,690.7-0.2c182.9,0,365.9,0,548.9-0.1c3.9,0,7.8-1.5,11.8-2.3C1275.5,689.8,1274,685.9,1271.5,682.9z
	 M953.5,349.1c0.4,0.2,0.8,0.3,1.3,0.5c-10.6,37.9-21.1,75.7-32.1,115c-11.8-13.8-22.7-26.5-33.9-39.6
	C910.6,399.5,932.1,374.3,953.5,349.1z M632.8,163.6c0.4,0.2,0.8,0.5,1.2,0.7c-30.3,108.2-60.5,216.3-91.1,325.8
	c-32-37.5-63.1-73.9-94.5-110.7C510,307.4,571.4,235.5,632.8,163.6z M234.3,688c-67.4-0.4-134.9-0.2-202.4-0.2H20.7
	c110.4-129,219.7-256.9,329-384.7c0.5,0.1,1,0.3,1.5,0.4c-5.3,19.2-10.6,38.4-16,57.5c-29.6,105.8-59.3,211.6-88.7,317.5
	C244.6,685.7,242,688.1,234.3,688z M252.4,687.2c36.7-131.3,73.3-262.5,110.4-395.1c113,132.2,224.9,263.2,337.6,395.1H252.4z
	 M1094.3,687.8c-125.3,0-250.7,0-376,0.1c-5,0-8.1-1.7-11.2-5.4c-51.2-60.2-102.6-120.1-153.7-180.4c-2-2.4-3.4-6.9-2.6-9.8
	c30.9-111.4,62.1-222.7,93.2-334c0.4-1.3,0.8-2.6,1.7-5.3c152.6,178.5,304.2,355.9,456.6,534.3
	C1098.7,687.6,1096.5,687.8,1094.3,687.8z M1182.2,687.8c-20.9,0-41.9-0.1-62.8,0.1c-4.5,0.1-7.3-1.4-10.1-4.8
	c-58.7-68.9-117.6-137.6-176.2-206.6c-1.9-2.2-3.3-6.5-2.6-9.1c11.2-41.5,22.9-82.9,34.5-124.2c0.4-1.3,1-2.5,1.8-4.4
	c99.4,116.2,198.3,231.9,298.4,349H1182.2z"
        />
      </svg>
    </div>
  );
}

export default AnimeBackground;
