// reactstrap components
import React, { useEffect, useState } from "react";

import Container from "reactstrap/lib/Container";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from "reactstrap";

function Event({ text, location }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  useEffect(() => {
    const common = {
      containment: "#bgndVideo1",
      autoPlay: true,
      mute: true,
      startAt: 0,
      opacity: 1,
      loop: false,
      ratio: "4/3",
      addRaster: true,
    };

    const videos = [
      {
        videoURL: "nwJH1uKt4Zk",
        ...common,
      },
    ];
    if (size) {
      window.jQuery("#bgndVideo1").YTPlaylist(videos, false);
    }
  }, []);

  return (
    <div className="section pt-0" style={{ backgroundColor: !size ? "#000" : "transparent" }}>
      {size && (
        <div
          style={{
            background: "#000",
            position: "absolute",
            top: 0,
            width: "100%",
            zIndex: -100,
            marginLeft: "auto",
            marginRight: "auto",
            pointerEvents: "none",
            // marginTop: "3.5rem",
          }}
        >
          <div
            id="bgndVideo1"
            className="section player"
            style={{ background: "transparent" }}
          ></div>
        </div>
      )}
      {size && <div className="overlay-primary" />}
      <div className="page-header" style={{ background: "transparent" }} data-parallax={true}>
        <Container>
          <Row className="text-700 justify-content-center" style={{ marginTop: "2rem" }}>
            <Col lg={6} className="mb-5">
              <h1 className="text-700 text-primary">Why Attend?</h1>
              <hr />
              <h5 className="text-400 text-white">
                Join us for an unparalleled opportunity to network and connect with over 150+ senior
                leaders from diverse industries. Our carefully curated agenda is designed to help
                you tackle your current business-critical challenges and drive your industry forward
                with innovative ideas and strategies. By attending our summit, you will gain access
                to valuable resources that are guaranteed to help you
              </h5>
              <Button
                href="/delegates/3"
                className="btn my-2 text-center px-5 rounded-0"
                color="primary"
                size="md"
              >
                <b>Learn More</b>
              </Button>
            </Col>
            <Col lg={6}>
              <h1 className="text-700 text-primary">Why Sponsor?</h1>
              <hr />
              <h5 className="text-400 text-white">
                Looking to boost your business development pipeline for 2023 and beyond with
                high-quality leads? Look no further than our event, where your team can engage in
                one-on-one interactions with senior buyers in the financial services industry. These
                buyers are actively seeking to invest millions of dollars in new FinTech software in
                the next 12 months, making this an ideal opportunity for your team to establish
                valuable connections.
              </h5>
              <Button
                href="/partners"
                className="btn my-2 text-center px-5 rounded-0"
                color="primary"
                size="md"
              >
                <b>BECOME A SPONSOR</b>
              </Button>
            </Col>
            <Col lg={6}></Col>

            <Col lg={6}></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Event;
