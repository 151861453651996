export const whosponsor = [
  {
    id: 1,
    title: "Thought Leadership",
    description: [
      "As a sponsor at Finnovex, your company will gain second-to-none exposure to senior-level decisionmakers at the point in time that they are seeking solutions, information and systems for improving their Oranisations’ strategies. For a select few sponsors, you can build your reputation as a market leader through subject-specific presentations, workshops and focus days. This highly selective sponsorship allows your firm to establish tremendous capability and expertise in your specialty as well as highlight successful work completed with your clients.",
    ],
    location: "left",
    image: "1V78K5ha6gqvDjGfXMLVHzz1RWk-1Z9wY",
  },
  {
    id: 2,
    title: "PREMIUM BRANDING",
    description: [
      "We bring together buyers and suppliers for collaboration just like us 10x Meetings (Find out more - ask your show manager/director), networking and knowledge sharing. Branding is often a major initiative for our clients who are seeking to get the message out about their offerings. Build your company’s brand and visibility in front of senior decision-makers in order to get shortlisted. ",
      "As a sponsor, your company branding will appear alongside the global leaders associated with best practices in this field. Our dedicated marketing team will help you achieve your promotional aims in the months leading up to the conference. Exibex leverages multiple marketing channels including online, direct mail, email, press releases, media partnerships and social media like how we have built within a year brand like Finnovex Global Series in Qatar and Africa.Find out more about team working on Finnovex who successfully executed Finnovex Global Series www.finnovex.com",
    ],
    location: "right",
    image: "1A20v1KotoAqslM1EBgwRQx0uYnXTxjM9",
  },
  {
    id: 3,
    title: "FEATURED NETWORKING EVENTS / FACE TIME:",
    description: [
      "Networking and information sharing are two major aspects of our conferences and Exibex builds in many opportunities for sponsors to benefit from meeting industry leaders. Focused and high-level, our summit will provide you with the perfect environment to initiate new business relationships, identify upcoming opportunities and achieve face-to-face contact that overcrowded tradeshows cannot deliver. The exhibition area is designed to be the heart of the event – a place to network and share strategies with key decision makers. Sponsorship opportunities range from exhibition stands to sponsored lunches, cocktail receptions, gala dinners and a host of other branding opportunities.",
      "Additionally, Exibex offers a selection of sponsorship opportunities that enables our clients to increase their opportunity to develop new relationships during our events,we have successfully Introduced more than 100+ Banks,FI meetings with our sponsors find out more on our YouTube Channel.",
    ],
    location: "left",
    image: "1zuMuqVW9O2JXWjXGHSpay6T_kjU15OzJ",
  },
];

export const sponsorsNew = [];

export const sponsors = [
  {
    image: "knoxwire.jpg",
    type: "Lead Sponsor",
    grid: 6,
    link: "https://knoxwire.com/",
  },
  {
    image: "threatLocker.jpg",
    type: "Platinum Sponsor",
    grid: 6,
    link: "https://www.threatlocker.com/",
  },
  {
    image: "infosys.jpg",
    type: "Strategic Partner",
    grid: 6,
    link: "https://www.edgeverve.com/finacle/",
  },
  {
    name: "Visa",
    image: "visa.jpg",
    link: "https://www.visa.com/",
    type: "Gold Sponsors",
    text: `
    Visa is a world leader in digital payments, facilitating transactions between consumers, merchants, financial institutions and government entities across more than 200 countries and territories. Our mission is to connect the world through the most innovative, convenient, reliable and secure payments network, enabling individuals, businesses and economies to thrive. We believe that economies that include everyone everywhere, uplift everyone everywhere and see access as foundational to the future of money movement.

When you join Visa, you join a culture of purpose and belonging – where your growth is priority, your identity is embraced, and the work you do matters. We believe that economies that include everyone everywhere, uplift everyone everywhere. Your work will have a direct impact on billions of people around the world – helping unlock financial access to enable the future of money movement.
    `,
    grid: 6,
  },
  {
    name: "Worldpay",
    image: "worldpay.jpg",
    type: "Gold Sponsors",
    link: "https://www.fisglobal.com/en/merchant-solutions-worldpay",
    grid: 6,
    text: "Worldpay from FIS is a leading payments technology company that powers global commerce for merchants, banks, and capital markets. Processing 79 billion transactions topping $10T for 20,000+ clients annually, Worldpay lifts economies and communities by advancing the way the world pays, banks, and invests. We create secure and scalable innovations that connect commerce across all geographies and sales channels. The company’s integrated technology platform offers a unified and comprehensive solution set to help clients run, grow, and achieve more for their business. With a 50+ year history in financial services, we remain ahead of the curve to outpace today’s competitive economic landscape. By delivering simple, streamlined, and secure experiences for all of our clients and their customers, we embody commitment to every aspect of the financial services industry.",
  },
  {
    name: "Temenos",
    image: "temenos.jpg",
    link: "https://www.temenos.png",
    type: "Gold Sponsors",
    text: "Temenos, headquartered in Geneva, is the world leader in banking software, partnering with banks and other financial institutions to transform their businesses and stay ahead of a changing marketplace. Over 3,000 firms across the globe, including 41 of the top 50 banks, rely on Temenos to process both the daily transactions and client interactions of more than 500 million banking customers. Temenos offers cloud-native, cloud-agnostic front office and core banking, payments, fund management and wealth management software products enabling banks to deliver consistent, friction-less customer journeys and gain operational excellence. Our vision is to provide financial institutions, of any size, anywhere in the world, the software to thrive in the digital banking age.",
    grid: 6,
  },
  {
    name: "Zentity",
    image: "zentity.png",
    type: "Silver Sponsors",
    link: "https://zentity.com/",
    grid: 6,
    text: "",
  },
  {
    name: "onespan",
    image: "onespan.jpg",
    type: "Silver Sponsors",
    text: "OneSpan helps protect the world from digital fraud by establishing trust in people’s identities, the devices they use and the transactions they carry out. We do this by making digital banking accessible, secure, easy and valuable. OneSpan’s Trusted Identity platform and security solutions significantly reduce digital transaction fraud and enable regulatory compliance for more than 10,000 customers, including over half of the top 100 global banks. Whether through automating agreements, detecting fraud or securing financial transactions, OneSpan helps reduce costs and accelerate customer acquisition while improving the user experience. Learn more at OneSpan.com.",
    link: "https://www.onespan.com/logos",
    grid: 3,
  },

  {
    name: "Trace Financial",
    image: "tracefinancialblue 700x250.png",
    type: "Bronze Sponsor",
    link: "https://www.tracefinancial.com/",
    grid: 6,
    text: "Trace Financial has been supplying software solutions to financial institutions, service providers and application vendors for over 30 years. Our reputation owes much to our highly qualified and experienced staff. We are especially rich in business and technical expertise in SWIFT messaging and MT ISO 20022 migrations for global payment initiatives using our market-leading message transformation software, Transformer. Transformer is used by our global customer base for many requirements and we specialise in migration projects for ISO 20022 guid",
  },
  {
    name: "BankservAfrica",
    image: "bankserv.png",
    type: "Bronze Sponsor",
    link: "https://www.bankservafrica.com/website/",
    grid: 6,
    text: "",
  },

  {
    name: "Microsoft",
    image: "microsoft.png",
    type: "Series Sponsors and Partners",
    link: "https://www.microsoft.com/en-in/",
    grid: 3,
    text: "",
  },
  {
    name: "Smartstream",
    image: "smartstream.png",
    type: "Series Sponsors and Partners",
    link: "https://www.smartstream-stp.com/",
    grid: 3,

    text: "SmartStream is a recognised leader in financial transaction management solutions that enables firms to improve operational control, reduce costs, build new revenue streams, mitigate risk and comply accurately with regulations. By helping its customers through their transformative digital strategies, SmartStream provides a range of solutions for the transaction lifecycle with artificial intelligence and machine learning technologies embedded – which can also be deployed in the cloud or as managed services. As a result, more than 2,000 clients—including 70 of the world’s top 100 banks, rely on SmartStream Transaction Lifecycle Management (TLM®) solutions to deliver greater efficiency to their operations.",
  },
  {
    name: "Oracle",
    image: "oracle.jpg",
    type: "Series Sponsors and Partners",
    link: "https://www.smartstream-stp.com/",
    grid: 3,

    text: "SmartStream is a recognised leader in financial transaction management solutions that enables firms to improve operational control, reduce costs, build new revenue streams, mitigate risk and comply accurately with regulations. By helping its customers through their transformative digital strategies, SmartStream provides a range of solutions for the transaction lifecycle with artificial intelligence and machine learning technologies embedded – which can also be deployed in the cloud or as managed services. As a result, more than 2,000 clients—including 70 of the world’s top 100 banks, rely on SmartStream Transaction Lifecycle Management (TLM®) solutions to deliver greater efficiency to their operations.",
  },

  {
    name: "Backbase",
    image: "backbase.jpeg",
    type: "Series Sponsors and Partners",
    link: "http://www.backbase.com/",
    grid: 3,
    text: "Backbase is a fast-growing fintech software provider that empowers financial institutions to accelerate their digital transformation and effectively compete in a digital-first world. We are the creators of the Backbase Omni-Channel Banking Platform, a state-of-the-art digital banking software solution that unifies data and functionality from traditional core systems and new fintech players into a seamless digital customer experience. We give financials the speed and flexibility to create and manage seamless customer experiences across any device and deliver measurable business results. We believe that superior digital experiences are essential to stay relevant, and our software enables financials to rapidly grow their digital business.",
  },
  {
    name: "Network",
    image: "network.jpg",
    type: "Series Sponsors and Partners",
    link: "https://www.network.ae/en",
    grid: 3,
    text: "",
  },

  {
    name: "Freshworks",
    image: "freshworks.png",
    type: "Series Sponsors and Partners",
    link: "https://www.freshworks.com/",
    grid: 3,
    text: "",
  },
  {
    name: "Sybrin",
    image: "sybrin.png",
    type: "Series Sponsors and Partners",
    link: "https://nitro.sybrin.com/",
    grid: 3,
    text: "",
  },
  {
    name: "Central bank of Nigeria",
    image: "cbon.png",
    link: "",
    type: "Series Sponsors and Partners",
    text: false,
    grid: 3,
  },
  {
    name: "EDB Bahrain",
    image: "edb.png",
    link: "",
    type: "Series Sponsors and Partners",
    text: false,
    grid: 3,
  },
  {
    name: "expo",
    image: "expo.jpeg",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  {
    name: "difc",
    image: "difc.jpg",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  {
    name: "abaka",
    image: "abaka.jpg",
    type: "Series Sponsors and Partners",
    text: `ABAKA is the global leader in digital saving and
      retirement enterprise SaaS solutions, powered by
      Artificial Intelligence. The ABAKA modular platform,
      leveraging new technology (Artificial Intelligence,
      open banking APIs, omni-channel engagement) is a
      great tool kit to help legacy FIs to keep up and
      fintechs to hit the ground running. The cloud-based
      platform and library of modular applications helps
      clients build highly differentiated digital solutions
      and a truly personalised customer experience. 
      
      ABAKA has built the economy applications and
      seamless data exchange platform enabling FIs to
      access customer data, build insights and intelligence
      through conversational AI, Next Best Actions and
      hyper-personalised behavioural nudges. We provide
      digital solutions covering saving and retirement
      (accumulation, at-retirement and decumulation),
      banking (PFM, short term digital savings), wealth
      and advisory (goal-based investing, data
      aggregation, advisor lead gen tools and business
      process automation).`,
    link: "https://abaka.me/",
    grid: 3,
  },

  {
    name: "consdata",
    image: "consdata.jpeg",
    type: "Series Sponsors and Partners",
    link: "https://consdata.com/",
    grid: 3,
    text: "Consdata is a modern and agile software development company based in Poznań, Poland, specializing in servicing banks, financial institutions and governmental organizations. We currently work with seven major banks, including subsidiaries of Commerzbank, Santander, Societe Generale, Credit Agricole as well as two associations of Polish co-operative banks, consisting altogether of 560 community banking organizations. In addition, we have service agreements in place with the leading e-commerce company and cable television operator on the Polish market.",
  },

  {
    name: "Comarch",
    image: "comarch.jpeg",
    type: "Series Sponsors and Partners",
    link: "http://www.comarch.com",
    grid: 3,
    text: "Comarch is a global provider of IT business solutions that aim to optimize operational and business processes. Since its inception in 1993, we have developed at a rapid pace. Today, Comarch is the leading IT company in Central & Eastern Europe and employs over 6400 experienced IT engineers and business consultants. Execution of over 2000 IT projects and implementation of our software in more than 40,000 companies worldwide is a source of great pride.",
  },
  {
    name: "Infobip",
    image: "infobip.jpeg",
    type: "Series Sponsors and Partners",
    link: "https://www.infobip.com/",
    grid: 3,
    // a: 1,
    text: "Infobip powers enterprises to deliver messages across any channel, any device, at anytime and anywhere worldwide. Infobip’s technology creates seamless mobile interactions between businesses and people and simplifies the integration of almost all communication capabilities. With over a decade of industry experience, Infobip has expanded to include 65 offices on six continents offering in-house developed messaging platform with the capacity to reach over seven billion mobile devices in 190+ countries connected to over 800 telecom networks. The company serves and partners with leading mobile operators, messaging apps, banks, social networks and tech companies.",
  },
  {
    name: "PAYONEER",
    image: "Payoneer.jpeg",
    type: "Series Sponsors and Partners",
    link: "https://www.payoneer.com",
    grid: 3,
    // a: 1,
    text: "Payoneer’s mission is to empower businesses to go beyond – beyond borders, limits and expectations. In today’s digital world, Payoneer enables any business of any size from anywhere to access new economic opportunities by making it possible to transact as easily globally as they do locally. Payoneer’s digital platform streamlines global commerce for millions of small businesses, marketplaces and enterprises from 200 countries and territories. Leveraging its robust technology, compliance, operations and banking infrastructure, Payoneer delivers a suite of services that includes cross-border payments, working capital, tax solutions and risk management. Powering growth for customers ranging from aspiring entrepreneurs in emerging markets to the world’s leading digital brands like Airbnb, Amazon, Google and Upwork, Payoneer makes global commerce easy and secure. Founded in 2005, Payoneer is profitable and has a team based all around the world. Payoneer - one world, one platform, unlimited opportunities.",
  },
  {
    name: "TRIPWIRE",
    image: "tripwire.jpeg",
    type: "Series Sponsors and Partners",
    link: "https://www.tripwire.com/",
    grid: 3,
    // a: 1,
    text: "For over 20 years Tripwire has specialized in finding and minimizing a wide range of cyber security threats both on-site and in the cloud. Our portfolio provides the most critical security controls covering unauthorized changes, misconfigurations, vulnerabilities, and compliance issues. We provide solutions for IT and Industrial and have both SaaS and Managed Service options.",
  },

  {
    name: "Coforge",
    image: "coforge.jpg",
    type: "Series Sponsors and Partners",
    link: "https://www.coforgetech.com/",
    grid: 3,

    text: `Coforge is a leading global IT solutions organization, enabling its clients to transform at the intersect of unparalleled domain expertise and emerging technologies to achieve real-world business impact. A focus on very select industries, a detailed understanding of the underlying processes of those industries, and partnerships with leading platforms provide us a distinct vantage. We leverage AI, Cloud, and Insight-driven technologies, allied with our industry expertise, to transform client businesses into intelligent, high growth enterprises. Today our proprietary platforms power critical business processes across the Financial Services and Travel industries. Our 11,000 technology and process consultants engineer, design, consult, operate, and modernize systems across the world.`,
  },

  {
    name: "VACCUMLABS",
    image: "Vaccumlabs.jpg",
    type: "Series Sponsors and Partners",
    link: "https://www.vacuumlabs.com/vacuumlabs-we-put-the-tech-in-fintech",
    grid: 3,

    text: "Vacuumlabs is a fintech-focused software house full of experienced product builders. It provides teams of agile, remote by nature, software engineers, and designers with years of experience building fintech, cryptocurrency, online marketplaces, and digital products. Every step of the way, their experts guide their clients, ensuring they can most fast, learn, and iterate. When it comes to world-class product development, they are here to be your guide and partner.",
  },
  {
    name: "Neosoft",
    image: "Neosoft.jpeg",
    type: "Series Sponsors and Partners",
    link: "http://www.neosofttech.com/",
    grid: 3,

    text: "NeoSOFT is an SEI-CMMI Level-5 and ISO 9001:2008 certified global IT consulting & software solutions provider with 2000+ software consultants working full time across 7 Delivery Centers. Established in 1996, NeoSOFT is headquartered in Mumbai, India with offices in the USA, UK, Dubai, Italy, Germany, Japan & Australia. Our commitment to quality and 23+ years of experience has made us serve over 1500+ clients across 50+ countries with 85% client retention. They build cohesive technology solutions for the World’s Leading Enterprises, SMEs & Startups and ISV's We cater to more than 22+ Industries like  Banking & Finance - E-Commerce & Retail - Education & E-Learning - Government/ Semi-Government Healthcare - Insurance - IT & Consulting - Manufacturing - Media & Entertainment to name a few.",
  },
  {
    name: "HyperPay",
    image: "hyperpay.jpeg",
    type: "Series Sponsors and Partners",
    link: "https://www.hyperpay.com/",
    grid: 3,

    text: "HyperPay is the fastest growing Middle East-based payment gateway, transforming the online buying experience in the MENA region. Since its launch in 2014, HyperPay has enabled +1000 internet businesses to accept and manage payments online, with more flexibility, security and ease. Today, HyperPay continues to quickly expand its products and services to meet the online payment needs of businesses, ranging from the smallest to the largest enterprises. Get started with HyperPay and start accepting payments in local currencies via local acquirers across KSA, UAE, Bahrain, Jordan, Egypt, and Lebanon.",
  },
  {
    name: "newgen",
    image: "newgen.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },

  {
    name: "comviva",
    image: "comviva.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  {
    name: "Daon",
    image: "daon.jpg",
    link: "",
    type: "Series Sponsors and Partners",
    text: false,
    grid: 3,
  },

  {
    name: "fss",
    image: "fss.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  {
    name: "openfactor",
    image: "openfactor.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  {
    name: "maxut",
    image: "maxut.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  {
    name: "piotech",
    image: "piotech.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },

  {
    name: "PWC",
    image: "pwc.jpg",
    link: "",
    type: "Series Sponsors and Partners",
    text: false,
    grid: 3,
  },

  {
    name: "assertify",
    image: "assertify.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  {
    name: "avaya",
    image: "avaya.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },

  {
    name: "netcore",
    image: "netcore.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },

  {
    name: "Bankserv Africa",
    image: "bankserv.jpg",
    link: "",
    type: "Series Sponsors and Partners",
    text: false,
    grid: 3,
  },
  {
    name: "infrasoft",
    image: "infrasoft.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  {
    name: "yethi",
    image: "yethi.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  {
    name: "Rubrik",
    image: "rubrik.jpg",
    link: "",
    type: "Series Sponsors and Partners",
    text: false,
    grid: 3,
  },
  {
    name: "turbonomic",
    image: "turbonomic.png",
    type: "Series Sponsors and Partners",
    link: "",
    grid: 3,
  },
  // {
  //   name: "iib",
  //   image: "iib.jpeg",
  //   type: "Media Partners",
  //   link: "https://www.iibcouncil.org/",
  //   grid: 3,
  // },

  {
    name: "TCS",
    image: "tcs.jpg",
    link: "",
    type: "Series Sponsors and Partners",
    text: false,
    grid: 3,
  },
  {
    name: "The Power 50",
    image: "fintechpower.jpeg",
    link: "https://www.thepower50.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "Crytonews",
    image: "cryptonewz.jpeg",
    link: "https://cryptonews.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "International Business magazine",
    image: "ibm.jpeg",
    link: "https://intlbm.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "BeinCrypto",
    image: "beingcrypto.png",
    link: "https://beincrypto.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "Cointelegraph",
    image: "Cointelegraph.jpeg",
    link: "https://cointelegraph.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "Fintechna",
    image: "fintechna.jpg",
    link: "https://www.fintechna.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },

  {
    name: "The Fintech Times",
    image: "fintechtimes.jpeg",
    link: "https://thefintechtimes.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "News Affinity",
    image: "NewsAffinity.jpeg",
    link: "https://newsaffinity.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "Industry Events",
    image: "ie.jpg",
    link: "http://www.industryevents.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "fintechfutures",
    image: "fintechfutures.jpeg",
    link: "https://www.fintechfutures.com/us/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "sinofy",
    image: "sinofy.png",
    link: "https://sinofy.group/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "coinquora",
    image: "coinquora.png",
    link: "https://coinquora.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "cio",
    image: "cio.png",
    link: "http://www.cioapplicationseurope.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "paypers",
    image: "paypers.jpg",
    link: "https://thepaypers.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "aroundb",
    image: "aroundb.png",
    link: "https://aroundb.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
  {
    name: "regtech",
    image: "regtech.png",
    link: "https://regtechafrica.com/",
    type: "Media Partners",
    text: false,
    grid: 3,
  },
];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);
export const sponsorsPage1 = groupByType(sponsorsNew);

export const live = [
  {
    id: 1,
    link: "Marketing-Strategies-For-Fintech-In-2020",
    description: "Marketing Strategies For Fintech In 2020",
    content:
      "Getting busier and more competitive by the day, it's important for those involved to consider strategies for how to stand out and sustain business.",
    image: "1.png",
  },
];

export const speakers = [
  {
    name: "Adeoluwa Akomolafe",
    title: "Chief Information Officer",
    company: "Wema Bank Plc, Nigeria",
    image: "Adeoluwa Akomolafe.jpeg",
    companyImage: "wema.png",
  },

  {
    name: "Fatai Tella",
    title: "Chief Data Officer",
    company: "Sterling Bank Plc, Nigeria",
    image: "Fatai Tella.jpeg",
    companyImage: "sterling.jpg",
  },

  {
    name: "Chris Esezobor",
    title: "Chief Digital Officer",
    company: "Keystone Bank, Nigeria",
    image: "Chris Esezobor.jpeg",
    companyImage: "keystone.jpg",
  },

  // {
  //   name: "Dr Abdourahmane Faye",
  //   title: "Executive Director - Data Management & Artificial Intelligence",
  //   company: "Vontobel Investment Bank, Zürich",
  //   image: "Abdourahmane.jpg",
  //   companyImage: "vontobel.jpeg",
  // },

  {
    name: "Emmanuel Morka",
    title: "Chief Information Officer",
    company: "Access Bank (Ghana) Plc",
    image: "Emmanuel morka.jpeg",
    companyImage: "Access.png",
  },

  {
    name: "Mounir Jazouli",
    title: "Director Communication, Media & Digital",
    company: "Bank Of Africa, Maroc",
    image: "Mounir Jazouli.jpg",
    companyImage: "boa.jpeg",
  },
  {
    name: "Calleb Osei",
    title: "Regional Chief Financial Officer",
    company: "Guaranty Trust Bank, Ghana",
    image: "Calleb Osei.jpg",
    companyImage: "gtb.png",
  },

  // {
  //   name: "Joseph Ojebola",
  //   title: "Group Chief Financial Officer",
  //   company: "FSL Securities Limited, Nigeria",
  //   image: "Joseph Ojebola.jpg",
  //   companyImage: "",
  // },

  // {
  //   name: "Lanre Bamisebi",
  //   title: "Group Director IT & Operations",
  //   company: "Equity Group Holdings, Kenya",
  //   image: "Lanre Bamisebi.jpeg",
  //   companyImage: "equity.png",
  // },

  {
    name: "Harrison Nnaji",
    title: "Chief Information Security Officer",
    company: "FirstBank Nigeria",
    image: "Harrison Nnaji.jpeg",
    companyImage: "firstbank.png",
  },

  // {
  //   name: "Ndungu Thairu",
  //   title: "Deputy Managing Director",
  //   company: "Consolidated Bank Ghana Limited",
  //   image: "Ndungu Thairu.jpeg",
  //   companyImage: "consolidated.jpg",
  // },

  // {
  //   name: "Adaeze Udensi",
  //   title: "Executive Director",
  //   company: "Titan Trust Bank, Nigeria",
  //   image: "Adaeze Udensi.jpeg",
  //   companyImage: "titan.png",
  // },

  {
    name: "Zekarias Mekonnen Yigzaw",
    title: "Managing Director",
    company: "Commercial Bank of Ethiopia",
    image: "Zekarias Mekonnen.jpeg",
    companyImage: "commercial.jpeg",
  },

  {
    name: "Lynet Owuor- Okoth",
    title: "Head of Customer Experience Department",
    company: "National Bank of Kenya",
    image: "Lynet Owuor.jpeg",
    companyImage: "nbk.png",
  },

  {
    name: "Dr. Philip Oti – Mensah",
    title: "Executive Director",
    company: "OmniBSIC Bank, Ghana",
    image: "Dr. Philip Oti - Mensah.jpeg",
    companyImage: "OmniBSICBank.png",
  },
  {
    name: "George Ogbonnaya",
    title: "Vice President & Group Head BusVice President & Group Head Business Banking",
    company: "First City Monument Bank Limited, Nigeria",
    image: "George.jpeg",
    companyImage: "fcmb.png",
  },
  {
    name: "Bipul Deka",
    title: "Chief Digital Officer",
    company: "First City Monument Bank Limited, Nigeria",
    image: "Bipul Deka.jpg",
    companyImage: "",
  },

  {
    name: "Idowu Korede Dada",
    title: "Group Chief Risk & Investment Officer",
    company: "TrustBanc Financial Group, Nigeria",
    image: "Idowu Dada.jpeg",
    companyImage: "trustbanc.jpeg",
  },
  {
    name: "Femi Olofintila",
    title: "Chief Information Security Officer",
    company: "Nova Merchant Bank Ltd, Nigeria",
    image: "Femi Olofintila.jpeg",
    companyImage: "nova.jpeg",
  },
  {
    name: "Nduneche Ezurike",
    title: "Group Head of Brand and Marketing Communications",
    company: "Polaris Bank Ltd, Nigeria",
    image: "NDUNECHE PIX. NEW.jpeg",
    companyImage: "polaris.png",
  },

  {
    name: "Abdul Wasiu Popoola",
    title: "Chief Digital Officer",
    company: "Parallex Bank Ltd, Nigeria",
    image: "AbdulWasiu Popoola.jpeg",
    companyImage: "parallax.png",
  },
  {
    name: "Funmi Akinfenwa",
    title: "Head Of Operations",
    company: "Zenith Bank Plc, Nigeria",
    image: "Funmi Sphri.jpg",
    companyImage: "",
  },

  {
    name: "Adewale I. Salami",
    title: "Chief Technology Officer",
    company: "First Bank of Nigeria Limited, Nigeria",
    image: "Adewale I. Salami.jpg",
    companyImage: "FBNBank.png",
  },

  {
    name: "Adebayo Osagie Olarewaju",
    title: "Head Digital Channels, E-Business & Retail Prodcuts",
    company: "First Bank of Nigeria Limited, Nigeria",
    image: "Adebayo Olarewaju.jpg",
    companyImage: "FBNBank.png",
  },

  {
    name: "Bola Atta",
    title: "Group Director, Corporate Communication",
    company: "UBA Group, Nigeria",
    image: "Bola Atta.jpg",
    companyImage: "uba.png",
  },

  {
    name: "Osahon Akpata",
    title: "Group Head, Consumer Payments",
    company: "Ecobank Transnational Incorporated, Nigeria",
    image: "Osahon Akpata Pic.jpg",
    companyImage: "Ecobank.png",
  },

  {
    name: "Emmanuel Onyeje",
    title: "Regional Manager West Africa and Head of Nigeria",
    company: "Backbase",
    image: "Emmanuel Onyeje - Backbase.jpg",
    companyImage: "backbase.jpeg",
  },

  {
    name: "Grace Anyetei",
    title: "Regional Director of Operations, Africa - Leaf, ",
    company: "IDT Corporation, Kenya",
    image: "Grace Anyetei - Profile pic.jpg",
    companyImage: "",
  },

  {
    name: "Barnabas Okwudibie",
    title: "Chief Operating Officer",
    company: "Links MFB Limited (a subsidiary of DLM Capital Group)",
    image: "Barnabas Okwudibie.jpg",
    companyImage: "",
  },

  {
    name: "Pattison Boleigha",
    title: "Group Chief Conduct and Compliance Officer,",
    company: "Access Bank Plc",
    image: "Pattison Boleigha.jpg",
    companyImage: "Access.png",
  },

  {
    name: "Oluremi Tinuolu-Gabriel",
    title: "Group Head, Digital Channels",
    company: "Access Bank Plc, Nigeria",
    image: "Oluremi Tinuolu-Gabriel.JPG",
    companyImage: "Access.png",
  },

  {
    name: "Jude Monye",
    title: "Executive Director/Executive Compliance Officer",
    company: "Heritage Bank Plc, Nigeria",
    image: "Jude Monye.jpg",
    companyImage: undefined,
  },

  {
    name: "Olumuyiwa Theophilus",
    title: "Group Chief Information Officer",
    company: "Equity Bank Limited",
    image: "Olumuyiwa Theophilus.jpg",
    companyImage: "equity.png",
  },

  {
    name: "Ikemefula N. Nwachukwu",
    title: "Head, Personal Banking Department",
    company: "First Bank of Nigeria Limited",
    image: "Ikemefula N. Nwachukwu.png",
    companyImage: "FBNBank.png",
  },

  {
    name: "Ope Okesola",
    title: "Deputy Chief Information Officer",
    company: "African Alliance Insurance PLC Nigeria",
    image: "Ope Okesola.jpg",
    companyImage: undefined,
  },

  // {
  //   name: "Israel Opio",
  //   title: "Chairman EXCO Risk Management Comm Uganda Bankers Association",
  //   company: "Uganda Bankers'​ Association, Uganda",
  //   image: "Israel Opio.jpeg",
  //   companyImage: "uba.jpeg",
  // },
];
