import React from "react";
import { Container, Row, Col } from "reactstrap";

function WhyAttend() {
  return (
    <>
      <Container>
        <Row>
          <Col lg={11}>
            <h2 className="text-700 text-g">
              YOUR PLATFORM – GETTING YOUR MESSAGE ACROSS THROUGH FINNOVEX
            </h2>
          </Col>
        </Row>
        <Row>
          <Col lg={8} className="text-white">
            <div className="mb-3">
              <h4 className="text-700 mt-0">Insights</h4>
              <h5 className="text-400 mt-0">
                Attend keynotes and panels to learn and understand the benefits of specific
                technologies
              </h5>
            </div>
            <div className="mb-3">
              <h4 className="text-700 mt-0">Networking</h4>
              <h5 className="text-400 mt-0">
                Connect with peers in a private business -focused environment
              </h5>
            </div>
            <div className="mb-3">
              <h4 className="text-700 mt-0">Leading solution providers in one location</h4>
              <h5 className="text-400 mt-0">
                Source the most cost effective, up-to-date solutions and innovations on one platform
              </h5>
            </div>
            <div className="mb-3">
              <h4 className="text-700 mt-0">Finnovex 10X Meetings</h4>
              <h5 className="text-400 mt-0">
                Pre-scheduled one-to-one consultations with chosen solution providers.
              </h5>
            </div>
            <div className="mb-3">
              <h5 className="text-700 mt-0">Product showcase</h5>
              <h5 className="text-400 mt-0">
                Get hands-on experience on the cyber security solutions available in the market
              </h5>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-start pb-4">
          <Col lg={12}>
            <h1 className="text-700 text-g">GCC Regional</h1>
            <h2 className="text-400 mt-0 text-g mb-3">Meet up</h2>
          </Col>
          {content.map((c) => (
            <Col lg={2} xs={6} key={c.name}>
              <div>
                <p className="text-white">{c.name}</p>
                <img src={require(`assets/images/countries/${c.image}`)} width="100%" alt="icon" />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}
export default WhyAttend;

const content = [
  { name: "Egypt", image: "egypt.jpg" },
  { name: "Morocco", image: "morroco.png" },
  { name: "Sudan", image: "sudan.png" },
  { name: "Algeria", image: "algeria.png" },
  { name: "Tunisia", image: "tunisia.webp" },
];
