import React from "react";

import { Container, Row, Col } from "reactstrap";
import ScrollAnimation from "react-animate-on-scroll";

const content = [
  { title: "Minimized contact at Registration", image: "27.png" },
  { title: "Sanitized Conference Kits and Material", image: "23.png" },
  {
    title: "Masks will be worn at all times. Extra Masks will be available at the registration",
    image: "21.png",
  },
  {
    title: "Unidirectional flow of Attendees for increased safety",
    image: "25.png",
  },
  { title: "Contactless temperature checks", image: "26.png" },
  {
    title: "Floor markings to ensure social distancing throughout the venue",
    image: "22.png",
  },
  {
    title:
      "All social distancing norms will be followed in the Conference Hall(s) and Exhibition Area",
    image: "20.png",
  },
  {
    title: "All equipment and facilities will be sterilized before and after use",
    image: "24.png",
  },
];

function KeyNotes() {
  return (
    <>
      <div className="section py-5" style={{ background: "#201b51" }}>
        <Container>
          <Row className="justify-content-center">
            <Col className="mx-auto text-center mb-5" md={12}>
              <h5 className="text-700 text-white">
                We at Exibex, believe that nothing can match the experience of meeting people
                face-to-face.
                <br />
                <br />
                We are in touch with the relevant authorities to ensure that the highest levels of
                health and safety SOP's are followed related to sanitization and practicing social
                distancing as we obtain to welcome 130+ attendees.
                <br />
                <br />
                With the world slowly and cautiously opening up as we recover from the impact of
                Covid-19, we will be taking all precautions to ensure that your experience at the
                conference is totally safe.
              </h5>
            </Col>
            {content.map((data, index) => (
              <Col lg={6} xs={12} key={index} className="mb-5 py-2 px-2">
                <ScrollAnimation animateIn="fadeInUp" animateOnce={true} duration={0.5}>
                  <Container fluid>
                    <Row>
                      <Col
                        xs={3}
                        className="align-self-center"
                        style={{
                          borderRight: "3px solid #a4508b",
                        }}
                      ></Col>
                      <Col xs={9} className="align-self-center">
                        <h3 className="m-0 text-600 text-white ">{data.title}</h3>
                      </Col>
                    </Row>
                  </Container>
                </ScrollAnimation>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
