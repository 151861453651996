import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "reactstrap";
import Button from "reactstrap/lib/Button";
import S2 from "./svgs/s2";
import S5 from "./svgs/s5";
import S8 from "./svgs/s8";
import S1 from "./svgs/s1";
import S3 from "./svgs/s3";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const content = [
    {
      title: "Digital Accessibility",
      description: "Bridging the gap through digital solutions, making banking accessible to all.",
      image: <S2 />,
    },
    {
      title: "Commercial Banking Evolution",
      description: "Reimagining commercial banking to accommodate changing markets.",
      image: <S3 />,
    },
    {
      title: "Secure Financial Systems",
      description: "Meeting regulatory demands with robust and secure financial systems.",
      image: <S8 />,
    },
    {
      title: "Regulatory Support",
      description:
        "Government-backed initiatives, like digitising remittances and encouraging digital payments, reinforce innovation's role. ",
      image: <S1 />,
    },
  ];
  return (
    <>
      <div
        style={{
          background: "transparent",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h1
                className=" pb-0 text-white mt-0 text-uppercase"
                style={{ fontSize: size ? "2.5rem" : "1.5rem" }}
              >
                <b>
                  Seizing the Fintech Boom:
                  <br />
                  Innovations Reshaping the Financial Landscape
                </b>
              </h1>
            </Col>
            <Col lg={6} md={6} sm={12} className="">
              <h5
                className="text-400 text-white text-justify"
                style={{
                  lineHeight: "30px",
                  color: "#010011",
                  textTransform: "none",
                }}
              >
                <br />
                <br />
                Finnovex North Africa's 22nd Edition is an unparalleled summit, driving
                transformative changes in the financial landscape. As the region's financial sector
                experiences a significant shift, this event emerges as a hub for forward-thinking
                leaders and innovators. With a focus on cutting-edge solutions and elevated
                regulatory standards, financial institutions are gearing up to surpass global
                benchmarks, catering to the demands of a dynamic population.
                <br />
                <br />
                In this epoch of digital renaissance, North Africa's financial sector is witnessing
                a profound revolution catalyzed by technological advancements. With a strong
                emphasis on digital accessibility, commercial banking evolution, secure financial
                systems, and governmental support, the event paves the way for a collaborative
                journey towards reshaping the financial future of the region. Don't miss the chance
                to be part of this historic gathering, where 200+ attendees come together to foster
                connections and drive the future of finance in North Africa
                <br/>
                <br/>
                <Button className="px-3 py-2  my-2 rounded-0" color="primary" href="/about">
                  <p className="m-0 text-700" style={{ color: "#fff" }}>
                    Know More
                  </p>
                </Button>
              </h5>
            </Col>
            <Col lg={6} className="mt-5 pr-0">
              <div className="container-fluid row pr-0">
                {content.map((s, index) => (
                  <Col className="text-center  p-2 px-0" lg={6} xs={6}>
                    <ScrollAnimation
                      animateIn={"fadeInUp"}
                      animateOnce={true}
                      duration={1.5 + index / 8}
                    >
                      <div className="pt-4 px-2 stat-div" style={{ minHeight: 270 }}>
                        {s.image}

                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "18px" : "14px",
                          }}
                          className="text-700 mt-4"
                        >
                          {s.title}
                        </h3>
                        <h3
                          style={{
                            color: "#fff",
                            fontSize: size ? "16px" : "12px",
                          }}
                          className="text-400 mt-0"
                        >
                          {s.description}
                        </h3>
                      </div>
                    </ScrollAnimation>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
