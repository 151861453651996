import React from "react";
import { Container, Row, Col, Card, Button } from "reactstrap";
import Slider from "react-slick";
function Sponsor({ sponsors }) {
  let pageHeader = React.createRef();
  // const [overlay, setOverlay] = React.useState(false);
  const settings = {
    // dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      style={{
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: 5,
      }}
      className="section section-with-space"
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <h2 className="text-700 text-center text-white mt-0 mb-4">OUR SERIES SPONSORS</h2>
          </Col>
        </Row>
        <Row>
          <React.Fragment>
            <Col lg={12}>
              {/* <h3 className="text-center text-muted pb-2" style={{ fontSize: "30px" }}>
                  <b>{sponsors[sponsor].length > 1 ? `${sponsor}S` : sponsor}</b>
                </h3> */}
              <Container fluid>
                <Row>
                  <Col lg={12} xs={12}>
                    <Slider {...settings}>
                      {sponsors.map((data, i) => (
                        <div key={i} className=" mr-2 bg-white">
                          <div
                            style={{
                              display: "flex",
                              aspectRatio: "2/1",
                              justifyContent: "center",

                              padding: "1em",
                            }}
                          >
                            <img
                              src={require(`assets/images/sponsors/${data.image}`)}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100px",
                                // height: "150px",
                                alignSelf: "center",
                              }}
                              alt={data.name}
                            />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </Col>
                </Row>
              </Container>
            </Col>
          </React.Fragment>

          <Col lg={12} className="text-center">
            <Button href="/partners" className="btn my-2 text-center px-5" color="info" size="lg">
              View All
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Sponsor;
