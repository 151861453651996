import React, { useEffect } from "react";
import anime from "animejs";

export default function S10({ active }) {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".s10",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 8000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={
        {
          // width: 100,
        }
      }
      className="mx-auto mb-2"
      id="svg"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 46 46"
        style={{
          //   fill: "rgba(255,255,255,0.1)",
          stroke: "#c87467",
          strokeWidth: 0.4,
          fill: "none",
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="s10"
          d="M10.78,26.18V23.87a5.4,5.4,0,0,0-2.91-4.78,3.85,3.85,0,1,0-5,0A5.38,5.38,0,0,0,0,23.87v2.31A.77.77,0,0,0,.77,27H10a.78.78,0,0,0,.77-.77m-7.7-10a2.31,2.31,0,1,1,2.31,2.31,2.3,2.3,0,0,1-2.31-2.31m6.16,9.24H1.54V23.87a3.85,3.85,0,1,1,7.7,0Z"
        />
        <path
          class="s10"
          d="M43.28,19.09a3.85,3.85,0,1,0-5,0,5.4,5.4,0,0,0-2.91,4.78v2.31a.78.78,0,0,0,.77.77h9.24a.77.77,0,0,0,.77-.77V23.87a5.38,5.38,0,0,0-2.91-4.78m-4.79-2.92a2.31,2.31,0,1,1,2.31,2.31,2.31,2.31,0,0,1-2.31-2.31m6.16,9.24H37V23.87a3.85,3.85,0,1,1,7.7,0Z"
        />
        <path
          class="s10"
          d="M14.63,18.48h.77v2.31a.77.77,0,0,0,.77.77H31.56a.77.77,0,0,0,.77-.77V2.31a.76.76,0,0,0-.77-.77h-2.3V.77A.77.77,0,0,0,28.48,0H19.25a.78.78,0,0,0-.55.22L14.08,4.84a.8.8,0,0,0-.22.55V17.71a.77.77,0,0,0,.77.77m14.63-.77V3.08H30.8V20H16.94V18.48H28.48a.78.78,0,0,0,.78-.77m-1.55-.77H15.4V6.16h3.85A.77.77,0,0,0,20,5.39V1.54h7.69ZM16.49,4.62l2-2v2Z"
        />
        <rect class="s10" x="16.94" y="7.7" width="9.24" height="1.54" />
        <rect class="s10" x="16.94" y="10.78" width="9.24" height="1.54" />
        <rect class="s10" x="16.94" y="13.86" width="9.24" height="1.54" />
        <rect class="s10" x="21.56" y="4.62" width="4.62" height="1.54" />
        <path
          class="s10"
          d="M32.33,27H13.86a2.3,2.3,0,0,0-2.31,2.31V40a2.3,2.3,0,0,0,2.31,2.31h5.79l-.92,2.31H16.17v1.54H30V44.65H27.47l-.93-2.31h5.79A2.3,2.3,0,0,0,34.64,40V29.26A2.3,2.3,0,0,0,32.33,27m-6.52,17.7H20.38l.93-2.31h3.57Zm6.52-3.85H13.86a.76.76,0,0,1-.77-.77V29.26a.76.76,0,0,1,.77-.77H32.33a.76.76,0,0,1,.77.77v8.46H30v1.54H33.1V40a.76.76,0,0,1-.77.77"
        />
        <rect class="s10" x="40.03" y="9.24" width="1.54" height="1.54" />
        <rect class="s10" x="40.03" y="6.16" width="1.54" height="1.54" />
        <rect class="s10" x="36.95" y="6.16" width="1.54" height="1.54" />
        <rect class="s10" x="33.87" y="6.16" width="1.54" height="1.54" />
        <rect class="s10" x="4.62" y="9.24" width="1.54" height="1.54" />
        <rect class="s10" x="4.62" y="6.16" width="1.54" height="1.54" />
        <rect class="s10" x="7.7" y="6.16" width="1.54" height="1.54" />
        <rect class="s10" x="10.78" y="6.16" width="1.54" height="1.54" />
        <path
          class="s10"
          d="M38,29.48,39,30.57l1-1v8.15H37.27l1-1-1.09-1.09L34.87,38a.76.76,0,0,0,0,1.09l2.31,2.31,1.09-1.09-1-1H40.8a.76.76,0,0,0,.77-.77V29.57l1,1,1.09-1.09-2.31-2.31a.79.79,0,0,0-1.09,0Z"
        />
        <path
          class="s10"
          d="M9,35.64,7.92,36.73l1,1H6.16V29.57l1,1,1.09-1.09L5.93,27.17a.79.79,0,0,0-1.09,0L2.53,29.48l1.09,1.09,1-1v8.92a.76.76,0,0,0,.77.77H8.92l-1,1L9,41.35,11.32,39a.76.76,0,0,0,0-1.09Z"
        />
        <path
          class="s10"
          d="M23.1,32.33a.78.78,0,0,1,.77.77h1.54a2.31,2.31,0,0,0-1.54-2.16V30H22.33v.92a2.3,2.3,0,0,0,.77,4.47.77.77,0,1,1,0,1.54.76.76,0,0,1-.77-.77H20.79a2.29,2.29,0,0,0,1.54,2.17v.91h1.54v-.91a2.31,2.31,0,0,0-.77-4.48.77.77,0,0,1,0-1.54"
        />
        <rect class="s10" x="26.94" y="37.72" width="1.54" height="1.54" />
      </svg>
    </div>
  );
}
