import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function SpeakerCard({ speaker, index, setOverlay, overlay }) {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? "25rem"
      : "25rem";
  return (
    <Card
      style={{
        maxWidth: "none",
        height: "22rem",
        background: "transparent",
        boxShadow: "none",
      }}
      className="rounded-0"
    >
      <Container fluid className="p-0">
        <img
          alt="..."
          className="img rounded-0 img-responsive"
          width="100%"
          src={require(`assets/images/speakers/${speaker.image}`)}
        />
      </Container>
      <Container style={{ textTransform: "none" }}>
        <Row>
          <Col xs={12} className="mt-2 px-1">
            <h4
              className="d-inline text-white text-700"
              style={{ fontSize: "22px", textTransform: "none" }}
            >
              {speaker.name}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-white text-left px-1">
            <h5
              className="mt-1 text-400 text-white"
              style={{ textTransform: "none", fontSize: "14px", lineHeight: "14px" }}
            >
              {speaker.title}
            </h5>
            <h5
              className="text-700"
              style={{ fontSize: "14px", textTransform: "none", lineHeight: "14px" }}
            >
              {speaker.company && <span>{speaker.company}</span>}
            </h5>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

function Speaker({ speakers }) {
  const [overlay, setOverlay] = React.useState([speakers[0], 0, false]);
  return (
    <>
      <div
        style={{
          backgroundColor: "#001717",
          overflow: "hidden",
        }}
        className="section"
      >
        <Container>
          <Row>
            {speakers.map((s, i) => (
              <Col lg={3} sm={6} xs={6} key={i} className="">
                <SpeakerCard speaker={s} index={i} overlay={overlay} setOverlay={setOverlay} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Speaker;
