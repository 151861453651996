import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import Placeholder from "../assets/images/speakers/speaker.png";
import { Container, Row, Col, Card, Button } from "reactstrap";

function SpeakerCard({ speaker }) {
  const size = "23rem";
  return (
    <Card
      style={{
        maxWidth: "none",
        height: "22rem",
        background: "transparent",
        boxShadow: "none",
      }}
      className="rounded-0"
    >
      <Container fluid className="p-0">
        <img
          alt="..."
          className="img rounded-0 img-responsive"
          width="100%"
          src={speaker.image || Placeholder}
        />
      </Container>
      <Container style={{ textTransform: "none" }}>
        <Row>
          <Col xs={12} className="mt-2 px-1">
            <h4
              className="d-inline text-white text-700"
              style={{ fontSize: "22px", textTransform: "none" }}
            >
              {speaker.name}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="text-white text-left px-1">
            <h5
              className="mt-1 text-400 text-white"
              style={{ textTransform: "none", fontSize: "14px", lineHeight: "14px" }}
            >
              {speaker.title}
            </h5>
            <h5
              className="text-700"
              style={{ fontSize: "14px", textTransform: "none", lineHeight: "14px" }}
            >
              {speaker.company && <span>{speaker.company}</span>}
            </h5>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

function Speaker() {
  const { speakers } = useContext(DataContext);
  return (
    <>
      <div
        style={{
          backgroundColor: "#770c2c",
          overflow: "hidden",
        }}
        className="section"
      >
        <Container fluid style={{}}>
          <Container>
            <Row>
              <Col lg={12} className={"mx-auto"}>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <Container fluid>
                        <Row className="justify-content-center">
                          {speakers.map((s, i) => (
                            <Col lg={3} sm={6} xs={12} key={i} className="">
                              <SpeakerCard speaker={s} />
                            </Col>
                          ))}
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
}

export default Speaker;
