import React from "react";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";

import Navbar from "components/Navbar";
import Safety from "components/Safety";
import Footer from "components/Footer";

import ScrollUpButton from "react-scroll-up-button";

function Speakers() {
  return (
    <div id="outer-container">
      <Navbar />
      <ScrollUpButton />
      <ProfilePageHeader title="SAFETY MEASURES AT FINNOVEX" />
      <Safety />
      <Footer />
    </div>
  );
}

export default Speakers;
