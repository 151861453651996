// reactstrap components
import { Container, Col, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import React, { useState } from "react";
import AnimeBackground from "components/Anime";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  const [modalRegister, setModalRegister] = useState(false);
  const toggleRegister = () => setModalRegister(!modalRegister);
  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundColor: "transparent",
        }}
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-start">
            <Col md={5} xs={12}>
              <img src={require("assets/images/logo/Logo.png")} width="100%" alt="main logo" />
              <h2
                className="text-700 text-i ml-2 mt-3 mb-2"
                style={{
                  color: "#fff",
                  fontSize: size ? "40px" : "20px",
                }}
              >
                March <span style={{ color: "#40b5cb" }}>5-6</span>, 2024
              </h2>
            </Col>
            <Col md={5} xs={12} className={size ? "border-left" : "border-top"}>
              <h3
                style={{
                  color: "#fff",
                  fontSize: size ? "37px" : "18px",
                  marginTop: size ? 0 : 20,
                }}
                className="text-400 ml-2"
              >
                <i>The Leading Summit on Financial Services Innovation and Excellence</i>
              </h3>
              <h4
                style={{
                  color: "#fff",
                  fontSize: size ? "30px" : "17px",
                }}
                className="text-700 mt-3 ml-2"
              >
                Four Seasons Hotel Cairo at the First Residence, Egypt
              </h4>
            </Col>
            <Col md={6} xs={12} className="mt-4">
              <Button
                // href="/register"
                onClick={() => toggleRegister()}
                className="navbar-item-custom text-400 text-dark rounded-0 ml-2"
                color="primary"
                size="lg"
              >
                <span
                  style={{
                    color: "#ffffff",
                    fontSize: "16px",
                  }}
                  className="text-700"
                >
                  REGISTER NOW
                </span>
              </Button>{" "}
              <Button
                href="/sponsor"
                className="navbar-item-custom text-400 text-dark rounded-0"
                size="lg"
                color="primary"
              >
                <span
                  style={{
                    color: "#ffffff",
                    fontSize: "16px",
                  }}
                  className="text-700"
                >
                  BECOME A SPONSOR
                </span>
              </Button>
            </Col>
          </Row>
        </Container>

        <AnimeBackground />
      </div>
      <Modal isOpen={modalRegister} toggle={toggleRegister} className="modal-dialog-centered">
        <ModalHeader toggle={toggleRegister}>
          <b>REGISTER FOR FINNOVEX NORTH AFRICA</b>
        </ModalHeader>
        <ModalBody>
          <iframe
            id="ts-iframe"
            src="https://www.townscript.com/v2/widget/22nd-edition-finnovex-north-africa-2024-003144/booking"
            frameborder="0"
            height="600"
            width="100%"
            title="townscript"
          ></iframe>
          <link
            rel="stylesheet"
            href="https://www.townscript.com/static/Bookingflow/css/ts-iframe.style.css"
          />
        </ModalBody>
      </Modal>
    </>
  );
}

export default LandingPageHeader;
