import React, { useEffect } from "react";
import anime from "animejs";

export default function S5({ active }) {
  // const size = document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024 ? true : false;
  function animation() {
    anime({
      targets: ".s5",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "cubicBezier(.5, .05, .1, .3)",
      duration: 8000,
      delay: function (el, i) {
        return i * 50;
      },
      loop: true,
      direction: "alternate",
    });
  }

  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      style={
        {
          // width: 100,
        }
      }
      className="mx-auto mb-2"
      id="svg"
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 46 51"
        style={{
          //   fill: "rgba(255,255,255,0.1)",
          stroke: "#c87467",
          strokeWidth: 0.4,
          fill: "none",
          strokeMiterlimit: 10,
        }}
      >
        <path
          class="s5"
          d="M4.07,37.22c-.65.66-1.32,1.32-1.89,1.89L4.07,41c.06-.86.26-1,1.26-1H13V38.32H5.33c-1,0-1.2-.19-1.26-1.1m28.58-25v1.63h8c.67,0,.94.28,1,1a1.19,1.19,0,0,0,0,.15l1.89-1.88L41.6,11.19c-.05.86-.26,1-1.25,1h-7.7ZM9,41.58v3a4.08,4.08,0,0,0,4.32,4.3H32.36a4.09,4.09,0,0,0,4.34-4.34V15.49H32.05c-.78,0-1.06-.28-1.06-1v-2.8c0-.79.28-1.06,1.08-1.06H36.7V5.93a4.09,4.09,0,0,0-4.33-4.3h-19A4.08,4.08,0,0,0,9,6V36.69h4.39c1.14,0,1.31.18,1.31,1.34v2.29c0,1.06-.2,1.26-1.28,1.26H9m-1.63,0H5.72c0,.49,0,1,0,1.45a.85.85,0,0,1-.51.91.83.83,0,0,1-1-.28L.36,39.82a.83.83,0,0,1,0-1.37l3.85-3.86a.85.85,0,0,1,1.51.64c0,.47,0,.94,0,1.43H7.35V5.91A5.67,5.67,0,0,1,11.75.17,6.47,6.47,0,0,1,13.21,0H32.47a5.71,5.71,0,0,1,5.86,5.86v4.71H40V9.09a.83.83,0,0,1,1.47-.62c1.31,1.31,2.62,2.61,3.92,3.93a.8.8,0,0,1,0,1.29c-1.3,1.32-2.61,2.62-3.92,3.93A.83.83,0,0,1,40,17V15.52H38.33V44.64a5.68,5.68,0,0,1-4.4,5.74,6.53,6.53,0,0,1-1.46.16H13.21a5.71,5.71,0,0,1-5.86-5.86c0-1,0-2,0-3.1"
        />
        <path
          class="s5"
          d="M35.06,9.76H33.44V6.87c0-1.38-.6-2-2-2H30.17c0,.31,0,.57,0,.84a2.43,2.43,0,0,1-2.44,2.42H18A2.44,2.44,0,0,1,15.5,5.68V4.94a15.08,15.08,0,0,0-2,0,1.53,1.53,0,0,0-1.24,1.49c0,.2,0,.41,0,.61V35.85H10.61V6.79a3.28,3.28,0,0,1,3.53-3.53h1.93c.79,0,1.06.28,1.06,1.09V5.57c0,.64.3.95.93.95q4.79,0,9.58,0a.82.82,0,0,0,.9-.92c0-.46,0-.92,0-1.38,0-.64.3-.95.92-.95.82,0,1.63,0,2.45,0a3.21,3.21,0,0,1,3.13,3c.05,1.13,0,2.27,0,3.46"
        />
        <path
          class="s5"
          d="M14.69,26.1a8.15,8.15,0,1,0,8.22-8.16,8.16,8.16,0,0,0-8.22,8.16m-1.63-.05a9.78,9.78,0,1,1,9.84,9.82,9.79,9.79,0,0,1-9.84-9.82"
        />
        <path
          class="s5"
          d="M10.61,42.42h1.63c0,.51,0,1,0,1.52A1.63,1.63,0,0,0,14,45.65H31.7a1.64,1.64,0,0,0,1.73-1.86q0-13.36,0-26.74v-.7h1.63V43.77a3.28,3.28,0,0,1-3.54,3.52H14.12a3.27,3.27,0,0,1-3.51-3.5V42.42"
        />
        <path class="s5" d="M26.88,4.89H18.79V3.31h8.09Z" />
        <rect class="s5" x="23.69" y="10.64" width="5.64" height="1.57" />
        <rect class="s5" x="16.35" y="36.71" width="5.65" height="1.57" />
        <rect class="s5" x="26.94" y="13.89" width="2.39" height="1.57" />
        <rect class="s5" x="16.34" y="39.99" width="2.38" height="1.57" />
        <path
          class="s5"
          d="M22,18.76h1.63c0,.43,0,.85,0,1.26a.53.53,0,0,0,.41.63,3.11,3.11,0,0,1,2,3H24.51c0-.13-.07-.27-.11-.41a1.63,1.63,0,1,0-1.62,2.06A3.29,3.29,0,0,1,25.3,26.4a3.24,3.24,0,0,1-1.22,5.13l-.41.17v1.69H22V31.72a3.37,3.37,0,0,1-2.47-3.15h1.6c0,.13.07.28.11.42A1.62,1.62,0,1,0,23,26.91a6.63,6.63,0,0,1-1.1-.16,3.25,3.25,0,0,1-2.29-2.95,3.18,3.18,0,0,1,2-3.13A.6.6,0,0,0,22,20c0-.38,0-.77,0-1.2"
        />
      </svg>
    </div>
  );
}
