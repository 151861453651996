import React from "react";

function Live() {
  React.useEffect(() => {
    window.location = `https://live.qa.finnovex.com`
  },[]);
 
  return null;
}

export default Live;
